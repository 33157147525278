import React, { useState, Fragment, useEffect} from "react";
import axios from 'axios';
import { Form, DatePicker, Button, Table, Spin, Tag, Select, Modal, Empty} from "antd";
import appEnvirontVariables from "../../../core_units/config/env-variables";
import * as SecureLS from "secure-ls";
import { connect } from "react-redux";
import _ from 'lodash';
import "./style.css";
function AeForm(props) {
    var ls = new SecureLS({ encodingType: 'aes' });
    const token = ls.get('isLogin')
    const [aeList, setAeList] = useState();
    const [loading, setLoading] = useState(false);
    console.log(props,"props==")
    useEffect(() => {
        AeDetails();
      }, []);
  
      const AeDetails = () => {
        setLoading(true);
        axios.get(`${appEnvirontVariables.API.BASE_URL}/api/course/aeform/feedback?studentId=${props.students.viewprofile.studentId}`, {
          headers: {
            Authorization: token["authorization"]
          },
        })
        .then(response => {
          setAeList(response.data[0]);
          console.log(response.data[0], "data==");
          setLoading(false);
        })
        .catch(error => {
          // Handle the error
        });
      };
  return (
    <div>
    {loading ? ( 
      <Spin 
      size="large"
      tip="Loading..."
      style={{ textAlign: "center" }}
      />
    ) : aeList ? ( 
      <div>
        <div className="ae-form-title">
          Assessment by : {aeList?.teacherName}
          <div className="ae-form-wrapper">
            {aeList?.feedbacks
              ?.filter((item) => item.question !== "courseId")
              .map((item, index) => {
                const { question, option } = item;
                return (
                  <div key={index} className="ae-form-row">
                    <p className="ae-form-question">
                      {index + 1}. {question}
                    </p>
                    <p className="ae-form-answer">{option} {question == "Preferred Day & Timing?" ? (props?.students?.viewprofile?.timezone ? " | TimeZone - " + props?.students?.viewprofile?.timezone : "") : ""}</p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    ) : (
     
      <Empty description="No Data Available" />
    )}
  </div>
  )
}

const mapStateToProps = state => (
    {
        students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),

    });

const mapDispatchToProps = dispatch => ({
  
    getkysData: (details) =>
    dispatch(global.redux.action.students.getkysData(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AeForm);










