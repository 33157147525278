import httpStatusCodes from './http-status-codes';
import notification from './notification';
import history from './history';
import localTimeZone from './dayjs';

import logs from './clear-console-war';
export default {
    httpStatusCodes,
    notification,
    history,
    localTimeZone,
    logs
};