import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Modal, message,  Switch, notification, Tag } from 'antd';
import { useSearchParams, useLocation } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { connect } from "react-redux";
import _ from "lodash";
const { confirm } = Modal;

const Teacheraction = (props) => {
  const [teacherId] = useSearchParams();
const teacherdetails  = useLocation();
const [status, setstatus] = useState();
  useEffect(()=>{
    initapicall()
  },[]);

  const initapicall =() =>{
    props.checkteacherstatus({
      params:{
        teacherId:teacherId.get("id")
      },
      callback:((req, data )=>{
        setstatus(_.isEqual(_.get(data,'teacherDisabled'),"true")?false:true)
      })
    })
  }
const onConfirm= (e) => {
    setstatus(e);
    let params = {
      teacherid: teacherId.get("id"),
    }
    if(e==true){
      params.status = false
    }else{
      params.status = true

    }
    confirm({
      title: `Do you Want do  ${e==true?'active': 'inactive'} this teacher ?`,
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        props.teacherstatus({
          params:params,
          callback: (res, data) => {
            if (_.isEqual(res, true)) {
              initapicall()
              notification.success({
                message: 'Teacher status changed successfully.',
              });
            } else {
          //     console.log('else', _.get(props,'students.studenttype'),_.get(props,'students.studenttypeLoadingInProgress'), _.isEmpty(_.get(props,'students.studenttype')));
          //     if(_.isEqual(_.get(props,'students.studenttypeLoadingInProgress'),false) &&_.isEmpty(_.get(props,'students.studenttype'))) {
          //       console.log('else if');
          //       notification.error({
          //         message: 'Something went wrong',
          //       });
          //   }else{  
          //      notification.success({
          //     message: 'Activated course plan',
          // }); 
         
                
          //   }
            notification.error({
              message: 'Something went wrong',
            });
            }
          }
        })
      },
      onCancel() {
        initapicall()

        // setstatus(!_.get(data,'teacherDisabled'))
      },
    });
  }
  const render = () => {
    return (
      <div className='col-lg-4' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>

        <div className='mr-2'>
        <Switch checked={status} onChange={onConfirm} />
                  </div>
       
      </div>
    );
  }
  return render();
}

const mapStateToProps = (state) => (
  {
    teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
  }
);

const mapDispatchToProps = (dispatch) => ({
  teacherstatus: (details) =>
    dispatch(global.redux.action.teachers.teacherstatus(details)),
    checkteacherstatus: (details) =>
    dispatch(global.redux.action.teachers.checkteacherstatus(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Teacheraction);