import React, { useState, useEffect } from "react";
import {
  Form,
  DatePicker,
  Button,
  Table,
  Spin,
  Tag,
  Select,
  Modal,
  Tooltip,
} from "antd";
import _ from "lodash";
import axios from 'axios';
import { connect } from "react-redux";
import "./style.css";
import appEnvirontVariables from "../../../core_units/config/env-variables";
import * as SecureLS from "secure-ls";
function CategoryWiseAeSlots() {
  const { RangePicker } = DatePicker;
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const [form] = Form.useForm();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (startDate && endDate) {
      fetchAPIResponse(startDate, endDate);
    }
  }, [startDate, endDate]);
  const fetchAPIResponse = (selectedDate) => {
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/ae/slots?date=${selectedDate}`;
    setLoading(true);
    setData([]);
    axios.get(apiUrl, {
      headers: {
        Authorization: token["authorization"]
      }
    })
   
    .then(response => {
      const responseData = response.data;
        if (responseData && responseData.length > 0) {
          const formattedData = responseData.reduce((acc, item) => {
            const teachers = item.teacherList.map((teacher) => {
              console.log(teacher.slotDetails,"9999")
              const startTimeTags = teacher.slotDetails && teacher.slotDetails.length > 0
              ? teacher.slotDetails.reduce((slots, slot) => {
                  const startTime = slot.startTime;
                  slots[startTime] = {
                    startTime,
                    isInternationalSlot: slot.isInternationalSlot,
                  };
                  return slots;
                }, {})
              : { "-": { startTime: "-", isInternationalSlot: false } };


              return {
                courseCategory: item.courseCategory,
                teacherName: teacher.teacherName || "-",
                mobileNumber: teacher.teacherContact || "-",
                ...startTimeTags,
              };
            });
  
            if (teachers.length === 0) {
              return [
                ...acc,
                {
                  courseCategory: item.courseCategory,
                  teacherName: "-",
                  startTime: "-",
                },
              ];
            }
  
            return [...acc, ...teachers];
          }, []);
          setData(formattedData);
          console.log(formattedData,"formattedData====")
        }
        setLoading(false);
        // form.resetFields();
      })
    
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    
  };
 
  const renderCell = (value) => {
    console.log(value,"value")
    if (!value || typeof value.isInternationalSlot === 'undefined') {
      return null; 
    }
    const cellStyle = {
      backgroundColor: value.isInternationalSlot ? "violet" : "lightsalmon",
      padding: "5px",
      color: "white" 
    };
  
    return {
      props: {
        style: cellStyle,
      },
      children: (
        <div style={{ maxWidth: "100%", overflow: "visible" }}>
          {value.startTime}
        </div>
      ),
    };
  };
  

  const columns = [
    {
      title: "Course Category",
      dataIndex: "courseCategory",
      key: "courseCategory",
      align: "center",
    },
    {
      title: "Teacher Name",
      dataIndex: "teacherName",
      key: "teacherName",
      align: "center",
    },
    
    
    {
      title: "05:00:00",
      dataIndex: "05:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "05:30:00",
      dataIndex: "05:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "06:00:00",
      dataIndex: "06:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "06:30:00",
      dataIndex: "06:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "07:00:00",
      dataIndex: "07:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "07:30:00",
      dataIndex: "07:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "08:00:00",
      dataIndex: "08:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "08:30:00",
      dataIndex: "08:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "09:00:00",
      dataIndex: "09:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "09:30:00",
      dataIndex: "09:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "10:00:00",
      dataIndex: "10:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "10:30:00",
      dataIndex: "10:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "11:00:00",
      dataIndex: "11:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "11:30:00",
      dataIndex: "11:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "12:00:00",
      dataIndex: "12:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "12:30:00",
      dataIndex: "12:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "13:00:00",
      dataIndex: "13:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "13:30:00",
      dataIndex: "13:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "14:00:00",
      dataIndex: "14:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "14:30:00",
      dataIndex: "14:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "15:00:00",
      dataIndex: "15:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "15:30:00",
      dataIndex: "15:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "16:00:00",
      dataIndex: "16:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "16:30:00",
      dataIndex: "16:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "17:00:00",
      dataIndex: "17:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "17:30:00",
      dataIndex: "17:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "18:00:00",
      dataIndex: "18:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "18:30:00",
      dataIndex: "18:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "19:00:00",
      dataIndex: "19:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "19:30:00",
      dataIndex: "19:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "20:00:00",
      dataIndex: "20:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "20:30:00",
      dataIndex: "20:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "21:00:00",
      dataIndex: "21:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "21:30:00",
      dataIndex: "21:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "22:00:00",
      dataIndex: "22:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "22:30:00",
      dataIndex: "22:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "23:00:00",
      dataIndex: "23:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "23:30:00",
      dataIndex: "23:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "00:00:00",
      dataIndex: "00:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
   
    },
    {
      title: "00:30:00",
      dataIndex: "00:30:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    {
      title: "01:00:00",
      dataIndex: "01:00:00",
      key: "startTime",
      align: "center",
      render: renderCell,
    },
    
    
    
    
  ];

  const onFinish = (values) => {
    const selectedDate = values.fromDate.format("YYYY-MM-DD");
    setLoading(true);
    fetchAPIResponse(selectedDate);
  };
  const exportToExcel = () => {
    if (!Array.isArray(data) || data.length === 0) {
        console.log("No data to export");
        return;
    }

    // Hardcoded headers
    const headers = [
        'Course Category',
        'Teacher Name',
        '05:00:00',
        '05:30:00',
        '06:00:00',
        '06:30:00',
        '07:00:00',
        '07:30:00',
        '08:00:00',
        '08:30:00',
        '09:00:00',
        '09:30:00',
        '10:00:00',
        '10:30:00',
        '11:00:00',
        '11:30:00',
        '12:00:00',
        '12:30:00',
        '13:00:00',
        '13:30:00',
        '14:00:00',
        '14:30:00',
        '15:00:00',
        '15:30:00',
        '16:00:00',
        '16:30:00',
        '17:00:00',
        '17:30:00',
        '18:00:00',
        '18:30:00',
        '19:00:00',
        '19:30:00',
        '20:00:00',
        '20:30:00',
        '21:00:00',
        '21:30:00',
        '22:00:00',
        '22:30:00',
        '23:00:00',
        '23:30:00',
        '00:00:00',
        '00:30:00',
        '01:00:00'
    ];

    let csvContent = "data:text/csv;charset=utf-8," + headers.join(",") + "\n";
    csvContent += data.map(item => {
        return headers.map(header => {
            if (header === 'Course Category') {
                return item.courseCategory || '';
            } else if (header === 'Teacher Name') {
                return item.teacherName || '';
            } else {
               
                return item[header] && item[header].startTime ? item[header].startTime : '';
            }
        }).join(",");
    }).join("\n");

    console.log(csvContent, "csvContent==");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "CategoryWiseAeSlots.csv");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};









  return (
    <div>
    <div className="" style={{width:"100%"}}>
      <span>CategoryWise AE Details</span>
      <Form form={form} onFinish={onFinish}>
        <div className="" style={{ maxWidth: "100%" }}>
          <Form.Item
            label="Date"
            name="fromDate"
            rules={[
              {
                required: true,
                message: "Please input your date range!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
          
        </div>
      </Form>
      {!data.length > 0 &&
      <div style={{ display: "flex", justifyContent: "center" }}>
                  No data found
                </div>
}
{
  loading && 
  <div style={{ textAlign: "center" }}>
              <Spin
                size="large"
                tip="Loading..."
                style={{ textAlign: "center" }}
              />
            </div>
}
{data.length > 0 && 
<div style={{ marginBottom: "16px" }}>
        <Tooltip title="International Slot" color="violet">
          <Tag color="violet">International Slot</Tag>
        </Tooltip>
        <Tooltip title="Domestic Slot" color="lightsalmon">
          <Tag color="lightsalmon">Domestic Slot</Tag>
        </Tooltip>
      </div>
}
<Button onClick={exportToExcel} style={{ marginTop: '20px', marginBottom:"20px" }}>Export to CSV</Button>
    </div>
    <div className="" style={{width:"fit-content"}}>
    {loading ? (
            <div style={{ textAlign: "center" }}>
              {/* <Spin
                size="large"
                tip="Loading..."
                style={{ textAlign: "center" }}
              /> */}
            </div>
          ) : (
            <>
              {data.length > 0 ? (
                
                <Table columns={columns} dataSource={data} pagination={false} style={{borderSpacing:"none"}} size="small"/>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                 
                </div>
              )}
            </>
          )}
         { console.log(data,"data==")}
          </div>
    </div>
  );
}

export default CategoryWiseAeSlots;
