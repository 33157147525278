import React, {useState, useEffect} from 'react';
import './styles.css';
import appEnvirontVariables from "../../../core_units/config/env-variables";
import { Button, Select, Table, Form, Input, InputNumber, notification } from 'antd';
import * as SecureLS from "secure-ls";
import _ from 'lodash';
import axios from 'axios';

const FreeCourse = (props) => {
    let ls = new SecureLS({ encodingType: 'aes' });
    const loginDetails = ls.get('isLogin');
    const [errorMessage, setErrorMessage] = useState('');
    const [courseList, setCourseList] = useState([]);
    const [teacherList, setTeacherList] = useState([]);
    const [configData, setConfigData] = useState([]);
    const [priceDetail, setPriceDetail] = useState([]);
    const [accountDetail, setAccountDetail] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState();
    const [selectedTeacher, setSelectedTeacher] = useState();
    const [noOfClass, setNoOfClass] = useState();
    const [isLoading, setIsLoading] = useState(false);
  
    useEffect(() => {
        getAllCousreList().then(data => {
            const cList = [];
            data.courses.forEach(element => {
                element.tags.forEach(ele => {
                  ele.groupId = element.id;
                  ele.courseName = `${element.name} - ${ele.name}`
                  cList.push(ele);
                });
              });
            setCourseList(cList);
            setConfigData(data);
        })
    },[])
    const getAllCousreList = async () => {
        const base = process.env.REACT_APP_ENV === 'production' ?
          'https://api.artiumacademy.com/config/prod/paymentconfig.json' :
          'https://api.artiumacademy.com/config/prod/paymentconfig.json'
        const response = await fetch(base);
        console.log(base);
        return await response.json();
    }
    const getArtiumAccountDetail = (courseId) => {
        return axios.get(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/account-detail/${courseId}`,
          { headers: { "Authorization": props.profile.token['authorization'] } });
    }
    const getCoursePrice = async (data) => {
        let courseId = encodeURIComponent(data.courseId)
        let ccObject = configData.timezoneWithCountryName[props.profile.timezone];
        let countryCode = encodeURIComponent(ccObject.code);
        let countryName = encodeURIComponent(ccObject.country.toLowerCase());
        let result = await axios
        .get(appEnvirontVariables.API.COURSE_PRICE_URL + "/api/courseDetails/getPrice" + `?courseId=${courseId}&countryCode=${countryCode}&countryName=${countryName}`);
        return result
    }
    const getAllTeacherList = (courseId) => {
        return axios.post(`${appEnvirontVariables.API.BASE_URL}/api/admin/teachers?courseId=${courseId}`,
          { page: 0, size: 500, courseId: courseId },
          { headers: { "Authorization": props.profile.token['authorization'] } });
    }
    const freeCourseAPI = (data) => {
      return axios.post(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/free-course`,
        data,
        { headers: { "Authorization": props.profile.token['authorization'] } });
    }
    const courseChange = async (event) => {
      setErrorMessage('');
        let index = (event.target.value || event.currentTarget.value);
        if (index != "") {
          let teacherlist = await getAllTeacherList(courseList[index].paidCourseId);
          if(teacherlist?.data?.content){
            teacherlist.data.content = teacherlist.data.content.filter(e => (e.workingStatus == "ACTIVE" && e.teacherDisabled == "false" && e.enableForPaid == "true" && e.isAcademicExpert == "false"));
          }
          setTeacherList(teacherlist?.data?.content || []);
          setSelectedCourse(courseList[index]);
          let coursePriceData = await getCoursePrice({ courseId: courseList[index].paidCourseId, countryCode: props.profile?.countryCode });
          setPriceDetail(coursePriceData);
          let accountDetailResponse = await getArtiumAccountDetail(courseList[index].paidCourseId);
          setAccountDetail(accountDetailResponse?.data?.data);
        }
    } 
    const teacherChange = async (event) => {
      setErrorMessage('');
      let teacherID = (event.target.value || event.currentTarget.value);
      if (teacherID != "") {
        setSelectedTeacher(teacherID);
      }
   } 

   const noOfClassChange = event => {
    setErrorMessage('');
    const value = event.target.value;
    if (/^\d*$/.test(value)) { // Only allow numeric input
      setNoOfClass(value);
    }
  };

   const createCourse = async () => {
      if (!selectedCourse || !selectedTeacher || !noOfClass) {
        setErrorMessage("Please fill all required fields.");
        return;
      }
      const confirmCreate = window.confirm("Are you sure you want to create free class?");
      if (!confirmCreate) {
        return;
      }
      setIsLoading(true);
      let dt = new Date();
      let d = dt.getDate();
      let m = dt.getMonth() + 1;
      let y = dt.getFullYear();
      let course = {};
      course.courseId = selectedCourse.paidCourseId;
      course.courseName = selectedCourse.courseName;
      course.discountedPercentage = 0;
      course.classesToPurchase = +noOfClass;
      course.unit_price = priceDetail?.data?.price;
      course.account_id_prod = accountDetail.account_id;
      course.account_id_uat = accountDetail.account_id;
      course.item_id_prod = accountDetail.item_id_free;
      course.item_id_uat = accountDetail.item_id_free;
      course.level = accountDetail.level;
      course.teacherId = selectedTeacher;
      let data = {
        "studentId": props.profile.studentId,
        "userId": props.profile.userId,
        "totalPrice": 0,
        "firstName": _.startCase(_.get(props.profile, 'firstName', 'No-name')),
        "name": _.startCase(_.get(props.profile, 'firstName', 'No-name')) + ' ' + _.startCase(_.get(props.profile, 'lastName', '')),
        "contact": _.get(props.profile, 'mobileNumber'),
        "email": _.get(props.profile, 'emailId'),
        "countryCode": _.get(props.profile, 'countryCode'),
        "currency": priceDetail?.data?.currency,
        "customer_id": (_.get(props.profile, 'zohoSubscriptionCustomerId') || ""),
        "place_of_supply": "",
        "token": _.get(props.profile.token, 'authorization'),
        "artiumCashPoint":  0,
        "myCashPoint":  0,
        "artiumCash":  0,
        "myCash": 0,
        "conversionRate": 0,
        "referralCode": "",
        "version": "2.0",
        "paymentFor": "directpayment",
        "isFreeClass":true,
        "createdBy": loginDetails?.studentName || "",
        "gender": _.get(props.profile, 'gender'),
        "ageGroup": _.get(props.profile, 'ageGroup'),
        "studentType": _.get(props.profile, 'studentType'),
        "userType": _.get(props.profile, 'userType'),
        "state": _.get(props.profile, 'state'),
        "invoice_date": (y + '-' + (m.toString().length < 2 ? '0' + m : m) + '-' + (d.toString().length < 2 ? '0' + d : d)),
        "courseList": [course]
      }
      console.log(data);
      freeCourseAPI(data).then(r => {
        setIsLoading(false);
        if (r?.data?.success) {
          setSelectedCourse(null);
          setSelectedTeacher('');
          setNoOfClass('');
          props.closeModal();
          notification.success({
            message: "Free class added successfully",
          });
        }
        setTimeout(() => {
          window.location.reload();
        },500);
      },(err) => {
        props.closeModal();
        setTimeout(() => {
          window.location.reload();
        },500);
      });
   }
   
    return (<div>
        <label className="form-label">Select Course <span style={{ color: 'red'}}>*</span></label>
        <select className="form-control" style={{ width: '300px', appearance: 'auto' }}
                          dropdownClassName='course-list-dropdown'
                          placeholder='Select Course'
                          id="course-list"
                          onChange={courseChange}
                        >
                          <option value="">Select Course</option>
                          {courseList.length && courseList.map((course, index) => {
                            return <option value={index} >{course['courseName']}</option>
                          })}
                        </select>
        <label className="form-label">Select Teacher <span style={{ color: 'red'}}>*</span></label>
        <select className="form-control" style={{ width: '300px', appearance: 'auto' }}
                          dropdownClassName='course-list-dropdown'
                          placeholder='Select Teacher'
                          id="teacher-list"
                          onChange={teacherChange}
                        >
                          <option value="">Select Teacher</option>
                          {teacherList.length && teacherList.map((teacher, index) => {
                            return <option value={teacher['teacherId']} >{teacher['teacherName']}</option>
                          })}
                        </select>
        <label className="form-label mt-2">Number of Classes <span style={{ color: 'red'}}>*</span></label>
        <input
        style={{ width: '300px', appearance: 'auto' }}
        type="number" // Change input type to number
        name="pricePerClass"
        placeholder='Enter No of Class'
        onChange={noOfClassChange}
        value={noOfClass}
        className="form-control"
      />
      <div style={{ color: 'red'}}>{errorMessage}</div>
      <button
          onClick={createCourse}
          className="form-button mt-3 btn btn-primary"
          disabled={isLoading}
        >
          {isLoading ? 'Creating...' : 'Create Free Class'}
        </button> 
    </div>)

}
export default FreeCourse;