import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, notification } from 'antd';
import './login.css'
import logo from '../../../assets/img/ArtiumAcademyLogo.svg';
import appEnvirontVariables from "../../../core_units/config/env-variables";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as SecureLS from "secure-ls";
import _ from 'lodash';
import { ArrowLeftOutlined } from "@ant-design/icons";
const ResetPassword = (props) => {
	console.log(props,"props")
	let history = useNavigate();
	
	
	var ls = new SecureLS({ encodingType: 'aes' });
	const token = ls.get("isLogin");
	console.log(token,"loginDetails")
	const [confirmPasswordError, setConfirmPasswordError] = useState('');

	const validateConfirmPassword = (_, value) => {
		const newPassword = value.newPassword;
		if (newPassword && newPassword !== value.confirmPassword) {
		  setConfirmPasswordError('Confirm password is not the same as New password.');
	
		  notification.error({
			message: 'Validation Error',
			description: 'Confirm password is not the same as New password.',
		  });
	
		  return Promise.reject('Confirm password is not the same as New password.');
		} else {
		  setConfirmPasswordError('');
		  return Promise.resolve();
		}
	  };
	//   const handlePasswordKeyPress = (e) => {
	// 	if (e.target.value.length >= 6) {
	// 	  e.preventDefault();
	// 	}
	//   };

	  const onFinish = async (values) => {
		try {
		  await validateConfirmPassword(null, values);
	
		  const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/auth/user/changepassword`;
		  const requestBody = {
			userId: token.credentialId,
			oldPassword: values.oldPassword,
			newPassword: values.newPassword,
			confirmPassword: values.confirmPassword
		  };
	
		  const response = await axios.post(apiUrl, requestBody, {
			headers: {
			  Authorization: token["authorization"],
			},
		  });
	
		  console.log('API Response:', response.data);
	
		  notification.success({
			message: 'Password changed successfully.',
		  });
	
		  history('/login');
		} catch (error) {
		  console.error('Error changing password:', error);
		}
	  };
const handleBack = () => {
	history('/teachers')
}
  return (
    <div>
      	
						<div className="d-flex flex-column flex-root login-page-container">
			<div className="d-flex flex-column flex-column-fluid login-bg-container">
				<div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
					<a href="#" className="mb-12">
						<img src={logo} alt="Logo" className="h-40px" />
					</a>
				<div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
				<ArrowLeftOutlined onClick={handleBack}/>
						<div className="form w-100" id="kt_sign_in_form" >
							<div className="text-center mb-4">
								<div className="text-dark mb-2 h1"></div>
							</div>
							<Form
								layout="vertical"
								autoComplete="off"
								onFinish={onFinish}
							>
								
									<Form.Item
									label="Old Password"
									name="oldPassword"
									rules={[{ required: true, message: 'Please input your Old Password!' }]}
								>
									<Input.Password placeholder="Enter your Old Password" />
								</Form.Item>
								<Form.Item
									label="New Password"
									name="newPassword"
									rules={[
										{ required: true, message: 'Please input your New Password!' },
										{ min: 6, message: 'Password must be at least 6 characters long' }, 
									  ]}
								>
									<Input.Password placeholder="New Password should be at least 6 characters long" />
								</Form.Item>

								<Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    dependencies={['newPassword']}
                    rules={[
                      { required: true},
					  { validator: validateConfirmPassword },
					  { min: 6, message: 'Password must be at least 6 characters long' }, 
                    ]}
                  >
                    <Input.Password placeholder="Confirm Password should be at least 6 characters long"/>
                  
                  </Form.Item>

								<Form.Item>
									<Button type="primary" htmlType="submit">
										Submit
									</Button>
								</Form.Item>
							</Form>
						</div>
					</div> 
				</div>
			</div>
		</div>
					
    </div>
  )
}

export default ResetPassword
