import React, { useState, useEffect } from "react";
import { Form, DatePicker, Button, Select, Modal, Empty , Input,Radio,notification,  Spin} from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import "./style.css";
import axios from 'axios';
import * as SecureLS from "secure-ls";
import { Link, useNavigate } from "react-router-dom";
import appEnvirontVariables from "../../core_units/config/env-variables";

import { constant } from "../../core_units/constant";

const { Option } = Select;
const RegForm = (props) => {
    var ls = new SecureLS({ encodingType: "aes" });
    const token = ls.get("isLogin");
    const [form] = Form.useForm();

    const mixpanel = require('mixpanel-browser');
    const loginDetails = ls.get('isLogin');

    const navigate = useNavigate();
    const [courseList, setCourseList] = useState();
    const [studentName, setStudentName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [emailId, setEmailId] = useState("");
    const [genderId, setGenderId] = useState("");
    const [ageGroup, setAgeGroup] = useState("");
    const [tagId, setTagId] = useState("");
    const [course, setCourse] = useState("");
    console.log(course,"989098")
    const [source,setSource] = useState("");

    const [customSource, setCustomSource] = useState("");

    const [isFormValid, setIsFormValid] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        configjson();
      }, []);

      const configjson = () => {
        props.courseList({
          callback: (res, data) => {
            if (_.isEqual(res, true)) { 
              console.log("courselist", data);
              setCourseList(data);
            }
          },
        });
      };
      const handleCourseChange = (selectedCourseId) => {
        const selectedCourse = _.flatMap(
          _.get(courseList, "offlineCentres"),
          "courses"
        ).find((course) => course.demoCourseId === selectedCourseId);
      
        if (selectedCourse) {
          const { demoCourseId, tagId } = selectedCourse;
          setCourse(demoCourseId); 
          setTagId(tagId); 
          console.log("Selected Course:", selectedCourse);
        } else {
          console.log("Selected course not found");
        }
      };
      useEffect(() => {
        setIsFormValid(
            studentName !== "" &&
            mobileNumber !== "" &&
            emailId !== "" &&
            genderId !== "" &&
            ageGroup !== "" &&
            tagId !== "" &&
            course !== "" &&
            (source !== "Others" || customSource !== "")
        );
    }, [studentName, mobileNumber, emailId, genderId, ageGroup, tagId, course, source,customSource]);


      const handleSubmit = async () => {
        try {
          setLoading(true);
            const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/users/addStudent/offline`;
            const requestBody = {
                firstName: studentName,
                mobileNumber: mobileNumber,
                emailId: emailId,
                genderId: genderId,
                ageGroupId: ageGroup,
                utmSource: source === "Others" ? customSource : source,
                interestId: Number(tagId),
                offlineCourseId: Number(course),
                preferredlanguage: 1,
                preferredTimezone: "Asia/Kolkata",
                timezone: "Asia/Kolkata",
                countryCode: "+91",
                centerId: 1
            };
    
            axios
                .post(apiUrl, requestBody, {
                    headers: {
                        Authorization: token["authorization"],
                    },
                })
                .then((response) => {
                    console.log(response, "res====");
                    if (response.status === 200) {
                        notification.success({
                            message: "Registration Form added Successfully.",
                        });
                        form.resetFields();
                        setTagId("");
                        setCourse("");

                        setCustomSource("");
                        setSource("");
                        setStudentName("");
                        setLoading(false);
                        mixpanel.track(constant.MIXPANEL_OFFLINE_STUDENT_REGISTRATION, {
                          LoginUser: loginDetails?.studentName,
                          firstName: studentName,
                mobileNumber: mobileNumber,
                emailId: emailId,
                genderId: genderId,
                ageGroupId: ageGroup,
                utmSource: source === "Others" ? customSource : source,
                interestId: Number(tagId),
                offlineCourseId: Number(course),
                preferredlanguage: 1,
                preferredTimezone: "Asia/Kolkata",
                timezone: "Asia/Kolkata",
                countryCode: "+91",
                centerId: 1
                        });
                    } else {
                        notification.error({
                            message: "Something went wrong",
                        });
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    notification.error({
                        message: "student is already mapped with this course for this center",
                        
                    });
                    setLoading(false);
                });
        } catch (error) {
            console.log("Validation Error:", error);
            setLoading(false);
            form.setFields(error.errorFields);
        }
    };
    
    

  return (
    <div>
           <div className="report-card">
    {/* <div>
    <Button
         type="primary" htmlType="submit"
          onClick={() => navigate("/BatchCreationOffline")}
        >
          Back
        </Button>
    </div> */}
    <div style={{fontWeight: 800, display:"flex", justifyContent:"center"}}>Registration Form</div>
   
 
    <div style={{marginTop:"25px"}}>
        <Form form={form}>
            <Form.Item
            label="Learners Name"
            name="firstName"
             rules={[{ required: true }]}
            > <Input style={{width:"50%"}}  value={studentName}
            onChange={(e) => setStudentName(e.target.value)}></Input>
            </Form.Item>
            <Form.Item
               label="Mobile Number"
               name="mobileNumber"
               rules={[{ required: true }]}
            >
            <Input style={{width:"50%"}}  value={mobileNumber}
                                onChange={(e) => setMobileNumber(e.target.value)}
                                onKeyPress={(e) =>
                                  !/[0-9]/.test(e.key) && e.preventDefault()
                                }
                                maxLength={10}
                                ></Input>
            </Form.Item>
            <Form.Item
               label="Email"
               name="emailId"
               rules={[{ required: true }]}
            >
            <Input style={{width:"50%"}}  value={emailId}
                                onChange={(e) => setEmailId(e.target.value)}></Input>
            </Form.Item>
            <Form.Item
                          label="Gender"
                          name={"genderId"}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group
                            style={{ width: "50%" }}
                            placeholder="Select Gender"
                            allowClear
                            onChange={(e) => setGenderId(e.target.value)}
                            value={genderId}
                          >
                            <Radio value="1">Male</Radio>
                            <Radio value="2">Female</Radio>
                            <Radio value="3">Other</Radio>
                          </Radio.Group>
                        </Form.Item>

                        <Form.Item
                        label="Age Group"
                          name={"ageGroupId"}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group placeholder="Select Agegroup" style={{width:"50%"}}  allowClear
                           onChange={(e) => setAgeGroup(e.target.value)}
                           value={ageGroup}
                          >
                            {_.map(
                              _.get(courseList, "ageGroups"),
                              (agegroups, each) => {
                                console.log("agegroups", agegroups);
                                return (
                                  <Radio
                                    value={_.get(agegroups, "id")}
                                    key={each}
                                  >
                                    {_.get(agegroups, "name")}
                                  </Radio>
                                );
                              }
                            )}
                          </Radio.Group>
                        </Form.Item>
                      
                        <Form.Item label="Course" name="offlineCourseId" rules={[{ required: true }]}>
                        { console.log(course,"value====")}
  <Radio.Group style={{ width: "100%" }} placeholder="Select Course"  allowClear
    onChange={(e) => handleCourseChange(e.target.value)}
   value={course}
  >
    {_.map(
                              _.get(courseList, "offlineCentres"),
                              (offlineCentres, each)=> (
                                offlineCentres.courses.map((course) => (
          <Radio key={course.demoCourseId} value={course.demoCourseId}>
            {course.name}
          </Radio>
        ))
      ))}
  </Radio.Group>
</Form.Item>
<Form.Item
                          label="Source"
                          name={"utmSource"}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group
                            style={{ width: "50%" }}
                            placeholder="Select Source"
                            allowClear
                            onChange={(e) => setSource(e.target.value)}
                            value={source}
                          >
                            <Radio value="InBound Call">InBound Call</Radio>
                            <Radio value="WalkIn">WalkIn</Radio>
                            <Radio value="Reference">Reference</Radio>
                            <Radio value="JustDial">Just Dial</Radio>
                            <Radio value="Others">Others</Radio>
                          </Radio.Group>
                          {source === "Others" && (
             
                <Input
                  style={{ width: "50%" }}
                  value={customSource}
                  onChange={(e) => setCustomSource(e.target.value)}
                />
            
            )}

                        </Form.Item>

            <Form.Item style={{display:"flex", float:"right"}}>
<Button type="primary" onClick={handleSubmit} disabled={!isFormValid}>Submit</Button>
            </Form.Item>

        </Form>
    </div>
  
  
     {loading && (
                <div className="loader-container" style={{display:"flex", justifyContent:"center",marginTop:"-28%"}}>
                    <Spin size="large" />
                </div>
            )}
    </div>
    </div>
  )
}


const mapStateToProps = (state) => ({
    teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
    });
    
    const mapDispatchToProps = (dispatch) => ({
    courseList: (details) =>
      dispatch(global.redux.action.students.courseList(details)),
      list: details => dispatch(global.redux.action.teachers.list(details))
    });
    
    export default connect(mapStateToProps, mapDispatchToProps)(RegForm);
