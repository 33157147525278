import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Upload, message, InputNumber, Select, notification, Tag } from 'antd';
import './style.css'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { useSearchParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import axios from 'axios';
import dayjs from 'dayjs';
import { Link, useNavigate } from "react-router-dom";
import StudentType from './typechange';
import * as SecureLS from "secure-ls";
import appEnvirontVariables from "../../../core_units/config/env-variables";
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const StudentProfilePage = (props) => {

    const { handleClickCourse = f => f } = props;
    var ls = new SecureLS({ encodingType: 'aes' });
    const token = ls.get('isLogin')
    let history = useNavigate();
    const [form] = Form.useForm();
    const [studentId] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [submitdisable, setSubmitdisable] = useState(true);
    const [courseList, setCourseList] = useState();
    const [countrycode, setcoundtrycode] = useState();
    const [selectedlanguage, setselectedlanguage] = useState([]);

    useEffect(() => {
        formfields(_.get(props, 'students.viewprofile'))
        configjson();
    }, [_.get(props, 'students.viewprofile.data')]);

    const apicall = (query = {}) => {
        props.view({
            params: {
                studentId: studentId.get("credid"),
            },
            callback: (response, data) => {
                formfields(data)
            },
        });
    };
    const classcountcall = () => {
        props.classcount({
            params: {
                studentId: studentId.get("id"),
            },
            callback: (response, data) => {
                console.log('student course');
            },
        });
    }
    const configjson = () => {
        props.courseList({
            callback: (res, data) => {
                if (_.isEqual(res, true)) {
                    setCourseList(data);
                }
            }
        })
    }

    const formfields = (data) => {
        const profile = data;
        _.map(_.get(profile, 'languageId'), (lang, i) => {
            let arr = [];
            arr.push(_.get(lang, 'name'))
            form.setFieldsValue({
                languageId: arr
            })
        })
        form.setFieldsValue({
            firstName: _.get(profile, 'firstName'),

            lastName: _.get(profile, 'lastName'),
            emailId: _.get(profile, 'emailId'),
            mobileNumber: `${_.get(profile, 'mobileNumber')}`,
            gender: _.get(profile, 'gender'),
            birthDate: dayjs(_.get(profile, 'birthDate'), dateFormat),
            currentAddressState: `${_.get(profile, 'currentAddressState') && _.get(profile, 'currentAddressState') ? (_.get(profile, 'currentAddressState'), _.get(profile, 'currentAddressCity'), _.get(profile, 'currentAddressCountry')) : _.get(profile, 'currentAddressCountry')}`,
            description: _.get(profile, 'description'),
            ageGroup: _.get(profile, 'ageGroup'),
            timezone: _.get(profile, 'timezone'),
            state: _.get(profile, 'state'),
            profilePhoto: _.get(profile, 'profilePhoto'),

        })

        setImageUrl(_.get(profile, 'profilePhoto'))
    }














    // const onFinish = (values) => {
    //     console.log('finish', values);
    //     let req = {
    //       userId: _.get(props, 'students.viewprofile.userId'),
    //       ...values,
    //       interestTags: _.get(props, 'students.viewprofile.interestTags'),
    //       socialHandles: _.get(props, 'students.viewprofile.socialHandles'),
    //     }

    //     if (!_.isEmpty(countrycode)) {
    //       req.countryCode = countrycode;
    //     } else {
    //       req.countryCode = props?.students?.viewprofile?.countryCode;
    //     }


    //     let reqParam = {
    //       "birthDate": props?.students?.viewprofile?.birthDate,
    //       "mobileNumber": req.mobileNumber,
    //       "countryCode": req.countryCode,
    //       "emailId": req.emailId,
    //       "firstName": req.firstName || "",
    //       "lastName": req.lastName || "",
    //       "genderId": (courseList?.genders || []).find((e) => e.name?.toLowerCase() == req.gender?.toLowerCase())?.id,
    //       "profilePhoto": req.profilePhoto,
    //       //"password":"",
    //       "timezone": (courseList?.timezones || []).find((e) => e.type?.toLowerCase() == req.timezone?.toLowerCase())?.id,
    //       "userId": req.userId,
    //       //"preferredCommunicationValue":"",
    //       "ageGroupId": (courseList?.ageGroups || []).find((e) => e.name?.toLowerCase() == req.ageGroup?.toLowerCase())?.id,
    //       "interestTags": req.interestTags,
    //       "socialHandles": req.socialHandles,
    //       "state": req.state,
    //       //"additionalInfo":"",
    //       //"profileInterests":""
    //     };


    //     if (req.emailId !== props?.students?.viewprofile?.emailId) {

    //       validateEmail(req.emailId);
    //     } else {

    //       validateMobile(req.countryCode, req.mobileNumber);
    //     }

    //     function validateEmail(email) {
    //         email = decodeURIComponent(email);
    //       props.validateEmail({
    //         params: {
    //           email: email,
    //         },
    //         callback: (res, data) => {
    //           console.log('Email validation response', data);
    //           if (data === 'EmailId Already Existed') {
    //             notification.error({
    //               message: "Email Already Exist.",
    //             });
    //             return;
    //           }
    //           if (_.isEqual(res, true)) {

    //             validateMobile(req.countryCode, req.mobileNumber);
    //           } else {
    //             notification.error({
    //               message: "Something went wrong with email validation.",
    //             });
    //           }
    //         },
    //       });
    //     }

    //     function validateMobile(countryCode, mobile) {

    //       props.validatemobile({
    //         params: {
    //           countryCode: countryCode,
    //           mobile: mobile,
    //         },
    //         callback: (res, data) => {
    //           console.log('Mobile validation response', data);
    //           if (data === 'mobile already existed') {
    //             notification.error({
    //               message: "Mobile Already Exist",
    //             });
    //             return;
    //           }
    //           if (_.isEqual(res, true)) {

    //             props.edit({
    //               body: reqParam,
    //               callback: (res, data) => {
    //                 if (_.isEqual(res, true)) {
    //                   if (data === "Failure") {
    //                     notification.error({
    //                       message: data,
    //                     });
    //                   } else {
    //                     apicall();
    //                     classcountcall();
    //                     notification.success({
    //                       message: 'Profile updated successfully.',
    //                     });
    //                   }
    //                 } else {
    //                   notification.error({
    //                     message: 'Something went wrong',
    //                   });
    //                 }
    //               },
    //             });
    //           } else {
    //             notification.error({
    //               message: "Country code and mobile number are not matching",
    //             });
    //           }
    //         },
    //       });
    //     }
    //   };

    const onFinish = (values) => {
        console.log('finish', values);
        let req = {
            userId: _.get(props, 'students.viewprofile.userId'),
            ...values,
            interestTags: _.get(props, 'students.viewprofile.interestTags'),
            socialHandles: _.get(props, 'students.viewprofile.socialHandles'),
        }

        if (!_.isEmpty(countrycode)) {
            req.countryCode = countrycode;
        } else {
            req.countryCode = props?.students?.viewprofile?.countryCode;
        }

        let reqParam = {
            "birthDate": props?.students?.viewprofile?.birthDate,
            "mobileNumber": req.mobileNumber,
            "countryCode": req.countryCode,
            "emailId": req.emailId,
            "firstName": req.firstName || "",
            "lastName": req.lastName || "",
            "genderId": (courseList?.genders || []).find((e) => e.name?.toLowerCase() == req.gender?.toLowerCase())?.id,
            "profilePhoto": req.profilePhoto,
            //"password":"",
            "timezone": (courseList?.timezones || []).find((e) => e.type?.toLowerCase() == req.timezone?.toLowerCase())?.id,
            "userId": req.userId,
            //"preferredCommunicationValue":"",
            "ageGroupId": (courseList?.ageGroups || []).find((e) => e.name?.toLowerCase() == req.ageGroup?.toLowerCase())?.id,
            "interestTags": req.interestTags,
            "socialHandles": req.socialHandles,
            "state": req.state,
            //"additionalInfo":"",
            //"profileInterests":""
        };

        if (req.emailId !== props?.students?.viewprofile?.emailId) {
            validateEmail(req.emailId);
        } else if (req.mobileNumber !== props?.students?.viewprofile?.mobileNumber) {
            validateMobile(req.countryCode, req.mobileNumber);
        } else {
            props.edit({
                body: reqParam,
                callback: (res, data) => {
                    if (_.isEqual(res, true)) {
                        if (data === "Failure") {
                            notification.error({
                                message: data,
                            });
                        } else {
                            apicall();
                            classcountcall();
                            notification.success({
                                message: 'Profile updated successfully.',
                            });
                        }
                    } else {
                        notification.error({
                            message: 'Something went wrong',
                        });
                    }
                },
            });
        }

        function validateEmail(email) {
            email = decodeURIComponent(email);
            props.validateEmail({
                params: {
                    email: email,
                },
                callback: (res, data) => {
                    console.log('Email validation response', data);
                    if (data === 'EmailId Already Existed') {
                        notification.error({
                            message: "Email Already Exists.",
                        });
                        return;
                    }
                    if (_.isEqual(res, true)) {
                        props.edit({
                            body: reqParam,
                            callback: (res, data) => {
                                if (_.isEqual(res, true)) {
                                    if (data === "Failure") {
                                        notification.error({
                                            message: data,
                                        });
                                    } else {
                                        apicall();
                                        classcountcall();
                                        notification.success({
                                            message: 'Profile updated successfully.',
                                        });
                                    }
                                } else {
                                    notification.error({
                                        message: 'Something went wrong',
                                    });
                                }
                            },
                        });
                    } else {
                        notification.error({
                            message: "Something went wrong with email validation.",
                        });
                    }
                },
            });
        }

        function validateMobile(countryCode, mobile) {
            props.validatemobile({
                params: {
                    countryCode: countryCode,
                    mobile: mobile,
                },
                callback: (res, data) => {
                    console.log('Mobile validation response', data);
                    if (data === 'mobile already existed') {
                        notification.error({
                            message: "Mobile Already Exists",
                        });
                        return;
                    }
                    if (_.isEqual(res, true)) {
                        props.edit({
                            body: reqParam,
                            callback: (res, data) => {
                                if (_.isEqual(res, true)) {
                                    if (data === "Failure") {
                                        notification.error({
                                            message: data,
                                        });
                                    } else {
                                        apicall();
                                        classcountcall();
                                        notification.success({
                                            message: 'Profile updated successfully.',
                                        });
                                    }
                                } else {
                                    notification.error({
                                        message: 'Something went wrong',
                                    });
                                }
                            },
                        });
                    } else {
                        notification.error({
                            message: "Country code and mobile number do not match",
                        });
                    }
                },
            });
        }
    };


    const onFinishFailed = (errorInfo) => {
    };

    const onReset = () => {
        formfields(_.get(props, 'students.viewprofile'))

    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>
                setImageUrl(imageUrl),
                setLoading(false)
            );
        }
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
    const selectBefore = (
        <Select defaultValue={_.get(props, 'students.viewprofile.countryCode')} style={{ width: 100 }} onSelect={(value) => setcoundtrycode(value)}>
            {_.map(_.get(courseList, 'countryCodes'), (country, i) => {
                return <Option value={_.get(country, 'countryCode')}>{_.get(country, 'countryCode')}</Option>
            })}
        </Select>
    );
    const onlanguageselect = (i) => {
        // setselectedlanguage(oldArray=>[...oldArray, ..._.get(courseList, `${'languages'[i]}`)])
        setselectedlanguage(prevValues => [...prevValues, { ..._.get(courseList, `${'languages'[i.key]}`) }])
        console.log('selected lang', i, selectedlanguage, _.get(courseList, 'languages'), _.get(courseList, `${'languages'[i.key]}`));
    }
    const render = () => {
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        return (
            <div className='teacher-profile-container'>
                <div>
                </div>
                <div>
                    <div className="card mb-5 mb-xl-10">
                        <div id="kt_account_settings_profile_details" className="collapse show">
                            <Form
                                initialValues={{
                                    remember: true,
                                }}
                                form={form}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                onValuesChange={() => setSubmitdisable(false)}
                            >
                                <div id="kt_account_profile_details_form" className="form">
                                    <div className="card-body border-top p-9">
                                        <div className="row mb-6">
                                            <div className="col-lg-6">
                                                <Form.Item
                                                    name="profilePhoto"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: 'Please input your image!',
                                                        },
                                                    ]}
                                                >
                                                    <Upload
                                                        name="avatar"
                                                        listType="picture-card"
                                                        className="avatar-uploader"
                                                        accept="image/*"
                                                        showUploadList={false}
                                                        beforeUpload={beforeUpload}
                                                        onChange={e => handleChange(e)}
                                                        customRequest={({ file, onSuccess }) => {
                                                            setTimeout(() => {
                                                                onSuccess("ok");
                                                            }, 0);
                                                        }}
                                                        disabled
                                                    >
                                                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> : uploadButton}
                                                    </Upload>

                                                </Form.Item>
                                            </div>

                                            <StudentType {...props} configData={courseList} apicall={apicall} classcountcall={classcountcall} handleClickCourse={handleClickCourse} />

                                        </div>
                                        <div className="row mb-6">
                                            <div className="col-lg-4 col-form-label required fw-bold fs-6">Full Name</div>

                                            <div className="col-lg-6">
                                                <div className="row">

                                                    <div className="col-lg-6 fv-row mb-2">
                                                        <Form.Item
                                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            name="firstName"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: 'Please input your firstname!',
                                                                },
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-lg-6 fv-row">
                                                        <Form.Item
                                                            className="form-control form-control-lg form-control-solid"
                                                            name="lastName"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: 'Please input your lastname!',
                                                                },
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-6">
                                            <div className="col-lg-4 col-form-label required fw-bold fs-6">Email</div>
                                            <div className="col-lg-6 fv-row">
                                                <Form.Item
                                                    className="form-control form-control-lg form-control-solid"
                                                    name={'emailId'}
                                                    rules={[
                                                        {
                                                            type: 'email',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </div>
                                        </div>

                                        <div className="row mb-6">
                                            <div className="col-lg-4 col-form-label fw-bold fs-6">
                                                <span className="required">Contact Phone</span>
                                            </div>
                                            <div className="col-lg-6 fv-row">
                                                <Form.Item
                                                    className="form-control form-control-lg form-control-solid"
                                                    name={'mobileNumber'}
                                                    rules={[
                                                    ]}
                                                >
                                                    <InputNumber addonBefore={selectBefore} minLength={10} maxLength={15} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="row mb-6">
                                            <div className="col-lg-4 col-form-label fw-bold fs-6">
                                                <span className="required">Gender</span>
                                            </div>
                                            <div className="col-lg-6 fv-row">
                                                <Form.Item
                                                    className="form-control form-control-lg form-control-solid"
                                                    name={'gender'}
                                                    rules={[
                                                    ]}
                                                >
                                                    <Select  >
                                                        <Option value="Male">Male</Option>
                                                        <Option value="Female">Female</Option>
                                                        <Option value="Other">Other</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        {/* <div className="row mb-6">
                                            <div className="col-lg-4 col-form-label fw-bold fs-6">
                                                <span className="required">Date of birth</span>
                                            </div>
                                            <div className="col-lg-6 fv-row">
                                                <Form.Item
                                                    className="form-control form-control-lg form-control-solid"
                                                    name={'birthDate'}
                                                    rules={[
                                                    ]}
                                                >
                                                    <DatePicker  format={dateFormat} style={{ width: 450 }} />                                            
                                                    </Form.Item>
                                            </div>
                                        </div> */}
                                        <div className="row mb-6">
                                            <div className="col-lg-4 col-form-label fw-bold fs-6">
                                                <span className="required">Age group</span>
                                            </div>
                                            <div className="col-lg-6 fv-row">
                                                <Form.Item
                                                    className="form-control form-control-lg form-control-solid"
                                                    name={'ageGroup'}
                                                    rules={[
                                                    ]}
                                                >
                                                    <Select >
                                                        <Option value="6-12">6-12</Option>
                                                        <Option value="13-21">13-21</Option>
                                                        <Option value="22-30">22-30</Option>
                                                        <Option value="31-40">31-40</Option>
                                                        <Option value="40+">40+</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="row mb-6">
                                            <div className="col-lg-4 col-form-label fw-bold fs-6">
                                                <span className="required">Time zone</span>
                                            </div>
                                            <div className="col-lg-6 fv-row">
                                                <Form.Item
                                                    className="form-control form-control-lg form-control-solid"
                                                    name={'timezone'}
                                                    rules={[
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {courseList?.timezones?.length && courseList?.timezones.map(timezone => (
                                                            <Option key={timezone.type} value={timezone.type}>
                                                                {`${timezone.type} ${timezone.name}`}
                                                            </Option>
                                                        ))}
                                                    </Select>

                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="row mb-6">
                                            <div className="col-lg-4 col-form-label fw-bold fs-6">
                                                <span className="required">State</span>
                                            </div>
                                            <div className="col-lg-6 fv-row">
                                                <Form.Item
                                                    className="form-control form-control-lg form-control-solid"
                                                    name={'state'}
                                                    rules={[
                                                    ]}
                                                >
                                                    <Select >
                                                        {courseList?.states?.length && courseList?.states.map(state => {
                                                            return <Option value={state}>{state}</Option>
                                                        })}

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="row mb-6 d-none">
                                            <div className="col-lg-4 col-form-label fw-bold fs-6">
                                                <span className="required">Languages</span>
                                            </div>
                                            <div className="col-lg-6 fv-row">
                                                <Form.Item
                                                    className="form-control form-control-lg form-control-solid"
                                                    name={'languageId'}
                                                    rules={[
                                                    ]}
                                                >
                                                    <Select mode="multiple" onSelect={(value, key) => { onlanguageselect(key) }}>
                                                        {_.map(_.get(courseList, 'languages'), (lang, i) => {
                                                            return <Option value={_.get(lang, 'id')} key={i}>{_.get(lang, 'name')}</Option>
                                                        })}

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="row mb-6 d-none">
                                            <div className="col-lg-4 col-form-label required fw-bold fs-6">Address</div>

                                            <div className="col-lg-6 fv-row">
                                                <Form.Item
                                                    className="form-control form-control-lg form-control-solid"

                                                    name={'currentAddressState'}
                                                    rules={[
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="row mb-6 d-none">
                                            <div className="col-lg-4 col-form-label required fw-bold fs-6">Description</div>

                                            <div className="col-lg-6 fv-row">
                                                <Form.Item
                                                    className="form-control form-control-lg form-control-solid"

                                                    name={'description'}
                                                    rules={[
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        <Form.Item
                                        >
                                            <Button htmlType="button" disabled className="btn btn-light btn-active-light-primary mr-2" onClick={onReset}>
                                                Discard
                                            </Button>
                                            <Button type="primary" htmlType="submit"
                                                // disabled={true}
                                                loading={
                                                    _.get(props, 'editprofileLoadingInprogress')
                                                }
                                                className="btn btn-primary" id="kt_account_profile_details_submit">
                                                Save Changes
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
    return render();
}

const mapStateToProps = (state) => (
    {
        students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
    }
);

const mapDispatchToProps = (dispatch) => ({
    edit: (details) =>
        dispatch(global.redux.action.students.editprofile(details)),
    view: (details) =>
        dispatch(global.redux.action.students.viewprofile(details)),
    classcount: (details) =>
        dispatch(global.redux.action.students.viewclasscount(details)),
    courseList: details => dispatch(global.redux.action.students.courseList(details)),
    validatemobile: (details) =>
        dispatch(global.redux.action.students.validatemobile(details)),
    validateEmail: (details) =>
        dispatch(global.redux.action.students.validateEmail(details)),
    studentphotoupload: details => dispatch(global.redux.action.students.studentphotoupload(details)),

});
export default connect(mapStateToProps, mapDispatchToProps)(StudentProfilePage);
