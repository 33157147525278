import React, { useState, useEffect } from "react";
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Modal,
    DatePicker,
    Table,
    Spin,
    notification,
    Collapse,
} from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import * as SecureLS from "secure-ls";
import axios from "axios";

import formatDate from '../../core_units/utils/dayjs';
import "./style.css";
import appEnvirontVariables from "../../core_units/config/env-variables";
const { RangePicker } = DatePicker;
const TeacherQa = (props) => {
    var ls = new SecureLS({ encodingType: "aes" });
    const token = ls.get("isLogin");
    const { Option } = Select;
    const [form] = Form.useForm();
    const [selectedTeacher, setSelectedTeacher] = useState("");
    const [selectedTeacherId, setSelectedTeacherId] = useState(null);
    const [selectedTeacherName, setSelectedTeacherName] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [teachersList, setTeachersList] = useState([]);
    const [page, setPage] = useState(0);
    const [studentName, setStudentName] = useState("");
    const [teacherCategory,setTeacherCategory] = useState("");
    const [sessionDate, setSessionDate] = useState(null);
    const [course, setCourse] = useState("");
    const [ageGroup, setAgeGroup] = useState("");
    const [auditDate, setAuditDate] = useState("");
    const [feedbackLink, setFeedbackLink] = useState("");
    const [feedbackStatus, setFeedbackStatus] = useState("");
    const [startedOnTime, setStartedOnTime] = useState("");
    const [greetings, setGreetings] = useState("");
    const [internetConnection, setInternetConnection] = useState("");
    const [assignmentOfPreviousClass, setAssignmentOfPreviousClass] = useState("");
    const [aoiWithExamples, setAoiWithExamples] = useState("");
    const [addressByStudentName, setAddressByStudentName] = useState("");
    const [engagementLevel, setEngagementLevel] = useState("");
    const [studentComfort, setStudentComfort] = useState("");
    const [avoidanceCriteria, setAvoidanceCriteria] = useState("");
    const [casualApproach, setCasualApproach] = useState("");
    const [acknowledgment, setAcknowledgment] = useState("");
    const [activeListening, setActiveListening] = useState("");
    const [interruption, setInterruption] = useState("");
    const [energyLevel, setEnergyLevel] = useState("");
    const [rateSpeech, setRateSpeech] = useState("");
    const [customerDelight, setCustomerDelight] = useState("");
    const [energetic, setEnergetic] = useState("");
    const [disconnect, setDisconnect] = useState("");
    const [doubtsSolving, setDoubtSolving] = useState("");
    const [mic, setMic] = useState("");
    const [headphones, setHeadphones] = useState("");
    const [jacket, setJacket] = useState("");
    const [videoQuality, setVideoQuality] = useState("");
    const [practiceStudio, setPracticeStudio] = useState("");
    const [instrumentUsed, setInstrumentUsed] = useState("");
    const [appropriatelyDressed, setAppropriatelyDressed] = useState("");
    const [videoOn, setVideoOn] = useState("");
    const [unprofessionalBehaviour, setUnprofessionalBehaviour] = useState("");
    const [sessionStandardTime, setSessionStandardTime] = useState("");
    const[qaScore, setQaScore]=useState("");
    const [observation, setObservation] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [courseList, setCourseList] = useState([]);
    const [getDetails,setGetDetails] = useState(null);
    const [formDataList, setFormDataList] = useState([]);
    const [editMode, setEditMode] = useState(Array(formDataList.length).fill(false));
    const [formVisibility, setFormVisibility] = useState(Array(formDataList.length).fill(false));
    const [editedIndex, setEditedIndex] = useState(null);
    const [excelList,setExcelList] = useState([]);
console.log(excelList,"excelList===")



    const [qaReportDetail, setQaReportDetail] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isExLoading, setIsExLoading] = useState(false);
    const [error, setError] = useState('');
    const [showForm, setShowForm] = useState(true);


    const { Panel } = Collapse;

    useEffect(() => {
        if (_.isEqual(typeof _.get(props, "teachers.list.number"), "number")) {
            setPage(_.get(props, "teachers.list.number"));
        }
    }, [_.get(props, "teachers.list.number")]);
    useEffect(() => {
        configjson();
      }, []);
    const configjson = () => {
        props.courseList({
          callback: (res, data) => {
            if (_.isEqual(res, true)) { 
              console.log("courselist", data);
              setCourseList(data);
            }
          },
        });
      };
    const handleRadioChange = (teacherId, teacherName) => {
        setSelectedTeacher(teacherId);
        setSelectedTeacherName(teacherName);
    };

    const handleSearch = () => {
        if (!searchQuery.trim()) {
            return;
        }

        props.list({
            body: {
                page: 0,
                size: 100,
                name: searchQuery,
            },
            callback: (response, data) => {
                if (response) {
                    setTeachersList(_.get(data, "content", []));
                    setModalVisible(true);
                } else {
                }
            },
        });
    };
    const handleModalClose = () => {
        setSelectedTeacherId(null);
        setModalVisible(false);
    };
    const handleAddTeacher = () => {
        if (selectedTeacherId) {
            const selectedTeacher = teachersList.find(teacher => teacher.teacherId === selectedTeacherId);
            if (selectedTeacher) {
                setSelectedTeacherName(selectedTeacher.teacherName);
            }
        }
        setModalVisible(false);
    };
    const handleGetForm = () => {
        setIsLoading(true);
        setIsModalVisible(true);
        setEditModalVisible(false);
        setShowForm(false);
        axios.get(`${appEnvirontVariables.API.BASE_URL}/api/admin/getQAScore`, {
            headers: {
                Authorization: token["authorization"], 
            },
        })
        .then((response) => {
            const data = response.data;
            if (Array.isArray(data) && data.length > 0) {
                const formDataList = data.map(qaData => {
                    const qaDetails = JSON.parse(qaData.qaDetails);
                    return {
                        feedbackStatus: qaDetails.feedbackStatus || '',
                        feedbackLink: qaDetails.feedbackLink || '',
                        teacherName: qaDetails.teacherName || '',
                        teacherId: qaData.teacherId || '',
                        sessionDate: qaDetails.sessionDate || '',
                        teacherCategory: qaDetails.teacherCategory ||'',
                        studentName: qaDetails.studentName || '',
                        course: qaDetails.course || '',
                        ageGroup: qaDetails.ageGroup || '',
                        auditDate: qaDetails.auditDate || '',
                        observation: qaDetails.observation || '',
                        startedOnTime: qaDetails.payload[0]?.Opening.startedOnTime || '',
                        internetConnection: qaDetails.payload[0]?.Opening.internetConnection || '',
                        greetingIntroduction: qaDetails.payload[0]?.Opening.greetingIntroduction || '',
                        constructiveFeedbackWhileDiscussingTheAssessmentAssignmentOfThePreviousClass: qaDetails.payload[0]?.Feedback.constructiveFeedbackWhileDiscussingTheAssessmentAssignmentOfThePreviousClass || '',
                        aOIWithExamples: qaDetails.payload[0]?.Feedback.aOIWithExamples || '',
                        addressTheStudentByTheirName: qaDetails.payload[0]?.Feedback.addressTheStudentByTheirName || '',
                        engagementLevel: qaDetails.payload[0]?.RapportBuilding.engagementLevel || '',
                        madeTheStudentFeelComfortable: qaDetails.payload[0]?.RapportBuilding.madeTheStudentFeelComfortable || '',
                        deadAirFumblingFillersUnnecessaryMuteNeedsToBeAvoided: qaDetails.payload[0]?.communicationSkillsAndSoftskills.deadAirFumblingFillersUnnecessaryMuteNeedsToBeAvoided || '',
                        casualApproachToneUsageOfSlangLanguage: qaDetails.payload[0]?.communicationSkillsAndSoftskills.casualApproachToneUsageOfSlangLanguage || '',
                        acknowledgment: qaDetails.payload[0]?.communicationSkillsAndSoftskills.acknowledgment || '',
                        activeListening: qaDetails.payload[0]?.communicationSkillsAndSoftskills.activeListening || '',
                        interruption: qaDetails.payload[0]?.communicationSkillsAndSoftskills.interruption || '',
                        energyLevel: qaDetails.payload[0]?.communicationSkillsAndSoftskills.energyLevel || '',
                        rateOfSpeech: qaDetails.payload[0]?.communicationSkillsAndSoftskills.rateOfSpeech || '',
                        customerDelight: qaDetails.payload[0]?.Closing.customerDelight || '',
                        DoubtsSolvingQueryResolution: qaDetails.payload[0]?.Closing.DoubtsSolvingQueryResolution || '',
                        energeticAndPositiveClosing: qaDetails.payload[0]?.Closing.energeticAndPositiveClosing || '',
                        disconnectionAfterTheStudent: qaDetails.payload[0]?.Closing.disconnectionAfterTheStudent || '',
                        mic: qaDetails.payload[0]?.TechGadgets.mic || '',
                        headphones: qaDetails.payload[0]?.TechGadgets.headphones || '',
                        jacket: qaDetails.payload[0]?.TechGadgets.jacket || '',
                        videoQualityCamAngleBodyLanguageBackground: qaDetails.payload[0]?.TechGadgets.videoQualityCamAngleBodyLanguageBackground || '',
                        practiceStudio: qaDetails.payload[0]?.TechGadgets.practiceStudio || '',
                        pleaseSpecifyTheInstrumentUsed: qaDetails.payload[0]?.TechGadgets.pleaseSpecifyTheInstrumentUsed || '',
                        appropriatelyDressed: qaDetails.payload[0]?.Presentation.appropriatelyDressed || '',
                        videoOn: qaDetails.payload[0]?.Presentation.videoOn || '',
                        rudeSarcasticIntimidatingBehaviourUnprofessionalism: qaDetails.payload[0]?.Presentation.rudeSarcasticIntimidatingBehaviourUnprofessionalism || '',
                        sessionAsPerStandardTime: qaDetails.payload[0]?.Time.sessionAsPerStandardTime || '',
                        qaScore: qaData.qaScore || 0 ,
                       
                    };
                });
                setIsLoading(false);
                setFormDataList(formDataList);
              console.log(formDataList,"formDataList==")
              setExcelList(formDataList);
            }else {
                setIsLoading(false);
                console.error("Error: Empty or invalid data returned from the API.");
            }
          
        })
        .catch((error) => {
            setIsLoading(false);
            console.error("Error:", error);
        });
    };
    const handleSubmit = () => {
        setIsLoading(true);
        const payload = {
            teacherId: selectedTeacherId,
            teacherName: selectedTeacherName,
            teacherCategory: teacherCategory,
            studentName: studentName,
            sessionDate: sessionDate ? sessionDate.format("YYYY-MM-DD") : "",
            course: course,
            ageGroup: ageGroup,
            auditDate: auditDate ? auditDate.format("YYYY-MM-DD") : "",
            feedbackStatus: feedbackStatus,
            feedbackLink: feedbackLink,
            observation: observation,
           
            payload:[ {
                Opening: {
                    startedOnTime: startedOnTime,
                    internetConnection: internetConnection,
                    greetingIntroduction: greetings,
                },
                Feedback: {
                    constructiveFeedbackWhileDiscussingTheAssessmentAssignmentOfThePreviousClass: assignmentOfPreviousClass,
                    aOIWithExamples: aoiWithExamples,
                    addressTheStudentByTheirName: addressByStudentName,
                },
                RapportBuilding: {

                    engagementLevel: engagementLevel,
                    madeTheStudentFeelComfortable: studentComfort
                },
                communicationSkillsAndSoftskills: {
                    deadAirFumblingFillersUnnecessaryMuteNeedsToBeAvoided: avoidanceCriteria,
                    casualApproachToneUsageOfSlangLanguage: casualApproach,
                    acknowledgment: acknowledgment,
                    activeListening: activeListening,
                    interruption: interruption,
                    energyLevel: energyLevel,
                    rateOfSpeech: rateSpeech
                },
                Closing: {
                    customerDelight: customerDelight,
                    DoubtsSolvingQueryResolution: doubtsSolving,
                    energeticAndPositiveClosing: energetic,
                    disconnectionAfterTheStudent: disconnect,
                },
                TechGadgets: {
                    mic: mic,
                    headphones: headphones,
                    jacket: jacket,
                    videoQualityCamAngleBodyLanguageBackground: videoQuality,
                    practiceStudio: practiceStudio,
                    pleaseSpecifyTheInstrumentUsed: instrumentUsed

                },
                Presentation: {
                    appropriatelyDressed: appropriatelyDressed,
                    videoOn: videoOn,
                    rudeSarcasticIntimidatingBehaviourUnprofessionalism: unprofessionalBehaviour
                },
                Time:{
                    sessionAsPerStandardTime: sessionStandardTime,
                   
                    

                }
            }]
        };


        axios
            .post(
                `${appEnvirontVariables.API.BASE_URL}/api/admin/teacherQAScore`,

                payload
                ,
                {
                    headers: {
                        Authorization: token["authorization"],
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    setIsLoading(false);
                    form.resetFields();
                    setSelectedTeacherName("");
                    notification.success({
                        message: 'Success',
                        description: 'Form submitted successfully.',
                    });
                } else {
                    console.error("Network response was not ok");
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                setIsLoading(false);
                notification.error({
                    message: 'Error',
                    description: 'Failed to submit form. Please try again.',
                });

            
            });
    };
    const handleEditForm = () => {
        console.log("  setEditModalVisible(true);=",setEditModalVisible);
        setEditModalVisible(true);
        setIsModalVisible(false);
        setShowForm(false);
    };
 
    // const handleEdit = (index) => {
    //     setEditedIndex(index); 
    //     const updatedEditModes = Array(formDataList.length).fill(false);
    //     updatedEditModes[index] = true; 
    //     setEditMode(updatedEditModes);
    // };

    const handleEdit = (index) => {
        setEditedIndex(index); 
        const updatedEditModes = Array(formDataList.length).fill(false);
        updatedEditModes[index] = true; 
        setEditMode(updatedEditModes);
    };

    const toggleFormVisibility = (index) => {
        const updatedVisibility = [...formVisibility];
        updatedVisibility[index] = !updatedVisibility[index];
        setFormVisibility(updatedVisibility);
    };

    const exportToExcel = () => {
        setIsExLoading(true);
        if (!Array.isArray(excelList)) {
            setIsExLoading(false);
            console.log(excelList, "form======");
            return; 
        }
        const headers = [
          
            'Feedback Status','Feedback Link','Teacher Name','Teacher ID','Session Date','Teacher Category','Student Name', 'Course',
            'Age Group', 'Audit Date','Observation', 'QA Score','Started On Time', 'Internet Connection', 'Greeting Introduction',
            'Constructive Feedback While Discussing The Assessment Assignment Of The Previous Class', 'AOI With Examples',
            'Address The Student By Their Name', 'Engagement Level', 'Made The Student Feel Comfortable',
            'Dead Air Fumbling Fillers Unnecessary Mute Needs To Be Avoided', 'Casual Approach Tone Usage Of Slang Language',
            'Acknowledgment','Active Listening', 'Interruption', 'Energy Level', 'Rate Of Speech','Customer Delight',
            'Doubts Solving Query Resolution','Energetic And Positive Closing', 'Disconnection After The Student',
             'Mic', 'Headphones', 'Jacket','Video Quality Cam Angle Body Language Background', 'Practice Studio',
             'Please Specify The Instrument Used', 'Appropriately Dressed', 'Video On','Rude Sarcastic Intimidating Behaviour Unprofessionalism', 
             'Session As Per Standard Time'
             
        ];
    
        let csvContent = "data:text/csv;charset=utf-8," + headers.join(",") + "\n";
        csvContent += excelList.map(report => Object.values(report).join(",")).join("\n");

   
   
    
        // Create a download link and trigger the download
        const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "QA_Report.csv");

    // Hide loader when download is complete
    link.onload = () => setIsExLoading(false);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    };
    const handleInputChange = (index, field, value) => {
        const updatedFormData = [...formDataList];
        updatedFormData[index][field] = value;
        setFormDataList(updatedFormData);
    };
    
    const handleSaveChanges = async () => {
        setIsLoading(true);
        try {
            if (editedIndex !== null) {
             
                const editedFormData = formDataList[editedIndex];
                
               
                const payload = {
                    teacherId: editedFormData.teacherId,
                    teacherName: editedFormData.teacherName,
                    teacherCategory: editedFormData.teacherCategory,
                    studentName: editedFormData.studentName,
                    sessionDate: editedFormData.sessionDate || "",
                    course: editedFormData.course || "",
                    ageGroup: editedFormData.ageGroup || "",
                    auditDate: editedFormData.auditDate || "",
                    feedbackStatus: editedFormData.feedbackStatus || "",
                    feedbackLink: editedFormData.feedbackLink || "",
                    observation: editedFormData.observation || "",
                    qaScore: editedFormData.qaScore || "0",
                    payload: [{
                        Opening: {
                            startedOnTime: editedFormData.startedOnTime || "",
                            internetConnection: editedFormData.internetConnection || "",
                            greetingIntroduction: editedFormData.greetingIntroduction || "",
                        },
                        Feedback: {
                            constructiveFeedbackWhileDiscussingTheAssessmentAssignmentOfThePreviousClass: editedFormData.constructiveFeedbackWhileDiscussingTheAssessmentAssignmentOfThePreviousClass || "",
                            aOIWithExamples: editedFormData.aOIWithExamples || "",
                            addressTheStudentByTheirName: editedFormData.addressTheStudentByTheirName || "",
                        },
                        RapportBuilding: {
                            engagementLevel: editedFormData.engagementLevel || "",
                            madeTheStudentFeelComfortable: editedFormData.madeTheStudentFeelComfortable || ""
                        },
                        communicationSkillsAndSoftskills: {
                            deadAirFumblingFillersUnnecessaryMuteNeedsToBeAvoided: editedFormData.deadAirFumblingFillersUnnecessaryMuteNeedsToBeAvoided || "",
                            casualApproachToneUsageOfSlangLanguage: editedFormData.casualApproachToneUsageOfSlangLanguage || "",
                            acknowledgment: editedFormData.acknowledgment || "",
                            activeListening: editedFormData.activeListening || "",
                            interruption: editedFormData.interruption || "",
                            energyLevel: editedFormData.energyLevel || "",
                            rateOfSpeech: editedFormData.rateOfSpeech || ""
                        },
                        Closing: {
                            customerDelight: editedFormData.customerDelight || "",
                            DoubtsSolvingQueryResolution: editedFormData.DoubtsSolvingQueryResolution || "",
                            energeticAndPositiveClosing: editedFormData.energeticAndPositiveClosing || "",
                            disconnectionAfterTheStudent: editedFormData.disconnectionAfterTheStudent || "",
                        },
                        TechGadgets: {
                            mic: editedFormData.mic || "",
                            headphones: editedFormData.headphones || "",
                            jacket: editedFormData.jacket || "",
                            videoQualityCamAngleBodyLanguageBackground: editedFormData.videoQualityCamAngleBodyLanguageBackground || "",
                            practiceStudio: editedFormData.practiceStudio || "",
                            pleaseSpecifyTheInstrumentUsed: editedFormData.pleaseSpecifyTheInstrumentUsed || ""
                        },
                        Presentation: {
                            appropriatelyDressed: editedFormData.appropriatelyDressed || "",
                            videoOn: editedFormData.videoOn || "",
                            rudeSarcasticIntimidatingBehaviourUnprofessionalism: editedFormData.rudeSarcasticIntimidatingBehaviourUnprofessionalism || ""
                        },
                        Time: {
                            sessionAsPerStandardTime: editedFormData.sessionAsPerStandardTime || "",
                          

                        }
                    }]
                };
                
              
                const response = await axios.put(
                    `${appEnvirontVariables.API.BASE_URL}/api/admin/updateQAScore`, 
                    payload,
                    {
                        headers: {
                            Authorization: token["authorization"],
                        },
                    }
                ); 
    
                console.log(response.data);
                setIsLoading(false);
             
                setEditMode(Array(formDataList.length).fill(false));
                setEditedIndex(null);
            } else {
                setIsLoading(false);
                console.log("No form data has been edited.");
            }
            setEditMode(prevEditModes => {
                const updatedEditModes = [...prevEditModes];
                updatedEditModes[editedIndex] = false;
                return updatedEditModes;
            });
           
        } catch (error) {
            setIsLoading(false);
            console.error('Error updating QA score:', error);
        }
    };
    const onFinish = (values) => {
        setIsLoading(true);
      
    
        const fromDate = values.dates[0].format('YYYY-MM-DD');
        const toDate = values.dates[1].format('YYYY-MM-DD');
        const teacherId = selectedTeacherId;
        
        axios.get(`${appEnvirontVariables.API.BASE_URL}/api/admin/QAreport`, {
            params: {
                fromDate,
                toDate,
                teacherId,
            },
            headers: {
                Authorization: token["authorization"], 
            },
        })
      
          .then(response => {
            setIsLoading(false);
       setQaReportDetail(response.data)
            console.log(response.data);
            form.resetFields();
            setSelectedTeacherName("");
            setSearchQuery("");
          })
          .catch(error => {
        
          
          })
          .finally(() => {
            setIsLoading(false);
          });
      };
      const columns = [
        {
            title: 'Teacher Name',
            dataIndex: 'teacherName',
            key: 'teacherName',
        },
        {
            title: 'QA Score',
            dataIndex: 'qaScore',
            key: 'qaScore',
        },
        {
            title: 'Audit Date',
            dataIndex: 'auditDate',
            key: 'auditDate',
        },
    ];
    const handleQaOpen = () =>{
        setShowForm(true);
        setEditModalVisible(false);
        setIsModalVisible(false);
        form.resetFields();
        setSelectedTeacherName("");
        setSearchQuery("");
    }
    return (
        <div>
            {!showForm &&
            <Button onClick={handleQaOpen}> 
                Back
            </Button>
}
               <div style={{marginTop:"20px"}}>

            <div>
                <Button type="primary" onClick={handleGetForm}>
                    Get Audit List
                </Button>
                <Button
                    type="primary"
                    onClick={handleEditForm}
                    style={{ marginLeft: "20px" }}
                >
                    Add Audit List
                </Button>
            </div>

            {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spin tip="Loading..." size="large"></Spin>
          </div>
        ) : (


            <div>
                
            {isModalVisible && (
                <>
                      <div>
                <Button onClick={exportToExcel} style={{ marginTop: '20px', marginBottom:"20px" }}>Export to CSV</Button>

       </div>
    <div style={{ marginTop: "30px" }}>
        {formDataList.map((formData, index) => (
            <div>
    
            <div key={index} style={{ marginBottom: "20px", border: "1px solid #ccc", borderRadius: "5px", padding: "20px", position: "relative" }}>
                {/* Buttons for edit/save and view details */}
                <div style={{ position: "absolute", top: "10px", right: "10px" }}>
                    <Button
                        type="primary"
                        onClick={() => {
                            toggleFormVisibility(index);
                            if (editMode[index]) {
                                handleSaveChanges();
                                setEditMode(prevEditModes => {
                                    const updatedEditModes = [...prevEditModes];
                                    updatedEditModes[index] = false;
                                    return updatedEditModes;
                                });
                            } else {
                                handleEdit(index);
                                setEditMode(prevEditModes => {
                                    const updatedEditModes = [...prevEditModes];
                                    updatedEditModes[index] = true;
                                    return updatedEditModes;
                                });
                            }
                        }}
                        disabled={formVisibility[index] && !editMode[index]}
                    >
                        {editMode[index] ? "Update" : "Edit"}
                    </Button>
                    <Button
    type="default"
    style={{ marginLeft: "10px" }}
    onClick={() => {
        toggleFormVisibility(index);
 
        setEditMode(prevEditModes => {
            const updatedEditModes = [...prevEditModes];
            updatedEditModes[index] = false;
            return updatedEditModes;
        });
    }}
>
    {formVisibility[index] ? "Hide Details" : "View Full Detail Form"}
</Button>
                </div>
                {/* Data fields */}
                <div style={{ marginTop: "20px", display: "flex", flexDirection: "row", flexWrap: "wrap", textAlign: "left" }}>
    <div style={{ marginRight: "20px", flex: "1" }}>
    <div style={{ display: "flex", marginBottom: "8px" }}>
            <div style={{ width: "150px", marginRight: "10px" }}>
                <label><b>Teacher Name:</b></label>
            </div>
            <div>{formData.teacherName}</div>
        </div>
        <div style={{ display: "flex", marginBottom: "8px" }}>
            <div style={{ width: "150px", marginRight: "10px" }}>
                <label><b>Teacher Category:</b></label>
            </div>
            <div>{formData.teacherCategory}</div>
        </div>
        <div style={{ display: "flex", marginBottom: "8px" }}>
            <div style={{ width: "150px",  marginRight: "10px" }}>
                <label><b>Student Name:</b></label>
            </div>
            <div>{formData.studentName}</div>
        </div>
        <div style={{ display: "flex", marginBottom: "8px" }}>
            <div style={{ width: "150px", marginRight: "10px" }}>
                <label><b>Age Group:</b></label>
            </div>
            <div>{formData.ageGroup}</div>
        </div>
    </div>
    <div style={{ marginLeft: "20px", flex: "2 1 0%" }}>
        <div style={{ display: "flex", marginBottom: "8px" }}>
            <div style={{ width: "150px",  marginRight: "10px" }}>
                <label><b>Session Date:</b></label>
            </div>
            <div>{formatDate(formData.sessionDate)}</div>
        </div>
        <div style={{ display: "flex", marginBottom: "8px" }}>
            <div style={{ width: "150px", marginRight: "10px" }}>
                <label><b>Course:</b></label>
            </div>
            <div>{formData.course}</div>
        </div>
        <div style={{ display: "flex", marginBottom: "8px" }}>
            <div style={{ width: "150px", marginRight: "10px" }}>
                <label><b>Audit Date:</b></label>
            </div>
            <div>{formatDate(formData.auditDate)}</div>
        </div>
    </div>
</div>


                            {formVisibility[index] && (
                                <div style={{ padding: "20px", backgroundColor: "#f7f7f7" }}>
                                    <Form labelAlign="left" labelCol={{ span: 14 }}>
                                        <Form.Item label="Feedback Status" style={{ marginBottom: 0 }}>
                                            <Input
                                                value={formData.feedbackStatus}
                                                style={{ width: "255px" }}
                                                disabled={!editMode[index]}
                                                onChange={(e) => handleInputChange(index, 'feedbackStatus', e.target.value)}
                                            />
                                        </Form.Item>
                                        <Form.Item label="Feedback Link" style={{ marginBottom: 0 }}>
                                            <Input
                                                value={formData.feedbackLink}
                                                style={{ width: "255px" }}
                                                disabled={!editMode[index]}
                                                onChange={(e) => handleInputChange(index, 'feedbackLink', e.target.value)}
                                            />
                                        </Form.Item>

                                        <p>
                                    <b>Openings</b>
                                </p>
                                <Form.Item label="Started on Time" style={{ marginBottom: 0 }}>
                                    <Input
value={formData.startedOnTime}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].startedOnTime = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Internet Connection"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.internetConnection}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].internetConnection = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Greeting/Introduction"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.greetingIntroduction}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].greetingIntroduction = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <p>
                                    <b>Feedback</b>
                                </p>
                                <Form.Item
                                    label="Assignment of the Previous Class"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.constructiveFeedbackWhileDiscussingTheAssessmentAssignmentOfThePreviousClass}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].constructiveFeedbackWhileDiscussingTheAssessmentAssignmentOfThePreviousClass = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="AOI with Examples"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.aOIWithExamples}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].aOIWithExamples = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Address the Student by Their Name"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.addressTheStudentByTheirName}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].addressTheStudentByTheirName = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <p>
                                    <b>Reporting Building</b>
                                </p>
                                <Form.Item label="Engagement Level" style={{ marginBottom: 0 }}>
                                    <Input
value={formData.engagementLevel}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].engagementLevel = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Made the Student Feel Comfortable"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.madeTheStudentFeelComfortable}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].madeTheStudentFeelComfortable = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <p>
                                    <b>Communication skills and Soft skills</b>
                                </p>
                                <Form.Item
                                    label="Dead Air/Fumbling/Fillers/Unnecessary Mute Needs to be Avoided"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.deadAirFumblingFillersUnnecessaryMuteNeedsToBeAvoided}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].deadAirFumblingFillersUnnecessaryMuteNeedsToBeAvoided = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Casual Approach/Tone, Usage of Slang Language"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.casualApproachToneUsageOfSlangLanguage}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].casualApproachToneUsageOfSlangLanguage = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Acknowledgment" style={{ marginBottom: 0 }}>
                                    <Input
value={formData.acknowledgment}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].acknowledgment = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Active Listening" style={{ marginBottom: 0 }}>
                                    <Input
value={formData.activeListening}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].activeListening = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Interruption" style={{ marginBottom: 0 }}>
                                    <Input
value={formData.interruption}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].interruption = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="energy Level" style={{ marginBottom: 0 }}>
                                    <Input
value={formData.energyLevel}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].energyLevel = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Rate of Speech" style={{ marginBottom: 0 }}>
                                    <Input
value={formData.rateOfSpeech}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].rateOfSpeech = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <p>
                                    <b>Closing</b>
                                </p>
                                <Form.Item label="Customer Delight" style={{ marginBottom: 0 }}>
                                    <Input
                                    value={formData.customerDelight}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].customerDelight = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Doubts solving/ query resolution"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.DoubtsSolvingQueryResolution}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].DoubtsSolvingQueryResolution = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Energetic and positive closing"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.energeticAndPositiveClosing}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].energeticAndPositiveClosing = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Disconnection after the student"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.disconnectionAfterTheStudent}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].disconnectionAfterTheStudent = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <p>
                                    <b>Tech & Gadgets</b>
                                </p>
                                <Form.Item label="Mic" style={{ marginBottom: 0 }}>
                                    <Input
value={formData.mic}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].mic = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Headphones" style={{ marginBottom: 0 }}>
                                    <Input
value={formData.headphones}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].headphones = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Jacket" style={{ marginBottom: 0 }}>
                                    <Input
value={formData.jacket}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].jacket = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Video Quality/Cam Angle/Body Language/Background"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.videoQualityCamAngleBodyLanguageBackground}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].videoQualityCamAngleBodyLanguageBackground = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Practice Studio (Yes/No)"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.practiceStudio}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].practiceStudio = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Please specify the instrument used (Personal/Dashboard)"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.pleaseSpecifyTheInstrumentUsed}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].pleaseSpecifyTheInstrumentUsed = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <p>
                                    <b>Presentation</b>
                                </p>
                                <Form.Item
                                    label="Appropriately Dressed"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.appropriatelyDressed}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].appropriatelyDressed = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Video On" style={{ marginBottom: 0 }}>
                                    <Input
value={formData.videoOn}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].videoOn = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Rude/Sarcastic/Intimidating Behaviour/Unprofessionalism"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.rudeSarcasticIntimidatingBehaviourUnprofessionalism}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].rudeSarcasticIntimidatingBehaviourUnprofessionalism = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <p>
                                    <b>Time</b>
                                </p>
                                <Form.Item
                                    label="Session as per standard Time"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
value={formData.sessionAsPerStandardTime}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].sessionAsPerStandardTime = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Observation" style={{ marginBottom: 0 }}>
                                    <Input
value={formData.observation}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].observation = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Qa Score" style={{ marginBottom: 0 }}>
                                    <Input
value={formData.qaScore}
                                        style={{ width: "255px" }}
                                        disabled={!editMode[index]}
                                        onChange={(e) => {
                                            const updatedFormData = [...formDataList];
                                            updatedFormData[index].qaScore = e.target.value;
                                            setFormDataList(updatedFormData);
                                        }}
                                    />
                                </Form.Item>
                                    </Form>
                                </div>
                            )}
                        </div>
        
                        </div>
                    ))}
                </div>
          
       </>
            )}
             
        </div>
            
        )}

    
      

<div>
{editModalVisible && (
                <div style={{ marginTop: "30px" }}>
                    <Form
                        form={form}
                        labelAlign="left"
                        labelCol={{ span: 14 }}
                        style={{
                            padding: "20px",
                            backgroundColor: "#f7f7f7",
                            overflow: "hidden",
                        }}
                        onFinish={handleSubmit}
                    >
                        <Form.Item label="Search Teacher" name="teacherId" style={{ marginBottom: 0 }}>
                            <Input
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Enter teacher name"
                                style={{ width: "60%" }}
                            />
                            <Button
                                type="primary"
                                disabled={!searchQuery.trim()}
                                onClick={handleSearch}
                            >
                                Search
                            </Button>
                        </Form.Item>
                        <Modal
                            title="Select Teacher"
                            visible={modalVisible}
                            onCancel={handleModalClose}
                            footer={[
                                <Button key="cancel" onClick={handleModalClose}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary" onClick={handleAddTeacher}>
                                    Add Teacher
                                </Button>,
                            ]}
                        >
                            <Radio.Group style={{ display: "inline" }}>
                                {teachersList.map((teacher) => (
                                    <div key={teacher.teacherId} className="teacher-card">
                                        <Radio
                                            value={teacher.teacherId}
                                            checked={selectedTeacherId === teacher.teacherId}
                                            onClick={() =>
                                                setSelectedTeacherId(teacher.teacherId)
                                            }
                                        >
                                            {teacher.teacherName} - {teacher.mobileNumber} -{" "}
                                            {teacher.emailId}
                                        </Radio>
                                    </div>
                                ))}
                            </Radio.Group>
                        </Modal>
                        <Form.Item label="Teacher Name"
                            // name="teacherName"
                            style={{ marginBottom: 0 }}>
                            <Input
                                value={selectedTeacherName}
                                style={{ width: "255px" }}
                                disabled
                                placeholder="Enter Teacher Name"
                            />
                        </Form.Item>
                        <Form.Item label="Teacher Category" name="teacherCategory" rules={[{ required: true, message: 'This field is required' }]} style={{ marginBottom: 0 }}>
                            <Input
                                value={teacherCategory}
                                onChange={(e) => setTeacherCategory(e.target.value)}
                                style={{ width: "255px" }}
                                placeholder="Enter Teacher Category"
                            />
                        </Form.Item>
                        <Form.Item label="Student Name" name="studentName" rules={[{ required: true, message: 'This field is required' }]} style={{ marginBottom: 0 }}>
                            <Input
                                value={studentName}
                                onChange={(e) => setStudentName(e.target.value)}
                                style={{ width: "255px" }}
                                placeholder="Enter Student Name"
                            />
                        </Form.Item>
                        <Form.Item label="Session Date" name="sessionDate" rules={[{ required: true, message: 'This field is required' }]} style={{ marginBottom: 0 }}>
                            <DatePicker
                                value={sessionDate}
                                onChange={(date) => setSessionDate(date)}
                                style={{
                                    width: "255px",
                                    border:"1px solid lightgrey",
                                    backgroundColor: "white",
                                }}
                                placeholder=" Please Select Date"
                            />
                        </Form.Item>
                        {/* <Form.Item label="Course" name="course" rules={[{ required: true, message: 'This field is required' }]} style={{ marginBottom: 0 }}>
                         
                            <Select 
                             value={course}
                             onChange={(value) => setCourse(value)}

                             style={{ width: "255px",border:"1px solid lightgrey",backgroundColor:"white" }}
                             placeholder="Please Select Course"
                            >
                            
                            {_.map(
                              _.get(courseList, "courses"),
                              (course, eachParent) => {
                                {
                                  return _.map(
                                    _.get(course, "tags"),
                                    (tag, each) => {
                                      console.log("tags", tag);
                                      console.log("course", course);
                                      return (
                                        <Option
                                        value={`${_.get(course, "name")} - ${_.get(tag, "name")}`}
                                          key={eachParent + "-" + each}
                                        >
                                          {_.get(course, "name")}-
                                          {_.get(tag, "name")}-{"Paid"}
                                        </Option>
                                      );
                                    }
                                  );
                                }
                              }
                            )}
                          </Select>
                        </Form.Item> */}
                        <Form.Item 
      label="Course" 
      name="course" 
      rules={[{ required: true, message: 'This field is required' }]} 
      style={{ marginBottom: 0 }}
    >
      <Select
        value={course}
        onChange={(value) => setCourse(value)}
        style={{ width: "255px", border: "1px solid lightgrey", backgroundColor: "white" }}
        placeholder="Please Select Course"
      >
        {_.map(courseList.courses, (course, eachParent) =>
          _.map(course.tags, (tag, each) => (
            <Option
              value={`${course.name} - ${tag.name}`}
              key={`${eachParent}-${each}`}
            >
              {`${course.name} - ${tag.name} - Paid`}
            </Option>
          ))
        )}
      </Select>
    </Form.Item>
                        <Form.Item label="Age Group" name="ageGroup" rules={[{ required: true, message: 'This field is required' }]} style={{ marginBottom: 0 }}>
                          
                            <Select   style={{
                                    width: "255px",
                                    border: "1px solid #d9d9d9",
                                    backgroundColor: "white",
                                }}
                             value={ageGroup}
                             onChange={(value) => setAgeGroup(value)}
                             placeholder="Please Select agegroup"
                            
                            >
                            {_.map(
                              _.get(courseList, "ageGroups"),
                              (agegroups, each) => {
                                console.log("agegroups", agegroups);
                                return (
                                  <Option
                                    value={_.get(agegroups, "name") + ""}
                                    key={each}
                                  >
                                    {_.get(agegroups, "name")}
                                  </Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>

                        <Form.Item label="Audit Date" name="auditDate" rules={[{ required: true, message: 'This field is required' }]} style={{ marginBottom: 0 }}>
                            <DatePicker
                                value={auditDate}
                                onChange={(date) => setAuditDate(date)}
                                style={{
                                    width: "255px",
                                    border:"1px solid lightgrey",
                                    backgroundColor: "white",
                                }}
                                placeholder="Please Select Audit Date"
                            />
                        </Form.Item>
                        <Form.Item label="Feedback Status" name="feedbackStatus" rules={[{ required: true, message: 'This field is required' }]} style={{ marginBottom: 0 }}>
                            <Input
                                value={feedbackStatus}
                                onChange={(e) => setFeedbackStatus(e.target.value)}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item label="Feedback Link" name="feedbackLink" rules={[{ required: true, message: 'This field is required' }]} style={{ marginBottom: 0 }}>
                            <Input
                                value={feedbackLink}
                                onChange={(e) => setFeedbackLink(e.target.value)}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <p>
                            <b>Openings</b>
                        </p>
                        <Form.Item
  label="Started on Time"
  name="startedOnTime"
  rules={[
    { required: true},
    {
      validator: (_, value) => {
        const valid = /^0(\.\d*)?$|^1(\.0*)?$/.test(value);
        if (!valid) {
          return Promise.reject('Please enter a number between 0 and 1');
        }
        return Promise.resolve();
      },
    },
  ]}
  className="started-on-time-form-item"
  style={{ marginBottom: "0px" }}
>
  <Input
    value={startedOnTime}
    onChange={(e) => {
      const { value } = e.target;
      if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
        setStartedOnTime(value);
      }
    }}
    style={{ width: "255px" }}
    placeholder=" Please Enter here..."
  />
</Form.Item>

                        <Form.Item label="Internet Connection" name="internetConnection" 
                         rules={[
                            { required: true},
                            {
                              validator: (_, value) => {
                                const valid = /^0(\.\d*)?$|^1(\.0*)?$/.test(value);
                                if (!valid) {
                                  return Promise.reject('Please enter a number between 0 and 1');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]} style={{ marginBottom: 0 }}>
                            <Input
                                value={internetConnection}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setInternetConnection(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item
                           rules={[
                            { required: true},
                            {
                              validator: (_, value) => {
                                const valid = /^([0-3](\.\d*)?|3(\.0*)?)$/.test(value);
                                if (!valid) {
                                  return Promise.reject('Please enter a number between 0 to 3');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                            label="Greeting/Introduction"
                            name="greetingIntroduction"
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                value={greetings}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setGreetings(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <p>
                            <b>Feedback</b>
                        </p>
                        <Form.Item
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-5](\.\d*)?|5(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 5');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            label="Assignment of the Previous Class"
                            name="constructiveFeedbackWhileDiscussingTheAssessmentAssignmentOfThePreviousClass"
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                value={assignmentOfPreviousClass}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setAssignmentOfPreviousClass(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item label="AOI with Examples"
                           rules={[
                            { required: true},
                            {
                              validator: (_, value) => {
                                const valid = /^([0-2](\.\d*)?|2(\.0*)?)$/.test(value);
                                if (!valid) {
                                  return Promise.reject('Please enter a number between 0 to 2');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                            name="aOIWithExamples"
                            style={{ marginBottom: 0 }}>
                            <Input
                                value={aoiWithExamples}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setAoiWithExamples(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item
                           rules={[
                            { required: true},
                            {
                              validator: (_, value) => {
                                const valid = /^([0-2](\.\d*)?|2(\.0*)?)$/.test(value);
                                if (!valid) {
                                  return Promise.reject('Please enter a number between 0 to 2');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                            label="Address the Student by Their Name"
                            name="addressTheStudentByTheirName"
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                value={addressByStudentName}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setAddressByStudentName(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <p>
                            <b>Reporting Building</b>
                        </p>
                        <Form.Item label="Engagement Level"
                           rules={[
                            { required: true},
                            {
                              validator: (_, value) => {
                                const valid = /^([0-5](\.\d*)?|5(\.0*)?)$/.test(value);
                                if (!valid) {
                                  return Promise.reject('Please enter a number between 0 to 5');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                            name="engagementLevel"
                            style={{ marginBottom: 0 }}>
                            <Input
                                value={engagementLevel}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setEngagementLevel(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item
                            label="Made the Student Feel Comfortable"
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-5](\.\d*)?|5(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 5');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            name="madeTheStudentFeelComfortable"
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                value={studentComfort}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setStudentComfort(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <p>
                            <b>Communication skills and Soft skills</b>
                        </p>
                        <Form.Item
                            label="Dead Air/Fumbling/Fillers/Unnecessary Mute Needs to be Avoided"
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-2](\.\d*)?|2(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 2');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            name="deadAirFumblingFillersUnnecessaryMuteNeedsToBeAvoided"
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                value={avoidanceCriteria}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setAvoidanceCriteria(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item
                            label="Casual Approach/Tone, Usage of Slang Language"
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-2](\.\d*)?|2(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 2');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            name="casualApproachToneUsageOfSlangLanguage"
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                value={casualApproach}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setCasualApproach(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item label="Acknowledgment"
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-5](\.\d*)?|5(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 5');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            name="acknowledgment"
                            style={{ marginBottom: 0 }}>
                            <Input
                                value={acknowledgment}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setAcknowledgment(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item label="Active Listening"
                            name="activeListening"
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-2](\.\d*)?|2(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 2');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            style={{ marginBottom: 0 }}>
                            <Input
                                value={activeListening}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setActiveListening(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item label="Interruption"
                            name="interruption"
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-2](\.\d*)?|2(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 2');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            style={{ marginBottom: 0 }}>
                            <Input
                                value={interruption}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setInterruption(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item label="Energy Level"
                            name="energyLevel"
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-5](\.\d*)?|5(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 5');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            style={{ marginBottom: 0 }}>
                            <Input
                                value={energyLevel}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setEnergyLevel(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item label="Rate of Speech"
                           rules={[
                            { required: true},
                            {
                              validator: (_, value) => {
                                const valid = /^([0-2](\.\d*)?|2(\.0*)?)$/.test(value);
                                if (!valid) {
                                  return Promise.reject('Please enter a number between 0 to 2');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                            name="rateOfSpeech"
                            style={{ marginBottom: 0 }}>
                            <Input
                                value={rateSpeech}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setRateSpeech(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <p>
                            <b>Closing</b>
                        </p>
                        <Form.Item label="Customer Delight"
                           rules={[
                            { required: true},
                            {
                              validator: (_, value) => {
                                const valid = /^([0-5](\.\d*)?|5(\.0*)?)$/.test(value);
                                if (!valid) {
                                  return Promise.reject('Please enter a number between 0 to 5');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                            name="customerDelight"
                            style={{ marginBottom: 0 }}>
                            <Input
                                value={customerDelight}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setCustomerDelight(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item
                            label="Doubts solving/ query resolution"
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-2](\.\d*)?|2(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 2');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            name="DoubtsSolvingQueryResolution"
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                value={doubtsSolving}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setDoubtSolving(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item
                            label="Energetic and positive closing"
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-2](\.\d*)?|2(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 2');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            name="energeticAndPositiveClosing"
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                value={energetic}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setEnergetic(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item
                            label="Disconnection after the student"
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-2](\.\d*)?|2(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 2');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            name="disconnectionAfterTheStudent"
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                value={disconnect}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setDisconnect(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <p>
                            <b>Tech & Gadgets</b>
                        </p>
                        <Form.Item label="Mic"
                           rules={[
                            { required: true},
                            {
                              validator: (_, value) => {
                                const valid = /^([0-5](\.\d*)?|5(\.0*)?)$/.test(value);
                                if (!valid) {
                                  return Promise.reject('Please enter a number between 0 to 5');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                            name="mic"
                            style={{ marginBottom: 0 }}>
                            <Input
                                value={mic}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setMic(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item label="Headphones"
                           rules={[
                            { required: true},
                            {
                              validator: (_, value) => {
                                const valid = /^([0-5](\.\d*)?|5(\.0*)?)$/.test(value);
                                if (!valid) {
                                  return Promise.reject('Please enter a number between 0 to 5');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                            name="headphones"
                            style={{ marginBottom: 0 }}>
                            <Input
                                value={headphones}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setHeadphones(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item label="Jacket"
                           rules={[
                            { required: true},
                            {
                              validator: (_, value) => {
                                const valid = /^([0-5](\.\d*)?|5(\.0*)?)$/.test(value);
                                if (!valid) {
                                  return Promise.reject('Please enter a number between 0 to 5');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                            name="jacket"
                            style={{ marginBottom: 0 }}>
                            <Input
                                value={jacket}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setJacket(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item
                            label="Video Quality/Cam Angle/Body Language/Background"
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-5](\.\d*)?|5(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 5');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            name="videoQualityCamAngleBodyLanguageBackground"
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                value={videoQuality}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setVideoQuality(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item
                            label="Practice Studio (Yes/No)"
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-5](\.\d*)?|5(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 5');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            name="practiceStudio"
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                value={practiceStudio}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setPracticeStudio(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item
                            label="Please specify the instrument used (Personal/Dashboard)"
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-5](\.\d*)?|5(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 5');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            name="pleaseSpecifyTheInstrumentUsed"
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                value={instrumentUsed}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setInstrumentUsed(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <p>
                            <b>Presentation</b>
                        </p>
                        <Form.Item
                            label="Appropriately Dressed"
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-5](\.\d*)?|5(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 5');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            name="appropriatelyDressed"
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                value={appropriatelyDressed}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setAppropriatelyDressed(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item label="Video On"
                          rules={[
                            { required: true},
                            {
                              validator: (_, value) => {
                                const valid = /^([0-5](\.\d*)?|5(\.0*)?)$/.test(value);
                                if (!valid) {
                                  return Promise.reject('Please enter a number between 0 to 5');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                            name="videOn"
                            style={{ marginBottom: 0 }}>
                            <Input
                                value={videoOn}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setVideoOn(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <Form.Item
                            label="Rude/Sarcastic/Intimidating Behaviour/Unprofessionalism"
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-5](\.\d*)?|5(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 5');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            name="rudeSarcasticIntimidatingBehaviourUnprofessionalism"
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                value={unprofessionalBehaviour}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setUnprofessionalBehaviour(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder=" Please Enter here..."
                            />
                        </Form.Item>
                        <p>
                            <b>Time</b>
                        </p>
                        <Form.Item
                            label="Session as per standard Time"
                            name="sessionAsPerStandardTime"
                            rules={[
                                { required: true},
                                {
                                  validator: (_, value) => {
                                    const valid = /^([0-5](\.\d*)?|5(\.0*)?)$/.test(value);
                                    if (!valid) {
                                      return Promise.reject('Please enter a number between 0 to 5');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                value={sessionStandardTime}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '' || value === '.') {
                                        setSessionStandardTime(value);
                                    }
                                  }}
                                style={{ width: "255px" }}
                                placeholder="Please Enter Session standard Time"
                            />
                        </Form.Item>
                        <Form.Item label="Observation"
                            rules={[{ required: true, message: 'This field is required' }]}
                            name="observation"
                            style={{ marginBottom: 0 }}>
                            <Input
                                value={observation}
                                onChange={(e) => setObservation(e.target.value)}
                                style={{ width: "255px" }}
                                placeholder="Please Enter Observation"
                            />
                        </Form.Item>
                      
                        <Form.Item
                            style={{ display: "flex", float: "right", marginBottom: 0 }}
                        >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            )}
</div>
            
            </div>
            {showForm && !isModalVisible && !editModalVisible &&
              <div style={{marginTop:"25px"}}>
      <h3>QA Reports</h3>
      <Form
        form={form}
        layout="vertical"
        // labelAlign="left"
        labelCol={{ span: 14 }}
        style={{
            padding: "20px",
            backgroundColor: "#f7f7f7",
            overflow: "hidden",
            marginTop:"20px"
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="dates"
          label="Date Range"
          rules={[{ required: true, message: 'Please select date range' }]}
        >
          <RangePicker/>
        </Form.Item>
        <Form.Item label="Search Teacher" name="teacherId" style={{ marginBottom: 0 }}>
                            <Input
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                              
                                style={{ width: "40%" }}
                            />
                            <Button
                                type="primary"
                                disabled={!searchQuery.trim()}
                                onClick={handleSearch}
                            >
                                Search
                            </Button>
                        </Form.Item>
                        <Modal
                            title="Select Teacher"
                            visible={modalVisible}
                            onCancel={handleModalClose}
                            footer={[
                                <Button key="cancel" onClick={handleModalClose}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary" onClick={handleAddTeacher}>
                                    Add Teacher
                                </Button>,
                            ]}
                        >
                            <Radio.Group style={{ display: "inline" }}>
                                {teachersList.map((teacher) => (
                                    <div key={teacher.teacherId} className="teacher-card">
                                        <Radio
                                            value={teacher.teacherId}
                                            checked={selectedTeacherId === teacher.teacherId}
                                            onClick={() =>
                                                setSelectedTeacherId(teacher.teacherId)
                                            }
                                        >
                                            {teacher.teacherName} - {teacher.mobileNumber} -{" "}
                                            {teacher.emailId}
                                        </Radio>
                                    </div>
                                ))}
                            </Radio.Group>
                        </Modal>
                        <Form.Item label="Teacher Name"
                      
                            style={{ marginBottom: 0 }}>
                            <Input
                                value={selectedTeacherName}
                                style={{ width: "255px" }}
                                disabled
                                placeholder="Enter Teacher Name"
                            />
                        </Form.Item>
        <Form.Item style={{marginTop:"20px"}}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Generate Report
          </Button>
        </Form.Item>
      </Form>
      {error && <p>{error}</p>}
      {qaReportDetail.length > 0 ? (
      <>
      {/* <Button onClick={exportToExcel} style={{ marginTop: '20px', marginBottom:"20px" }}>Export to CSV</Button> */}
      <Table
        dataSource={qaReportDetail}
        columns={columns}
        loading={isLoading}
        rowKey={(record) => record.teacherName}
        pagination={false}
      />
    </>

            
     ) : (
        <div style={{display:"flex", justifyContent:"center"}}>No data Found</div>
     )}
    </div>
}
        </div>
    );
};

const mapStateToProps = (state) => ({
    teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
    list: (details) => dispatch(global.redux.action.teachers.list(details)),
    courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherQa);