import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, notification, Form, Input, Card, Modal, Select, Spin } from 'antd';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import * as SecureLS from "secure-ls";
import _ from "lodash";
import appEnvirontVariables from "../../core_units/config/env-variables";
const { TextArea } = Input;
const { Option } = Select;

const DetailViewOps = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const ls = new SecureLS({ encodingType: "aes" });
  const location = useLocation();
  const activeTab = location.state?.activeTab || "1";
  const token = ls.get("isLogin");

  const [leadData, setLeadData] = useState({});
  const [demoCallStatus1, setDemoCallStatus1] = useState("");
  const [demoCallStatus2, setDemoCallStatus2] = useState("");
  const [notConductReason, setNotConductReason] = useState("");
  const [notConductedClicked, setNotConductedClicked] = useState(false);
  const [demoCallSelectClicked, setDemoCallSelectClicked] = useState(false); 
  const [demoCallSelect2Clicked, setDemoCallSelect2Clicked] = useState(false); 
  const [commentClicked, setCommentClicked] = useState(false);
  const [comment, setComment] = useState("");
  const [historyList, setHistoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    emailId: '',
    mobileNumber: '', 
    countryCode: '', 
    state: '', 
    timeZone: '', 
    ageGroup: '', 
    couponCode: '', 
    studentId: '', 
    course: '', 
    demoStatus: '', 
    courseName: '',
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isEditable = !!leadData.recordId;

  const showModal = async () => {
    try {
      const response = await getHistory();
      if (response?.data?.data?.Items) {
        response.data.data.Items.forEach((v) => {
          v.createdOn = convertDate(v.createdOn);
        });
        setHistoryList(response.data.data.Items);
      }
      setIsModalVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  
  const convertDate = (gmtDate) => {
    const istDate = new Date(gmtDate);
    const day = istDate.getDate();
    const month = istDate.toLocaleString('default', { month: 'short' });
    const year = istDate.getFullYear();
    let hours = istDate.getHours();
    const minutes = istDate.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    return `${day} ${month} ${year} ${hours}:${formattedMinutes} ${ampm}`;
  };

  const fetchLeadData = async (stateData) => {
    setLoading(true);
    let reqBody;
    if(stateData.selectedFromDate && stateData.selectedToDate){
      reqBody = {
        fromDate: stateData.selectedFromDate,
        toDate: stateData.selectedToDate,
        recordId: stateData.recordId,
        filter:"",
        filterOn:""
    
        
      }
    }else{
      reqBody = {
        agentId: stateData.agentId,
        recordId: stateData.recordId,
        filter:"",
        filterOn:""
      }
    }
    try {

      const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/leads`;
      const response = await axios.post(apiUrl, reqBody, {
        headers: {
          Authorization: token["authorization"]
        }
      });
      if (response.data.content.length) {
        setLeadData(response.data.content[0]);
      } else {
        setLeadData({});
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state?.recordId) {
      fetchLeadData(location.state);
    } else {
      setLeadData(location.state || {});
      setLoading(false);
    }
  }, [location.state.recordId]);



  useEffect(() => {
    setComment(leadData.agentComments || "");
    setDemoCallStatus1(leadData.demoCallStatus1 || "");
    setDemoCallStatus2(leadData.demoCallStatus2 || "");
    setNotConductReason(leadData.notConductedReason || "");
    if (leadData && Object.keys(leadData).length > 0) {
      const demoStatus = (leadData.demoStatus === "Class_Ready_For_Scheduling" && leadData.classDeleted)
      ? "RESCHEDULED"
      : (leadData.demoStatus === "SCHEDULED"
        ? leadData.actionPerformed === "teacher_demo_cancelclass"
          ? "NOT CONDUCTED"
          : leadData.actionPerformed === "student_trial_cancelclass"
            ? "CANCELLED"
            : leadData.demoStatus
        : leadData.demoStatus);
      const formData = {
        agentName: leadData.agentName || '',
        firstName: leadData.firstName || '',
        teacherName: leadData.teacherFirstName || '', 
        courseName: leadData.courseName || '',  
        emailId: leadData.email || '',
        mobileNumber: `${leadData.countryCode || ''}${leadData.mobileNumber || ''}`,
        startTime:leadData.startTime || '',
        countryCode: leadData.countryCode || '',
        state: leadData.state || '',
        timeZone: leadData.studentTimezone || '',
        ageGroup: leadData.ageGroup || '',
        couponCode: leadData.couponCode || '',
        studentId: leadData.studentId || '',
        agentComments: leadData.agentComments || '',
        course: leadData.courseName || '',
        demoStatus: demoStatus,
      };
      form.setFieldsValue(formData);
      setFormData(formData);
    }
  }, [leadData, form]);

  const navigateBack = () => {
    navigate(`/DemoOps`, { state: { activeTab } });
  };

  const getHistory = () => {
    return axios.get(`${appEnvirontVariables.API.DEMOOPS_HISTORY_URL}/api/lead-history/${leadData.studentId}`,
      { headers: { Authorization: token["authorization"] } });
  };

  const handleSaveChanges = () => {
    setLoading(true);
    let status = [];
    let leadHistoryList = [];

    if (demoCallStatus1 || leadData.demoCallStatus1) {
      if (demoCallSelectClicked) {
        status.push(`&latestDemoStatus=${demoCallStatus1 || leadData.demoCallStatus1}`);
        leadHistoryList.push({
          id: leadData.studentId.toString(),
          field: "Demo Call Status 1",
          user: token.studentName,
          user_id: token.uId,
          old_value: leadData.demoCallStatus1,
          new_value: demoCallStatus1 || leadData.demoCallStatus1,
        });
      }
    }

    if (demoCallStatus2 || leadData.demoCallStatus2) {
      if (demoCallSelect2Clicked) {
        status.push(`&latestDemoStatus=${demoCallStatus2 || leadData.demoCallStatus2}`);
        leadHistoryList.push({
          id: leadData.studentId.toString(),
          field: "Demo Call Status 2",
          user: token.studentName,
          user_id: token.uId,
          old_value: leadData.demoCallStatus2,
          new_value: demoCallStatus2 || leadData.demoCallStatus2,
        });
      }
    }

    if (notConductReason || leadData.notConductedReason) {
      if (notConductReason && notConductReason !== leadData.notConductedReason) {
        if (notConductedClicked) {
          status.push(`&notConductedReason=${notConductReason || leadData.notConductedReason}`);
          leadHistoryList.push({
            id: leadData.studentId.toString(),
            field: "Not Conduct Reason",
            user: token.studentName,
            user_id: token.uId,
            old_value: leadData.notConductedReason,
            new_value: notConductReason || leadData.notConductedReason,
          });
        }
      }
    }

    if (comment?.trim() && commentClicked ) {
      if (commentClicked) {
        leadHistoryList.push({
          id: leadData.studentId.toString(),
          field: "Comments",
          user: token.studentName,
          user_id: token.uId,
          old_value: leadData.agentComments || "",
          new_value: comment,
        });
      }
    }

    if (demoCallSelectClicked || demoCallSelect2Clicked || notConductedClicked ||  leadData.agentComments !== comment || (notConductReason && notConductReason !== leadData.notConductedReason)) {
      Modal.confirm({
        title: 'Are you sure you want to save changes?',
        onOk: () => {
          updateStatusAPI(leadData, status, () => {
            leadHistoryList.forEach(data => addLeadHistory(data));
          });
          setLoading(false);
        },
        onCancel: () => {
          console.log('Save changes cancelled');
          setLoading(false);
        },
      });
    } else {
      setLoading(false);
    }
  };

  const updateStatusAPI = (leadData, status, cb) => {
    setLoading(true);
    const payload = {
      classId: leadData.classScheduleId,
      DemoCallStatus1:  demoCallStatus1 || leadData.demoCallStatus1 ,
      latestDemoStatus: demoCallSelectClicked ? demoCallStatus1 : demoCallStatus2,
      DemoCallStatus2: demoCallStatus2 || leadData.demoCallStatus2,
      notConductedReason: notConductReason || leadData.notConductedReason,
      comments: comment,
      agentId: leadData.agentId
    };
    axios.post(`${appEnvirontVariables.API.BASE_URL}/api/admin/new/update/agent/lead`, payload, {
      headers: {
        Authorization: token["authorization"],
        "Content-Type": "application/json"
      }
    }).then(response => {
      notification.success({ message: "Status updated successfully!" });
      cb();
      setLoading(false);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });
  };

  const addLeadHistory = (data, notify = false) => {
    const apiUrl = `${appEnvirontVariables.API.DEMOOPS_HISTORY_URL}/api/lead-history`;

    axios.post(apiUrl, data, {
      headers: { Authorization: token["authorization"] }
    })
      .then(response => {
        if (notify) {
          notification.success({ message: "Comments added successfully!" });
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
        notification.error({ message: "Failed to update lead history." });
      });
  };

  return (
    <div>
      <div style={{ marginBottom: "30px" }}>
        <Button
          type="primary"
          htmlType="submit"
          onClick={navigateBack}
        >
          Back
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          style={{ display: "flex", float: "right" }}
          onClick={showModal}
        >
          Lead History
        </Button>
      </div>
      <Card style={{ width: "100%", height: "auto", backgroundColor: "white" }}>
        <div style={{ border: "1px solid grey", padding: "35px" }}>
          {formData && (
            <div>
              <Form form={form}>
                {leadData.viewName === "allLeads" && 
                  <div className="row mb-6">
                    <div className="col-lg-4 col-form-label required fw-bold fs-6">
                      Agent Name
                    </div>
                    <div className="col-lg-6 fv-row">
                      <Form.Item
                        className="form-control form-control-lg form-control-solid"
                        name={"agentName"}
                        style={{ backgroundColor: "#f5f8fa" }}
                      >
                        <Input style={{ backgroundColor: "#f5f8fa", border: "none" }} readOnly />
                      </Form.Item>
                    </div>
                  </div>
                }
                <div className="row mb-6">
                  <div className="col-lg-4 col-form-label required fw-bold fs-6">
                    Student Name
                  </div>
                  <div className="col-lg-6 fv-row">
                    <Form.Item
                      className="form-control form-control-lg form-control-solid"
                      name={"firstName"}
                      style={{ backgroundColor: "#f5f8fa" }}
                    >
                      <Input style={{ backgroundColor: "#f5f8fa", border: "none" }} readOnly />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mb-6">
                  <div className="col-lg-4 col-form-label required fw-bold fs-6">
                    Course Name
                  </div>
                  <div className="col-lg-6 fv-row">
                    <Form.Item
                      className="form-control form-control-lg form-control-solid"
                      name={"courseName"}
                      style={{ backgroundColor: "#f5f8fa" }}
                    >
                      <Input style={{ backgroundColor: "#f5f8fa", border: "none" }} readOnly />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mb-6">
                  <div className="col-lg-4 col-form-label required fw-bold fs-6">
                    Student ID
                  </div>
                  <div className="col-lg-6 fv-row">
                    <Form.Item
                      className="form-control form-control-lg form-control-solid"
                      name={"studentId"}
                      style={{ backgroundColor: "#f5f8fa" }}
                    >
                      <Input style={{ backgroundColor: "#f5f8fa", border: "none" }} readOnly />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mb-6">
                  <div className="col-lg-4 col-form-label required fw-bold fs-6">
                    Teacher Name
                  </div>
                  <div className="col-lg-6 fv-row">
                    <Form.Item
                      className="form-control form-control-lg form-control-solid"
                      name={"teacherName"}
                      style={{ backgroundColor: "#f5f8fa" }}
                    >
                      <Input style={{ backgroundColor: "#f5f8fa", border: "none" }} readOnly />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mb-6">
                  <div className="col-lg-4 col-form-label required fw-bold fs-6">
                    Email
                  </div>
                  <div className="col-lg-6 fv-row">
                    <Form.Item
                      className="form-control form-control-lg form-control-solid"
                      name={"emailId"}
                      style={{ backgroundColor: "#f5f8fa" }}
                    >
                      <Input style={{ backgroundColor: "#f5f8fa", border: "none" }} readOnly />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mb-6">
                  <div className="col-lg-4 col-form-label required fw-bold fs-6">
                    Mobile Number
                  </div>
                  <div className="col-lg-6 fv-row">
                    <Form.Item
                      className="form-control form-control-lg form-control-solid"
                      name={"mobileNumber"}
                      style={{ backgroundColor: "#f5f8fa" }}
                    >
                      <Input style={{ backgroundColor: "#f5f8fa", border: "none" }} readOnly />
                    </Form.Item>
                  </div>
                </div>


                <div className="row mb-6">
                  <div className="col-lg-4 col-form-label required fw-bold fs-6">
                    Course
                  </div>
                  <div className="col-lg-6 fv-row">
                    <Form.Item
                      className="form-control form-control-lg form-control-solid"
                      name={"course"}
                      style={{ backgroundColor: "#f5f8fa" }}
                    >
                      <Input style={{ backgroundColor: "#f5f8fa", border: "none" }} readOnly />
                    </Form.Item>
                  </div>
                </div>

                <div className="row mb-6">
                  <div className="col-lg-4 col-form-label required fw-bold fs-6">
                 Class Time
                  </div>
                  <div className="col-lg-6 fv-row">
                    <Form.Item
                      className="form-control form-control-lg form-control-solid"
                      name={"startTime"}
                      style={{ backgroundColor: "#f5f8fa" }}
                    >
                      <Input style={{ backgroundColor: "#f5f8fa", border: "none" }} readOnly />
                    </Form.Item>
                  </div>
                </div>
                {leadData.gender && 
                  <div className="row mb-6">
                    <div className="col-lg-4 col-form-label required fw-bold fs-6">
                      Gender
                    </div>
                    <div className="col-lg-6 fv-row">
                      <Form.Item
                        className="form-control form-control-lg form-control-solid"
                        name={"gender"}
                        style={{ backgroundColor: "#f5f8fa" }}
                      >
                        <Input style={{ backgroundColor: "#f5f8fa", border: "none" }} readOnly />
                      </Form.Item>
                    </div>
                  </div>
                }
                <div className="row mb-6">
                  <div className="col-lg-4 col-form-label required fw-bold fs-6">
                    TimeZone
                  </div>
                  <div className="col-lg-6 fv-row">
                    <Form.Item
                      className="form-control form-control-lg form-control-solid"
                      name={"timeZone"}
                      style={{ backgroundColor: "#f5f8fa" }}
                    >
                      <Input style={{ backgroundColor: "#f5f8fa", border: "none" }} readOnly />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mb-6">
                  <div className="col-lg-4 col-form-label required fw-bold fs-6">
                    State
                  </div>
                  <div className="col-lg-6 fv-row">
                    <Form.Item
                      className="form-control form-control-lg form-control-solid"
                      name={"state"}
                      style={{ backgroundColor: "#f5f8fa" }}
                    >
                      <Input style={{ backgroundColor: "#f5f8fa", border: "none" }} readOnly />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mb-6">
                  <div className="col-lg-4 col-form-label required fw-bold fs-6">
                    Age Group
                  </div>
                  <div className="col-lg-6 fv-row">
                    <Form.Item
                      className="form-control form-control-lg form-control-solid"
                      name={"ageGroup"}
                      style={{ backgroundColor: "#f5f8fa" }}
                    >
                      <Input style={{ backgroundColor: "#f5f8fa", border: "none" }} readOnly />
                    </Form.Item>
                  </div>
                </div>
                {leadData.couponCode && 
                  <div className="row mb-6">
                    <div className="col-lg-4 col-form-label required fw-bold fs-6">
                      Coupon Code
                    </div>
                    <div className="col-lg-6 fv-row">
                      <Form.Item
                        className="form-control form-control-lg form-control-solid"
                        name={"couponCode"}
                        style={{ backgroundColor: "#f5f8fa" }}
                      >
                        <Input style={{ backgroundColor: "#f5f8fa", border: "none" }} readOnly />
                      </Form.Item>
                    </div>
                  </div>
                }
                <div className="row mb-6">
                  <div className="col-lg-4 col-form-label required fw-bold fs-6">
                    Demo Status 
                  </div>
                  <div className="col-lg-6 fv-row">
                    <Form.Item
                      className="form-control form-control-lg form-control-solid"
                      name={"demoStatus"}
                      style={{ backgroundColor: "#f5f8fa" }}
                    >
                      <Input style={{ backgroundColor: "#f5f8fa", border: "none" }} readOnly />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mb-6">
                  <div className="col-lg-4 col-form-label required fw-bold fs-6">
                    Demo Call Status 1
                  </div>
                  <div className="col-lg-6 fv-row">
                    <Form.Item
                      className="form-control form-control-lg form-control-solid"
                      style={{ backgroundColor: "#f5f8fa" }}
                    >
                      <Select 
                        value={demoCallStatus1} 
                        disabled={!isEditable || leadData.viewName === "allLeads"}
                        onClick={() => setDemoCallSelectClicked(true)} 
                        onChange={(value) => setDemoCallStatus1(value)}
                        style={{ backgroundColor: "#f5f8fa", border: "none" }}
                        placeholder="Select Demo Call Status 1"
                      >
                        <Option value="Confirmed">Confirmed</Option>
                        <Option value="Unanswered">Unanswered</Option>
                        <Option value="Not contactable">Not contactable</Option>
                        <Option value="Wishes to reschedule">Wishes to reschedule</Option>
                        <Option value="Wishes to cancel">Wishes to cancel</Option>
                        <Option value="Wrong number">Wrong number</Option>
                        <Option value="Invalid number">Invalid number</Option>
                        <Option value="Langauge barrier">Langauge barrier</Option>
                        <Option value="Call back later">Call back later</Option>
                        <Option value="Disconnected after intro">Disconnected after intro</Option>
                        <Option value="Voicemail">Voicemail</Option>
                        <Option value="Not interested">Not interested</Option>  
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row mb-6">
                  <div className="col-lg-4 col-form-label required fw-bold fs-6">
                    Demo Call Status 2
                  </div>
                  <div className="col-lg-6 fv-row">
                    <Form.Item
                      className="form-control form-control-lg form-control-solid"
                      style={{ backgroundColor: "#f5f8fa" }}
                    >
                      <Select 
                        value={demoCallStatus2} 
                        disabled={!isEditable || leadData.viewName === "allLeads"}
                        onClick={() => setDemoCallSelect2Clicked(true)} 
                        onChange={(value) => setDemoCallStatus2(value)}
                        style={{ backgroundColor: "#f5f8fa", border: "none" }}
                        placeholder="Select Demo Call Status 2"
                      >
                        <Option value="Confirmed">Confirmed</Option>
                        <Option value="Unanswered">Unanswered</Option>
                        <Option value="Not contactable">Not contactable</Option>
                        <Option value="Wishes to reschedule">Wishes to reschedule</Option>
                        <Option value="Wishes to cancel">Wishes to cancel</Option>
                        <Option value="Wrong number">Wrong number</Option>
                        <Option value="Invalid number">Invalid number</Option>
                        <Option value="Langauge barrier">Langauge barrier</Option>
                        <Option value="Call back later">Call back later</Option>
                        <Option value="Disconnected after intro">Disconnected after intro</Option>
                        <Option value="Voicemail">Voicemail</Option>
                        <Option value="Not interested">Not interested</Option>  
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row mb-6">
                  <div className="col-lg-4 col-form-label required fw-bold fs-6">
                    Not Conduct Reason 
                  </div>
                  <div className="col-lg-6 fv-row">
                    <Form.Item
                      className="form-control form-control-lg form-control-solid"
                      style={{ backgroundColor: "#f5f8fa" }}
                    >
                      <Select 
                        value={notConductReason} 
                        disabled={!isEditable || leadData.viewName === "allLeads"}
                        onClick={() => setNotConductedClicked(true)} 
                        onChange={(value) => setNotConductReason(value)}
                        style={{ backgroundColor: "#f5f8fa", border: "none" }}
                        placeholder="Select Not Conducted Reason"
                      >
                        <Option value="No AE found">No AE found</Option>
                        <Option value="Network/connectivity issues">Network/connectivity issues</Option>
                        <Option value="Language barrier">Language barrier</Option>
                        <Option value="Course change">Course change</Option>
                        <Option value="Zoom connectivity issue">Zoom connectivity issue</Option>
                        <Option value="No response from the student">No response from the student</Option>
                        <Option value="Tech issue">Tech issue</Option>
                        <Option value="Student canceled/rescheduled">Student canceled/rescheduled</Option>
                        <Option value="Teacher last minute unavailable">Teacher last minute unavailable</Option>
                        <Option value="The teacher joined late">The teacher joined late</Option>
                        <Option value="The student joined late">The student joined late</Option>
                        <Option value="Parents not around">Parents not around</Option>
                        <Option value="User not prepared to sing">User not prepared to sing</Option>
                        <Option value="The student wishes to reschedule">The student wishes to reschedule</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                {leadData.viewName !== "allLeads" && 
                  <div className="row mb-6">
                    <div className="col-lg-4 col-form-label required fw-bold fs-6">
                      Comments
                    </div>
                    <div className="col-lg-6 fv-row">
                      <Form.Item>
                        <TextArea
                          className="form-control form-control-lg form-control-solid"
                          style={{ backgroundColor: "#f5f8fa" }}
                          disabled={!isEditable || leadData.viewName === "allLeads"}
                          onClick={() => setCommentClicked(true)} 
                          name="comment"
                          value={comment}
                          onChange={e => setComment(e.target.value)}
                          rows={4}
                        />
                      </Form.Item>
                    </div>
                  </div>
                }
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {leadData.viewName !== "allLeads" && 
                    <Button
                      type="primary" 
                      htmlType="submit"
                      onClick={handleSaveChanges}
                      disabled={!isEditable}
                    >
                      Save Changes
                    </Button>
                  }
                </div>
              </Form>
            </div>
          )}
        </div>
      </Card>
      <Modal title="Lead History" visible={isModalVisible} onCancel={handleCancel} width={800} footer={null}>
        {historyList && (
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>Field</th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>Old Value</th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>New Value</th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>Date</th>
              </tr>
            </thead>
            <tbody>
              {historyList.map((history, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{history.field}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{history.old_value}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{history.new_value}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{history.createdOn}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Modal>
    </div>
  );
};

export default DetailViewOps;
