import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Checkbox,
  Tabs,
  Form,
  Button,
  Radio,
  Select,
  Modal,
  notification,
} from "antd";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import appEnvirontVariables from "../../../core_units/config/env-variables";
import * as SecureLS from "secure-ls";
import { data } from "jquery";

const Rescheduleteachers = (props) => {
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const [isModalVisible, setIsModalVisible] = useState(props.demoslotteacher);
  const [form] = Form.useForm();
  const [studentId] = useSearchParams();
  const { Option } = Select;
  const { confirm } = Modal;
  const [courselist, setcourselist] = useState();
  const [selectedStatus, setSelectedStatus] = useState("");
  // useEffect(() => {
  //   apiCall();
  //   console.log("courselist", courselist);
  // }, []);

  const apiCall = () => {
    props.getavailableTeacher({
      body: {
        bookingClassEndTime: _.get(props, "rescheduleslotteacher.classEndTime"),
        bookingClassStartTime: _.get(
          props,
          "rescheduleslotteacher.classStartTime"
        ),
        bookingDate: moment(
          _.get(props, "rescheduleslotteacher.classDate")
        ).format("DD/MM/YYYY"),
        bookingType: "",
        isDemoStudent: true,
        isFreeStudent: false,
        isPaidStudent: false,
        studentId: null,

        teacherId: null,
        userId: studentId.get("credid"),
        voucherCode: "",
      },
      callback: (response, data) => {
        console.log("courselist", data);
        setcourselist(data);
      },
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    props.setdemoslotteacher(false);
  };
  const reAssignTeacherforAirline = async (data) => {
    console.log(selectedStatus,"selectedStatus==")
    return axios.post(
      `${appEnvirontVariables.API.BASE_URL}/api/admin/airline/resassign/teacher?classScheduleId=${data.classScheduleId}&airlineTeacherSlot=${selectedStatus}`,
      {
        classScheduleId: +data.classScheduleId,
        airlineTeacherSlot: selectedStatus,
      },
      { headers: { Authorization: token["authorization"] } }
    );
  };
  const onFinish = (values) => {
    confirm({
      title: "Do you Want to do this action?",
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        if (props?.rescheduleslotteacher?.isAirlineBooking == "true") {
          reAssignTeacherforAirline(
            props?.rescheduleslotteacher,
            _.get(values, "id")
          ).then((res) => {
            
            props.setdemoslotteacher(false);
            props.setPreviewModalVisible(false);
            if (_.isEqual(selectedStatus, "Success")) {
              console.log(selectedStatus,"res.data.success")
              // props.apiCall(datefrom, dateTo);
              notification.success({
                message: "Slot allocated successfully.",
              });
            } else {
              notification.success({
                message: "Slot is Booked successfully.",
              });
            }
          });
        } else {
          props.courseallocation({
            params: {
              status: "prebook",
            },
            body: {
              bookingClassEndTime: _.get(
                props,
                "rescheduleslotteacher.classEndTime"
              ),
              bookingClassStartTime: _.get(
                props,
                "rescheduleslotteacher.classStartTime"
              ),
              bookingDate: moment(
                _.get(props, "rescheduleslotteacher.classDate")
              ).format("DD/MM/YYYY"),
              bookingType: "reschedule",
              isDemoStudent: true,
              isFreeStudent: false,
              isPaidStudent: false,
              studentId: null,
              classScheduleId: _.get(
                props,
                "rescheduleslotteacher.classScheduleId"
              ),
              userId: studentId.get("credid"),
              voucherCode: "",
              teacherId: _.get(values, "teacherid"),
              
            },
            callback: (res, data) => {
              props.setdemoslotteacher(false);

              props.setPreviewModalVisible(false);

              if (_.isEqual(res, true)) {
                // props.apiCall(datefrom, dateTo);
                notification.success({
                  message: "Slot allocated successfully.",
                });
              } else {
                notification.error({
                  message: "Teacher is available",
                });
              }
            },
          });
        }
      },
      onCancel() {},
    });
  };
  const handleStatusFilter = (value) => {
    setSelectedStatus(value);
    console.log(value,"value==")
   
  };
  
  const onFinishFailed = (errorInfo) => {};
  return (
    <Modal
      className="demo-modal-tag"
      title=""
      visible={isModalVisible}
      footer={false}
      onCancel={handleCancel}
      zIndex={9999}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={
          {
            // mode: tag[Object.keys(tag)[2]].substring(tag[Object.keys(tag)[2]].indexOf("-")),
          }
        }
      >
        <div className={`modal-container demo-schedule`}>
          <div className="cookiesContent" id="cookiesPopup">
            <div className="viewmodaltitle mb-2">
              <div className="title-container">
                <span>Book your slot</span>
              </div>
              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 RegPassionateCourse"></div>
              <div className="interested-course"></div>
              <div className="course-mode course-list">
                <Form.Item
                  name="teacherid"
                  rules={[{ required: true, message: "Please input!" }]}
                >
                  <Select dropdownClassName="course-list-dropdown" placeholder="Select Teacher"
                   value={selectedStatus}
                   onChange={handleStatusFilter}
                  >
                    {_.size(props.reassignAirlineTeacher) > 0 &&
                      _.map(props.reassignAirlineTeacher, (course, index) => {
                        console.log("props.reassignAirlineTeacher==",props.reassignAirlineTeacher);
                        console.log(
                          "props.reassignAirlineTeacher",
                          props.reassignAirlineTeacher[0]?.id
                        );
                        return (
                          <Option
                            key={index}
                            value={_.get(course, "id")}
                            submit={() =>
                              reAssignTeacherforAirline(
                                props.airlineTeacherSlot
                              )
                            }
                          >
                            {" "}
                            {course?.teacher?.userDetails?.firstName}{" "}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <Button
              className="reschdeule"
              htmlType="submit"
              loading={_.get(
                props,
                "students.courseallocationLoadingInProgress"
              )}
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  scheduledcancel: (details) =>
    dispatch(global.redux.action.students.scheduledcancel(details)),
  courseallocation: (details) =>
    dispatch(global.redux.action.students.courseallocation(details)),
  getavailableTeacher: (details) =>
    dispatch(global.redux.action.teachers.getavailableTeacher(details)),
  getTeacherforAirline: (details) =>
    dispatch(global.redux.action.teachers.getTeacherforAirline(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Rescheduleteachers);
