import React, { useState, useEffect } from "react";
import { notification, Table, Button, Spin } from "antd";
import axios from "axios";
import * as SecureLS from "secure-ls";
import appEnvirontVariables from "../../../src/core_units/config/env-variables";
import { constant } from "../../core_units/constant";
import dayjs from "dayjs";
import "./style.css";

const TeacherLeave = (props) => {
    const ls = new SecureLS({ encodingType: "aes" });
    const token = ls.get('isLogin');
    const loginDetails = ls.get('isLogin');
    const [teacherLeaveData, setTeacherLeaveData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 0,
    });

    const mixpanel = require('mixpanel-browser');

    const fetchData = async (page, pageSize) => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${appEnvirontVariables.API.BASE_URL}/api/admin/TeacherLeaveHistory`,
                {
                    params: {
                        page: page,
                        limit: pageSize
                    },
                    headers: { Authorization: token["authorization"] }
                }
            );
            setTeacherLeaveData(response.data);
            setPagination({
                ...pagination,
                total: response.data.length 
            });
        } catch (error) {
            console.error("Error fetching teacher leave history:", error);
            notification.error({
                message: "Error",
                description: "An error occurred while fetching teacher leave history.",
            });
        } finally {
            setLoading(false);
        }
    };

    
    useEffect(() => {
        fetchData(pagination.current, pagination.pageSize);
    }, [pagination.current, pagination.pageSize]);

    const formatDate = (date) => {
        return dayjs(date).format("DD-MMM-YYYY hh:mmA");
    };

    const handlePageChange = (page, pageSize) => {
        setPagination({ ...pagination, current: page });
    };

    const handleApprove = async (record, status) => {
        setLoading(true);
        try {
            let mixpanelEventName;
            if (status === 'LEAVE_APPROVED') {
                mixpanelEventName = constant.MIXPANEL_LEAVE_APPROVE;
            } else if (status === 'LEAVE_REJECTED') {
                mixpanelEventName = constant.MIXPANEL_LEAVE_REJECT;
            } else {
                mixpanelEventName = constant.MIXPANEL_LEAVE_WITHDRAWAL;
            }

            mixpanel.track(mixpanelEventName, {
                user: loginDetails?.studentName,
                userName: token?.username,
                userId: token?.uId,
                transactionId: record.id,
                transactionStatus: status,
                teacherName: record.teacherDetails.userDetails.firstName,
                mobileNumber: record.teacherDetails.userDetails.credential ? record.teacherDetails.userDetails.credential.mobileNumber : '',
                email: record.teacherDetails.userDetails.credential ? record.teacherDetails.userDetails.credential.emailId : '',
                fromDate: formatDate(record.fromDate),
                toDate: formatDate(record.toDate),
                startTime: record.startTime,
                endTime: record.endTime,
                reason: record.reasons,
            });

            const response = await axios.post(
                `${appEnvirontVariables.API.BASE_URL}/api/admin/approveTeacherLeave?transactionId=${record.id}&transactionStatus=${status}`,
                { transactionId: record.id, transactionStatus: status },
                { headers: { Authorization: token["authorization"] } }
            );

            console.log("Approval successful:", response.data);
            if(response.status ==200) {
                notification.success({
                    description: "Status Updated Successfully"
                });
            } else {
                notification.error({
                    message: "Error While Updating Status",
                });
            }
            setLoading(false);
            fetchData();
        } catch (error) {
            console.error("Error approving teacher leave:", error);
            notification.error({
                message: "Error",
                description: "An error occurred while approving teacher leave.",
            });
            setLoading(false);
        }
    };

    // Function to render loading spinner in table footer
    const renderLoaderRow = () => (
        <tr>
            <td colSpan={columns.length}>
                <div style={{ textAlign: "center", padding: "20px 0" }}>
                   
                </div>
            </td>
        </tr>
    );

    // Table columns definition
    const columns = [
        {
            title: 'Teacher Name',
            dataIndex: ['teacherDetails', 'userDetails', 'firstName'],
            key: 'teacherName',
        },
        {
            title: 'Mobile Number',
            dataIndex: ['teacherDetails', 'userDetails', 'credential', 'mobileNumber'],
            key: 'mobileNumber',
            render: (mobileNumber) => mobileNumber || '-',
        },
        {
            title: 'Email',
            dataIndex: ['teacherDetails', 'userDetails', 'credential', 'emailId'],
            key: 'email',
            render: (email) => email || '-',
        },
        {
            title: 'Leave Duration',
            dataIndex: 'leaveDuration',
            key: 'leaveDuration',
            render: (leaveDuration) => leaveDuration || '-',
        },
        {
            title: 'From Date',
            dataIndex: 'fromDate',
            key: 'fromDate',
            render: (fromDate) => formatDate(fromDate),
        },
        {
            title: 'To Date',
            dataIndex: 'toDate',
            key: 'toDate',
            render: (toDate) => formatDate(toDate),
        },
        {
            title: 'Start Time',
            dataIndex: 'startTime',
            key: 'startTime',
            render: (startTime) => startTime || '-',
        },
        {
            title: 'End Time',
            dataIndex: 'endTime',
            key: 'endTime',
            render: (endTime) => endTime || '-',
        },
        {
            title: 'Reason',
            dataIndex: 'reasons',
            key: 'reasons',
            render: (reasons) => reasons || '-',
        },
        {
            title: 'Status',
            dataIndex: 'leaveStatus',
            key: 'status',
            render: (leaveStatus) => leaveStatus?.type || '-',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
                    {record.leaveStatus?.name === "LEAVE_APPROVAL_PENDING" && (
                        <>
                            <Button type="primary" onClick={() => handleApprove(record, "LEAVE_APPROVED")} style={{fontWeight:"bold",width:"100%",backgroundColor:"lightgreen", border:"none"}}>Approve</Button>
                            <Button type="danger" onClick={() => handleApprove(record, "LEAVE_REJECTED")} style={{marginTop:"10px",width:"100%",fontWeight:"bold", display:"flex"}}>
                                <span style={{padding:"0px 17px 5px 17px"}} > Reject</span></Button>
                        </>
                    )}
                    {record.leaveStatus?.name === "LEAVE_APPROVED" && record.reasons !== "Due To NoShow" && (
                        <Button type="primary" onClick={() => handleApprove(record, "LEAVE_WITHDRAWAL")}
                        style={{fontWeight:"bold",width:"100%"}}>Withdrawal</Button>
                    )}
                </span>
            ),
        },
    ];

    return (

        <div>
            
        <div style={{ padding: "20px"}} className="boderBox">
            <Table
          style={{width:"none", justifyContent:"center"}}
                dataSource={teacherLeaveData}
                columns={columns}
                pagination={{
                    current: pagination.current,
                    // pageSize: pagination.pageSize,
                    total: pagination.total,
                    onChange: handlePageChange,
                   
                    // pageSizeOptions: ['20', '50', '100'],
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`    
                }}
                loading={loading}
                locale={{ emptyText: "No Data Found" }}
               
            />
        </div>
        </div>
    );
};

export default TeacherLeave;


