import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Checkbox,
  Tabs,
  Form,
  Button,
  Radio,
  Select,
  Modal,
  notification,
} from "antd";
import axios from "axios";
import { connect } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import appEnvirontVariables from "../../../core_units/config/env-variables";
import * as SecureLS from "secure-ls";
import { data } from "jquery";

const ChangeStatusClass = (props) => {
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const [isModalVisible, setIsModalVisible] = useState(props.demoChangeStatus);

  const [form] = Form.useForm();
  const [teacherId] = useSearchParams();
  const [studentId] = useSearchParams();
  const { Option } = Select;
  const { confirm } = Modal;
  const [status, setStatus] = useState();
  const [courseList, setCourseList] = useState();

  const [activeItem, setActiveItem] = useState();
  const [file, setFile] = useState("");
  useEffect(() => {
    apiCall();
    configjson();
  }, []);
  const apiCall = () => {
    props.studentclass({
      params: {
        studentId: teacherId.get("id"),
      },
      body: {
        page: 0,
        size: 600,
      },
      callback: (response, data) => {
        console.log("courselist", data);
        setStatus(data);
      },
    });
  };
  const configjson = () => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          setCourseList(data);
        }
      },
    });
  };
  useEffect(() => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          setCourseList(data);
        }
      },
    });
  }, []);

  const onFileChange = (event) => {
    setFile(event);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    props.setDemoChangeStatus(false);
  };
  const ChangeStatusForStudentSlot = async (data) => {
    console.log('hereee')
    return axios.post(
      `${appEnvirontVariables.API.BASE_URL}/api/admin/admin/changeClassStatus?classScheduleId=${props.classScheduleId}&classStatus=${file}&teacherId=${props.teacherId}`,
      {
        classScheduleId: +props.classScheduleId,
        classStatus: file,
        teacherId: +props.teacherId,
      },
      { headers: { Authorization: token["authorization"] } }
    );
  };

  const onFinish = (values) => {
    confirm({
      title: "Do you Want to do this action?",
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        ChangeStatusForStudentSlot().then(response=>{
          console.log('response==',response)
          if(response?.status == 200){
                        props.setDemoChangeStatus(false);
             props.setPreviewModalVisible(false);
          
                    notification.success({
                        message: 'Status updated successfully.',
                    });
                } else {
                    notification.error({
                        message: _.get(data, 'message', 'Something went wrong'),
                    });
                }
        },(err=>{
          console.log('In errorr',err);
          props.setDemoChangeStatus(false);
          props.setPreviewModalVisible(false);
          notification.error({
            message:  'Error updating Status',
        });
        }))
          
      },
      onCancel() {},
    });
  };

  const onFinishFailed = (errorInfo) => {};
  return (
    <Modal
      className="demo-modal-tag"
      title=""
      visible={isModalVisible}
      footer={false}
      onCancel={handleCancel}
      zIndex={9999}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{}}
      >
        <div className={`modal-container demo-schedule`}>
          <div className="cookiesContent" id="cookiesPopup">
            <div className="viewmodaltitle mb-2">
              <div className="title-container">
                <span>Change The Status</span>
              </div>
              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 RegPassionateCourse"></div>
              <div className="interested-course"></div>
              <div className="course-mode course-list">
                <Form.Item
                  label="Status"
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: "Please input your status!",
                    },
                  ]}
                >
                  <b>
                    <Select
                      dropdownClassName="course-list-dropdown"
                      onSelect={(value) =>
                        form.setFieldsValue({ status: value })
                      }
                      placeholder="Select Status"
                      onChange={onFileChange}
                    >
                      {_.map(
                        _.get(courseList, "classStatus"),
                        (classStatus, each) => {
                          console.log("classStatus", classStatus);
                          return (
                            <Option
                              value={_.get(classStatus, "type") + ""}
                              key={each}
                              submit={() =>
                                ChangeStatusForStudentSlot(classStatus)
                              }
                            >
                              {_.get(classStatus, "localized_name")}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </b>
                </Form.Item>
              </div>
            </div>

            <Button className="reschdeule" htmlType="submit">
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  scheduledcancel: (details) =>
    dispatch(global.redux.action.students.scheduledcancel(details)),
  studentclass: (details) =>
    dispatch(global.redux.action.students.classdetails(details)),
  courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatusClass);
