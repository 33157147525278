import React from 'react';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import PageHeader from '../Header';
import LayoutContent from '../Content';
import PageFooter from '../Footer';
import Sidebar from '../sidebar';

class WebLayOut extends React.Component {

	render() {
		return (
			<Router>
			<div className="d-flex flex-column flex-root header-fixed">
				<div className="page d-flex flex-row flex-column-fluid">
					 <Sidebar />
					<div className="wrapper d-flex flex-column flex-row-fluid w-100 page-layout">
						<PageHeader />
						<div className="Page-inner-container">
							<LayoutContent />
						</div>
						<PageFooter />
					</div>
				</div>
			</div>
		</Router>
		);
	}
}

export default WebLayOut;