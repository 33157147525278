import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  message,
  InputNumber,
  Checkbox,
  Select,
  DatePicker,
  Radio,
  notification,
} from "antd";
import _ from 'lodash';
import './style.css'
import { Spin } from "antd";
import './style.css';
import { connect } from "react-redux";
import axios from 'axios';
import appEnvirontVariables from "../../../core_units/config/env-variables";
import * as SecureLS from "secure-ls";
const TeacherSlotSearch = (props) => {
  const dateformat = "YYYY-MM-DD";
  var ls = new SecureLS({ encodingType: 'aes' });
  const token = ls.get('isLogin') 
  const [courseList, setCourseList] = useState();
  const [selectedCourse, setSelectedCourse] = useState();
  const [slotDate, setSlotDate] = useState();
  const [form] = Form.useForm();
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [slotDetails, setSlotDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    //configjson();
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          console.log("courselist", data);
          setCourseList(data);
        }
      },
    });
  }, []);
  const configjson = () => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          console.log("courselist", data);
          setCourseList(data);
        }
      },
    });
  };
  const onFinish = (values) => {
    setLoading(true);
    setDisabledBtn(true);
  
    getSlotList(selectedCourse, slotDate)
      .then((result) => {
        setSlotDetail(result.data);
        console.log("view details =====", result.data);
        setDisabledBtn(false);
        setLoading(false);
      })
      .catch(() => {
        setDisabledBtn(false);
        setLoading(false);
      });
    };
  const getSlotList = (courseId, slotDate) => {
    return axios.get(`${appEnvirontVariables.API.BASE_URL}/api/admin/airlineBookings?slotDate=${slotDate}&ParentTagId=${courseId}`,
      { headers: { "Authorization": token['authorization'] } });
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const clearForm = (e) => {
    setDisabledBtn(false); 
   }
   const onChange = (date, dateString) => {
    console.log(date, dateString);
    setSlotDate(dateString);
  };
  const exportToExcel = () => {
    if (!Array.isArray(slotDetails) || slotDetails.length === 0) {
        console.log("No data to export");
        return;
    }

    // Define headers for the CSV file
    const headers = ["Start Time", "End Time", "Total Slots", "Slots Booked", "Teacher Available", "Student Participated", "Student", "Teacher"];

    // Generate CSV content
    let csvContent = "data:text/csv;charset=utf-8," + headers.join(",") + "\n";
    csvContent += slotDetails.map(slot => {
        const rowValues = [
            slot.startTime,
            slot.endTime,
            slot.totalSlots,
            slot.slotsBooked,
            slot.teacherSlots,
            slot.studentParticipated,
            // Concatenate student and teacher details if available
            slot.classDetails ? slot.classDetails.map(detail => `${detail.studentName} - ${detail.teacherName}`).join(", ") : "",
            ""
        ];
        return rowValues.join(",");
    }).join("\n");

    // Create a download link and trigger the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "TeacherSlotDetails.csv");

    // Hide loader when download is complete
    link.onload = () => setLoading(false);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  return (
    
    <div className="report-card">
    <div className="report-filter-conatiner">
        <span><b>Airline Slot Search</b></span>

        <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={(change, allValues) => {  }}
            form={form}
        >
            <div className="row" style={{"paddingTop": "10px"}}>
            <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <Form.Item
                    label="Courses"
                    name="course"
                    rules={[
                        {
                            required: true,
                            message: 'Please input Course!',
                        },
                    ]}
                >
                    <select id="course" onChange={(e) => setSelectedCourse(e.target.value)} value={selectedCourse} className="form-control" style={{appearance: 'auto'}} placeholder='Select Course'>
                        <option value="">Select course</option>
                        {courseList && courseList.AirlineCourseCategory.map(e => {
                          return  <option value={e.id}>{e.name}</option>
                        })}
                        
                    </select>
                </Form.Item>
            </div>
            <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <Form.Item
                          className=""
                          name={"slotDate"}
                          rules={[]}
                        >
                          <DatePicker onChange={onChange}
                            format={dateformat}
                           
                          />{" "}
                        </Form.Item>
            </div>
            <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <Form.Item>
                    {/* <Button onClick={(e) => clearForm(e)} type="primary mr-2" danger >
                        Clear
                    </Button> */}
                    <Button type="primary" style={{float: 'right'}} htmlType="submit" disabled={disabledBtn}>
                    {disabledBtn ? 'Loading' : 'Search'}
                    </Button>
                </Form.Item>
            </div>
            </div>
                  <Button onClick={exportToExcel} style={{ marginTop: '20px', marginBottom:"20px" }}>Export to CSV</Button>

            <div className="row" style={{ paddingTop: "10px" }}>
    <div className="col-12">
      {loading ? (
        <Spin tip="Loading..." style={{ textAlign: "center", marginLeft: "36%" }} />
      ) : slotDetails.length ? (
        <div className="table-container">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Total Slots</th>
                <th>Slots Booked</th>
                <th>Teacher Available</th>
                <th>Student Participated</th>
                <th style={{ paddingLeft: '1rem' }}>Student</th>
                <th>Teacher</th>
              </tr>
            </thead>
            <tbody>
              {slotDetails.map((e, index) => (
                <tr key={index}>
                  <td>{e.startTime}</td>
                  <td>{e.endTime}</td>
                  <td>{e.totalSlots}</td>
                  <td>{e.slotsBooked}</td>
                  <td>{e.teacherSlots}</td>
                  <td>{e.studentParticipated}</td>
                  <td colSpan="2">
                    {e?.classDetails?.length ? (
                      <table style={{ margin: 'auto', width: '100%' }}>
                        <tbody>
                          {e.classDetails.map((a, i) => (
                            <tr key={i}>
                              <td style={{ width: '50%' }}>{a?.studentName}</td>
                              <td style={{ paddingLeft: '0px' }}>
                                <span style={{ borderLeft: '3px solid #9abee3', paddingLeft: '0.75rem' }}>
                                  {a?.teacherName}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No Slots Available</p>
      )}
    </div>
  </div>

            {/* <div className="report-btn">
                <Form.Item>
                    <Button onClick={(e) => clearForm(e)} type="primary mr-2" danger >
                        Clear
                    </Button>
                    <Button type="primary" htmlType="submit" disabled={disabledBtn}>
                    {disabledBtn ? 'Loading' : 'Submit'}
                    </Button>
                </Form.Item>
            </div> */}

        </Form>
    </div>
</div>
  )
}

const mapStateToProps = (state) =>
  // console.log("state", state),
  ({
    teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
  });

const mapDispatchToProps = (dispatch) => ({
  courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeacherSlotSearch);