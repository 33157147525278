import React, {useState, useEffect} from 'react';
import './styles.css';
import appEnvirontVariables from "../../../core_units/config/env-variables";
import { Button, Select, Table, Form, Input, InputNumber, notification, Collapse } from 'antd';
import * as SecureLS from "secure-ls";
import _ from 'lodash';

import formatDate from '../../../core_units/utils/dayjs';
import axios from 'axios';
const { Panel } = Collapse;
const RefundRequest = (props) => {
    const [refundList, setRefundList] = useState([]);
    const [loading, setLoading] = useState(false);
    var ls = new SecureLS({ encodingType: 'aes' });
    const token = ls.get('isLogin');

    useEffect(async () => {
        init();
    },[]);

    const init = async () => {
        getRefundList().then(result => {
            if(result?.data?.data?.Items){
                setRefundList(result?.data?.data?.Items.sort((a,b) => new Date(b.refundCreatedAt) - new Date(a.refundCreatedAt)).sort((a, b) => {
                  let fa = a.refund_status.toLowerCase(),
                      fb = b.refund_status.toLowerCase();
              
                  if (fa < fb) {
                      return 1;
                  }
                  if (fa > fb) {
                      return -1;
                  }
                  return 0;
              }));
            }
        })
    }

    const getRefundList = async () => {
        return axios.get(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/refund-request`,
        { headers: { "Authorization": token['authorization'] } });
    }

    const handleChange = (index,idx,e) => {
          const { name, value } = e.target;
          refundList[index]["courseList"][idx][name] = value;
          refundList[index]["courseList"][idx].approvel_amount = ((refundList[index]["courseList"][idx].approvel_classes || 0) * refundList[index]["courseList"][idx].unit_price) - (refundList[index]["courseList"][idx].approvel_discount || 0);
          setRefundList([...[],...refundList]);
    };

    const approve = (v) => {
      let dt = new Date();
      let d = dt.getDate();
      let m = dt.getMonth() + 1;
      let y = dt.getFullYear();
        setLoading(true);
          v.token = token['authorization'];
          v.refund_status = "approved";
          v.refundDate = (y + '-' + (m.toString().length < 2 ? '0' + m : m) + '-' + (d.toString().length < 2 ? '0' + d : d));
          v.courseList.forEach(e => {
            if(e.isFree){
               e.approvel_amount = 0;
               e.approvel_classes = +e.classesToPurchase;
            }
          });
          axios.post(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/approve-refund-request`,
          v,
          { headers: { "Authorization": token['authorization'] } }).then((r) => {
            setLoading(false);
            if(r.data.success){
                init();
              notification.success({
                message: "",
                description: "Refund request approved successfully",
              });
            }
          });
    }


    return (
      <div>
      <h4>Refund Request List</h4>
      {(refundList || []).map((v, index) => {
        return (
          <Collapse key={index} accordion style={{ marginTop: '20px'}}>
            {/* <Panel header={`${v.name}, Transaction Date - ${new Date(v.createdAt).toLocaleString()}, Status - ${v.refund_status}`} key="1"> */}
            <Panel header={
              <div className="panel-header-grid">
           <div>
  <span style={{ width: '226px', display: 'inline-block' }}><b>Name:</b> {v.name}</span>
  <span style={{ width: '382px', display: 'inline-block' }}><b>Refund Requested date:</b> {formatDate (v.refundCreatedAt, true).toLocaleString()}</span>
  <span style={{ width: '255px', display: 'inline-block' }}><b>Transaction Amount:</b> {v.currency} {v.totalPrice}</span>
  <span style={{ width: '196px' }}>
    {/* <b>Status:</b> */}
    <span style={{
      borderRadius: "8px",
      padding: "10px",
      cursor: "auto",
      backgroundColor: v.refund_status.toLowerCase() === "pending" ? "yellow" : (v.refund_status.toLowerCase() === "approved" ? "green" : ""),
      textTransform: "capitalize",
      color: v.refund_status.toLowerCase() === "approved" ? "white" : "black",
      //margin:"32px",
      float:"right",
      fontWeight: 600
    }}>
      {v.refund_status.toLowerCase() === "pending" ? "Pending" : (v.refund_status.toLowerCase() === "approved" ? "Approved" : v.refund_status)}
    </span>
  </span>
</div>
            
            </div>
          } key="1">
              <ul style={{ listStyle: "none", background: "white" }}>
                <li>
                  <table>
                    {/* <tr><td>Name</td>
                      <td>: <span>{v.name}</span></td></tr> */}
                   <tr>
    <td><b>Phone</b></td>
    <td style={{ paddingRight: '50px' }}>: <span>{v.countryCode + '' + v.contact}</span></td>
    <td><b>Transaction Date</b></td>
    <td>: <span>{formatDate (v.createdAt, true).toLocaleString()}</span></td>
  </tr>
                    {/* <tr><td>Transaction Date</td>
                      <td>: <span>{new Date(v.createdAt).toLocaleString()}</span></td></tr> */}
                    {/* <tr><td>Refund Requested date</td>
                      <td>: <span>{new Date(v.refundCreatedAt).toLocaleString()}</span></td></tr>
                    <tr><td>Transaction Amount</td>
                      <td>: <span>{v.currency} {v.totalPrice}</span></td></tr> */}
                  </table>
                </li>
                <li>
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">Course Name</th>
                        <th className="text-center">Price/Class (inc.Tax)</th>
                        <th className="text-center">Classes</th>
                        <th className="text-center">Discount</th>
                        <th className="text-center">Total Price</th>
                        <th className="text-center">Refundable Classes</th>
                        <th className="text-center">Refundable Amount ({v.currency})</th>
                        <th className="text-center">Discount on Refund({v.currency})</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(v.courseList || []).map((z, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{z.courseName}{z.isFree ? <b style={{ color: "green" }}>-Free Course</b> : ""}</td>
                            <td>{z.unit_price}</td>
                            <td>{z.classesToPurchase}</td>
                            <td>{z.discountedPrice}</td>
                            <td>{z.price}</td>
                            <td>
                              <input
                                type="text"
                                name="approvel_classes"
                                disabled={z.isFree || v.refund_status !== "pending"}
                                value={z.approvel_classes}
                                onChange={(e) => handleChange(index, idx, e)}
                                className="form-control"
                              />
                            </td>
                            <td>
                              <span>
                                <input
                                  type="text"
                                  name="approvel_amount"
                                  disabled={true}
                                  value={z.approvel_amount}
                                  onChange={(e) => handleChange(index, idx, e)}
                                  className="form-control"
                                />
                              </span>
                            </td>
                            <td>
                              <span>
                                <input
                                  type="text"
                                  name="approvel_discount"
                                  disabled={z.isFree || v.refund_status !== "pending"}
                                  value={z.approvel_discount}
                                  onChange={(e) => handleChange(index, idx, e)}
                                  className="form-control"
                                />
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td>
                          <b>Capture date of refund : </b>
                          <b style={{ whiteSpace: "nowrap" }}>{ formatDate(v.captureDate, true)}</b>
                        </td>
                        <td className='reasonRefund' colSpan={2}>
                          *Reason for Refund : </td>
                        <td style={{ borderLeft: "none", padding: "15px 5px" }} colSpan={4}>
                          <b>{v.reasonforrefund}</b>
                        </td>
                        <td>
                          {v.refund_status === "pending" ? (
                            <button
                              onClick={() => { approve(v) }}
                              className="btn btn-primary float-right"
                            >
                              {'Approve'}
                            </button>
                          ) : (
                            <b style={{ color: "green" }}>Approved</b>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ul>
            </Panel>
          </Collapse>
        );
      })}
    </div>
      )
}
export default RefundRequest;