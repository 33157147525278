import React, { useState, useEffect } from 'react'
import dayjs from "dayjs"
import { getMediaUrl } from "./services"
import * as SecureLS from "secure-ls";

function formatDateTime(timestamp) {
  const jsDate = new Date(timestamp * 1000)
  return dayjs(jsDate).format("h:mm A")
}

function ChatMessage({ item, userId, teacherChat, selectedChatData }) {
  const {senderId, text, timestamp, seen, type, fileName, size} = item
  // console.log(item, "chat message data")
  const ls = new SecureLS({ encodingType: 'aes' });
  const token = ls.get('isLogin')

  const [loadingVideoUrl, setLoadingVideoUrl] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")
  const [loadingImageUrl, setLoadingImageUrl] = useState(false)
  const [imageUrl, setImageUrl] = useState("")
  const [loadingPdfUrl, setLoadingPdfUrl] = useState(false)
  const [pdfUrl, setPdfUrl] = useState("")
  const [loadingAudioUrl, setLoadingAudioUrl] = useState(false)
  const [audioUrl, setAudioUrl] = useState("")

  const handleMediaPlay = e => {
    const allVideos = document.getElementsByClassName('message-media-wrapper')
    Array.from(allVideos).forEach(item => {
      if(item !== e.target) {
        item?.pause()
      }
    })
  }

  const [showMediaPreviewModal, setShowMediaPreviewModal] = useState(false)
  const [previewFileType, setPreviewFileType] = useState("")
  const [previewFileUrl, setPreviewFileUrl] = useState("")

  const handleFilePreview = (type, url) => {
    setPreviewFileType(type)
    setPreviewFileUrl(url)
    setShowMediaPreviewModal(true)
  }

  let messageAlignmentCheck = Number(selectedChatData?.studentId) === Number(senderId)

  return (
    <div className={messageAlignmentCheck ? "chat-row-left" : "chat-row-right"}>
      <div className={messageAlignmentCheck ? "chat-left-dark" : "chat-right-dark"}>
        <div>
          {type === "text" && <p className="chat-text-message">{text}</p>}

          {type === "video" && (
            <div className={'message-video-thumbnail'}>
                {loadingVideoUrl ? <div>Loading Video...</div> : (
                  videoUrl ? (
                    <video className="message-media-wrapper" controls controlsList="nodownload" onPlay={handleMediaPlay}>
                      <source src={videoUrl} type="video/mp4" />
                      <source src={videoUrl} type="video/webm" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <div onClick={() => getMediaUrl(setLoadingVideoUrl, setVideoUrl, senderId, fileName, token.authorization)} className="media-before-download">
                      {/* <img src="https://artiumacademy.mo.cloudinary.net/v1n/Course-play-button.svg" alt="video-play" />  */}
                        🎞️
                    </div>
                  )
                )}
            </div>
          )}

          {type === "audio" && (
            <>
              <div className={audioUrl ? 'message-audio-thumbnail-transparent' : 'message-audio-thumbnail'}>
                  {loadingAudioUrl ? <div>Loading Audio...</div> : (
                    audioUrl ? (
                        <audio className="message-media-wrapper" controls controlsList="nodownload" onPlay={handleMediaPlay}>
                          <source src={audioUrl} type="audio/ogg" />
                          <source src={audioUrl} type="audio/mpeg" />
                          Your browser does not support the audio tag.
                        </audio>
                    ) : (
                      <div onClick={() => getMediaUrl(setLoadingAudioUrl, setAudioUrl, senderId, fileName, token.authorization)} className="media-before-download">
                        🎵
                      </div>
                    )
                  )}
              </div>
              <div className='audio-file-name'>{fileName}</div>
            </>
          )}

          {type === "image" && (
            <div className={'message-image-thumbnail' }>
                {loadingImageUrl ? <div>Loading Image...</div> : (
                  imageUrl ? (
                    <div className='message-image-wrapper'>
                      <div className='expand-icon' onClick={() => handleFilePreview("image", imageUrl)}>
                        Expand
                      </div>
                      <img onClick={() => handleFilePreview("image", imageUrl)} src={imageUrl} alt="media-image" />
                    </div>
                  ) : (
                    <div onClick={() => getMediaUrl(setLoadingImageUrl, setImageUrl, senderId, fileName, token.authorization)} className="media-before-download">
                      🖼️
                    </div>
                  )
                )}
            </div>
          )}

          {type === "pdf" && (
            <>
              <div className={'message-image-thumbnail' }>
                  {loadingPdfUrl ? <div>Loading PDF...</div> : (
                    pdfUrl ? (
                      <div className="message-media-wrapper">
                        {/* <a href={pdfUrl} target="_blank" className='message-download-icon'><OpenInNewIcon sx={{color: "#373447", fontSize: "1.5rem"}}/></a> */}
                        <div className='expand-icon' onClick={() => handleFilePreview("pdf", pdfUrl)}>
                          Expand
                        </div>
                        <embed src={pdfUrl} type="application/pdf"></embed>
                      </div>
                    ) : (
                      <div onClick={() => getMediaUrl(setLoadingPdfUrl, setPdfUrl, senderId, fileName, token.authorization)} className="media-before-download">
                        📄
                      </div>
                    )
                  )}
              </div>
              <div className='pdf-details-row'>
                <div className='pdf-details-row__left'>
                  <img src="https://artiumacademy.mo.cloudinary.net/v1n/pdf-icon-small.svg" alt="pdf" />
                  <div className='pdf-name'>{fileName}</div>
                </div>
                {pdfUrl && (
                  <a href={pdfUrl} target="_blank">
                    <img src="https://artiumacademy.mo.cloudinary.net/v1n/download-icon-small.svg" alt="download" />
                  </a>
                )}
              </div>
            </>
          )}
          
          {timestamp && teacherChat && (
            <div className='single-message-time'>
              <div>{formatDateTime(timestamp.seconds)}</div>
            </div>
          )}
        </div>
      </div> 


      {showMediaPreviewModal && (
        <div className='chat-media-preview-modal-container'>
          <div className='top-row'>
            <div onClick={() => setShowMediaPreviewModal(false)} className='close-btn'>
              ✕
            </div>
          </div>
          {previewFileType === "image" && (
            <div className='image-wrapper'>
              <img src={previewFileUrl} alt="image-message" />
            </div>
          )}
          {previewFileType === "pdf" && (
            <div className='pdf-wrapper'>
              <embed src={previewFileUrl} type="application/pdf" className="pdf"></embed>
            </div>
          )}
        </div>
      )}
    </div> 
  )
}

export default ChatMessage