import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Form, Input, Spin, Empty } from "antd"; 
import appEnvirontVariables from "../../../core_units/config/env-variables";
import * as SecureLS from "secure-ls";
import _ from 'lodash';

const formItemLayout = {
  labelCol: {
    // span: 8,
  },
  wrapperCol: {
    // span: 14,
  },
};

const KysForm = (props) => {
  var ls = new SecureLS({ encodingType: 'aes' });
  const token = ls.get('isLogin');
  const [kysForm, setKysForm] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    KysDetails();
  }, [props.selectedcourse.parentTagId]);
console.log(props.selectedcourse.parentTagId,"props.selectedcourse.parentTagId")
  const KysDetails = () => {
    setLoading(true);
    axios.get(`${appEnvirontVariables.API.UTILITY_SERVICE_URL}/api/form/kycform/${props.selectedcourse.parentTagId}/${props.students.viewprofile.userId}`, {
      headers: {
        Authorization: token["authorization"]
      },
    })
    .then(response => {
      setKysForm(response.data.data); 
      setLoading(false);
    })
    .catch(error => {
      console.error(error);
      setLoading(false);
    });
  };
  
  const renderField = (field, groupIndex, fieldIndex) => {
    if (field.type === "radio") {
      return (
        <div key={`field-${groupIndex}-${fieldIndex}`} className="custom-form-item">
          <span className="custom-label">{field.label}</span>
          <span className="custom-input">{field.answer}</span>
        </div>
      );
    } else if (field.type === "textarea") {
      return (
        <div key={`field-${groupIndex}-${fieldIndex}`} className="custom-form-item">
          <span className="custom-label">{field.label}</span>
          <Input.TextArea
            readOnly
            defaultValue={field.answer}
            className="custom-input"
          />
        </div>
      );
    } else if (field.type === "inputGroup") {
     
      return (
        <div key={`field-${groupIndex}-${fieldIndex}`} className="custom-form-item">
          <span className="custom-label">{field.label}</span>
          {field.inputFields.map((inputField, inputFieldIndex) => (
            <div key={`inputField-${groupIndex}-${fieldIndex}-${inputFieldIndex}`}>
              <span className="custom-label">{inputField.label}</span>
              <Input
                readOnly
                defaultValue={inputField.answer}
                className="custom-input"
              />
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div key={`field-${groupIndex}-${fieldIndex}`} className="custom-form-item">
          <span className="custom-label">{field.label}</span>
          <Input
            readOnly
            defaultValue={field.answer || field.textAreaEnabledForYes?.answer}
            className="custom-input"
          />
        </div>
      );
    }
  };
  
  return (
    <div>
      {props.students.viewprofile.studentType === "PAID" ? (
        loading ? (
          <Spin />
        ) : (
          kysForm && kysForm.form && kysForm.form.formGroup && kysForm.form.formGroup.length > 0 ? (
            <Form {...formItemLayout}>
              {kysForm.form.formGroup.map((group, groupIndex) => (
                <div key={`group-${groupIndex}`}>
                  <h2>{group.title}</h2>
                  {group.formFields && group.formFields.length > 0 && group.formFields.map((field, fieldIndex) => (
                    renderField(field, groupIndex, fieldIndex)
                  ))}
                </div>
              ))}
            </Form>
          ) : (
            <Empty description="No KYS form available." />
          )
        )
      ) : (
        <div style={{ display: "flex", justifyContent: "center", fontWeight: "800" }}>No KYS form for trial student.</div>
      )}
    </div>
  );
  
  
      }  

export default KysForm;


