import React, { useState, useEffect } from "react";
import {
  Card,
  Spin,
  Input,
  Button,
  Modal,
  Select,
  Tag,
  notification,
  Audio,
  Tabs,
} from "antd";
import * as SecureLS from "secure-ls";
import axios from "axios";
import waveicon from "../../assets/img/waveicon.png";
import {
  FilterOutlined,
  PlayCircleOutlined,
  FilePdfOutlined,
  DeleteOutlined,
 
  SearchOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import appEnvirontVariables from "../../../src/core_units/config/env-variables";
import _ from "lodash";
import { constant } from '../../core_units/constant';
const { TabPane } = Tabs;
const { Meta } = Card;
const { Search } = Input;
const { Option } = Select;
const AssignList = () => {
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const [assignList, setAssignList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectChecked, setSelectChecked] = useState();
  const loginDetails = ls.get('isLogin');
  const mixpanel = require('mixpanel-browser');
  const [cardMixPanel, setCardMixPanel] = useState();
  const [deleteLibrary, setDeleteLibrary] = useState();
  const [clickedCardDetails, setClickCardDetails] = useState();
  const [buttonColor, setButtonColor] = useState("primary");
  const [previousSelectedFilters, setPreviousSelectedFilters] = useState({});
  const [selectedCard, setSelectedCard] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [filterApplied, setFilterApplied] = useState(false)
  const [buttonClickedMap, setButtonClickedMap] = useState({});

  const [selectedFilters, setSelectedFilters] = useState({
    key: "",
    timeSign: "",
    tempo: "",
    searchVal: "",
  });
  const [selectedAssignmentId, setSelectedAssignmentId] = useState(null);
  const [selectedDeleteLib, setSelectedDeleteLib] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [assignmentIdToDelete, setAssignmentIdToDelete] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  const [hoveredAssignmentId, setHoveredAssignmentId] = useState(null);
  const [audioModalVisible, setAudioModalVisible] = useState(false);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFileDetails, setSelectedFileDetails] = useState({});
  const [audioUrl, setAudioUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [shouldPlayAudio, setShouldPlayAudio] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [fullUrl, setFullUrl] = useState();
  const [appliedFilters, setAppliedFilters] = useState(false);
  const [originalFilters, setOriginalFilters] = useState({});
  useEffect(() => {
    // AssignDetails();
  }, []);

  const AssignDetails = (queryParams = {}) => {
    // setLoading(true);
    const updatedQueryParams = { ...queryParams, hitsPerPage: 20 };
    if (selectedFilters.artiumLibrary !== undefined) {
      updatedQueryParams.artiumLibrary = selectedFilters.artiumLibrary;
    }
    if (queryParams.page !== undefined) {
      updatedQueryParams.page = queryParams.page;
    }
    axios
      .get(
        `${appEnvirontVariables.API.LIBRARY_URL}/api/assignment/library-list?`,
        {
          headers: {
            Authorization: token["authorization"],
          },
          params: updatedQueryParams,
        }
      )
      .then((response) => {
console.log(response,"response6");

        if (Array.isArray(response.data.assignments)) {
          if (queryParams.page !== undefined) {
            setAssignList((prevAssignList) => [...prevAssignList, ...response.data.assignments]);
            if (response.data.assignments.length === 0) {
              // Check if the browser supports notifications
              if ("Notification" in window) {
                // Show the notification
                notification.success({
                  message: "No  more data available.",
                  
                });
              } else {
                // Fallback for browsers that don't support notifications
                console.log("This browser does not support desktop notification.");
              }
            }
            
            
          } else {
            setAssignList(response.data.assignments);
          }
        } else {
          console.error(
            "Assignments array not found in API response:",
            response.data
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching assignments:", error);
        setLoading(false);
      });
  };
  const loadMore = () => {
    setLoading(false);
    const currentPage = Math.floor(assignList.length / 20) + 1;
    if (activeTab === "2") {
      AssignDetails({ ...selectedFilters, page: currentPage, artiumLibrary: true , fromLoadMore: true,});
    } else if (activeTab === "1") {
      AssignDetails({ ...selectedFilters, page: currentPage, fromLoadMore: true });
    }
  };
  const isAudioFile = (fileName) => {
    return (
      fileName &&
      (fileName.endsWith(".mp3") ||
        fileName.endsWith(".wav") ||
        fileName.endsWith(".m4a") ||
        fileName.endsWith(".ogg"))
    );
  };
  const handleTimeSignClick = (timeSign) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      timeSign: prevFilters.timeSign === timeSign ? "" : timeSign,
    }));
  };
  const handleKeyClick = (key) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      key: prevFilters.key === key ? "" : key,
    }));
  };
  const handleFilter = () => {
    setOriginalFilters({ ...selectedFilters });
    setShowFilterModal(true);
    // setFilterApplied(true);

  };
  useEffect(() => {
    if (activeTab === "1") {
      AssignDetails();
    } else if (activeTab === "2") {
      AssignDetails({ artiumLibrary: true });
    }
  }, [activeTab]);


  const applyFilters = (selectedFilters) => {
    let queryParams = {};
    setFilterApplied(true);
    // setPreviousSelectedFilters(previousSelectedFilters);


    if (selectedFilters.tempo) {
      queryParams.tempo = selectedFilters.tempo;
    }
    if (selectedFilters.key) {
      queryParams.key = selectedFilters.key;
    }
    if (selectedFilters.timeSign) {
      queryParams.timeSign = selectedFilters.timeSign;
    }
    if (selectedFilters.searchVal) {
      queryParams.searchVal = selectedFilters.searchVal;
    } else {
      queryParams.searchVal = undefined;
    }
    if (activeTab === "2") {
      queryParams.artiumLibrary = true;
    }

    setPreviousSelectedFilters(selectedFilters);
    AssignDetails(queryParams);
    setAppliedFilters(true);
  };


  // const handleDeleteIconClick = (assignmentId, assignment) => {
  //   setDeleteLibrary(assignment);
  //   setAssignmentIdToDelete(assignmentId);
  //   setShowDeleteConfirmationModal(true);
  // };

  const handleConfirmDelete = () => {
    const idToDelete = selectedAssignmentId;
    setShowDeleteConfirmationModal(false);
    setButtonClickedMap(prevState => ({
      ...prevState,
      [selectedAssignmentId]: true // Set buttonClicked to true for the selected card
    }));
    console.log("handleConfirmDelete called", idToDelete);
    axios
      .patch(
        `${appEnvirontVariables.API.LIBRARY_URL}/api/assignment/no-show-in-library`,
        {
          docId: idToDelete,
        },
        {
          headers: {
            Authorization: token["authorization"],
          },
        }
      )
      .then((response) => {
        setIsModalVisible(false);
        console.log("Assignment removed from library successfully");
        notification.success({
          message: "Success",
          description: "Assignment successfully Deleted from library.",
        });

        // console.log(mixpanel.track(constant.MIXPANEL_DELETE_FROM_ASSIGNMENT_LIST, {
        //   LoginUser: loginDetails?.studentName,
        //   displayName: deleteLibrary.displayName,
        //   fileName: deleteLibrary.fileName,
        //   filePath: deleteLibrary.filePath,
        //   objectId: deleteLibrary.objectID,
        //   userId: deleteLibrary.userId
        // }), "Mixpanel")

        // mixpanel.track(constant.MIXPANEL_DELETE_FROM_ASSIGNMENT_LIST, {
        //   LoginUser: loginDetails?.studentName,
        //   displayName: deleteLibrary.displayName,
        //   fileName: deleteLibrary.fileName,
        //   filePath: deleteLibrary.filePath,
        //   objectId: deleteLibrary.objectID,
        //   userId: deleteLibrary.userId
        // });
        setLoading(false);
        setAssignList((prevAssignList) =>
          prevAssignList.filter(
            (assignment) => assignment.objectID !== idToDelete
          )
        );
        setSelectedAssignmentId(null);
        resetButtonClicked(selectedAssignmentId);

        console.log(mixpanel.track(constant.MIXPANEL_DELETE_FROM_ASSIGNMENT_LIST, {
          LoginUser: loginDetails?.studentName,
          displayName: deleteLibrary.displayName,
          fileName: deleteLibrary.fileName,
          filePath: deleteLibrary.filePath,
          objectId: deleteLibrary.objectID,
          userId: deleteLibrary.userId
        }), "Mixpanel")

        mixpanel.track(constant.MIXPANEL_DELETE_FROM_ASSIGNMENT_LIST, {
          LoginUser: loginDetails?.studentName,
          displayName: deleteLibrary.displayName,
          fileName: deleteLibrary.fileName,
          filePath: deleteLibrary.filePath,
          objectId: deleteLibrary.objectID,
          userId: deleteLibrary.userId
        });
      })
      .catch((error) => {
        console.error("Failed to remove assignment from library", error);
      });
  };

  const handleImageClick = (e, assignment) => {
  
    openAudioModal(assignment);
  }

  const handleCancelDelete = () => {
    setShowDeleteConfirmationModal(false);
  };

  const handleSearch = (value) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, searchVal: value });
    if (activeTab === "1") {
      AssignDetails({ ...selectedFilters, searchVal: value });
    }
    if (activeTab === "2") {
      AssignDetails({ ...selectedFilters, searchVal: value, artiumLibrary: true });
    }
  };

  // const handleClearSearch = () => {
  //   setSelectedFilters({ ...selectedFilters, searchVal: '' });
  //   setSelectedFilters({ key: "", timeSign: "", tempo: "", searchVal: "" });
  //   AssignDetails();
  // };

  const filterReset = (value) => {
    // setPreviousSelectedFilters(selectedFilters)
    // setPreviousSelectedFilters(selectedFilters)
    // setSelectedFilters({});
    setSelectedFilters({ ...selectedFilters, key: "", timeSign: "", tempo: ""});
    console.log('selectedfilters=',{...selectedFilters})
    // AssignDetails({ ...selectedFilters, key: "", timeSign: "", tempo: ""});
    if (activeTab === "1") {
      AssignDetails({ ...selectedFilters, key: "", timeSign: "", tempo: ""});
    }
    if (activeTab === "2") {
      AssignDetails({  ...selectedFilters, key: "", timeSign: "", tempo: "", artiumLibrary: true });
    }

    setFilterApplied(false);
    
  }
  
 const handleFilterModal = () => {
  console.log('previouSelectedFilters=',previousSelectedFilters);
  setSelectedFilters({...selectedFilters, key: "", timeSign: "", tempo: ""});
  setShowFilterModal(false);
 }
    // setPreviousSelectedFilters(selectedFilters);
  // setPreviousSelectedFilters({})
  // setSelectedFilters({ ...selectedFilters});
//  setSelectedFilters({});
  // const handleCheckboxChange = (assignmentId, assignment) => {
  
  //   setCardMixPanel(assignment)
  //   setDeleteLibrary(assignment)
  //   console.log(assignList, "assignment3")
  //   console.log(assignment, "assignment1")
  //   setSelectedAssignmentId((prevId) => {
  //     const newState = prevId === assignmentId ? null : assignmentId;
  //     setSelectChecked(newState);
  //     console.log(newState, "newstate")
  //     console.log(newState !== null);
  //     return newState;
  //   });
  // };

  


  const handleCheckboxChange = (assignmentId, assignment) => {
    setCardMixPanel(assignment)
    setSelectedAssignmentId(null); 
    setDeleteLibrary(assignment)
    console.log(assignList, "assignment3")
    console.log(assignment, "assignment1")
    // setSelectedAssignmentId((prevId) => {
    //   const newState = prevId === assignmentId ? null : assignmentId;
    setSelectedAssignmentId(prevId => {
      // If the clicked card is already selected, don't change the state
      return prevId === assignmentId ? null : assignmentId;
      // setSelectChecked(newState);
      //     console.log(newState, "newstate")
      //     console.log(newState !== null);
      //     return newState;
      
    });
  };


  const handleArtiumCheckbox = (assignmentId, assignment) => {
    setClickCardDetails(assignment)
    console.log(assignment, "assignment2")
    setSelectedAssignmentId(prevId => {
      // If the clicked card is already selected, don't change the state
      return prevId === assignmentId ? prevId : assignmentId;
    // setSelectedAssignmentId((prevId) => {
    //   const newState = prevId === assignmentId ? null : assignmentId;
    //   console.log(newState !== null);
    //   return newState;
    });
  };

  const handleDeleteIconClick = (assignmentId, assignment) => {
    setAssignmentIdToDelete(assignmentId);
    setShowDeleteConfirmationModal(true);
  };

  const removeFromLibrary = () => {
    setLoading(true);
    setButtonClickedMap(prevState => ({
      ...prevState,
      [selectedAssignmentId]: true // Set buttonClicked to true for the selected card
    }));
    if (selectedAssignmentId) {
      axios
        .patch(
          `${appEnvirontVariables.API.LIBRARY_URL}/api/assignment/remove-from-library`,
          {
            docId: selectedAssignmentId,
          },
          {
            headers: {
              Authorization: token["authorization"],
            },
          }
        )
        .then((response) => {
          setIsModalVisible(false);
          console.log("Successfully removed from library:", response.data);
          notification.success({
            message: "Success",
            description: "Assignment successfully removed from library.",
          });

          console.log(mixpanel.track(constant.MIXPANEL_MOVE_TO_ASSIGNMENT_LIST, {
            LoginUser: loginDetails?.studentName,
            displayName: clickedCardDetails.displayName,
            fileName: clickedCardDetails.fileName,
            filePath: clickedCardDetails.filePath,
            objectId: clickedCardDetails.objectID,
            userId: clickedCardDetails.userId
          }), "docID")

          mixpanel.track(constant.MIXPANEL_MOVE_TO_ASSIGNMENT_LIST, {
            LoginUser: loginDetails?.studentName,
            displayName: clickedCardDetails.displayName,
            fileName: clickedCardDetails.fileName,
            filePath: clickedCardDetails.filePath,
            objectId: clickedCardDetails.objectID,
            userId: clickedCardDetails.userId
          });
          setLoading(false);
          setAssignList((prevAssignList) =>
            prevAssignList.filter(
              (assignment) => assignment.objectID !== selectedAssignmentId
            )
          );
          setSelectedAssignmentId(null);
          resetButtonClicked(selectedAssignmentId);
        })
        .catch((error) => {
          console.error("Error removing from library:", error);
          notification.error({
            message: "Error",
            description: "Failed to remove assignment from library.",
          });
          setLoading(false);
        });
    } else {
      console.log("No assignment selected to remove from library");
    }
  };

  const addToLibrary = () => {
    setLoading(true);
    setButtonClickedMap(prevState => ({
      ...prevState,
      [selectedAssignmentId]: true // Set buttonClicked to true for the selected card
    }));
    if (selectedAssignmentId) {
      console.log(selectedAssignmentId, "selectassigmnetId")
      axios
        .patch(
          `${appEnvirontVariables.API.LIBRARY_URL}/api/assignment/add-to-library`,
          {
            docId: selectedAssignmentId,
          },
          {
            headers: {
              Authorization: token["authorization"],
            },
          }
        )
        .then((response) => {
          setIsModalVisible(false);
          console.log("Successfully added to library:", response.data);
          notification.success({
            message: "Success",
            description: "Assignment successfully added to library.",
          });
          console.log(mixpanel.track(constant.MIXPANEL_MOVE_TO_ARTIUM_LIBRARY, {
            user: loginDetails?.studentName,
            displayName: cardMixPanel.displayName,
            fileName: cardMixPanel.fileName,
            filePath: cardMixPanel.filePath,
            objectId: cardMixPanel.objectID,
            userId: cardMixPanel.userId
          }), "consolemixpanel")
          mixpanel.track(constant.MIXPANEL_MOVE_TO_ARTIUM_LIBRARY, {
            LoginUser: loginDetails?.studentName,
            displayName: cardMixPanel.displayName,
            fileName: cardMixPanel.fileName,
            filePath: cardMixPanel.filePath,
            objectId: cardMixPanel.objectID,
            userId: cardMixPanel.userId
          });
          setLoading(false);
          // AssignDetails();
          setAssignList((prevAssignList) =>
            prevAssignList.filter(
              (assignment) => assignment.objectID !== selectedAssignmentId
            )
          );
          setSelectedAssignmentId(null);
          resetButtonClicked(selectedAssignmentId);

        })
        .catch((error) => {
          console.error("Error adding to library:", error);
          notification.error({
            message: "Error",
            description: "Failed to add assignment to library.",
          });
          setLoading(false);
        });
    } else {
      console.log("No assignment selected to add to library");
    }
  };
  const fetchUrl = (assignment) => {
    axios
      .post(
        `${appEnvirontVariables.API.RETRIEVE_URL}/api/library/retrieve`,
        {
          userId: assignment.userId,
          fileName: assignment.fileName,
        },
        {
          headers: {
            Authorization: token["authorization"],
          },
        }
      )
      .then((response) => {
        setFullUrl(response.data);
        console.log(response.data, "response.data");
      })
      .catch((error) => { });
  };

  const openAudioModal = (assignment) => {
    console.log("assignment===:", assignment);
    setIsModalVisible(true);
    setSelectedFileDetails(assignment);
    // setAudioModalVisible(true);
  };

  // const handleCancel = () => {
  //   setSelectedFilters({ ...selectedFilters, key: "", timeSign: "", tempo: ""});
  //   console.log('selectedfilters=',{...selectedFilters})
  //   // AssignDetails({ ...selectedFilters});
  //   setIsModalVisible(false);
  //   setSelectedFilters(previousSelectedFilters);
  //   console.log('previous=',setSelectedFilters)
  //   setSelectedFileDetails({});
  //   setFullUrl({});
  //   if (!appliedFilters) {
  //     setSelectedFilters({ ...originalFilters });
  //   }
  //   setShouldPlayAudio(false);
  //   const audioElement = document.getElementById("audioPlayer");
  //   const videoElement = document.getElementById("videoPlayer");
  //   if (audioElement) {
  //     audioElement.pause();
  //   }
  //   if (videoElement) {
  //     videoElement.pause();
  //   }
  // }
  const handleCancel = () => {
    setSelectedFilters({ ...selectedFilters, key: "", timeSign: "", tempo: "" });
    // AssignDetails({ ...selectedFilters });
    setIsModalVisible(false);
    setSelectedAssignmentId(null); // Uncheck the checkbox
    setSelectedFileDetails({});
    setFullUrl({});
    if (!appliedFilters) {
        setSelectedFilters({ ...originalFilters });
    }
    setShouldPlayAudio(false);
    const audioElement = document.getElementById("audioPlayer");
    const videoElement = document.getElementById("videoPlayer");
    if (audioElement) {
        audioElement.pause();
    }
    if (videoElement) {
        videoElement.pause();
    }
}

 const resetButtonClicked = (assignmentId) => {
    setButtonClickedMap(prevState => ({
      ...prevState,
      [assignmentId]: false // Reset buttonClicked to false for the specified assignment ID
    }));
  };

  
  // useEffect(() => {
  //   if (activeTab === "1") {
  //     // AssignDetails();
  //   } else if (activeTab === "2") {
  //     // AssignDetails({ artiumLibrary: true });
  //   }
  // }, [activeTab]);

  const handleTabChange = (key) => {
    setSelectedFilters({ key: "", timeSign: "", tempo: "", searchVal: "" });
    setSelectedAssignmentId("");
    setActiveTab(key);
    setLoading(true);

  };

  // const resetFilters = () => {
  //   setSelectedFilters({ key: "", timeSign: "", tempo: "", searchVal: "" });
  //   if (appliedFilters) {

  //     applyFilters({ key: "", timeSign: "", tempo: "", searchVal: "" });
  //   }
  // };
  return (


    <div>
   
     
   
      <div style={{ marginBottom: "20px" }}>
      <Search
  placeholder="Search"
  style={{ width: "50%", borderRadius: "30px" }}
  onChange={(e) => setSelectedFilters({ ...selectedFilters, searchVal: e.target.value })}
  onSearch={handleSearch}
  value={selectedFilters.searchVal}
  //  CloseCircleOutlined onClick={handleClearSearch} 

  allowClear
  
/>


        {/* <FilterOutlined
          onClick={handleFilter}
          style={{
            marginLeft: "10px",
            fontSize: "27px",
            color: "grey",
            cursor: "pointer",
          }}
        /> */}
         <FilterOutlined
        onClick={handleFilter}
        style={{
          marginLeft: "10px",
          fontSize: "27px",
          color: filterApplied ? "#1890ff" : "grey",
          cursor: "pointer",
        }}
      />

        {activeTab === "1" && (

          <div>
           
          <Button
            type="primary"
            style={{ display: "flex", float: "right", fontWeight: 800, borderRadius: "30px" }}
            onClick={addToLibrary}
            disabled={!selectedAssignmentId}
          >
            Move to Artium Library
          </Button>
         
             <Button
             type={selectedAssignmentId ? "danger" : "primary"}
             style={{ display: "flex", float: "right", fontWeight: 800, borderRadius: "30px", marginRight: "20px" }}
             onClick={() => handleDeleteIconClick(selectedAssignmentId)}
       
 
 
             disabled={!selectedAssignmentId}
           >
             Remove From Assignment List
           </Button>
           </div>
        )}

        {activeTab === "2" && (
          <Button
            type="primary"
            style={{ display: "flex", float: "right", fontWeight: 800, borderRadius: "30px"}}
            // onClick={removeFromLibrary}
            onClick={() => { removeFromLibrary() }} 
            disabled={!selectedAssignmentId}
          >
           Remove From Artium Library
          </Button>
        )}
      


      </div>

     


      <div className="calendar-legendset-conatiner">
        <div className="calendar-legend-set">

          <div>
            <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        
              <TabPane tab="Assignment List" key="1">

              </TabPane>
              <TabPane tab="Artium Library List" key="2">

              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
      {assignList.length > 0 ? (
        <div>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin size="large" />
            </div>
          ) : assignList === null ? (
            <div>No data</div>

          ) : (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
          {assignList.map((assignment) => (
  <Card
    className="cardlist"
    key={assignment.objectID}
    hoverable
    
    onClick={() => {
      fetchUrl(assignment);
      setClickCardDetails(assignment)
      handleCheckboxChange(assignment.objectID, assignment);
      console.log("Clicked card details:", assignment);
      
    }}
    style={{
      height: "230px",
      width: "320px",
      position: "relative",
      boxShadow: "inset 0 0 10px rgba(0,0,0,0.3)",
      backgroundColor: "black",
      border: `3px solid ${assignment.artiumLibrary ? "deeppink" : "blue"
      }`,
      opacity:
        selectedAssignmentId === assignment.objectID ? 1 : 0.8,
      transition: "opacity 0.3s ease",
      borderRadius: "18px !important"
    }}
    onMouseEnter={() => setHoveredAssignmentId(assignment.objectID)}
    onMouseLeave={() => setHoveredAssignmentId(null)}
    cover={
      <div>
        {isAudioFile(assignment.fileName) ? (
          <img
            style={{
              position: "absolute",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
              width: "100%",
            }}
            
            src={waveicon}
            alt="waveicon"
            onClick={(e) => {
              openAudioModal(assignment);
              for (let element of document.getElementsByClassName("cardlist")) {
                console.log(element)
                element.style.borderColor = assignment.artiumLibrary ? "deeppink" : "blue";
              }
              e.currentTarget.closest('.ant-card').style.border = '4px solid #FF6500';
            }}
            
          />
          
        ) : (
          <div>
  {assignment.thumbnailUrl && (
    <div>
      <img
        alt="assignment thumbnail"
        src={assignment.thumbnailUrl}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
     
      <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
      
      <div style={{ position: "absolute", bottom: "10px", left: 0, width: "100%", textAlign: "center", backgroundColor:"black" }}>
      <b>{assignment.topic}</b>
  <div style={{ color: "white", fontWeight: 800, whiteSpace: "nowrap" }}>
    {assignment.key && <span style={{ backgroundColor: "grey", color: "white", marginRight: "10px" }}>Key: {assignment.key}</span>}
    {assignment.tempo && <span style={{ backgroundColor: "grey", color: "white", marginRight: "10px" }}>Tempo: {assignment.tempo}</span>}<br />
    {assignment.timeSign && <span style={{ backgroundColor: "grey", color: "white", marginRight: "10px", whiteSpace: "pre-wrap" }}>TimeSign: {assignment.timeSign}</span>}
  </div>
</div>

      </div>
    </div>
 

          )}
        </div>
          )}
        {(assignment.fileName.endsWith(".webm") ||
          assignment.fileName.endsWith(".mp4")) && (
            <PlayCircleOutlined
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "34px",
                color: "white",
                zIndex: 2,
              }}
              onClick={(e) => {
                openAudioModal(assignment);
                for (let element of document.getElementsByClassName("cardlist")) {
                  console.log(element)
                  element.style.borderColor = assignment.artiumLibrary ? "deeppink" : "blue";
                }
                e.currentTarget.closest('.ant-card').style.border = '4px solid #FF6500';
              }}
            />
          )}
        {assignment.fileName.endsWith(".pdf") && (
          <FilePdfOutlined
            style={{
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: "34px",
              color: "white",
              zIndex: 2,
            }}
            onClick={(e) => {
              openAudioModal(assignment);
              for (let element of document.getElementsByClassName("cardlist")) {
                console.log(element)
                element.style.borderColor = assignment.artiumLibrary ? "deeppink" : "blue";
              }
              e.currentTarget.closest('.ant-card').style.border = '4px solid #FF6500';
            }}

          />
        )}
      </div>
    }
  >
    {!assignment.artiumLibrary && (
      <div style={{ position: "absolute", top: "10px" }}>
        <input
          type="checkbox"
          checked={selectedAssignmentId === assignment.objectID }
          onChange={() => {
            handleCheckboxChange(assignment.objectID, assignment);
            
            // handleDeleteIconClick(assignment.objectID, assignment);
          }}
        />
      </div>
    )}
    {assignment.artiumLibrary && (
      <div style={{ position: "absolute", top: "10px", right: "10px" }}>
        <input
          type="checkbox"
          checked={selectedAssignmentId === assignment.objectID}
          onChange={() => handleArtiumCheckbox(assignment.objectID, assignment)}
        />
      </div>
    )}
    <div
      style={{
        marginTop:
          assignment.fileName.endsWith(".mp3") ||
          assignment.fileName.endsWith(".wav") ||
          assignment.fileName.endsWith(".m4a") ||
          assignment.fileName.endsWith(".ogg")
            ? "57%"
            : "50%",
      }}
    >
      <div
        style={{
          color: "white",
          fontWeight: 800,
          textAlign: "center",
          bottom: "20px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          marginTop: "-10%"
        }}
      >
        {assignment.topic}
      </div>
      <div style={{ backgroundColor: "black", padding: "5px", borderRadius: "5px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
  <div style={{
    color: "white",
    fontWeight: 800,
    bottom: "20px",
    marginLeft: "0px",
    textAlign: "center",
    whiteSpace: "nowrap",
  }}>
    {assignment.key && <span style={{ backgroundColor: "grey", color: "white", marginRight: "10px" }}>Key: {assignment.key}</span>}
    {assignment.tempo && <span style={{ backgroundColor: "grey", color: "white", marginRight: "10px" }}>Tempo: {assignment.tempo}</span>}<br />
    {assignment.timeSign && <span style={{ backgroundColor: "grey", color: "white", marginRight: "10px", whiteSpace: "pre-wrap" }}>TimeSign: {assignment.timeSign}</span>}
  </div>
</div>
    </div>
  </Card>
))}

            </div>
          )}
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>No Data Found</div>
      )}
     {assignList.length > 0 &&
  <div style={{ float: "right", display: "flex" }}>
    <Button
      type="primary"
      onClick={loadMore}
      // disabled={assignList.length < 20}
    >
      Load more
    </Button>
  </div>
}
      <Modal
        title="Filter"
        visible={showFilterModal}
        onCancel={() => {handleFilterModal()}
     
          
        }
        footer={[
          <Button
            key="reset"
            onClick={() => {
              setShowFilterModal(true);
              setSelectedFilters(null);
              filterReset();
            }}
          >
            Reset
          </Button>,
          <Button key="apply" type="primary" 
          onClick={() => { applyFilters(selectedFilters); setShowFilterModal(false); }}
          >
            Apply
          </Button>,
        ]}
        getContainer={false}
        style={{ zIndex: 1050 }}
      >
        <div>
          <div style={{ marginBottom: "10px" }}>
            <label htmlFor="keyFilter">
              <b>Key:</b>
            </label>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
              {[
                "C",
                "C#",
                "D",
                "D#",
                "E",
                "F",
                "F#",
                "G",
                "G#",
                "A",
                "A#",
                "B",
              ].map((key) => (
                <span
                  key={key}
                  style={{
                    padding: "5px",
                    border: "3px solid #ccc",
                    borderRadius: "10px !important",
                    cursor: "pointer",
                    backgroundColor:
                      selectedFilters.key === key ? "grey" : "transparent",
                    color: selectedFilters.key === key ? "white" : "black",
                  }}
                  onClick={() => handleKeyClick(key)}
                >
                  {key}
                </span>
              ))}
            </div>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <label htmlFor="timeSignFilter">
              <b>Time Signature:</b>
            </label>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
              {[
                "2/4",
                "2/2",
                "3/8",
                "3/4",
                "3/2",
                "4/8",
                "4/4",
                "4/2",
                "5/4",
                "6/8",
                "6/4",
                "7/8",
                "9/8",
                "9/4",
                "10/8",
                "11/8",
                "12/8",
                "12/4",
                "Druva talam",
                "Jampa talam",
                "Atta talam",
                "tisram",
                "chathushram",
                "khandam",
                "mishram",
                "sankeernam",
                "Dadra",
                "Keharva",
                "Bhajani Keharva",
                "Rupak",
                "Deepchandi",
                "Jhap",
                "Teental",
                "Addha Teental",
                "Ektaal",
                "Chachar",
              ].map((timeSign) => (
                <span
                  key={timeSign}
                  style={{
                    padding: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "3px",
                    cursor: "pointer",
                    backgroundColor:
                      selectedFilters.timeSign === timeSign ? "blue" : "transparent",
                    color: selectedFilters.timeSign === timeSign ? "white" : "black",
                  }}
                  onClick={() => handleTimeSignClick(timeSign)}
                >
                  {timeSign}
                </span>
              ))}
            </div>
          </div>

          <div>
            <label htmlFor="tempoFilter">
              <b>Tempo:</b>
            </label>
            <Input
              id="tempoFilter"
              placeholder="Enter Tempo"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title="Audio/Video/Pdf"
        visible={isModalVisible}
        onCancel={() => {
          handleCancel();
        }}
        footer={null}
       
        
      >
        <div>
          {" "}


          {selectedFileDetails &&
            (selectedFileDetails?.fileName?.endsWith(".mp3") ||
              selectedFileDetails?.fileName?.endsWith(".m4a")) &&
            fullUrl?.signedURL && (
              <audio
                id="audioPlayer"
                controls
               style={{width:"100%"}}
                autoPlay={isAudioPlaying}
                src={fullUrl.signedURL}
                onPlay={() => setIsAudioPlaying(true)}
                onPause={() => setIsAudioPlaying(false)}
              />
            )}
          {selectedFileDetails &&
            (selectedFileDetails?.fileName?.endsWith(".mp4") ||
              selectedFileDetails?.fileName?.endsWith(".webm")) &&
            fullUrl?.signedURL && (
              <video
                id="videoPlayer"
                width="100%"
                height="100%"
                controls
                autoPlay
                controlsList="nodownload"
                src={fullUrl.signedURL}
                onPlay={() => setIsAudioPlaying(true)}
                onPause={() => setIsAudioPlaying(false)}
              />

            )}
          <div style={{overflow:"auto"}}>
            {selectedFileDetails &&
              selectedFileDetails?.fileName?.endsWith(".pdf") &&
              fullUrl?.signedURL && (

                // <iframe
                //   src={fullUrl.signedURL}
                //   title="PDF Viewer"
                //   width="100%"
                //   height="100%"
                //   style={{ border: 'none' }}
                // />
                <Document
                  file={fullUrl.signedURL}
                  onContextMenu={(e) => e.preventDefault()}
                  externalLinkTarget="_blank"
                  className='pdfjs-container'

                >
                  {/* {Array.from(new Array(numPages), (el, index) => ( */}
                  <Page size="A4"
                    key={`page_${1}`}
                    pageNumber={1}

                  // height="100%"
                  />
                  {/* ))} */}
                </Document>
              )}
          </div>
  


          <div style={{
                        color: "black",
                        fontWeight: 800,
                        textAlign: "center",
                        bottom: "10px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {selectedFileDetails.topic}</div>
<div   style={{
    color: "black",
    fontWeight: 800,
    textAlign: "center",
    bottom: "10px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginTop:"10px",
  }}>
    
  {selectedFileDetails.key && <Tag>Key-{selectedFileDetails.key}</Tag>}
  {selectedFileDetails.tempo && <Tag>Tempo-{selectedFileDetails.tempo}</Tag>}
  {selectedFileDetails.timeSign && <Tag>TimeSign-{selectedFileDetails.timeSign}</Tag>}
  <div>{activeTab === "1" && (
          <div>
           
          <Button
            type="primary"
            style={{ display: "flex", float: "right", fontWeight: 500, borderRadius: "30px", marginTop:"10px" }}
            onClick={addToLibrary}
            disabled={buttonClickedMap[selectedAssignmentId]} 
          >
            Move to Artium Library
          </Button>
             <Button
             type={selectedAssignmentId ? "danger" : "primary"}
             style={{ display: "flex", float: "right", fontWeight: 500, borderRadius: "30px", marginRight: "45px",marginTop:"10px" }}
             onClick={() => handleDeleteIconClick(selectedAssignmentId)} 
            
           >
             Remove From Assignment List
           </Button>
           </div>
        )}
        {activeTab === "2" && (
          <Button
            type="primary"
            style={{ display: "flex", float: "right", fontWeight: 800, borderRadius: "30px", marginTop:"10px", marginRight:"110px"}}
            onClick={removeFromLibrary} 
              disabled={buttonClickedMap[selectedAssignmentId]} 


          >
           Remove From Artium Library
          </Button>
        )}</div>
</div>
        </div>
      </Modal>
      <Modal
        title="Confirm Delete"
        visible={showDeleteConfirmationModal}
        onOk={handleConfirmDelete}
        onCancel={handleCancelDelete}


      >
        <p style={{ color: "red" }}>Are you sure you want to delete this assignment?</p>
      </Modal>

    </div>
  );
};

export default AssignList;