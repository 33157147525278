import React, { useEffect, useState } from "react";
import "./teacher-profile-style.css";
import { useSearchParams, useLocation } from "react-router-dom";
import Profile from "../view-details/index";
import { connect } from "react-redux";
import _ from "lodash";
import * as SecureLS from "secure-ls";
import axios from "axios";
import {
  Form,
  Input,
  Button,
  Upload,
  message,
  InputNumber,
  Select,
  notification,
  DatePicker,
  Radio,
} from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import appEnvirontVariables from "../../../core_units/config/env-variables";
import TeacherStatusModal from "./teacher-status";
import Teacheraction from "./teacheraction";


const { Option } = Select;
const dateformat = "DD/MM/YYYY";
const TeacherProfilePage = (props) => {
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  console.log ("token", token)
  const userEmail = token.username;
  const [form] = Form.useForm();
  const [teacherId] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [submitdisable, setSubmitdisable] = useState(true);
  const [courselist, setcourselist] = useState();
  const [agegroups, setagegroups] = useState();
  const [languageslist, setlanguageslist] = useState();
  const [courseList, setCourseList] = useState();
  const [countrycode, setcoundtrycode] = useState();
  const [countryChange, setCountryChange] = useState();
  const [teacherDetails, seteacherDetails] = useState();
  const [disableDomestic, setDisableDomestic] = useState(false);
  const [disableInternational, setDisableInternational] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [teacherCourseMap, setTeacherCourseMap] = useState();
  let teacherCourseIds = [];
  let teachercourse = {};
  useEffect(() => {
    // apicall();
    teachercourselist();
    agegrouplist();
    languagelist();
    getallcourselist();
    // formfields(_.get(props, "teachers.viewteacherprofile"));
    configjson();
    // getTeacherDetails();
    getTeacherDetails().then((datadetails) => {
      console.log("fdfdf", datadetails.data);
      const fieldResponse = datadetails.data;
      setUserDetails(datadetails.data);
      formfields(fieldResponse);
    });
  }, [_.get(props, "teachers.viewteacherprofile.data")]);
  console.log('props', userDetails);

  //API call for teacherdetails
  const getTeacherDetails = async () => {
    return axios.get(
      `${appEnvirontVariables.API.BASE_URL
      }/api/admin/teacherdetails?teacherId=${teacherId.get("id")}`,
      { headers: { Authorization: token["authorization"] } }
    );
  };
  const onlyDomestic = (ev) => {
    console.log('onlyDomestic', ev);
    if (ev.target.value) {
      form.setFieldsValue({ isOnlyInternational: false })
    }
    setDisableInternational(ev.target.value);
  }
  const onlyInternational = (ev) => {
    console.log('onlyInternational', ev);
    if (ev.target.value) {
      form.setFieldsValue({ isOnlydomestic: false })
    }
    setDisableDomestic(ev.target.value);
  }


  const apicall = (query = {}) => {
    props.view({
      params: {
        teacherId: teacherId.get("credid"),
      },
      callback: (response, data) => {
        formfields(data);
      },
    });
  };
  const configjson = () => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          console.log("courselist", data);
          data.onlineGroupCourses = data.onlineGroupCourses.filter(e => e.enabled);
          setCourseList(data);
        }
      },
    });
  };
  const agegrouplist = () => {
    props.getagroups({
      params: {
        teacherId: teacherId.get("id"),
      },
      callback: (response, data) => {
        setagegroups(data);
        // form.setFieldsValue({
        //     course: _.uniqBy(_.map(_.get(data, 'courses'), 'name') )
        // })
      },
    });
  };
  const languagelist = () => {
    props.getlanguages({
      params: {
        teacherId: teacherId.get("id"),
      },
      callback: (response, data) => {
        setlanguageslist(_.get(data, "courses"));
        _.map(_.get(data, "courses"), "name");
        // form.setFieldsValue({
        //     course: _.uniqBy(_.map(_.get(data, 'courses'), 'name') )
        // })
      },
    });
  };
  const getallcourselist = () => {
    props.getallcourse({
      params: {
        teacherId: teacherId.get("id"),
      },
      callback: (response, data) => {
        setcourselist(_.get(data, "courses"));
        // _.map(_.get(data, 'courses'), 'name');
        // form.setFieldsValue({
        //     course: _.uniqBy(_.map(_.get(data, 'courses'), 'name') )
        // })
      },
    });
  };
  const teachercourselist = () => {
    props.courselist({
      params: {
        teacherId: teacherId.get("id"),
      },
      callback: (response, data) => {
        setcourselist(_.get(data, "courses"));
        _.map(_.get(data, "courses"), "name");
        form.setFieldsValue({
          course: _.uniqBy(_.map(_.get(data, "courses"), "name")),
        });
      },
    });
  };
  const formfields = (data) => {

    console.log("formfilds", data)

    const profile = data.teacher;
    (data?.teachercourse?.courses || []).forEach(e => teacherCourseIds.push(e.id.toString()));
    setTeacherCourseMap(teacherCourseIds)
    var genderToSet;
    if (data.preferredGender == null) {
      genderToSet = "Both";
    } else {
      genderToSet = data.preferredGender;
    }
   


    let filteredIds = [];
    if (data.teacher.language) {
      data.teacher.language.map((item, index) => {
        filteredIds.push(item.id.toString());
      });
      // console.log("filteredIds", filteredIds);
    }

    let filteredAgeIds = [];
    if (data.teacher.ageGroup) {
      data.teacher.ageGroup.map((item, index) => {
        filteredAgeIds.push(item.id.toString());
      });
      console.log("filteredAgeIds", filteredAgeIds);
    }

    let userDetails = {};
    if (data.UserDetails) {
      userDetails.firstName = data.UserDetails.firstName;
      userDetails.lastName = data.UserDetails.lastName;
      userDetails.birthDate = data.UserDetails.birthDate;
      userDetails.mobileNumber = data.UserDetails.credential.mobileNumber;
      userDetails.password = data.UserDetails.credential.password;
      userDetails.countryCode = data.UserDetails.credential.countryCode;
      userDetails.emailId = data.UserDetails.credential.emailId;
      userDetails.description = data.UserDetails.descriptionDetails.description;
      userDetails.state = data.UserDetails.state;

      userDetails.id = data.UserDetails.id;

    }
    if (data.teachercourse) {
      teachercourse.courses = data.teachercourse.courses;
    }


    //to view the profile page
    form.setFieldsValue({
      firstName: userDetails.firstName,
      emailId: userDetails.emailId,
      password: userDetails.password,
      countrycode: userDetails.countryCode,
      mobileNumber: userDetails.mobileNumber,
      lastName: userDetails.lastName,
      ageGroupId: filteredAgeIds,
      dateofbirth: _.get(profile, "dateofbirth") ?? "",
      state: userDetails.state?.name || "",
      profilePhoto: _.get(profile, "profilePhoto"),
      academicQualifications: _.get(profile, "academicQualifications"),
      description: userDetails.description,
      lastname: _.get(profile, "lastname"),
      isOnlydomestic: _.get(profile, "onlyDomestic"),
      isOnlyInternational: _.get(profile, "onlyInternational"),
      timeZone: _.get(profile, "preferredTimeZone")?.type,
      Teacherphotolink: _.get(profile, "photolinkdetails")?.photolink,
      TeacherVideoLink: _.get(profile, "videolinkDetails")?.videolink,
      isMarkedForOffers: _.get(profile, "markedForOffers"),
      employmentType: _.get(profile, "employmentType") || "",
      isAcademicExpert: _.get(profile, "isAcademicExpert"),
      isBatchOffline: _.get(profile, "isBatchOffline"),
      isBatchOnline: _.get(profile, "isBatchOnline"),
      is1to1Enabled: _.get(profile, "is1to1Enabled"),
      referredBy: _.get(profile, "referredBy"),
      readynessForTraining: _.get(profile, "readynessForTraining"),
      yearsOfExperience: _.get(profile, "yearsOfExperience"),
      preferredGender: _.get(profile, "preferredGender") ?? "Both",
      teacherGender: _.get(data, "teacherGender") ?? null,
      languageId: filteredIds,
      payoutCategory: _.get(profile, "payoutCategory", ) || "",
      courseId: teacherCourseIds,
      isKidsFriendly: _.get(profile, "isKidsFriendly") ?? true,
    });
    setImageUrl(_.get(profile, "profilePhoto"));
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImageUrl(imageUrl),
        setLoading(false)
      );
      if (
        !_.isEmpty(_.get(props, "teachers.viewteacherprofile.profilePhoto"))
      ) {
        props.photodelete({
          params: {
            FileUrl: teacherId.get("credid"),
          },
          callback: (res, data) => { },
        });
      } else {
        const formData = new FormData();
        formData.append("file", imageUrl);
        props.photoupload({
          params: {
            credentialId: teacherId.get("credid"),
          },
          body: formData,
          callback: (res, data) => { },
        });
      }
    }
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const onFinish = (values) => {

    teacherCourseIds = teacherCourseMap;
    values.courseId.forEach(e => {
      let ind = teacherCourseIds.indexOf(e);
      if (ind > -1) {
        teacherCourseIds.splice(ind, 1);
      } else {
        teacherCourseIds.push(e);
      }
    });
    values.courseId = teacherCourseIds;
    let course = _.remove(_.get(values, "course"), (each) => {
      return typeof each == "number";
    });
    let coursedetails = [];
    _.map(course, (each, i) => {
      coursedetails.push({
        teacherId: teacherId.get("id"),
        courseId: each,
        marquee: true,
        cdnUrl: null,
        status: 8,
      });
    });

    let req = {

      isKidsFriendly: true,
      isOnlydomestic: true,
      isOnlyInternational: false,
      isMarkedForOffers: true,
      userId: _.get(userDetails, "UserDetails.id") + "",


      academicQualifications: _.get(props, "teachers.viewteacherprofile. academicQualifications"),
      teacherId: teacherId.get("id"),
      ...values,
      profilePhoto: imageUrl,
      preferredGender: values.preferredGender == "Both" ? null : values.preferredGender,
      employmentType: values.employmentType, 
      payoutCategory: values.payoutCategory,
    }

    // props.updatecourselist({
    //   body: coursedetails,
    //   callback: (res, data) => {
    //     console.log("api works", data);    
    //   },
    // });

    props.edit({
      body: req,
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          apicall();
          notification.success({
            message: "Profile updated successfully.",
          });
        } else {
          notification.error({
            message: "Something went wrong",
          });
        }
      },
    });
  };

  const onFinishFailed = (errorInfo) => { };
  const onReset = () => {
    form.resetFields();
    formfields(_.get(props, "teachers.viewteacherprofile"));
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  const selectBefore = (
    <Select
      defaultValue={_.get(props, "teachers.viewteacherprofile.countryCode")}
      style={{ width: 100 }}
      onSelect={(value) => setcoundtrycode(value)}
    >
      {_.map(_.get(courseList, "countryCodes"), (country, i) => {
        return (
          <Option value={_.get(country, "countryCode")}>
            {_.get(country, "countryCode")}
          </Option>
        );
      })}
    </Select>
  );
  const render = () => {
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload Photo</div>
      </div>
    );

    return (
      <div className="teacher-profile-container">
        <div>{/* <Profile /> */}</div>
        <div>
          <div className="card mb-5 mb-xl-10">
            {/* <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                            <div className="card-title m-0">
                                <h3 className="fw-bolder m-0">Teacher Profile Details</h3>
                            </div>
                        </div> */}

            <div
              id="kt_account_settings_profile_details"
              className="collapse show"
            >
              <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                onValuesChange={() => setSubmitdisable(false)}
              >
                <div id="kt_account_profile_details_form" className="form">
                  <div className="card-body border-top p-9">
                    <div className="row mb-6">
                      <div className="col-lg-6">
                        <Form.Item
                          name="profilePhoto"
                          rules={[
                            {
                              required: false,
                              message: "Please input your image!",
                            },
                          ]}
                        >
                          <Upload
                            listType="picture-card"
                            className="avatar-uploader"
                            accept="image/*"
                            showUploadList={false}
                            onPreview={onPreview}
                            beforeUpload={beforeUpload}
                            onChange={(e) => handleChange(e)}
                            customRequest={({ file, onSuccess }) => {
                              setTimeout(() => {
                                onSuccess("ok");
                              }, 0);
                            }}
                            disabled
                          >
                            {imageUrl ? (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </Form.Item>
                      </div>
                      <TeacherStatusModal {...props} apicall={apicall} />
                      {/* <Teacheraction {...props} apicall={apicall} /> */}
                    </div>
                    <div >
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          First Name  <span className="validationsign">*</span>
                        </div>

                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"firstName"}
                            // label="Email"
                            rules={[{ required: true }]}
                          >
                            <Input placeholder="Enter Your First Name " />
                          </Form.Item>{" "}
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          Last Name
                        </div>

                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"lastName"}
                            // label="Email"
                            rules={[{ required: false }]}
                          >
                            <Input disabled placeholder="Enter Your Last Name " />
                          </Form.Item>{" "}
                        </div>
                      </div>

                      <div className="row mb-6 mt-2">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          Email  <span className="validationsign">*</span>
                        </div>

                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"emailId"}
                            // label="Email"
                            rules={[
                              {
                                required: true,
                                type: "email",
                              },
                            ]}
                          >
                            <Input placeholder="Enter valid EmailId " />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          Password
                        </div>

                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"password"}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input disabled placeholder="Enter Your Password" />
                          </Form.Item>{" "}
                        </div>
                      </div>

                      <div className="row mb-6 mt-2">
                        <div className="col-lg-4 col-form-label fw-bold fs-6">
                          <span className="required">Country Code</span>
                        </div>
                        <div className="col-lg-6">
                          <div className="row">
                            <div className="col-lg-4 fv-row">

                              <Form.Item
                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                name={"countrycode"}
                                rules={[
                                  {
                                    required: true,
                                  },
                                ]}
                              >
                                <Select disabled
                                  style={{ width: 100 }}
                                  placeholder="Select CountryCode"
                                >
                                  {_.map(
                                    _.get(courseList, "countryCodes"),
                                    (countrycodes, each) => {
                                      //console.log("countrycodes", countrycodes);
                                      return (
                                        <Option
                                          value={_.get(
                                            countrycodes,
                                            "countryCode"
                                          )}
                                          key={each}
                                        >
                                          {_.get(countrycodes, "countryCode")}
                                        </Option>
                                      );
                                    }
                                  )}
                                </Select>
                              </Form.Item>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          Mobile Number  <span className="validationsign">*</span>
                        </div>

                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"mobileNumber"}
                            rules={[{ required: true }]}
                          >
                            <Input disabled
                              style={{ width: 260 }}
                              onKeyPress={(e) =>
                                !/[0-9]/.test(e.key) && e.preventDefault()
                              }

                              placeholder="Enter Your Mobile Number"
                            />
                          </Form.Item>{" "}
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label fw-bold fs-6">
                          <span className="required">Gender  <span className="validationsign">*</span></span>
                        </div>
                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"teacherGender"}
                            rules={[{ required: true }]}
                          >
                            <Select
                              style={{ width: 420 }}
                              placeholder="Select Gender"
                            >
                              <Option value="Male">Male</Option>
                              <Option value="Female">Female</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </div>


                      {/* <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Gender  <span className="validationsign">*</span></span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"genderId"}
                          rules={[{ required: true }]}
                        >
                          <Select
                                // defaultValue={profile.gender == male?"1":'2'}
                            style={{ width: 420 }}
                            placeholder="Select Gender"
                          >
                            {_.map(
                        _.get(courseList, "genders"),
                        (genders, each) => {
                          console.log("genders", genders);
                          return (
                            <option
                              value={_.get(genders, "id") + ""}
                              key={each}
                            >
                              {_.get(genders, "name")}
                            </option>
                          );
                        }
                      )}
                          </Select>
                        </Form.Item>
                      </div>
                    </div> */}
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label fw-bold fs-6">
                          <span className="required">Preferred agegroup  <span className="validationsign">*</span></span>
                        </div>
                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"ageGroupId"}
                            style={{ height: "auto" }}
                            rules={[{ required: true }]}
                          >
                            <Select mode="multiple" placeholder="Select Agegroup">
                              {_.map(
                                _.get(courseList, "ageGroups"),
                                (agegroups, each) => {
                                  console.log("agegroups", agegroups);
                                  return (
                                    <Option
                                      value={_.get(agegroups, "id") + ""}
                                      key={each}
                                    >
                                      {_.get(agegroups, "name")}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          Academic Qualifications  <span className="validationsign">*</span>
                        </div>
                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"academicQualifications"}
                            rules={[{ required: true }]}
                          >
                            <Input placeholder="Enter Your Academic Qualifications" />
                          </Form.Item>{" "}
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label fw-bold fs-6">
                          <span className="required">State  <span className="validationsign">*</span></span>
                        </div>
                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={'state'}
                            rules={[
                              { required: true }
                            ]}
                          >
                            <Select >
                              {courseList?.states?.length && courseList?.states.map(state => {
                                return <Option value={state}>{state}</Option>
                              })}

                            </Select>
                          </Form.Item>
                        </div>

                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label fw-bold fs-6">
                          <span className="required">Time zone  <span className="validationsign">*</span></span>
                        </div>
                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={'timeZone'}
                            rules={[
                              { required: true }
                            ]}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {courseList?.timezones?.length && courseList?.timezones.map(timezone => (
                                <Option key={timezone.type} value={timezone.type}>
                                  {`${timezone.type} ${timezone.name}`}
                                </Option>
                              ))}
                            </Select>

                          </Form.Item>
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          Only International <span className="validationsign">*</span>
                        </div>

                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"isOnlyInternational"}
                            rules={[{ required: true }]}
                          >
                            <Radio.Group disabled={disableInternational} className="click" onChange={(event) => onlyInternational(event)}>
                              <Radio value={true}>True</Radio>
                              <Radio value={false}>false</Radio>
                            </Radio.Group>
                          </Form.Item>{" "}
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          Only Domestic <span className="validationsign">*</span>
                        </div>
                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"isOnlydomestic"}
                            rules={[{ required: true }]}
                          >
                            <Radio.Group disabled={disableDomestic} onChange={(event) => onlyDomestic(event)}>
                              <Radio value={true}>True</Radio>
                              <Radio value={false}>false</Radio>
                            </Radio.Group>
                          </Form.Item>{" "}
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          Photo Link  <span className="validationsign">*</span>
                        </div>

                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"Teacherphotolink"}
                            rules={[{ required: true }]}
                          >
                            <Input placeholder="Enter Valid PhotoLink" />
                          </Form.Item>{" "}
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          Video Link  <span className="validationsign">*</span>
                        </div>

                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"TeacherVideoLink"}
                            rules={[{ required: true }]}
                          >
                            <Input placeholder="Enter valid VideoLink" />
                          </Form.Item>{" "}
                        </div>
                      </div>

                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          Marked For Offers  <span className="validationsign">*</span>
                        </div>
                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"isMarkedForOffers"}
                            rules={[]}
                          >
                            <Radio.Group>
                              <Radio value={true}>True</Radio>
                              <Radio value={false}>False</Radio>
                            </Radio.Group>
                          </Form.Item>{" "}
                        </div>
                      </div>
                  
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Employment Type  <span className="validationsign">*</span>
                        </div>
                        <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"employmentType"}
                          rules={[]}
                        >
                          <Radio.Group>
                          <Radio value="full_time">Full Time</Radio>
                            <Radio value="part_time">Part Time</Radio>
                          </Radio.Group>
                        </Form.Item>{" "}
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          Is Academic Expert  <span className="validationsign">*</span>
                        </div>
                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"isAcademicExpert"}
                            rules={[]}
                          >
                            <Radio.Group>
                              <Radio value={true}>True</Radio>
                              <Radio value={false}>False</Radio>
                            </Radio.Group>
                          </Form.Item>{" "}
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          Eligible for Offline Batches <span className="validationsign">*</span>
                        </div>
                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"isBatchOffline"}
                            rules={[]}
                          >
                            <Radio.Group>
                              <Radio value={true}>True</Radio>
                              <Radio value={false}>False</Radio>
                            </Radio.Group>
                          </Form.Item>{" "}
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          Eligible for Online Batches <span className="validationsign">*</span>
                        </div>
                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"isBatchOnline"}
                            rules={[]}
                          >
                            <Radio.Group>
                              <Radio value={true}>True</Radio>
                              <Radio value={false}>False</Radio>
                            </Radio.Group>
                          </Form.Item>{" "}
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          Eligible for 1:1 classes <span className="validationsign">*</span>
                        </div>
                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"is1to1Enabled"}
                            rules={[]}
                          >
                            <Radio.Group>
                              <Radio value={true}>True</Radio>
                              <Radio value={false}>False</Radio>
                            </Radio.Group>
                          </Form.Item>{" "}
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          Years Of Experience  <span className="validationsign">*</span>
                        </div>
                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"yearsOfExperience"}
                            rules={[{ required: true }]}
                          >
                            <Input
                              style={{ width: 260 }}
                              onKeyPress={(e) =>
                                !/[0-9]/.test(e.key) && e.preventDefault()
                              }
                              placeholder="Enter Your Year Of Experience"
                            />
                          </Form.Item>{" "}
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label fw-bold fs-6">
                          <span className="required">Preferred Gender  <span className="validationsign">*</span></span>
                        </div>
                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"preferredGender"}
                            rules={[{ required: true }]}
                          >
                            <Select
                              style={{ width: 420 }}
                              placeholder="Select Gender"
                            >
                              <Option value="Male">Male</Option>
                              <Option value="Female">Female</Option>
                              <Option value="Both">Both</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </div>

                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label fw-bold fs-6">
                          <span className="required">Preferred language  <span className="validationsign">*</span></span>
                        </div>
                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            style={{ height: "auto" }}
                            name={"languageId"}
                            rules={[{ required: true }]}
                          >
                            <Select mode="multiple" placeholder="Select Language">
                              {_.map(
                                _.get(courseList, "languages"),
                                (languages, each) => {
                                  console.log("languages", languages);
                                  return (
                                    <Option
                                      value={_.get(languages, "id") + ""}
                                      key={each}
                                    >
                                      {_.get(languages, "name")}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row mb-6">
  <div className="col-lg-4 col-form-label fw-bold fs-6">
    <span className="required"> Level <span className="validationsign">*</span></span>
  </div>
  <div className="col-lg-6 fv-row">
    <Form.Item
      className="form-control form-control-lg form-control-solid"
      name={"payoutCategory"}
      rules={[{ required: true }]}
    >
      <Select
        style={{ width: 420 }}
        placeholder="Payout Category"
        disabled={userEmail !== '9821249951' && userEmail !== '8638013101'} 
      >
        <Option value="SILVER">SILVER</Option>
        <Option value="GOLD">GOLD</Option>
        <Option value="PLATINUM">PLATINUM</Option>
        <Option value="DIAMOND">DIAMOND</Option>
      </Select>
    </Form.Item>
  </div>
</div>

                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label fw-bold fs-6">
                          <span className="required">Courses  <span className="validationsign">*</span></span>
                        </div>
                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            style={{ height: "auto" }}
                            name={"courseId"}
                            rules={[{ required: true }]}
                          >
                            <Select mode="multiple" placeholder="Select Course">
                              {_.map(
                                _.get(courseList, "courses"),
                                (course, eachParent) => {
                                  {
                                    return _.map(
                                      _.get(course, "tags"),
                                      (tag, each) => {
                                        //console.log("tags", tag);
                                        //console.log("course", course);
                                        return (
                                          <Option
                                            value={_.get(tag, "demoCourseId")}
                                            key={eachParent + "-" + each}
                                          >
                                            {_.get(course, "name")}-
                                            {_.get(tag, "name")}-{"Demo"}
                                          </Option>
                                        );
                                      }
                                    );
                                  }
                                }
                              )}


                              {_.map(
                                _.get(courseList, "onlineGroupCourses"),
                                (course, eachParent) => {
                                  {
                                    return _.map(
                                      _.get(course, "tags"),
                                      (tag, each) => {
                                        //console.log("tags", tag);
                                        //console.log("course", course);
                                        return (
                                          <Option
                                            value={_.get(tag, "paidCourseId")}
                                            key={eachParent + "-" + each}
                                          >
                                            {_.get(course, "name")}-
                                            {_.get(tag, "name")}
                                          </Option>
                                        );
                                      }
                                    );
                                  }
                                }
                                
                              )}
                                 
                                


                                 




{courseList && courseList.offlineCourseCentres && courseList.offlineCourseCentres.map((offlineCourseCentre, index1) => (
 
 offlineCourseCentre.courses.map((course, index2) => {
    
      {
        return _.map(
          _.get(course, "tags"),
          (tag, each) => {
            //console.log("tags", tag);
            //console.log("course", course);
            return (
              <Option
                value={_.get(tag, "paidCourseId")}
                key={index2 + "-" + each}
              >
                {_.get(course, "name")}-
                {_.get(tag, "name")}
              </Option>
            );
          }
        );
      }
  })
 
))}





                              
                              {_.map(
                                _.get(courseList, "courses"),
                                (course, eachParent) => {
                                  {
                                    return _.map(
                                      _.get(course, "tags"),
                                      (tag, each) => {
                                        //console.log("tags", tag);
                                        //console.log("course", course);
                                        return (
                                          <Option
                                            value={_.get(tag, "paidCourseId")}
                                            key={eachParent + "-" + each}
                                          >
                                            {_.get(course, "name")}-
                                            {_.get(tag, "name")}-{"Paid"}
                                          </Option>
                                        );
                                      }
                                    );
                                  }
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row mb-6">
                        <div className="col-lg-4 col-form-label required fw-bold fs-6">
                          Description  <span className="validationsign">*</span>
                        </div>

                        <div className="col-lg-6 fv-row">
                          <Form.Item
                            className="form-control form-control-lg form-control-solid"
                            name={"description"}
                            rules={[{ required: true }]}
                          >
                            <Input placeholder="Enter Your Description" />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <Form.Item>
                      <Button
                        htmlType="button"
                        className="btn btn-light btn-active-light-primary mr-2"
                        onClick={onReset}
                      >
                        Discard
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        // disabled={submitdisable}
                        loading={_.get(
                          props,
                          "editTeacherProfileLoadingInprogress"
                        )}
                        className="btn btn-primary"
                        id="kt_account_profile_details_submit"
                      >
                        Save Changes
                      </Button>
                    </Form.Item>

                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => ({
  teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
  students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  edit: (details) =>
    dispatch(global.redux.action.teachers.editteacherprofile(details)),
  view: (details) =>
    dispatch(global.redux.action.teachers.viewteacherprofile(details)),

  photoupload: (details) =>
    dispatch(global.redux.action.admin.photoupload(details)),
  photodelete: (details) =>
    dispatch(global.redux.action.admin.photodelete(details)),
  courselist: (details) =>
    dispatch(global.redux.action.teachers.courselist(details)),
  getagroups: (details) =>
    dispatch(global.redux.action.teachers.getagroups(details)),
  getlanguages: (details) =>
    dispatch(global.redux.action.teachers.getlanguages(details)),
  getallcourse: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
  updatecourselist: (details) =>
    dispatch(global.redux.action.teachers.coursetags(details)),

  courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TeacherProfilePage);
