import React, {  useEffect, useState } from 'react';
import _ from 'lodash';
import {  Route,Routes, useLocation, Navigate,useNavigate } from "react-router-dom";
import routeConfigs from './private-route-config';
import publicRouteConfigs from './public-route-config';
import global from '../config/global'
import { connect } from 'react-redux';
import appPages from './app-pages';
import * as SecureLS from "secure-ls";
import RedirectRouteConfig from '../routes/redirect-route-config'
import logs from '../utils'
import { Input,Form,Button,Spin, Upload,Pagination,notification, Table,Select, message   } from "antd";
import PageHeader from '../../components/Header';
import PageFooter from '../../components/Footer';
import Sidebar from '../../components/sidebar';
import Header from '../../components/Header';

const RoutesPage = (props) => {
   let navigate = useNavigate();
	var ls = new SecureLS({encodingType: 'aes'});
   // Declare a new state variable, which we'll call "appRouteConfigs"
   const [appRouteConfigs, setAppRouteConfigs] = useState([]);
   // Declare a new state variable, which we'll call "appRouteConfigsCount"
   const [appRouteConfigsCount, setAppRouteConfigsCount] = useState(0);
   const [isLoginDetails, setIsloginDetails] = useState({});
   const [loading, setLoading] = useState(false);
   const location = useLocation()
   const [isLogin, setIsLogin] = useState();
   const loggin = ls.get('isLogin')
//show hide sidebar
	const[showSidebar, setShowSidebar]= useState(false)
	useEffect(()=>{
if (window.innerWidth > 998.91){
	setShowSidebar(true)
}
},[])
useEffect(()=>{
const handleResize =()=>{
		if (window.innerWidth< 998.91){
			setShowSidebar(true)
		} else {
			setShowSidebar(false)
		}
};
window.addEventListener("resize", handleResize);
return()=>{
	window.addEventListener("resize", handleResize)
}
	},[])

   
   // Defining component didMount
   useEffect(()=>{
      setIsLogin(ls.get('isLogin'))
      commonIslogin()
      interceptRouteConfigs();

   },[]);

   const commonIslogin =()=>{
      if(_.get(loggin,'isLoggedIn')===true ) {
         setAppRouteConfigs(routeConfigs)

        if( _.isEqual(_.get(loggin,'authorities[0]'),'ROLE_TEACHERADMIN')){
         navigate('/reports')
//         }else{
// navigate('/teachers')
        }

         // initRoute();
      }else{
         setAppRouteConfigs(publicRouteConfigs) 
      }
       
   }
const initRoute = () => {
   navigate('reports')
   // setAppRouteConfigs(routeConfigs)
}
   useEffect(()=>{
      setIsloginDetails(loggin)
      if(_.get(loggin,'isLoggedIn')===true){
//        if( _.isEqual(_.get(loggin,'authorities[0]'),'ROLE_TEACHERADMIN')){
//          navigate('/reports')
// //         }else{
// // navigate('/teachers')
//         }
        setAppRouteConfigs(routeConfigs)

      }else{
         setAppRouteConfigs(publicRouteConfigs) 

      }
      
     },[_.get(loggin,'isLoggedIn')]);

   useEffect(() => {
      if (_.get(loggin,'isLoggedIn')===true){
         // navigate('teachers')

      }else{
         navigate('login')
      }
   },[_.get(loggin,'isLoggedIn')])
   // handler for appRouteConfigs
   useEffect(()=>{
      setAppRouteConfigsCount(_.add(appRouteConfigsCount, 1));
   },[appRouteConfigs]);

 
    
   const interceptRouteConfigs=async()=>{ 
      setLoading(true)
      let modifyRouteConfig = _.clone(appRouteConfigs);
      await new Promise((resolve)=>{
         _.forEach(modifyRouteConfig, (routeConfig, key)=>{
            if(_.chain(routeConfig).get('dashboardWrapper', false).eq(true).value()){
               const Component=_.chain(routeConfig).clone().get('component').value();
               const header=_.chain(routeConfig).clone().get('header').value();
               routeConfig.element=(props)=>{
                  if(_.isEqual(header,false)) {
                     return (<React.Fragment>  <Component {...props}/></React.Fragment> );
                  }
                  else{
                     return (<React.Fragment> 
                        <PageHeader/> <Component {...props}/>
             <PageFooter/></React.Fragment>);
                  }
                 
               };
               if(_.chain(modifyRouteConfig).size().subtract(1).eq(key).value()){
                  resolve();
               }
            }
         });
         
         
         setAppRouteConfigs(modifyRouteConfig);
      setLoading(false)
      });
      
      
   };

   const render=()=>{
      return (
         <React.Fragment>
            {window.location.pathname !== "/login" ? 
             <div className="d-flex flex-column flex-root header-fixed">
             <div className="page d-flex flex-row flex-column-fluid">
             {window.location.pathname !== "/login" && window.innerWidth > 991.98 ? <Sidebar collapsedWidth={0} trigger={null}/>: <Sidebar/>}
                <div className="wrapper d-flex flex-column flex-row-fluid w-100 page-layout">
                {window.location.pathname !== "/login" ? <PageHeader/>: null}
                   <div className="Page-inner-container">
                   {(
                 _.size(appRouteConfigs)==0|| loading)?
              <div style={{width: '100%',float: 'left',textAlign:'center', height: 'calc(100vh - 206.73px)',display:'flex',alignItems:'center',justifyContent:'center'}}><Spin size='large'/></div>
              :  <div style={{width: '100%',float: 'left'}}>
                <Routes>
                 {/* <Route exact path="/" element={ _.get(props,'islogindata.isLoggedIn')===true?
                  <appPages.homePage /> :<appPages.loginPage /> } /> */}
                {
                     _.map(appRouteConfigs, appRouteConfig=>{
                      const props = {
                        ...appRouteConfig
                       };
                       
                      return (<Route {...props} />)
                     })
                   }
                 </Routes>  
                 </div>
    }
                   </div>
                </div>
             </div>
          </div>
            : 
            (
               _.size(appRouteConfigs)==0|| loading)?
            <div style={{width: '100%',float: 'left',textAlign:'center', height: 'calc(100vh - 206.73px)',display:'flex',alignItems:'center',justifyContent:'center'}}><Spin size='large'/></div>
            :  <div style={{width: '100%',float: 'left'}}>
              <Routes>
               {/* <Route exact path="/" element={ _.get(props,'islogindata.isLoggedIn')===true?
                <appPages.homePage /> :<appPages.loginPage /> } /> */}
              {
                   _.map(appRouteConfigs, appRouteConfig=>{
                    const props = {
                      ...appRouteConfig
                     };
                     
                    return (<Route {...props} />)
                   })
                 }
               </Routes>
                 {/* {_.isEmpty(isLoginDetails)===false &&<Navigate to={RedirectRouteConfig(props,isLoginDetails)}/>} */}
                
               </div>
  }
            
         </React.Fragment>
        
      //    <div className='full-width'>
      //    <div style={{width: '100%',float: 'left'}}>
      //  {window.location.pathname !== "/login" ? <PageHeader/>: null}
      //        <div className="pagecontainer">
      //        {(
      //           _.size(appRouteConfigs)==0|| loading)?
      //        <div style={{width: '100%',float: 'left',textAlign:'center', height: 'calc(100vh - 206.73px)',display:'flex',alignItems:'center',justifyContent:'center'}}><Spin size='large'/></div>
      //        :  <div style={{width: '100%',float: 'left'}}>
      //          <Routes>
      //             {console.log('isLoginDetails',isLoginDetails)}
      //           {/* <Route exact path="/" element={ _.get(props,'islogindata.isLoggedIn')===true?
      //            <appPages.homePage /> :<appPages.loginPage /> } /> */}
      //          {
      //               _.map(appRouteConfigs, appRouteConfig=>{
      //                const props = {
      //                  ...appRouteConfig
      //                 };
                      
      //                return (<Route {...props} />)
      //               })
      //             }
      //           </Routes>
      //             {/* {_.isEmpty(isLoginDetails)===false &&<Navigate to={RedirectRouteConfig(props,isLoginDetails)}/>} */}
                 
      //           </div>
      //           }
      //        </div>

      //        {window.location.pathname !== "/login" ? <PageFooter/>: null}
      //     </div>
      //          </div>
               );
   };
   
   return render();
};
const mapStateToProps = state => {
 return  {
     checkUpdate:_.get(state,'app.account.isLogin.isLoggedIn'),
     islogindata:_.get(state,'app.account.isLogin'),
		account: _.get(state, `app.${global.redux.actionTypes.account.name}`, {}),
   }}

const mapDispatchToProps = dispatch => ({
   isLogin: details => dispatch(global.redux.action.account.isLogin(details)),
   profileData: details => dispatch(global.redux.action.account.profileData(details)),
	logout: details => dispatch(global.redux.action.account.logout(details)),

});
export default connect(mapStateToProps, mapDispatchToProps)(RoutesPage)
