import accountAction from './account';
import teachersAction from './teachers';
import studentsAction from './students';
import adminAction from './admin';
import paymentAction from './payment';


export const reduxAction = {
  account: accountAction,
  teachers: teachersAction,
  students: studentsAction,
  admin:adminAction,
  payment:paymentAction
}
export default reduxAction;