import actionType from '../actionTypes';

import _ from 'lodash';
const reducerName = actionType.reduxUpdateAccountState;
const pathUrl = `/api/admin`

// account actions

export default {
    // get profile data of the current logged in account
    isLogin: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `${pathUrl}/isLoggedIn`
                },
                actionType: actionType.account.isLogin,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    login: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `${pathUrl}/login`
                },
                actionType: actionType.account.login,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    newLogin: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `api/auth/user/otplogin`
                },
                actionType: actionType.account.newLogin,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    profile: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `${pathUrl}/admin/${_.get(componentRequest,'params.credid')}/profileAPI`
                },
                actionType: actionType.account.profile,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
   
    logout: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `${pathUrl}/logout`
                },
                actionType: actionType.account.logout,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
  

   
}
