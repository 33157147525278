
import React, { useState, Fragment, useEffect } from 'react';
import { connect } from "react-redux";
// import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import { Button, Select, Table, Form, Input, InputNumber , Popover,Spin, notification} from 'antd';
import { Modal } from 'antd';
import _ from 'lodash';
import axios from 'axios';
import { useSearchParams, useLocation } from "react-router-dom";
import formatDate from '../../../core_units/utils/dayjs';
import * as SecureLS from "secure-ls";
import PaymentList from './payment-list';
import gmail from '../../../assets/img/Gmail.svg';
import WhatsApp from '../../../assets/img/WhatsApp.png';
import Courselist from './courselist';
import FreeCourse from './freeCourse';
import Refund from './refund';
import AddClass from './addClass';
import appEnvirontVariables from "../../../core_units/config/env-variables";
import { MailOutlined, WhatsAppOutlined, MessageOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const { Option } = Select;
const { confirm } = Modal;
const PaymentLinks = (props) => {
  var ls = new SecureLS({ encodingType: 'aes' });
  let studentId;
  [studentId] = useSearchParams();
  if(props.batchData){
    studentId = {get: (key) => {
      return props.studentId;
    }};
  }
  
  const [form] = Form.useForm();

  const token = ls.get('isLogin')

  const [value, setValue] = useState(false);
  const [paymentlist, setpaymentlist] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFreeCourseModalVisible, setIsFreeCourseModalVisible] = useState(false);
  const [refundModalVisible, setRefundModalVisible] = useState(false);
  const [isAddClassModalVisible, setIsAddClassModalVisible] = useState(false);
  const [isTeacherMapModalVisible, setIsTeacherMapModalVisible] = useState(false);
  const [showTeacherMapError, setShowTeacherMapError] = useState(false);
  const [teacherCoursList, setTeacherCourseList] = useState();
  const [allcourselist, setallcourselist] = useState();
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [externalPaymentlist, setExternalPaymentlist] = useState([]);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [isQrModalVisible, setIsQrModalVisible] = useState(false);
  const [qrCodeSrc, setQrCodeSrc] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // props.createpayment({
    //   params: {
    //     studentId: studentId.get("id")
    //   },
    //   callback: (response, data) => {
    //     if (response == true && !_.isEmpty(data)) {
    //       setcourselist(data)
    //     };
    //   }
    // })
    PaymentDataApiCall();
    getExternalPaymentDetails();
    getallcourselist();
  }, [])
  const showModal = () => {
    setIsQrModalVisible(true);
};

const handleOk = () => {
    setIsQrModalVisible(false);
};

const handleCancel = () => {
    setIsQrModalVisible(false);
    setQrCodeSrc('');
    setLoading(false);
};
  const getSubcriptionCourse = async () => {
    return axios.get(`${appEnvirontVariables.API.BASE_URL}/api/course/subscription/data?studentId=${studentId.get("id")}`,
    { headers: { "Authorization": token['authorization'] } });
  }
  const getExternalPayment = async () => {
    return axios.get(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/external-payment/${studentId.get("id")}`,
    { headers: { "Authorization": token['authorization'] } });
  }
  const createPaymentBtnClick = () => {
    
    setDisabledBtn(true);

    getSubcriptionCourse().then((result) => {
      if(result.data.length){
        setIsModalVisible(true);
      }else{
        setShowTeacherMapError(true);
      }
      setDisabledBtn(false);
    });
   // setIsModalVisible(true);
  }
  const getallcourselist = () => {
    props.couselist({

      callback: (response, data) => {
        if(response==true) {

          setallcourselist(_.get(data, 'courses'))
          console.log('tag data',_.get(data, 'courses'), data );
        }
      },
    });
  }
  const getExternalPaymentDetails = () => {
    getExternalPayment().then((result) => {
      if(result.data?.length){
        setExternalPaymentlist(result.data);
      }
    });
  }
  const PaymentDataApiCall = () => {
    props.paymentlink({
      params: {
        studentId: studentId.get("id")
      },
      callback: (response, data) => {
        if(data.length){
          data = data.filter(e => e.paymentFor == "directpayment").sort((a,b) => b.created_at - a.created_at);
          if (response = true && !_.isEmpty(data)) {
            setpaymentlist(data)
          };
        }
      }
    })
  }
  // const getallcousrelist = () => {
  //   axios.get('https://lmsapi.artiumacademy.com/production/api/courses',
  //     { headers: { "Authorization": _.get(token, 'authorization') } })
  //     .then(response => {
  //       setallcourselist(_.get(response, 'data'))
  //       // setHomePageData(response.data)
  //     });
  // }

  const closeModel = () => {
    PaymentDataApiCall();
    setIsModalVisible(false);
  }
  const closeFreeCourseModel = () => {
    setIsFreeCourseModalVisible(false);
  }
  const closeRefundModel = () => {
    setRefundModalVisible(false);
  }
  const closeAddClassModel = () => {
    setIsAddClassModalVisible(false);
  }

  const triggerMail = (item, messageType) => {
    let obj = paymentlist[item.currentTarget.dataset.key];
    console.log(obj, "obj===");
  
    const msg = {
      emailId: obj.email,
      studentName: obj.name,
      messageType: messageType,
      mobileNumber: obj.countryCode + obj.contact,
      paymentLink: obj.payment_link
    };
  
    confirm({
      title: 'Are you sure you want to share this link?',
      onOk() {
        setLoading(true);
        axios
          .post(`${appEnvirontVariables.API.BASE_URL}/api/users/payment/notification`, msg, {
            headers: {
              "Content-type": "application/json; charset=UTF-8"
            }
          })
          .then(response => {
            notification.success({
              message: "Link shared successfully"
            });
            setLoading(false);
          })
          .catch(error => {
            console.error("Error sending Link:", error);
            notification.error({
              message: "Failed to send Link"
            });
            setLoading(false);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const childFunc = React.useRef(null);
  const oncoursechange = (event) => {
    console.log('event', event);
    teacherlist(event)
  }
  const teacherlist = (courseId) => {
    axios.post(`${appEnvirontVariables.API.BASE_URL}/api/admin/teachers?courseId=${courseId}`,
          { page: 0, size: 500, courseId: courseId },
          { headers: { "Authorization": token['authorization'] } }).then(data => {
            setTeacherCourseList(data?.data?.content);
          });
    return;
    props.listteacherforcourse({
      params: {
        courseId: courseId
      },
      callback: (response, data) => {
        setTeacherCourseList(data.content);
      }
    })
  }
  const onFinish = (values) => { 
    let interestTag = '';
    allcourselist.forEach(e => {
        e.tags.forEach(a => {
            if(a.demoCourseId == values.courseId){
                interestTag = a.id;
            }
        });
    })
    setDisabledBtn(true);
    let req = {
      "teacher":values.teacherId,
      "student":studentId.get("id"),
      "course": values.courseId,
      "enrollmentDate":new Date().getTime(),
      "noOfClasses":"1",
      "status_id":"9",
      "cancelCount":0
    }
    console.log('request-param',req);
    axios.post(`${appEnvirontVariables.API.BASE_URL}/api/course/enrollStudent`, req, {
      headers: {
        "Authorization": token['authorization'], 
        "Authorization": token['authorization'],
        "Content-type": "application/json; charset=UTF-8"
      }
    })
      .then(response => response.text())
      .then(json => {
        setIsTeacherMapModalVisible(false);
        setShowTeacherMapError(false);
        console.log('json====',json);
        setDisabledBtn(false);
      });
      axios.post(`${appEnvirontVariables.API.BASE_URL}/api/users/updateTag/${studentId.get("id")}/${interestTag}`, {}, {
        headers: {
          "Authorization": token['authorization'], 
          "Authorization": token['authorization'],
          "Content-type": "application/json; charset=UTF-8"
        }
      })
        .then(response => response.text())
        .then(json => {
        });
  }
  const onFinishFailed = (values) => { 
    console.log("form-finish-failed",values);
    
  }
  const  createQRCode = (link) => {
    setLoading(true);
    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(link)}`;

    fetch(qrCodeUrl)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.blob();
        })
        .then(blob => {
           const imgSrc = URL.createObjectURL(blob);
                setQrCodeSrc(imgSrc); 
                showModal();
                setLoading(false);
            // const qrCodeDiv = document.getElementById('qrcode');
            // if (qrCodeDiv) {
            //     qrCodeDiv.innerHTML = ''; 
            //     qrCodeDiv.appendChild(img);
            // } else {
            //     console.error('QR code container element not found.');
            // }
        })
        .catch(error => {
          setLoading(false); 
            console.error('There has been a problem with your fetch operation:', error);
        });
  };

 
  
  return (
    <div className='payment-container'>
      {/* {!props.batchData ? <> */}
      <Modal title="Map Teacher" width={930}
        visible={isTeacherMapModalVisible}
        onOk={() => {
          setIsTeacherMapModalVisible(false);
        }}
        onCancel={() => {
          setIsTeacherMapModalVisible(false);
        }}
        footer={null}>
        <div className='teacher-map'>
        <Form
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}

                  >
                
                <Form.Item
                  label="Course"
                  name="courseId"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your course!',
                    },
                  ]}
                >
                  <Select
                    dropdownClassName='course-list-dropdown'
                    onSelect={(value) => oncoursechange(value)}
                  >
                    {_.map(allcourselist, (course, index) => {
                      return _.map(_.get(course, 'tags'), (tag, i) => {
                        return <Option value={_.get(tag, 'demoCourseId')} key={_.get(tag, 'id')}>{_.get(tag, 'parent') + "- " + _.get(tag, 'name')}</Option>
                      })
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Teacher"
                  name="teacherId"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the teacher!',
                    },
                  ]}
                >
                  <Select
                    dropdownClassName='course-list-dropdown'
                  >
                    {_.map(teacherCoursList, (teacher, index) => {
                      return <Option value={_.get(teacher, 'teacherId')} key={index}>{_.get(teacher, 'teacherName')}</Option>
                    })}
                  </Select>

                </Form.Item>
                <Form.Item
                >
                  <Button type="primary" disabled={disabledBtn} htmlType="submit">
                  {disabledBtn ? 'Loading' : 'Submit'}
                  </Button>
                </Form.Item>
                </Form>
        </div>
      </Modal>
      <Modal title="Create Payment" width={1150}
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false);
        }}
        onCancel={() => {
          setIsModalVisible(false);
          if(!props.batchData){
            childFunc.current();
          }          
        }}
        footer={null}>
        <div className='new-payment-form' style={{width: "100%", overflow:"auto"}}>
          {props.batchData ? 
           <Courselist fromNewLink={props.fromNewLink} selectedOnlineGroupCourse={props.selectedOnlineGroupCourse} batchData={props.batchData} childFunc={childFunc} closemodal={closeModel} viewProfile={props.viewProfile} profile={props.profile} /> :
           <Courselist childFunc={childFunc} closemodal={closeModel} viewProfile={_.get(props, 'students.viewprofile')} isOffline={props.isOffline} profile={{ ..._.get(props, 'students.viewprofile'), ...{credId: studentId.get("credid")}, ...{token}}} /> }
          
        </div>
      </Modal>
      { !showTeacherMapError &&
      <Button disabled={disabledBtn} className="float-right mb-2 btn-primary" onClick={() => {
        createPaymentBtnClick();
        // handleGetProfile(); 
      }}> {disabledBtn ? 'Loading' : 'Create Payment link / Record External Payment'}</Button>}
      {isFreeCourseModalVisible ? <Modal title="Free Course" width={1150}
        visible={isFreeCourseModalVisible}
        onOk={() => {
          setIsFreeCourseModalVisible(false);
        }}
        onCancel={() => {
          setIsFreeCourseModalVisible(false);
        }}
        footer={null}>
        <div className='free-course-form' style={{width: "100%", overflow:"auto"}}>
          <div>
             <FreeCourse isFreeCourseModalVisible={isFreeCourseModalVisible} closeModal={closeFreeCourseModel} profile={{ ..._.get(props, 'students.viewprofile'), ...{credId: studentId.get("credid")}, ...{token}}} />
          </div>
        </div>
      </Modal> : ""}
      {/* <Button className="float-right mb-2 mr-2 btn-primary" onClick={() => {
          setIsFreeCourseModalVisible(true);
      }}>Add Free Class</Button> */}
      {refundModalVisible ? <Modal title="Refund Request" width={1150}
        visible={refundModalVisible}
        onOk={() => {
          setRefundModalVisible(false);
        }}
        onCancel={() => {
          setRefundModalVisible(false);
        }}
        footer={null}>
        <div className='free-course-form' style={{width: "100%", overflow:"auto"}}>
          <div>
             <Refund refundModalVisible={refundModalVisible} closeModal={closeRefundModel} profile={{ ..._.get(props, 'students.viewprofile'), ...{credId: studentId.get("credid")}, ...{token}}} />
          </div>
        </div>
      </Modal> : ""}
      {/* <Button className="float-right mb-2 mr-2 btn-primary" onClick={() => {
          setRefundModalVisible(true);
      }}>Refund Request</Button> */}
      {isAddClassModalVisible ? <Modal title="Add Class for External Payment" width={1150}
        visible={isAddClassModalVisible}
        onOk={() => {
          setIsAddClassModalVisible(false);
        }}
        onCancel={() => {
          setIsAddClassModalVisible(false);
        }}
        footer={null}>
        <div className='free-course-form' style={{width: "100%", overflow:"auto"}}>
          <div>
             <AddClass childFunc={childFunc} viewProfile={_.get(props, 'students.viewprofile')} profile={{ ..._.get(props, 'students.viewprofile'), ...{credId: studentId.get("credid")}, ...{token}}} isAddClassModalVisible={isAddClassModalVisible} closeModal={closeAddClassModel}/>
          </div>
        </div>
      </Modal> : ""}
      {/* <Button className="float-right mb-2 mr-2 btn-primary" onClick={() => {
          setIsAddClassModalVisible(true);
      }}>Add Class For External Payment</Button> */}
      { showTeacherMapError && <div style={{'color': 'red', 'float':'right', 'paddingRight':'10px'}}>*Course and Teacher Not Mapped for this Student. <Button onClick={() => {
        setIsTeacherMapModalVisible(true);
      }} className="btn-info mb-2">Map Now</Button></div>} 
      {/* </>:""} */}
      {paymentlist.length ? <div>
      <b style={{fontSize: "20px"}}>Payment link</b>
      <table
        className="table table-bordered table-hover"
        id="tab_logic"
      >
        <thead>
          <tr>
            <th className="text-center">Date Created By</th>
            <th className="text-center"> Course Name </th>
            <th className="text-center"> No Of Classes </th>
            <th className="text-center">Amount</th>
            <th className="text-center"> Discount</th>
            <th className="text-center">Net Amount</th>
            <th className="text-center">Link</th>
            <th className="text-center">Payment Status</th>
            {!props.batchData ? <th className="text-center">Payment Update</th> : ""}
          </tr>
        </thead>
        <tbody>
        {paymentlist.map((item, idx) => (
          <tr>
            <td>
            { formatDate(item.createdAt).toLocaleString()}
            </td>
             {_.size(_.get(item,'courseList'))>0 && item.courseList.map(e => (<div>{e.courseName}</div>))}
            <td>
            {_.size(_.get(item,'courseList'))>0 &&item.courseList.map(e => (<div>{e.classesToPurchase}</div>))}
            </td>
            <td>
            {_.size(_.get(item,'courseList'))>0 &&item.courseList.map(e => (<div>{e.price}</div>))}
            </td>
            <td>
            {_.size(_.get(item,'courseList'))>0 &&item.courseList.map(e => (<div>{e.discountedPrice}</div>))}
            </td>
            <td>
              {item.currency == 'INR'? '₹ ' : '$ '}{item.totalPrice} {item.enrollmentFee ? <b> Inc Enrollment Fee (₹ {item.enrollmentFee})</b>:""}
            </td>
            <td>
               <a href={item.payment_link} target={'_blank'}>{item.payment_link}</a> {item.isOffline && 
               <button onClick={() => createQRCode(item.payment_link)}>
                Show QR</button>}
            </td>
            <td>
              {item.payment_status == "order_created"? 'New': item.payment_status}
            </td>
            {!props.batchData ? <td style={{textAlign: 'center'}}>
            <div className="sociallink">
   
    {/* <div>
              <img onClick={(item) => { triggerMail(item) }} data-key={idx} src={gmail}/>
              </div>
              <div>💬</div>
              <div>
              <img src={WhatsApp}/>
              </div> */}

<Popover
      content={
        <div className="sociallink">
        <div>
              <img onClick={(e) => triggerMail(e, 'email')} data-key={idx} src={gmail} alt="Gmail" />
                                                    </div>
              {/* <div style={{marginLeft:"5px"}} onClick={(e) => triggerMail(e, 'SMS')}>💬</div> */}
              <div>
            <img onClick={(e) => triggerMail(e, 'whatsApp')} data-key={idx} src={WhatsApp} alt="WhatsApp" />
                </div>
        </div>
      }
      trigger="click"
      placement="bottom"
    >
      {item.payment_status !== "payment_success_class_created" ? (

    
      <Button type="primary">Share Link</Button>
      ) : (
      <div>-</div>
      )
}
    </Popover>

            </div>
            </td> :""}
          </tr>
          ))}
        </tbody>
      </table></div> : ""}
        <Modal title="QR Code" visible={isQrModalVisible}  onCancel={handleCancel} footer={null}>
        {loading ? (
                    <div style={{ textAlign: 'center', display:"flex", justifyContent:"center" }}>
                        <Spin />
                    </div>
                ) : (
                    qrCodeSrc && <img style={{ display: 'block', margin: '0 auto' }} src={qrCodeSrc} alt="QR Code" />
                )}
            </Modal>
      {externalPaymentlist && externalPaymentlist.filter(e => !e.isFreeClass).length ? <div>
      <b style={{fontSize: "20px"}}>External Payment</b>
      <table
        className="table table-bordered table-hover"
        id="tab_logic"
      >
        <thead>
          <tr>
            <th className="text-center">Date Created By</th>
            <th className="text-center"> Course Name </th>
            <th className="text-center"> No Of Classes </th>
            <th className="text-center">Amount</th>
            <th className="text-center"> Discount</th>
            <th className="text-center">Net Amount</th>
            <th className="text-center">Payment Mode</th>
            <th className="text-center">Created By</th>
          </tr>
        </thead>
        <tbody>
        {externalPaymentlist.filter(e => !e.isFreeClass).map((item, idx) => (
          <tr>
            <td>
            { (formatDate(item.createdAt).toLocaleString())}
            </td>
             {_.size(_.get(item,'courseList'))>0 && item.courseList.map(e => (<div>{e.courseName}</div>))}
            <td>
            {_.size(_.get(item,'courseList'))>0 &&item.courseList.map(e => (<div>{e.classesToPurchase}</div>))}
            </td>
            <td>
            {_.size(_.get(item,'courseList'))>0 &&item.courseList.map(e => (<div>{e.price}</div>))}
            </td>
            <td>
            {_.size(_.get(item,'courseList'))>0 &&item.courseList.map(e => (<div>{e.discountedPrice}</div>))}
            </td>
            <td>
              {item.currency == 'INR'? '₹ ' : '$ '}{item.totalPrice} {item.enrollmentFee ? <b> Inc Enrollment Fee (₹ {item.enrollmentFee})</b>:""}
            </td>
            <td>
              {item.paymentMode || ""}
            </td>
            <td>
               {item.createdBy}
            </td>
          </tr>
          ))}
        </tbody>
      </table></div> : ""}
      {externalPaymentlist && externalPaymentlist.filter(e => e.isFreeClass).length ? <div>
      <b style={{fontSize: "20px"}}>Free Classes</b>
      <table
        className="table table-bordered table-hover"
        id="tab_logic"
      >
        <thead>
          <tr>
            <th className="text-center">Date Created By</th>
            <th className="text-center"> Course Name </th>
            <th className="text-center"> No Of Classes </th>
            <th className="text-center">Amount</th>
            <th className="text-center"> Discount</th>
            <th className="text-center">Net Amount</th>
            <th className="text-center">Created By</th>
          </tr>
        </thead>
        <tbody>
        {externalPaymentlist.filter(e => e.isFreeClass).map((item, idx) => (
          <tr>
            <td>
            {formatDate(item.createdAt).toLocaleString()}
            </td>
             {_.size(_.get(item,'courseList'))>0 && item.courseList.map(e => (<div>{e.courseName}</div>))}
            <td>
            {_.size(_.get(item,'courseList'))>0 &&item.courseList.map(e => (<div>{e.classesToPurchase}</div>))}
            </td>
            <td>
            {_.size(_.get(item,'courseList'))>0 &&item.courseList.map(e => (<div>{e.price}</div>))}
            </td>
            <td>
            {_.size(_.get(item,'courseList'))>0 &&item.courseList.map(e => (<div>{e.discountedPrice}</div>))}
            </td>
            <td>
              {item.currency == 'INR'? '₹ ' : '$ '}{item.totalPrice}{item.enrollmentFee ? <b> Inc Enrollment Fee (₹ {item.enrollmentFee})</b>:""}
            </td>
            <td>
               {item.createdBy}
            </td>
          </tr>
          ))}
        </tbody>
      </table></div> : ""}
    </div>





  )

};
const mapStateToProps = state => (
  {
    payment: _.get(state, `app.${global.redux.actionTypes.payment.name}`, {}),
    students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),


  });

const mapDispatchToProps = dispatch => ({
  createpayment: details => dispatch(global.redux.action.payment.createpayment(details)),
  paymentlink: details => dispatch(global.redux.action.payment.paymentlink(details)),
  directpayment: details => dispatch(global.redux.action.payment.directpayment(details)),
  listteacherforcourse: (details) =>
    dispatch(global.redux.action.teachers.listteacherforcourse(details)),
  couselist: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PaymentLinks);