import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { Upload, Button, Form, Input, DatePicker, TimePicker, notification } from 'antd';
import { useSearchParams, useLocation } from "react-router-dom";
import { UploadOutlined } from '@ant-design/icons';
const ExcelAudience = (props) => {
  const [teacherId] = useSearchParams();
  const [form] = Form.useForm();
  const [file, setFile] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(props.excelview);
  const [selectedFileList, setselectedFileList] = useState([]);
  const [selectedFile, setselectedFile] = useState();

  useEffect(() => {
    setIsModalVisible(_.get(props, 'excelview'))
  }, [_.get(props, 'excelview')])

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {

  };

  const onFileChange = info => {
    setFile(info.target.files[0]);
    props.setfile(info.target.files[0])
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const render = () => {
    if (_.isEqual(_.get(props, 'excelview'), true)) {
      return (
        <div className='row'>
          <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <Form.Item
              label="Upload"
              name="format"
              rules={[
                {
                  required: false,
                  message: 'Please input your email!',
                },
              ]}
            >
              <form>
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  accept='.csv'
                  onChange={onFileChange}
                />
              </form>
              <a href={require("../../assets/format/email-format.csv")} target="_blank" download="sample-excel-format" className='mt-2 float-left'>sampleformat</a>
            </Form.Item>
          </div>
        </div>
      )
    }
    else {
      return null
    }
  }
  return render()
}
export default ExcelAudience;