import React, { useState, useEffect, useCallback } from "react";
import { Empty, Button } from "antd";
import { Input } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { Scrollbars } from "react-custom-scrollbars";
import boy1 from "../../../assets/img/default-image.png";
import "./students.css";
import logo from "../../../assets/gif/aa_loader.gif";
import { Form, Select, Radio, notification } from "antd";
import * as SecureLS from "secure-ls";
import axios from "axios";
import appEnvirontVariables from "../../../core_units/config/env-variables"

const { Search } = Input;
const { Option } = Select;
const StudentPage = (props) => {
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const [studentslist, setStudentsList] = useState([]);
  const [form] = Form.useForm();
  const [pagenum, setPage] = useState();
  const [studentId, setStudentId] = useState();
  const [pageloading, setpageloading] = useState();
  const [alpha, setalpha] = useState("All");
  const [queryvalue, setqueryvalue] = useState();
  const [appConfigData, setAppConfig] = useState();
  const [agegroup, setAgeGroup] = useState();
  const [studentgender, setStudentGender] = useState();
  const [usertype, setUserType] = useState();
  const [searchTxt, setSearchTxt] = useState();
  const [centerList, setCenterList] = useState();
  const [selectedBranch, setSelectedBranch] = useState();

  useEffect(() => {
    configjson();
    setpageloading(true);
    if (!(props.isOffline == "true")) {
      apicall();
    }
  }, []);
  useEffect(() => {
    if(selectedBranch){
      apicall();
    }
  }, [selectedBranch]);

  const getCenterList = (uId) => {
    return axios.get(`${appEnvirontVariables.API.BASE_URL}/api/admin/counsellor/centre?uId=${uId}`,
      { headers: { "Authorization": token["authorization"] } });
  }

  useEffect(() => {
    if (_.isEqual(typeof _.get(props, "students.list.number"), "number")) {
      setPage(_.get(props, "students.list.number"));
    }
  }, [_.get(props, "students.list.number")]);

  const configjson = () => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          setAppConfig(data);
          getCenterList(token.uId).then(result => {
            if (result.data && result.data.length) {
              setSelectedBranch(result.data[0].centreId)
              setCenterList(result.data);
            }else{
              setSelectedBranch(data.offlineCentres[0].centerId)
              setCenterList(data.offlineCentres);
            }
          })
        }
      },
    });
  };

  // Initial api call
  const apicall = async (query = {}, page = 0, reset = false, centreId = null) => {
    if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(searchTxt)) {
      query.emailId = searchTxt?.trim();
    } else if (new RegExp(/^[0-9\b]+$/).test(searchTxt)) {
      query.mobileNumber = searchTxt?.trim();
    } else {
      query.name = searchTxt?.trim();
    }

    if (props.isOffline == "true") {
      query["typeOfStudent"] = "offline";
    }

    if (props.isOffline == "true") {
      query["offlineCenterId"] = selectedBranch;
    }

    if (agegroup) {
      query["studentAgeGroup"] = agegroup;
    }
    if (studentgender) {
      query["studentGender"] = studentgender;
    }
    if (usertype) {
      query["userType"] = usertype;
    }
    if (reset == true) {
      query = {}
      query.name = '';
    }
    setqueryvalue(query);
    await props.list({
      body: {
        page: page,
        size: 20,
        ...query,
      },

      callback: (response, data) => {
        setpageloading(false);
        if (_.isEqual(response, true)) {
          let arr = [];
          if (_.isEmpty(query) && !_.isEmpty(_.get(data, "content"))) {
            arr = _.uniqBy(
              [...studentslist, ..._.get(data, "content")],
              function (e) {
                return e.studentId;
              }
            );
          } else if (!_.isEmpty(query)) {
            if (
              _.size(studentslist) < _.get(data, "totalElements") &&
              _.isEqual(queryvalue, query)
            ) {
              arr = _.uniqBy(
                [...studentslist, ..._.get(data, "content")],
                function (e) {
                  return e.studentId;
                }
              );
            } else {
              arr = _.get(data, "content");
            }
          }
          setStudentsList(arr);
          console.log(arr, "arr")
        }
      },
    });
  };

  // Scroll load more func
  // const handleNavigation = useCallback((e) => {
  //   const window = e.target;
  //   if (
  //     window.scrollingElement.scrollHeight -
  //       window.scrollingElement.scrollTop ===
  //     window.scrollingElement.clientHeight
  //   ) {
  //     const page = pagenum + 1;
  //     //  !_.isEmpty(_.get(props, 'students.list.pageable.pageNumber')) ? `${_.get(props, 'students.list.pageable.pageNumber') + 1}` : pagenum + 1
  //     if (
  //       page <= _.get(props, "students.list.totalPages") &&
  //       _.size(studentslist) < _.get(props, "students.list.totalElements")
  //     ) {
  //       if (!_.isEqual(alpha, "All")) {
  //         apicall(alpha, page);
  //       } else {
  //         apicall({}, page);
  //       }
  //     }
  //   }
  // });

  // Search filter
  const onSearch = (value) => {
    let query = {};
    if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value)) {
      query = {
        emailId: value,
      };
    } else if (new RegExp(/^[0-9\b]+$/).test(value)) {
      query = {
        mobileNumber: value,
      };
    } else {
      query = {
        name: value,
      };
    }


    if (usertype) {
      query["userType"] = usertype;
    }
    apicall(query);

    // if (_.isEmpty(value)) {
    //   apicall(query);
    // }
  };

  // const onReset = () => {
  //   setAgeGroup('');
  //   setStudentGender('');
  //   setUserType('');
  //   document.getElementById('ageSelect').value = '';
  //   document.getElementById('genderSelect').value = '';
  //   document.getElementById('typeSelect').value = '';
  //   document.getElementsByClassName('ant-input-clear-icon')[0].click();
  //   apicall({},0,true);

  //   //document.getElementById("create-course-form").reset();
  // };

  const handleScroll = () => {
    const page = pagenum + 1;
    if (
      page < _.get(props, "students.list.totalPages") &&
      _.size(studentslist) < _.get(props, "students.list.totalElements")
    ) {
      if (!_.isEmpty(alpha) && !_.isEqual(alpha, "All")) {
        apicall({ name: alpha }, page);
      } else {
        apicall({}, page);
      }
    }
  };

  const menuToggle = () => {
    document.body.classList.toggle("menu-close");
  };

  const onAlphabetClick = (value) => {
    let query = {};
    if (_.isEmpty(value)) {
      apicall(query);
    } else {
      query = {
        name: value,
      };
      apicall(query);
    }
  };

  const prepareAlphabets = () => {
    let result = [
      <div
        key={1}
        className={`sort-btn ${alpha === "All" ? "active" : ""} `}
        onClick={(value) => {
          setalpha("All");
          onAlphabetClick();
        }}
        value={"All"}
      >
        {"All"}
      </div>,
    ];
    for (let i = 65; i < 91; i++) {
      result.push(
        <div
          key={i}
          className={`sort-btn ${alpha === String.fromCharCode(i) && "active"
            }  `}
          onClick={() => {
            setalpha(String.fromCharCode(i));
            onAlphabetClick(String.fromCharCode(i));
          }}
          value={String.fromCharCode(i)}
        >
          {String.fromCharCode(i)}
        </div>
      );
    }
    return result;
  };
  const onSearchChange = (e) => {
    setSearchTxt(e.target.value);
  }
  const render = () => {
    if (_.isEqual(pageloading, false)) {
      return (
        <Scrollbars
          className=" hidden-scroll-x"
          style={{ height: "calc(100vh - 125px)" }}
          onScrollFrame={handleScroll}
          renderTrackHorizontal={(props) => (
            <div
              {...props}
              className="track-horizontal"
              style={{ display: "none" }}
            />
          )}
          renderThumbHorizontal={(props) => (
            <div
              {...props}
              className="thumb-horizontal"
              style={{ display: "none" }}
            />
          )}
        >
          <div className="DashboardScreen-container">

            <div className="row">
              <div className="row">
                {!(props.isOffline == "true") && <div className="col">

                  <Button
                    type="default"
                    htmlType="reset"
                    className="addteachbtn"
                    id="kt_account_profile_details_submit"
                  ><Link to={`/add-student`}>
                      Add Student</Link>
                  </Button>

                </div>}
              </div>

              <div className="row" style={{ 'paddingBottom': '20px' }}>
                {/* <div className="col-sm-1">Filter:</div> */}
                {/* <div className="col-sm-2">
                    <select
                      className="form-control cursor"
                      style={{
                        appearance: "auto",
                      }}
                      id="ageSelect"
                      dropdownClassName=""
                      placeholder=""
                      onChange={(e) => setAgeGroup(e.target.value)}
                    >
                      <option value="">Select Age</option>

                      {_.map(
                        _.get(appConfigData, "ageGroups"),
                        (agegroups, each) => {
                          return (
                            <option
                              value={_.get(agegroups, "name") + ""}
                              key={each}
                            >
                              {_.get(agegroups, "name")}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div className="col-sm-2">
                    <select
                      className="form-control cursor"
                      style={{
                        appearance: "auto",
                      }}
                      id="genderSelect"
                      dropdownClassName=""
                      placeholder=""
                      onChange={(e) => setStudentGender(e.target.value)}
                    >
                      <option value="">Select Gender</option>

                      {_.map(
                        _.get(appConfigData, "genders"),
                        (genders, each) => {
                          return (
                            <option
                              value={_.get(genders, "name") + ""}
                              key={each}
                            >
                              {_.get(genders, "name")}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div> */}
                <div className="col-sm-2">
                  <select
                    className="form-control cursor"
                    style={{
                      appearance: "auto",
                    }}
                    dropdownClassName=""
                    placeholder=""
                    id="typeSelect"
                    onChange={(e) => setUserType(e.target.value)}
                  >
                    <option value="">Select Usertype</option>
                    <option value="DEMO">DEMO</option>
                    <option value="PAID">PAID</option>
                  </select>
                </div>
                <div className="col-sm-4">
                  <Search
                    placeholder="Search by name/mobile number"
                    enterButton="Search"
                    onSearch={onSearch}
                    onChange={onSearchChange}
                    allowClear
                    style={{}}
                  />
                </div>
                {props.isOffline == "true" ? <div className="col-sm-4">
                  <label htmlFor="branchname" style={{ fontWeight: 800 }}>
                    Branch Name:
                  </label>
                  <select
                    id="branchname"
                    value={selectedBranch}
                    onChange={(e) => setSelectedBranch(e.target.value)}
                    style={{ marginLeft: "15px", width: "140px", cursor: "pointer" }}
                  >
                    {centerList?.map((centre, index) => (
                      <option value={centre.centreId || centre.centerId} key={index}>
                        {centre.centreName}
                      </option>
                    ))}
                  </select>
                </div>: ""}
                {/* <div className="col-sm-1">
                    <Button
                      type="default"
                      htmlType="reset"
                      className="resetbtn cursor"
                      onClick={onReset}
                    >
                      Reset
                    </Button>
                  </div> */}
              </div>
            </div>
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {prepareAlphabets()}
            </div> */}
            {_.size(studentslist) > 0 ? (
              <div className="Dashboard-card-screen">
                <div className="row">
                  {_.size(studentslist) > 0 &&
                    _.map(studentslist, (student, index) => {
                      return (
                        <div
                          className="col-12 col-lg-4"
                          key={index}
                          onClick={menuToggle}
                        >
                          <Link
                            to={{
                              pathname: `/student-view`,
                              search: `?id=${_.get(
                                student,
                                "studentId"
                              )}&&credid=${_.get(student, "credentialId")}`
                            }}
                            state={props.isOffline == "true" ? { ...student, ...{ isOffline: true } } : student}
                          >
                            <div className="card card-xl-stretch mb-xl-8">
                              <div className="card-body d-flex align-items-center gggg">
                                <img
                                  id="profilePhoto"
                                  src={
                                    !_.isEmpty(_.get(student, "profilePhoto"))
                                      ? _.get(student, "profilePhoto", boy1)
                                      : boy1
                                  }
                                  alt="user"
                                  className="align-self-end h-100px w-100px teacher-list-img fillImage"
                                />

                                <div
                                  className="d-flex flex-column flex-grow-1 text-truncate"
                                  style={{ paddingLeft: "15px" }}
                                >
                                  <a
                                    href=""
                                    className="fw-bolder text-dark fs-4 mb-2 text-hover-primary text-truncate"
                                    id="name"
                                  >
                                    {_.get(student, "studentName")
                                      ? _.startCase(
                                        _.get(
                                          student,
                                          "studentName",
                                          "No-Name"
                                        )
                                      )
                                      : "No-Name"}
                                  </a>
                                  <span
                                    className="fw-bold text-muted fs-5 teacher-email"
                                    id="coursename"
                                  >
                                    {_.get(student, "courseName", "-")}
                                  </span>
                                  <span
                                    className="fw-bold text-muted fs-5 teacher-email"
                                    id="emailid"
                                  >
                                    {_.get(student, "emailId", "-")}
                                  </span>
                                  <span
                                    className="fw-bold text-muted fs-5 teacher-email"
                                    id="mobilenumber"
                                  >
                                    {_.get(student, "mobileNumber") &&
                                      _.get(student, "countryCode", "")}{" "}
                                    {_.get(student, "mobileNumber", "-")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : (
              <Empty />
            )}
          </div>
        </Scrollbars>
      );
    } else {
      return (
        <div className="loadingclass">
          <img src={logo} alt="loading..." />
        </div>
      );
    }
  };
  return render();
};

const mapStateToProps = (state) => ({
  students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  list: (details) => dispatch(global.redux.action.students.list(details)),

  courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentPage);