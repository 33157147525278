import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import logo from '../../assets/img/logo-1-dark.svg';
import "./sidebar.css"
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import * as routeName from "../../core_units/routes/routes-name";
import * as SecureLS from "secure-ls";
import { connect } from "react-redux";
import { notification } from 'antd';

const Sidebar = (props) => {
    let history = useNavigate();
    const { SubMenu } = Menu;
    const [key, setKey] = useState(['1']);
    const [courseList, setCourseList] = useState();
    const ls = new SecureLS({ encodingType: 'aes' });
    const logindetails = ls.get('isLogin');

    useEffect(() => {
        configjson();
    }, []);

    const configjson = () => {
        props.courseList({
            callback: (res, data) => {
                if (_.isEqual(res, true)) {
                    console.log("courselist", data);
                    setCourseList(data);
                }
            },
        });
    };

    const handleClick = e => {
        // menuDefaultvalue();
        setKey([_.get(e, 'key')])
    };

    useEffect(() => {
        menuDefaultvalue();
    }, [key]);

    const menuDefaultvalue = async () => {
        switch (props.location && props.location.pathname) {
            case '/teachers':
                await setKey(['1']);
                break;
            case '/students':
                await setKey(['2']);
                break;
            case '/reports':
                await setKey(['3']);
                break;
            case '/Restart':
                await setKey(['18']);
                break;
            default:
                break;
        }
    };

    const onchangeRoute = (route) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        history(route);
    };
    
    
    const menuToggle = () => {
        document.body.classList.toggle('menu-close');
    };

   
  
const renderMenuItems = () => {
    const userRole = _.get(logindetails, 'authorities[0]');
    console.log(userRole,"userRole")
    if (!userRole || !courseList || !courseList.AdminRoles) return null;

    const roleData = courseList.AdminRoles.find(role => role.Role === userRole);
    if (!roleData || !roleData.Permissions) return null;
    console.log(roleData);
    const aeReportsPermissions = roleData.Permissions['AE Reports'];
    console.log(aeReportsPermissions, "aeReportsPermissions");
    // const allAeReportsFalse = Object.values(aeReportsPermissions).every(permission => permission === 'false');

    const reportsPermissions = roleData.Permissions['Reports'];
    console.log(reportsPermissions, "reportsPermissions");
    // const allReportsPermissionsFalse = Object.values(reportsPermissions).every(permission => permission === 'false');

    const weeklyReportsPermissions = roleData.Permissions['Teacher WeeklyReports'];
    console.log(weeklyReportsPermissions, "weeklyReportsPermissions");
    // const allWeeklyReportsPermissionsFalse = Object.values(weeklyReportsPermissions).every(permission => permission === 'false');

    const batchPermissions = roleData.Permissions['Batch Management'];
    console.log(batchPermissions, "batchPermissions");
    // const allBatchPermissionsFalse = Object.values(batchPermissions).every(permission => permission === 'false');

    return (
        <>
            {/* {roleData.Permissions['Teachers'] === 'true' && <Menu.Item key="1" 
            onClick={() => onchangeRoute('/teachers')}>
                Teachers
                </Menu.Item>
                } */}
<Menu.Item key="1" onClick={() => {
    if (!roleData.Permissions['Teachers'] || roleData.Permissions['Teachers'] === 'false') {
        notification.error({
            message: 'Permission Denied',
            description: 'You do not have permission to access this page.',
        });
    } else {
        onchangeRoute('/teachers');
    }
}}>Teachers</Menu.Item>

<Menu.Item key="2" onClick={() => {
    if (!roleData.Permissions['Students'] || roleData.Permissions['Students'] === 'false') {
        notification.error({
            message: 'Permission Denied',
            description: 'You do not have permission to access this page.',
        });
    } else {
        onchangeRoute('/students');
    }
}}>Students</Menu.Item>
<SubMenu key="3" title="Teacher Operations">
<Menu.Item key="4" 
 
 onClick={() => {
    if (!reportsPermissions['Teacher Compensation'] || reportsPermissions['Teacher Compensation'] === 'false') {
        notification.error({
            message: 'Permission Denied',
            description: 'You do not have permission to access this page.',
        });
    } else {
        onchangeRoute('/teacher-compensation');
    }}}
 
 
 >Teacher Compensation</Menu.Item>
 <Menu.Item key="5" 
 
 onClick={() => {
    if (!reportsPermissions['Teacher Capacity Forecast'] || reportsPermissions['Teacher Capacity Forecast'] === 'false') {
        notification.error({
            message: 'Permission Denied',
            description: 'You do not have permission to access this page.',
        });
    } else {
        onchangeRoute('/TeacherForecast');
    }}}
 
 
 >Teacher Capacity Forecast</Menu.Item>
  <Menu.Item key="6" 
            // onClick={() => {
            //     if (!roleData.Permissions['AHA Moments'] || roleData.Permissions['AHA Moments'] === 'false') {
            //         notification.error({
            //             message: 'Permission Denied',
            //             description: 'You do not have permission to access this page.',
            //         });
            //     } else {
            //         onchangeRoute('/AahaMoment');
            //     }
            // }}
            onClick = {() => {
                onchangeRoute('/TeacherLeave');
            }}
            >Teacher Leave Requests</Menu.Item>
              <Menu.Item key="7" 
              onClick={() => {
                if (!weeklyReportsPermissions['Weekly Engagement'] || weeklyReportsPermissions['Weekly Engagement'] === 'false') {
                    notification.error({
                        message: 'Permission Denied',
                        description: 'You do not have permission to access this page.',
                    });
                } else {
                    onchangeRoute('/weeklyReport');
                }
            }}
              >Weekly Engagement</Menu.Item>

<Menu.Item key="8" 
 
 onClick={() => {
  
        onchangeRoute('/PayoutPolicy');
    }}
 
 
 >Teacher Reports</Menu.Item>
</SubMenu>
<SubMenu key="8" title="Operations">
<Menu.Item key="9" 
 onClick={() => {
    if (!reportsPermissions['Class Report'] || reportsPermissions['Class Report'] === 'false') {
        notification.error({
            message: 'Permission Denied',
            description: 'You do not have permission to access this page.',
        });
    } else {
        onchangeRoute('/reports');
    }}}
 
 
 >Class Report</Menu.Item>
 <Menu.Item key="10" 
 
 onClick={() => {
    if (!reportsPermissions['Addon Report'] || reportsPermissions['Addon Report'] === 'false') {
        notification.error({
            message: 'Permission Denied',
            description: 'You do not have permission to access this page.',
        });
    } else {
        onchangeRoute('/addon-reports');
    }}}
 
 >Addon Report</Menu.Item>
 <Menu.Item key="11" 
 onClick={() => {
    if (!reportsPermissions['Paid Slot Details'] || reportsPermissions['Paid Slot Details'] === 'false') {
        notification.error({
            message: 'Permission Denied',
            description: 'You do not have permission to access this page.',
        });
    } else {
        onchangeRoute('/PaidSlots');
    }}}
 
 
 
 >Paid Slot Details</Menu.Item>
 <Menu.Item key="12" 
            onClick={() => {
                if (!roleData.Permissions['Certificates'] || roleData.Permissions['Certificates'] === 'false') {
                    notification.error({
                        message: 'Permission Denied',
                        description: 'You do not have permission to access this page.',
                    });
                } else {
                    onchangeRoute('/Certificate');
                }
            }}
            >Certificates</Menu.Item>
            <Menu.Item key="13" 
             onClick = {() => {
                onchangeRoute('/TeacherQa');
            }}
            >QA Policy</Menu.Item>
</SubMenu>

<SubMenu key="14" title="AE Reports">
<Menu.Item key="15" 
            onClick={() => {
                if (!aeReportsPermissions['Airline Slot'] || aeReportsPermissions['Airline Slot'] === 'false') {
                    notification.error({
                        message: 'Permission Denied',
                        description: 'You do not have permission to access this page.',
                    });
                } else {
                    onchangeRoute('/teacher-slot-search');
                }
            }}
            >Airline Slot</Menu.Item>
           
            <Menu.Item key="16" 
            onClick={() => {
                if (!aeReportsPermissions['Demo Status'] || aeReportsPermissions['Demo Status'] === 'false') {
                    notification.error({
                        message: 'Permission Denied',
                        description: 'You do not have permission to access this page.',
                    });
                } else {
                    onchangeRoute('/Status');
                }
            }}
            >Demo Status</Menu.Item>
           
            <Menu.Item key="17" 
            onClick={() => {
                if (!aeReportsPermissions['Category Wise AE slots'] || aeReportsPermissions['Category Wise AE slots'] === 'false') {
                    notification.error({
                        message: 'Permission Denied',
                        description: 'You do not have permission to access this page.',
                    });
                } else {
                    onchangeRoute('/CategoryWiseAeSlots');
                }
            }}
            >Category Wise AE slots</Menu.Item>
           
            <Menu.Item key="18" 
            onClick={() => {
                if (!aeReportsPermissions['Category Wise AE slots'] || aeReportsPermissions['Category Wise AE slots'] === 'false') {
                    notification.error({
                        message: 'Permission Denied',
                        description: 'You do not have permission to access this page.',
                    });
                } else {
                    onchangeRoute('/AeConversion');
                }
            }}
            >AE Conversion</Menu.Item>
            </SubMenu>

            <SubMenu key="19" title="Finance">
            <Menu.Item key="20" 
            
            onClick={() => {
                if (!roleData.Permissions['Referrals'] || roleData.Permissions['Referrals'] === 'false') {
                    notification.error({
                        message: 'Permission Denied',
                        description: 'You do not have permission to access this page.',
                    });
                } else {
                    onchangeRoute('/Referral');
                }
            }}
            
            >Referrals</Menu.Item>
          
            <Menu.Item key="21" 
            
            onClick={() => {
                if (!roleData.Permissions['Refunds'] || roleData.Permissions['Refunds'] === 'false') {
                    notification.error({
                        message: 'Permission Denied',
                        description: 'You do not have permission to access this page.',
                    });
                } else {
                    onchangeRoute('/Refund');
                }
            }}
            >Refunds</Menu.Item>
            </SubMenu>

            <SubMenu key="22" title="Online Group Management">

<Menu.Item key="23" 
onClick={() => {
   if (!batchPermissions['Online'] || batchPermissions['Online'] === 'false') {
       notification.error({
           message: 'Permission Denied',
           description: 'You do not have permission to access this page.',
       });
   } else {
       onchangeRoute('/BatchCreation');
   }
}}

>Create Group</Menu.Item>


</SubMenu>
<SubMenu key="40" title="Offline">
<Menu.Item key="24" 
 onClick={() => {
   if (!batchPermissions['Offline'] || batchPermissions['Offline'] === 'false') {
       notification.error({
           message: 'Permission Denied',
           description: 'You do not have permission to access this page.',
       });
   } else {
       onchangeRoute('/BatchCreationOffline');
   }
}}
 
 >Create Group</Menu.Item>
 {/* <Menu.Item key="41" 
 onClick={() => {
    if (!batchPermissions['Offline'] || batchPermissions['Offline'] === 'false') {
        notification.error({
            message: 'Permission Denied',
            description: 'You do not have permission to access this page.',
        });
    } else {
        onchangeRoute('/RegForm');
    }
 }}

 
 >Register Student</Menu.Item> */}
  <Menu.Item key="42" 
 onClick={() => {
    if (!batchPermissions['Offline'] || batchPermissions['Offline'] === 'false') {
        notification.error({
            message: 'Permission Denied',
            description: 'You do not have permission to access this page.',
        });
    } else {

        onchangeRoute('/offlineStudents');

    }
 }}

 
 >Students</Menu.Item>
</SubMenu>
{/* <SubMenu key="43" title="School">
<Menu.Item key="44" 
 onClick={() => {
   if (!batchPermissions['Offline'] || batchPermissions['Offline'] === 'false') {
       notification.error({
           message: 'Permission Denied',
           description: 'You do not have permission to access this page.',
       });
   } else {
       onchangeRoute('/BatchCreationOffline');
   }
}}
 
 >Create Group</Menu.Item>
  <Menu.Item key="46"
 onClick={() => {
    if (!batchPermissions['Offline'] || batchPermissions['Offline'] === 'false') {
        notification.error({
            message: 'Permission Denied',
            description: 'You do not have permission to access this page.',
        });
    } else {

        onchangeRoute('/offlineStudents');

    }
 }}

 
 >Students</Menu.Item>
</SubMenu> */}

<SubMenu key="25" title="Marketing">
<Menu.Item key="26" 
            onClick={() => {
                if (!roleData.Permissions['AHA Moments'] || roleData.Permissions['AHA Moments'] === 'false') {
                    notification.error({
                        message: 'Permission Denied',
                        description: 'You do not have permission to access this page.',
                    });
                } else {
                    onchangeRoute('/AahaMoment');
                }
            }}
            
            >AHA Moment</Menu.Item>
          
           
</SubMenu>
<SubMenu key="50" title="Academics">
            <Menu.Item key="27"
                onClick={() => {
                if (!roleData.Permissions['Assignment'] || roleData.Permissions['Assignment'] === 'false') {
                    notification.error({
                        message: 'Permission Denied',
                        description: 'You do not have permission to access this page.',
                    });
                } else {
                    onchangeRoute('/AssignList');
                }
            }}
            > Artium Library  </Menu.Item>
            </SubMenu>
<Menu.Item key="28" 
 
 onClick={() => {
    if (!roleData.Permissions['Webinar'] || roleData.Permissions['Webinar'] === 'false') {
        notification.error({
            message: 'Permission Denied',
            description: 'You do not have permission to access this page.',
        });
    } else {
        onchangeRoute('/Webinar-masterclass');
    }
}}
 >Webinar</Menu.Item>
           

            {/* <Menu.Item key="29" 
             onClick = {() => {
                onchangeRoute('/FindTeacherStudent');
            }}
            >Find Teacher for Student</Menu.Item> */}
          
          <Menu.Item key="60" 
           onClick={() => {
            if (!roleData.Permissions['Demo Operations'] || roleData.Permissions['Demo Operations'] === 'false') {
                notification.error({
                    message: 'Permission Denied',
                    description: 'You do not have permission to access this page.',
                });
            } else {
                onchangeRoute('/DemoOps');
            }
        }}
            //  onClick = {() => {
            //     onchangeRoute('/DemoOps');
            // }}
            >Demo Operation</Menu.Item>

            {/* <Menu.Item key="30" onClick={() => {
    if (!roleData.Permissions['Emails'] || roleData.Permissions['Emails'] === 'false') {
        notification.error({
            message: 'Permission Denied',
            description: 'You do not have permission to access this page.',
        });
    } else {
        onchangeRoute('/emails');
    }
}}>Emails</Menu.Item> */}
  {/* <Menu.Item key="30" 
             onClick = {() => {
                onchangeRoute('/QaReports');
            }}
            >QA Reports</Menu.Item> */}

        </>
    );
};


    return (
        <div id="kt_aside" className="aside aside-dark aside-hoverable Sidebar-Menu-Container" style={{ height: '100vh' }}>
            <div className="aside-logo flex-column-auto" id="kt_aside_logo">
                <a href="../../demo1/dist/index.html">
                    <img alt="Logo" src={logo} className="h-25px logo" />
                </a>
                <span className="SideMenuIcon" onClick={menuToggle}>
                    <MenuOutlined />
                </span>
            </div>
            <div className="aside-menu flex-column-fluid hover-scroll-overlay-y">
                <Menu
                    style={{ width: 256 }}
                    defaultSelectedKeys={setKey}
                    mode="inline"
                    onClick={handleClick}
                >
                    {renderMenuItems()}
                </Menu>
            </div>
            <div className="text-dark order-2 order-md-1">
                <span className="text-muted fw-bold me-1">2022©</span>
                <a href="" target="_blank" className="text-gray-800 text-hover-primary ml-1">Artium academy</a>
                <span className="text-gray-800  ml-1" style={{ fontSize: '10px' }}>V1.0.6 </span>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    account: _.get(state, `app.${global.redux.actionTypes.account.name}`, {}),
});

const mapDispatchToProps = dispatch => ({
    courseList: (details) =>
        dispatch(global.redux.action.students.courseList(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);













 