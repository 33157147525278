import axios from "axios";
import appEnvirontVariables from "../config/env-variables";
// Set config defaults when creating the instance
const instance = axios.create({
    baseURL: appEnvirontVariables.API.BASE_URL
});
const apiinstance = axios.create({
    baseURL: appEnvirontVariables.API.API_URL
});
const paymentinstance = axios.create({
    baseURL: appEnvirontVariables.API.PAYMENT_URL
})

export default {instance, apiinstance, paymentinstance};