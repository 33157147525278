import React, {useState, useRef, useEffect} from 'react'

import ChatMessage from './ChatMessage';

function ChatHistory({ allChats, userId, selectedChatData, fetchMoreChatMessages, runLoadMoreChats, noMoreChatsAvailable }) {
  const [loadMore, setLoadMore] = useState(false)
  const [callLoadPreviousMessage, setCallLoadPreviousMessages] = useState(false)

  // scroll to bottom on page load
  useEffect(() => {
    if (!loadMore) {
      scrollToBottom()
    }
  }, [allChats, loadMore])

  function scrollToBottom() {
    const chatHistoryWrapper = document.querySelector('.single-chat__body')
    chatHistoryWrapper.scrollTo(0,  chatHistoryWrapper.scrollHeight);
  }

  // REVERSE INFINITE SCROLL
  useEffect(() => {
    const chatHistoryWrapper = document.querySelector('.single-chat__body')
    let lastScroll = 0
     function handleScroll() {    
      let currentScroll = chatHistoryWrapper.scrollTop

      if (currentScroll > 0 && lastScroll <= currentScroll) { // SCROLLING DOWN
        lastScroll = currentScroll
      } else { // SCROLLING UP
        lastScroll = currentScroll
        const scrollHeight = chatHistoryWrapper.scrollHeight // entier height
        const scrollTop = chatHistoryWrapper.scrollTop // from top
        
        // const offset = 0.3 * scrollHeight
        const offset = 225
        // console.log(Math.ceil(scrollTop), Math.ceil(offset))

        if (scrollTop < offset) {
            setCallLoadPreviousMessages(true)
        } else {
          setCallLoadPreviousMessages(false)
        }
      }
    }
    
    chatHistoryWrapper?.addEventListener("scroll", handleScroll)
    return () => chatHistoryWrapper?.removeEventListener("scroll", handleScroll)
  }, [])

  useEffect(() => {
    if (callLoadPreviousMessage) {
      setLoadMore(true)
      fetchMoreChatMessages()
    }
  }, [callLoadPreviousMessage])
  
  return (
    <div className='single-chat__body'>
      {runLoadMoreChats && noMoreChatsAvailable && (
        <div className='no-existing-conv'>No more messages</div>
      )}
      {/* Removing items that have timestamp as null */}
      {allChats.filter(item => item.timestamp)?.map((item, index) => {
        return <ChatMessage key={index} item={item} userId={userId} teacherChat={true} selectedChatData={selectedChatData} />
      })}
    </div>
  )
}

export default ChatHistory