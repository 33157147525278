import React, { useState } from "react";
import { Form, Input, Button, DatePicker, TimePicker, Select, notification } from 'antd';
import _ from 'lodash';
import { connect } from "react-redux";
import moment from 'moment';
import logo from '../../../../assets/gif/aa_loader.gif';
import '../style.css'
const ReportFilter = (props) => {

  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [disablesubmit, setdisablesubmit] = useState(true);
  const [datereq, setdatereq] = useState(false);
  const [classtype, setclasstype] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [datetype, setdatetype] = useState('');

  const onFinish = (values) => {
    setIsLoading(true)
    const reqParams = {
      "fromDate": moment(_.get(values, 'fromDate[0]')).format('YYYY-MM-DD'),
      "toDate": moment(_.get(values, 'fromDate[1]')).format('YYYY-MM-DD'),
      "fromTime": moment(_.get(values, 'formtime[0]')).format('HH:mm:ss'),
      "toTime": moment(_.get(values, 'formtime[1]')).format('HH:mm:ss'),
      "emailId": _.get(values, 'emailId'),
      "parameterType": _.get(values, 'parameterType'),
      "scheduled": _.isEqual(_.get(values, 'class'), 'scheduled') ? true : false,
      "completed": _.isEqual(_.get(values, 'class'), 'completed') ? true : false,
      "cancelled": _.isEqual(_.get(values, 'class'), 'cancelled') ? true : false,
      "toslotDate": moment(_.get(values, 'slotDate[1]')).format('YYYY-MM-DD'),
      "fromslotDate": moment(_.get(values, 'slotDate[0]')).format('YYYY-MM-DD'),
    }
    if (_.isEqual(_.get(values, 'parameterType'), 'paidDemo')) {
      delete reqParams.parameterType
    }
    if (_.isEmpty(_.get(values, 'fromDate'))) {
      delete reqParams.fromDate
      delete reqParams.toDate
    }
    if (_.isEmpty(_.get(values, 'formtime'))) {
      delete reqParams.fromTime
      delete reqParams.toTime
    }
    props.report({
      body: reqParams,
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          setIsLoading(false)
          form.resetFields();
          notification.success({
            message: data,
          });
        } else {
          setIsLoading(false)
          notification.error({
            message: _.get(data, 'body', "Something went wrong."),
          });
        }
      }
    })
  };

  const onFinishFailed = (errorInfo) => {
  };

  const render = () => {
    return (
      <div className="report-card">
        <div className="report-filter-conatiner">
          <span>Class Report</span>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={(change, allValues) => { setdisablesubmit(false); console.log('allvalues', allValues); }}
            form={form}
          >
            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6 ">
              <Form.Item
                label="Class type"
                name="parameterType"
                rules={[
                  {
                    required: true,
                    message: 'Please input your class type!',
                  },
                ]}
              >
                <Select onSelect={(value) => setclasstype(value)} placeholder='Select'>
                  <Option value="demo">Demo </Option>
                  <Option value="paid">Paid </Option>
                  <Option value="paidDemo">Paid and Demo </Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6 ">
              <Form.Item
                label="Class Status"
                name="class"
                rules={[
                  {
                    required: true,
                    message: 'Please input your class type!',
                  },
                ]}
              >
                <Select placeholder='Select '>
                  <Option value="scheduled">Scheduled</Option>
                  <Option value="completed">Completed</Option>
                  <Option value="schcom">Scheduled and completed</Option>
                  <Option value="cancelled">Cancelled</Option>
                  <Option value="all">All</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <Form.Item
                label="Email"
                name="emailId"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  }, {
                    pattern: new RegExp(/^[A-Za-z0-9._%+-]+@artiumacademy.com$/)
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6 ">
              <Form.Item
                label="Based on"
                name="datetype"
                rules={[
                  {
                    required: true,
                    message: 'Please input your report based!',
                  },
                ]}
              >
                <Select onSelect={(value) => setdatetype(value)} placeholder='Select' >
                  <Option value="create">Create date </Option>
                  <Option value="slot">Slot date </Option>
                </Select>
              </Form.Item>
            </div>
            {_.isEqual(datetype, 'create') &&
              <React.Fragment>
                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <Form.Item
                    label="Date"
                    name="fromDate"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your date!',
                      },
                    ]}
                  >
                    <RangePicker disabledDate={current => {
                      return current && current > moment().add(1, "month");
                    }}
                      onChange={(e) => { _.isEmpty(e) ? setdatereq(false) : setdatereq(true) }} />
                  </Form.Item>
                </div>
                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <Form.Item
                    label="Time"
                    name="formtime"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your time!',
                      },
                    ]}
                  >
                    <TimePicker.RangePicker />
                  </Form.Item>
                </div>
              </React.Fragment>

            }
            {_.isEqual(datetype, 'slot') &&
              <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <Form.Item
                  label="Date"
                  name="slotDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your date!',
                    },
                  ]}
                >
                  <RangePicker />
                </Form.Item>
              </div>
            }
            <div className="report-btn">
              <Form.Item >
                <Button type="primary mr-2" danger onClick={() => form.resetFields()}>
                  Clear
                </Button>
                <Button type="primary" htmlType="submit" disabled={disablesubmit}>
                  Submit
                </Button>
              </Form.Item>
              
            </div>
            {isLoading &&
      <div className="loadbtn">
          <img src={logo} alt="loading..." />
        </div>}
          </Form>
        </div>
      </div>

    )
  }
  return render();
}

const mapStateToProps = state => (
  {
    account: _.get(state, `app.${global.redux.actionTypes.account.name}`, {}),

  });

const mapDispatchToProps = dispatch => ({
  report: details => dispatch(global.redux.action.admin.report(details))
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportFilter);
