import React, { useEffect } from 'react';
import WebLayOut from './components/Layout';
import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '../node_modules/antd/dist/antd.css';
import './assets/css/bootstrap.css';
import './App.css';
import '../node_modules/jquery/dist/jquery.min.js';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import './assets/css/animation-effects.css';
import { Provider } from 'react-redux';
import axios from 'axios';
import Login from './containers/account/login/login';
import ResetPassword from './containers/account/login/reset.js';
import configStore from './core_units/redux/config/store';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import RoutesPage from './core_units/routes';
const mixpanel = require('mixpanel-browser');
mixpanel.init("d3d368bc15b8dac6a0eae7c3dec26fe2");
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && (error.response.status === 401 || error.response.data == 'UnAuthorized')) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default function App(props) {
  useEffect(() => {
    // if (error.response && error.response.status === 401) {
    //   window.location.href = '/login';
    
    // }
  }, []);

  return (
    <Provider store={configStore()}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="*" element={<RoutesPage />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}




