import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TeacherOpsDashboard.css';
import SecureLS from "secure-ls";
import appEnvirontVariables from "../../core_units/config/env-variables";
import { Table, Select, Input, Spin, InputNumber, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { constant } from '../../core_units/constant';

const mixpanel = require('mixpanel-browser');
const { Option } = Select;
const { Search } = Input;
var ls = new SecureLS({ encodingType: 'aes' });
const token = ls.get('isLogin');
const userRoles = token.authorities;
let canEdit;
if (userRoles) {
  canEdit = userRoles.includes('ROLE_ADMIN') || userRoles.includes('ROLE_TEACHERADMIN');
}

const TeacherOpsDashboard = () => {
  const [sortConfig, setSortConfig] = useState({ columnKey: '', order: '' });
  const [selectedQuarter, setSelectedQuarter] = useState('Q1');  // Default to Q1
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());  // Default year is the current year
  const [teachersData, setTeachersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [kpiData, setKpiData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    fetchTeachersData();
    fetchKpiData();
  }, [selectedQuarter, selectedYear, currentPage, ]);  // Re-fetch when quarter, year, page, or sortConfig changes

  const fetchTeachersData = async () => {
    setLoading(true);
    try {
      const { fromDate, toDate } = getDateRange(selectedQuarter, selectedYear);  // Pass the selected quarter and year
      const response = await axios.get(`${appEnvirontVariables.API.BASE_URL}/api/admin/teacherPerformanceDetails`, {
        params: {
          fromDate,
          toDate,
          page: currentPage - 1,
          size: 500,
          // sortColumn: sortConfig.columnKey,  // Sort by column key
          // sortOrder: sortConfig.order === 'ascend' ? 'asc' : 'desc'  // Convert to 'asc' or 'desc'
        },
        headers: {
          Authorization: ` ${token["authorization"]}`,
          "Content-Type": "application/json"
        }
      });

      const data = response.data.content.map(teacher => ({
        id: teacher.teacherId,
        name: teacher.teacherName,
        level: teacher.level,
        totalActiveStudents: teacher.totalActiveStudents,
        classesConducted: teacher.classes,
        qaScore: teacher.qaScore,
        renewals: teacher.renewals,
        refunds: teacher.refunds,
        noShows: teacher.noShow,
        joinedLate: teacher.joinedLate,
        teacherCredentialId: teacher.teacherCredentialId,
        escalations: teacher.esclations,
        pip: teacher.pip === "true" || teacher.pip === true,  
        isEscalationsEdited: false,
        isPipEdited: false,
      }));

      setTeachersData(data);
    } catch (error) {
      console.error('Error fetching teacher data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchKpiData = async () => {
    try {
      const response = await axios.get(`${appEnvirontVariables.API.BASE_URL}/api/admin/teacherCategoryCount`, {
        headers: {
          Authorization: `${token["authorization"]}`,
          "Content-Type": "application/json"
        }
      });
      setKpiData(response.data);
    } catch (error) {
      console.error('Error fetching KPI data:', error);
    }
  };

  // Function to get the date range for the selected quarter and year
  const getDateRange = (selectedQuarter, year) => {
    let fromDate;
    let toDate;

    switch (selectedQuarter) {
      case 'Q1':  // January to March
        fromDate = new Date(year, 0, 1);  // January 1st
        toDate = new Date(year, 2, 31);  // March 31st
        break;
      case 'Q2':  // April to June
        fromDate = new Date(year, 3, 1);  // April 1st
        toDate = new Date(year, 5, 30);  // June 30th
        break;
      case 'Q3':  // July to September
        fromDate = new Date(year, 6, 1);  // July 1st
        toDate = new Date(year, 8, 30);  // September 30th
        break;
      case 'Q4':  // October to December
        fromDate = new Date(year, 9, 1);  // October 1st
        toDate = new Date(year, 11, 31);  // December 31st
        break;
      default:
        fromDate = toDate = '';
    }

    // Convert to ISO Date String format
    fromDate.setMinutes(fromDate.getMinutes() - fromDate.getTimezoneOffset());
    toDate.setMinutes(toDate.getMinutes() - toDate.getTimezoneOffset());

    return {
      fromDate: fromDate.toISOString().split('T')[0],
      toDate: toDate.toISOString().split('T')[0]
    };
  };

  // Handle sorting, pagination, and filters
  const handleChange = (pagination, filters, sorter) => {
    setSortConfig({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
    setCurrentPage(pagination.current);  // Handle page change
  };

  const handleQuarterChange = (value) => {
    setSelectedQuarter(value);
    setCurrentPage(1);
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleNameClick = (teacherData) => {
    if (teacherData.teacherCredentialId) {
      navigate(`/teacher-view?id=${teacherData.id}&&credid=${teacherData.teacherCredentialId}`, { state: { ...teacherData } });
    } else {
      console.error('teacherCredentialId is undefined');
    }
  };

  const handlePipChange = (value, id) => {
    if (canEdit) {
      const updatedTeachers = teachersData.map((teacher) =>
        teacher.id === id ? { ...teacher, pip: value === 'Yes', isPipEdited: true } : teacher
      );
      setTeachersData(updatedTeachers);
    } else {
      message.warning('You do not have permission to edit this field.');
    }
  };

  const handleEscalationChange = (value, id) => {
    if (canEdit) {
      const updatedTeachers = teachersData.map((teacher) =>
        teacher.id === id ? { ...teacher, escalations: value, isEscalationsEdited: true } : teacher
      );
      setTeachersData(updatedTeachers);
    } else {
      message.warning('You do not have permission to edit this field.');
    }
  };

  const handleSaveEscalation = async (teacher) => {
    try {
      await axios.post(`${appEnvirontVariables.API.BASE_URL}/api/admin/teacher/editprofile`, {
        teacherId: teacher.id,
        escalations: teacher.escalations,
      }, {
        headers: {
          Authorization: `${token["authorization"]}`,
          "Content-Type": "application/json",
        },
      });

      mixpanel.track(constant.MIXPANEL_TEACHER_ESCALATION, {
        teacherId: teacher.id,
        escalations: teacher.escalations,
        LoginUser: token?.username,
      });
      message.success('Escalations saved successfully');
      
      await fetchTeachersData();
      
    } catch (error) {
      message.error('Error saving escalations');
      console.error('Error saving data:', error);
    }
  };

  const handleSavePip = async (teacher) => {
    try {
      await axios.post(`${appEnvirontVariables.API.BASE_URL}/api/admin/teacher/editprofile`, {
        teacherId: teacher.id,
        pip: teacher.pip,
      }, {
        headers: {
          Authorization: `${token["authorization"]}`,
          "Content-Type": "application/json",
        },
      });

      mixpanel.track(constant.MIXPANEL_TEACHER_PIP, {
        teacherId: teacher.id,
        pip: teacher.pip,
        LoginUser: token?.username, 
      });
      message.success('PIP saved successfully');
      
      await fetchTeachersData();
      
    } catch (error) {
      message.error('Error saving PIP');
      console.error('Error saving data:', error);
    }
  };

  const filteredTeachers = teachersData.filter((teacher) =>
    (teacher.name || '').toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'teacherName',
      sorter: (a, b) => {
        const nameA = a.name ? a.name.toLowerCase() : ''; 
        const nameB = b.name ? b.name.toLowerCase() : ''; 
        return nameA.localeCompare(nameB);
      },
      render: (text, record) => (
        <a
          style={{ color: 'blue', textDecoration: 'none' }}
          onClick={() => handleNameClick(record)}
          onMouseEnter={(e) => e.currentTarget.style.textDecoration = 'underline'}
          onMouseLeave={(e) => e.currentTarget.style.textDecoration = 'none'}
        >
          {text}
        </a>
      ),
      ellipsis: true,
      width: 170,
      sortOrder: sortConfig.columnKey === 'teacherName' && sortConfig.order,
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      sorter: (a, b) => {
        const order = ['SILVER', 'GOLD', 'DIAMOND', 'PLATINUM']; // Custom sorting order
        return order.indexOf(a.level) - order.indexOf(b.level);
      },
      sortOrder: sortConfig.columnKey === 'level' && sortConfig.order,
      width: 100,
    },
    {
      title: 'Students',
      dataIndex: 'totalActiveStudents',
      key: 'totalActiveStudents',
      sorter: (a, b) => a.totalActiveStudents - b.totalActiveStudents,
      sortOrder: sortConfig.columnKey === 'totalActiveStudents' && sortConfig.order,
    },
    {
      title: 'Classes',
      dataIndex: 'classesConducted',
      key: 'classesConducted',
      sorter: (a, b) => a.classesConducted - b.classesConducted,
      sortOrder: sortConfig.columnKey === 'classesConducted' && sortConfig.order,
    },
    {
      title: 'QA Score',
      dataIndex: 'qaScore',
      key: 'qaScore',
      sorter: (a, b) => a.qaScore - b.qaScore,
      sortOrder: sortConfig.columnKey === 'qaScore' && sortConfig.order,
    },
    {
      title: 'Renewals',
      dataIndex: 'renewals',
      key: 'renewals',
      sorter: (a, b) => a.renewals - b.renewals,
      sortOrder: sortConfig.columnKey === 'renewals' && sortConfig.order,
    },
    {
      title: 'Refunds',
      dataIndex: 'refunds',
      key: 'refunds',
      sorter: (a, b) => a.refunds - b.refunds,
      sortOrder: sortConfig.columnKey === 'refunds' && sortConfig.order,
    },
    {
      title: 'No Shows',
      dataIndex: 'noShows',
      key: 'noShows',
      sorter: (a, b) => a.noShows - b.noShows,
      sortOrder: sortConfig.columnKey === 'noShows' && sortConfig.order,
    },
    {
      title: 'Joined Late',
      dataIndex: 'joinedLate',
      key: 'joinedLate',
      sorter: (a, b) => a.joinedLate - b.joinedLate,
      sortOrder: sortConfig.columnKey === 'joinedLate' && sortConfig.order,
    },
    {
      title: 'Escalations',
      dataIndex: 'escalations',
      key: 'escalations',
      sorter: (a, b) => a.escalations - b.escalations,
      sortOrder: sortConfig.columnKey === 'escalations' && sortConfig.order,
      render: (text, record) => (
        <div>
          <InputNumber
            min={0}
            value={text}
            onChange={(value) => handleEscalationChange(value, record.id)}
          />
          {record.isEscalationsEdited && (
            <div style={{ marginTop: 5 }}>
              <Button type="link" onClick={() => handleSaveEscalation(record)}>
                Save
              </Button>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'PIP',
      dataIndex: 'pip',
      key: 'pip',
      sorter: (a, b) => a.pip - b.pip,
      sortOrder: sortConfig.columnKey === 'pip' && sortConfig.order,
      render: (text, record) => (
        <div>
          <Select
            value={text ? 'Yes' : 'No'}  
            onChange={(value) => handlePipChange(value, record.id)}
            style={{ width: 80 }} 
          >
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
          {record.isPipEdited && (
            <div style={{ marginTop: 5 }}>
              <Button type="link" onClick={() => handleSavePip(record)}>
                Save
              </Button>
            </div>
          )}
        </div>
      ),
    }
  ];

  return (
    <div>
      <div className="kpi-cards">
        <div className="kpi-card silver">
          <h3>Silver</h3>
          <p>{kpiData.silverCategory || 0}</p>
        </div>
        <div className="kpi-card gold">
          <h3>Gold</h3>
          <p>{kpiData.goldCategory || 0}</p>
        </div>
        <div className="kpi-card diamond">
          <h3>Diamond</h3>
          <p>{kpiData.diamondCategory || 0}</p>
        </div>
        <div className="kpi-card platinum">
          <h3>Platinum</h3>
          <p>{kpiData.platinumCategory || 0}</p>
        </div>
      </div>

      <div className="dashboard-container">
        <div className="filters-container">
          <Search
            placeholder="Search by name"
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ width: 320 }}
          />

          {/* Year Dropdown */}
          <Select
            value={selectedYear}
            onChange={handleYearChange}
            style={{ width: 150, marginLeft: 20 }}
          >
            <Option value={2024}>2024</Option>
            <Option value={2025}>2025</Option>
          </Select>

          {/* Quarter Dropdown */}
          <Select value={selectedQuarter} onChange={handleQuarterChange} style={{ width: 150, marginLeft: 20 }}>
            <Option value="Q1">Q1 (Jan–Mar)</Option>
            <Option value="Q2">Q2 (Apr–Jun)</Option>
            <Option value="Q3">Q3 (Jul–Sep)</Option>
            <Option value="Q4">Q4 (Oct–Dec)</Option>
          </Select>
        </div>

        {loading ? (
          <div className="loading-container">
            <Spin size="large" />
          </div>
        ) : (
          <div className="table-container">
            <Table
              className="table-container"
              style={{ maxHeight: "none" }}
              columns={columns}
              dataSource={filteredTeachers}
              onChange={handleChange} 
              rowKey="teacherId"
              pagination={false}
              scroll={{ x: 500, y: 500 }} 
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TeacherOpsDashboard;
