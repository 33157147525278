import React, { useState } from "react";
import { Form, Input, Button, DatePicker, Select, notification } from 'antd';
import _ from 'lodash';
import { connect } from "react-redux";
import moment from 'moment';
import '../style.css'
const AddonReportFilter = (props) => {
    const { RangePicker } = DatePicker;
    const { Option } = Select;
    const [form] = Form.useForm();
    const [disablesubmit, setdisablesubmit] = useState(true);
    const [datereq, setdatereq] = useState(false);
    const [classtype, setclasstype] = useState('');

    const onFinish = (values) => {
        setdisablesubmit(true)
        const reqParams = {
            "fromDate": moment(_.get(values, 'fromDate[0]')).format('YYYY-MM-DD'),
            "toDate": moment(_.get(values, 'fromDate[1]')).format('YYYY-MM-DD'),
            "emailId": _.get(values, 'emailId'),
        }
        if (_.isEmpty(_.get(values, 'fromDate'))) {
            delete reqParams.fromDate
            delete reqParams.toDate
        }
        props.addonreport({
            params: reqParams,
            callback: (res, data) => {
                if (_.isEqual(res, true)) {
                    form.resetFields();
                    notification.success({
                        message: data,
                    });
                } else {
                    notification.error({
                        message: _.get(data, 'body', "Something went wrong."),
                    });
                }
            }
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const render = () => {
        return (
            <div className="report-card">
                <div className="report-filter-conatiner">
                    <span>Addon Class Report</span>

                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        onValuesChange={(change, allValues) => { setdisablesubmit(false); }}
                        form={form}
                    >
                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <Form.Item
                                label="Date"
                                name="fromDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your date!',
                                    },
                                ]}
                            >
                                <RangePicker onChange={(e) => { console.log('e', e); _.isEmpty(e) ? setdatereq(false) : setdatereq(true) }} />
                            </Form.Item>
                        </div>
                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <Form.Item
                                label="Email"
                                name="emailId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!',
                                    }, {
                                        pattern: new RegExp(/^[A-Za-z0-9._%+-]+@artiumacademy.com$/)
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="report-btn">
                            <Form.Item>
                                <Button type="primary mr-2" danger >
                                    Clear
                                </Button>
                                <Button type="primary" htmlType="submit" disabled={disablesubmit}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>

                    </Form>
                </div>
            </div>

        )
    }
    return render();
}

const mapStateToProps = state => (
    {
        account: _.get(state, `app.${global.redux.actionTypes.account.name}`, {}),

    });

const mapDispatchToProps = dispatch => ({
    addonreport: details => dispatch(global.redux.action.admin.addonreport(details))
});
export default connect(mapStateToProps, mapDispatchToProps)(AddonReportFilter);
