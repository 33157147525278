import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { Checkbox, Tabs, Form, Button, Radio, Select, Modal, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { useSearchParams, useLocation } from "react-router-dom";
import './demo-modal.css';
const { TabPane } = Tabs;
const afterNoonRange = [12, 1, 2, 3, 4, 5, 6];
const morningEveningRange = [7, 8, 9, 10, 11];
const { Option } = Select;
const DemoClassModal = (props) => {
  console.log('scheduleId',props.classScheduleId)
  const [form] = Form.useForm();
  const [id] = useSearchParams();
  const [isModalVisible, setIsModalVisible] = useState(props.demoslot);
  const [courselist, setCourseList] = useState();
  const [courselanguages, setCourseLanguages] = useState();
  const [courseslots, setCourseSlots] = useState();
  const [slotdate, setSlotDate] = useState();
  const [bookdate, setBookdate] = useState();
  const [interestTags, setinterestTags] = useState();
  const [value, setValue] = useState(1);
  const [studentId] = useSearchParams();
  const [teacherlist, setteacherlist] = useState();
  

  const onChange = (e) => {
    setValue(e.target.value);
    props.updateTag({
      params:{
        studentId: studentId.get("id"),
        tagId: e.target.value
      },
      callback: (response, data) => {
      },
    });
  };
  const tag = _.get(props, "students.viewprofile.tagsMap");
  useEffect(() => {
    setIsModalVisible(_.get(props, 'demoslot'))
  }, [_.get(props, 'demoslot')])
  const showModal = () => {
    setIsModalVisible(true);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
    props.setdemoslot(false)
    props.setBookDemo(false)
  };
  useEffect(() => {
    const base = process.env.REACT_APP_ENV === 'production' ?
      'https://api.artiumacademy.com/config/prod/config.json' :
      'https://api.artiumacademy.com/config/prod/config.json'
    fetch(base)
      .then(
        function (response) {
          if (response.status !== 200) {
            return;
          }

          // Examine the text in the response
          response.json().then(function (data) {
            setCourseList(_.get(data, 'courses'))
          });
        }
      )
      .catch(function (err) {
      });

  }, []);
  useEffect(() => {
    apiCall();
  }, [slotdate])
  const apiCall = () => {
    props.getavailableTeacher({
      body:
      {
        "bookingClassEndTime": _.get(slotdate, 'slotEndTime'),
        "bookingClassStartTime": _.get(slotdate, 'slotStartTime'),
        "bookingDate": _.get(slotdate, 'slotDate'),
        "bookingType": "",
        "isDemoStudent": true,
        "isFreeStudent": false,
        "isPaidStudent": false,
        "studentId": null,
        "teacherId": null,
        "userId": studentId.get("credid"),
        "voucherCode": ""
      },
      callback: (response, data) => {
        console.log('courselist', data);
        setteacherlist(data);
      },
    });
  }
  const oncourseselect = (e, checkedValues) => {
    setinterestTags(checkedValues)
    setCourseLanguages()
    setCourseSlots()

    props.courselanguage({
      params: {
        interestId: _.get(checkedValues, 'id')
      },
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          setCourseLanguages(data)
        }
      }
    })
    props.courseslots({
      params: {
        studentId: id.get("credid"),
        customParameter: "adminportal"
      },
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          let sortDate = Object.values(data).sort((obj1, obj2) => {
            if (obj1.slotDate != null && obj2.slotDate != null) {
              const date1 = obj1.slotDate.split('/').reverse().join();
              const date2 = obj2.slotDate.split('/').reverse().join();
              return date1 < date2 ? -1 : (date1 > date2 ? 1 : 0);
            }
          });
          Object.values(sortDate).forEach(e => {
            let slots = [...e.morningSlots,...e.afternoonSlots,...e.eveningSlots];
            slots.sort((a,b) => parseInt(a?.slotStartTime?.split(':')[0]) - parseInt(b?.slotStartTime?.split(':')[0]));
            e.morningSlots = [];
            e.afternoonSlots = [];
            e.eveningSlots = [];
            slots.forEach(z => {
              const split = z?.slotStartTimeStrInGivenTimeZone?.split(' ');
              if(split[1] && split[1].toLowerCase().indexOf('pm') > -1 && afterNoonRange.indexOf(parseInt(split[0])) > -1){
                e.afternoonSlots.push(z);
              }else if(split[1] && split[1].toLowerCase().indexOf('pm') > -1 && morningEveningRange.indexOf(parseInt(split[0])) > -1){
                e.eveningSlots.push(z);
              }else{
                e.morningSlots.push(z);
              }
            })
          })
          setCourseSlots(sortDate)
        }
      }
    })
  }
  const classcountcall = () => {
    props.classcount({
      params: {
        studentId: studentId.get("id"),
      },
      callback: (response, data) => {
      },
    });
  }
  const onFinish = (values) => {
    props.courseallocation({
      params: {
        status: 'prebook'
      },
      body: {
        "bookingClassEndTime": _.get(slotdate, 'slotEndTime') == "00:00:00" ? "24:00:00": _.get(slotdate, 'slotEndTime'),
        "bookingClassStartTime": _.get(slotdate, 'slotStartTime'),
        "bookingDate": _.get(slotdate, 'slotDate'),
        "bookingType": props.bookDemo ? "" :"reschedule",
        "isDemoStudent": true,
        "isFreeStudent": false,
        "isPaidStudent": false,
        "studentId": null,
        "teacherId": _.get(values, 'teacherId'),
        "userId": id.get("credid"),
        "voucherCode": "",
        "languageId": "1",  //_.get(values, 'language') ? _.get(values, 'language') : null,
        "tagId": _.get(values, 'mode') ? _.get(values, 'mode') : null,
        "classScheduleId": props.classScheduleId,
        "classToCancelId": (props.classScheduleId || "")
      },
      callback: (res, data) => {

        classcountcall()
        props.setdemoslot(false)
        props.setBookDemo(false)
        setIsModalVisible(false);
        props.setPreviewModalVisible(false)
        if (res === true) {
          notification.success({
            message: props.bookDemo ? 'Class scheduled successfully' : 'Class rescheduled successfully.',
          });
        } else {
          notification.error({
            message: 'No Slots available.',
          });
        }

        props.apiCall();
      }
    })
  };

  const onFinishFailed = (errorInfo) => {
  };
  const render = () => {

    return (
      <Modal className='demo-modal-tag' title="" visible={isModalVisible} footer={false} onCancel={handleCancel} zIndex={9999}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            // mode: tag[Object.keys(tag)[2]].substring(tag[Object.keys(tag)[2]].indexOf("-")),
          }}
        >
          <div className={`modal-container demo-schedule`}>
            <div className="cookiesContent" id="cookiesPopup">
              <div className='viewmodaltitle mb-2'>
                <div className='title-container'>
                  <span>Book your slot</span>
                </div>
                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 RegPassionateCourse">

                </div>
                <div className='interested-course'>
                  Select interested course
                </div>
                <div className='course-mode course-list'>
                  <Form.Item
                    name="course"
                    rules={[{ required: true, message: 'Please input!' }]}
                  >
                    <Radio.Group className='row'>

                      {/* {_.isEqual(form.getFieldValue('coursemode'), 'Instrument') ?
                      _.size(interestTags) > 0 && _.map(interestTags, (course, index) => {
                        return (
                          <Radio value={_.get(course, 'id')} className='col-12 col-md-4 col-lg-4' onChange={(e) => oncourseselect(e, course)}> {_.get(course, 'name')} </Radio>
                        )
                      })
                    : */}

                      {_.size(courselist) > 0 && _.map(courselist, (course, index) => {
                        return (
                          <Radio key={index} value={_.get(course, 'id')} className='col-12 col-md-4 col-lg-4' onChange={(e) => oncourseselect(e, course)}> {_.get(course, 'name')} </Radio>
                        )
                      })
                      }
                    </Radio.Group>

                  </Form.Item>
                </div>
                {_.size(courselanguages) > 0 &&

                  <React.Fragment>
                    <div className='interested-course'>
                      Select course level
                    </div>
                    <div className='course-mode'>
                      <Form.Item
                        name="mode"
                        rules={[{ required: true, message: 'Please input!' }]}
                      >
                        <Radio.Group onChange={onChange} className='row' value={value}
                          defaultValue={_.get(interestTags, 'tags[0].name')}>
                          {_.size(interestTags) > 0 && _.map(_.get(interestTags, 'tags'), (tag, index) => {
                            return (
                              <Radio className='col-12 col-md-4 col-lg-4' value={_.get(tag, 'id')}>{_.get(tag, 'name')}</Radio>

                            )
                          })}
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </React.Fragment>
                }

                {/* {_.size(courselanguages) > 0 ?
                  <React.Fragment>
                    <div className='interested-course'>
                      Select preferred learning language
                    </div>
                    <div className='language-list'>
                      <Form.Item
                        name="language"
                        rules={[{ required: false, message: 'Please input!' }]}
                      >
                        <Select getPopupContainer={trigger => trigger.parentNode} defaultValue={courselanguages[0].name}>
                          {courselanguages.map(d => (
                            <Option key={d.id} disabled={_.isEqual(_.get(d, 'active'), "true") ? false : true}>
                              {_.get(d, 'name')}
                            </Option>

                          ))}

                        </Select>

                      </Form.Item>
                    </div>
                  </React.Fragment>
                  :
                  null
                } */}

                {_.size(courseslots) > 0 ?
                  <React.Fragment>
                    <div style={{'color':'red','fontWeight': 'bold','marginBottom': '10px'}}>Note : This slots are showing based on student time zone</div>
                    <div className='interested-course'>
                      Select slot
                    </div>
                    <div className='course-mode'>
                      <Form.Item
                        name="bookingDate"
                        rules={[{ required: true, message: 'Please input!' }]}
                      >
                        <Radio.Group className='row'>
                          {_.size(courseslots) > 0 && _.map(courseslots, (slot, index) => {
                            return (
                              <Radio value={_.get(slot, 'slotDateStr')}
                                key={index}
                                className='col-12 col-md-4 col-lg-4'
                                onChange={() => setBookdate(_.get(slot, 'slotDateStr'))}> {_.get(slot, 'slotDateStr')} </Radio>
                            )
                          })}
                        </Radio.Group>

                      </Form.Item>
                    </div>
                  </React.Fragment>
                  :
                  <div className='interested-course'>
                    There is no slot available for this student
                  </div>
                }


                {!_.isEmpty(bookdate) &&
                  <div className='course-mode'>
                    <Form.Item
                      name="bookingClassStartTime"
                      rules={[{ required: true, message: 'Please input slot time!' }]}
                    >
                      <Tabs defaultActiveKey="morningSlots" >
                        <TabPane tab="Morning (7am - 11am)" key="morningSlots">
                          <div className='course-mode'>
                            <Radio.Group className='row' onChange={() => { apiCall() }}>
                              {_.map(courseslots, function (o) {
                                if (form.getFieldValue('bookingDate') === _.get(o, 'slotDateStr')) {

                                  console.log('bookingDate', _.size(_.get(o, 'morningSlots')) > 0);
                                  if (_.size(_.get(o, 'morningSlots')) > 0) {

                                    return _.map(_.get(o, 'morningSlots'), (each, i) => {
                                      console.log('morningSlots', each);
                                      return (
                                        <Radio value={_.get(each, 'slotStartTime'), _.get(each, 'slotEndTime')} key={i}
                                          className='col-12 col-md-4 col-lg-4'
                                          onChange={() => { form.setFieldsValue({ "bookingClassStartTime": _.get(each, 'slotStartTime') }); setSlotDate(each) }}> {_.get(each, 'slotStartTimeStrInGivenTimeZone')} </Radio>

                                      )
                                    })
                                  } else {
                                    console.log('else ');
                                    return <div className='no-slots-course' >
                                      No Slots available</div>
                                  }

                                }

                              })}
                            </Radio.Group>
                          </div>
                        </TabPane>
                        <TabPane tab=" Afternoon (12pm - 6pm)" key="afternoonSlots">

                          <div className='course-mode'>
                            <Radio.Group className='row' onChange={() => { apiCall() }}>
                              {_.map(courseslots, function (o) {
                                if (form.getFieldValue('bookingDate') === _.get(o, 'slotDateStr')) {
                                  if (_.size(_.get(o, 'afternoonSlots')) > 0) {

                                    return _.map(_.get(o, 'afternoonSlots'), (each, i) => {
                                      return (
                                        <Radio value={_.get(each, 'slotStartTime'), _.get(each, 'slotEndTime')}
                                          className='col-12 col-md-4 col-lg-4' key={i}
                                          onChange={() => { form.setFieldsValue({ "bookingClassStartTime": _.get(each, 'slotStartTime') }); setSlotDate(each) }}> {_.get(each, 'slotStartTimeStrInGivenTimeZone')} </Radio>

                                      )
                                    })
                                  } else {
                                    console.log('else ');
                                    return <div className='no-slots-course' >
                                      No Slots available</div>
                                  }
                                }
                              })}
                            </Radio.Group>
                          </div>
                        </TabPane>
                        <TabPane tab="Evening (7pm - 11pm)" key="eveningSlots">
                          <div className='course-mode'>
                            <Radio.Group className='row' onChange={() => { apiCall() }}>
                              {_.map(courseslots, function (o) {
                                if (form.getFieldValue('bookingDate') === _.get(o, 'slotDateStr')) {
                                  if (_.size(_.get(o, 'eveningSlots')) > 0) {

                                    return _.map(_.get(o, 'eveningSlots'), (each, i) => {
                                      return (
                                        <Radio value={_.get(each, 'slotStartTime'), _.get(each, 'slotEndTime')}
                                          key={i}
                                          className='col-12 col-md-4 col-lg-4'
                                          onChange={() => { form.setFieldsValue({ "bookingClassStartTime": _.get(each, 'slotStartTime') }); setSlotDate(each) }}> {_.get(each, 'slotStartTimeStrInGivenTimeZone')} </Radio>
                                      )
                                    })
                                  } else {
                                    console.log('else ');
                                    return <div className='no-slots-course' >
                                      No Slots available</div>
                                  }
                                }
                              })}
                            </Radio.Group>
                          </div>
                        </TabPane>
                      </Tabs>
                    </Form.Item>
                  </div>
                }


                {!_.isEmpty(slotdate) ?
                  <React.Fragment>
                    <div className='interested-course'>
                      Select teacher
                    </div>
                    <div className='language-list'>
                      <Form.Item
                        name="teacherId"
                        rules={[{ required: false, message: 'Please input!' }]}
                      >
                        <Select dropdownClassName='course-list-dropdown'>
                          {_.size(teacherlist) > 0 && _.map(teacherlist, (course, index) => (
                            <Option key={index} value={_.get(course, 'teacherId')}
                            > {_.get(course, 'teacherName')} </Option>

                          ))}

                        </Select>

                      </Form.Item>
                    </div>
                  </React.Fragment>
                  :
                  null
                }

              </div>


              <Button className="reschdeule" htmlType="submit" disabled={!_.isEmpty(bookdate) ? false : true} loading={_.get(props, 'students.courseallocationLoadingInProgress')}>
                Confirm Free Trial
              </Button>

            </div>
          </div>
        </Form>

      </Modal>
    )

  }
  return render()
}

const mapStateToProps = state => (
  {
    students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),

  });

const mapDispatchToProps = dispatch => ({

  list: details => dispatch(global.redux.action.students.courseList(details)),
  courseslots: details => dispatch(global.redux.action.students.courseslots(details)),
  courselanguage: details => dispatch(global.redux.action.students.courselanguages(details)),
  courseallocation: details => dispatch(global.redux.action.students.courseallocation(details)),
  getavailableTeacher: details => dispatch(global.redux.action.teachers.getavailableTeacher(details)),
  updateTag: details => dispatch(global.redux.action.students.updateTag(details)),
  classcount: (details) =>
    dispatch(global.redux.action.students.viewclasscount(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DemoClassModal);
