import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { notification, Table, Button, Select, Modal } from "antd";
import { constant } from "../../../core_units/constant";
import * as SecureLS from "secure-ls";
const Referrallink = (props) => {
  const { Option } = Select;
  const [allReferralData, setAllReferralData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState(null);
  const mixpanel = require('mixpanel-browser');
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get('isLogin')
  const loginDetails = ls.get('isLogin');
  const allReferral = (status, sort) => {
    props.referral({
      params: {
        pageSize: 5,
        sortOrder: sort || "desc",
        status: status === "" ? undefined : status,
      },
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          setAllReferralData(data.data.Items);
          console.log(data.data.Items, "data.data.Items");
          setLastEvaluatedKey(data.data.LastEvaluatedKey);
        } else {
          notification.error({
            message: _.get(data, "body", "Something went wrong."),
          });
        }
      },
    });
  };
  useEffect(() => {
    allReferral();
  }, []);

  useEffect(() => {
  
    allref();
    
  }, [allReferralData]);
  
const allref = () => {
  if (Array.isArray(allReferralData) && allReferralData.length > 0) {
  const formattedData = allReferralData.map((item, index) => ({
    key: index,
    mobileNumber: item.mobileNumber || item.upiId,
    name: item.name || item.firstName || "-",
    points: item.points,
    userId: item.userId,
    timestamp: item.timestamp,
    status:
      item.status === 0
        ? "Pending"
        : item.status === 1
        ? "Approved"
        : item.status === 2
        ? "Rejected"
        : "-",
  }));

  setFormattedData(formattedData);
  console.log(formattedData, "formattedData");
}
}
  const handleStatusFilter = (value) => {
    setSelectedStatus(value);
    allReferral(value);
  };
  const handleSortOrderChange = (value) => {
    setSortOrder(value);
    allReferral(selectedStatus, value);
  };
  const handleApprove = (timestamp, userId) => {
    setConfirmationVisible(true);
    setConfirmationAction(() => () => {
      const body = {
        timestamp,
        userId,
        isApprove: true,
      };

      props.approvereferral({
        body,
        callback: (res, data) => {
          if (_.isEqual(res, true)) {
            console.log(res,"res==")
            notification.success({
                message: "Referral Approved successfully.",
              });
            allReferral(selectedStatus);
            mixpanel.track(constant.MIXPANEL_REFERRAL_APPROVE, {
              user: loginDetails?.studentName,
              userName:token?.username,
              userId:token?.uId,
              timestamp,
        userId,
        isApprove: true,
          });
          } else {
            notification.error({
              message: _.get(data, "body", "Something went wrong."),
            });
          }
        },
      });
    });
  };

  const handleReject = (timestamp, userId) => {
    setConfirmationVisible(true);
    setConfirmationAction(() => () => {
      const body = {
        timestamp,
        userId,
        isApprove: false,
      };

      props.approvereferral({
        body,
        callback: (res, data) => {
          if (_.isEqual(res, true)) {
            notification.success({
                message: "Referral rejected successfully.",
              });
            allReferral(selectedStatus);
            mixpanel.track(constant.MIXPANEL_REFERRAL_REJECT, {
              user: loginDetails?.studentName,
              userName:token?.username,
              userId:token?.uId,
              timestamp,
        userId,
        isApprove: false,
            });
          } else {
            notification.error({
              message: _.get(data, "body", "Something went wrong."),
            });
          }
        },
      });
    });
  };
  const handleConfirmationOk = () => {
    if (confirmationAction) {
      confirmationAction();
      setConfirmationVisible(false);
    }
  };

  const handleConfirmationCancel = () => {
    setConfirmationVisible(false);
    setConfirmationAction(null);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter && sorter.columnKey === "timestamp") {
      const newSortOrder = sorter.order === "ascend" ? "asc" : "desc";
      setSortOrder(newSortOrder);
      allReferral(selectedStatus, newSortOrder);
    }
  };
  const loadMore = () => {
    props.referral({
        params: {
            pageSize: 5,
            sortOrder: sortOrder,
            lastEvaluatedKey: lastEvaluatedKey,
            status: selectedStatus === "" ? undefined : selectedStatus,
        },
        callback: (res, data) => {
            if (_.isEqual(res, true)) {
                const newData = data.data.Items;
                setAllReferralData(prevData => [...prevData, ...newData]);
                setLastEvaluatedKey(data.data.LastEvaluatedKey);
            } else {
                notification.error({
                    message: _.get(data, "body", "Something went wrong."),
                });
            }
        },
    });
};

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
  
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
    },
    {
        title: "UserId",
        dataIndex: "userId",
        key: "userId",
      },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      sorter: true, 
      sortOrder: sortOrder === "asc" ? "asc" : "desc",
      render: (text, record) => (
        <span>{new Date(text).toLocaleString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        })}</span>
      ),
    
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) =>
        record.status === "Pending" ? (
          <div>
            <Button
              type="primary"
              onClick={() => {
                handleApprove(record.timestamp,record.userId);
              }}
              style={{ marginRight: 8 }}
            >
              Approve
            </Button>
            <Button
              onClick={() => {
               
                handleReject(record.timestamp,record.userId);
              }}
            >
              Reject
            </Button>
          </div>
        ) : null,
    },
    
  ];
  return (
    <div>
      <div>
        <div>status</div>
        <Select
          value={selectedStatus}
          onChange={handleStatusFilter}
          label="status"
          style={{
            width: 120,
            border: "1px solid black",
            marginBottom: "20px",
          }}
          placeholder="select status"
        >
          <Option value="0">Pending</Option>
          <Option value="1">Approved</Option>
          <Option value="2">Rejected</Option>
          <Option value="">All</Option>
        </Select>
      </div>
      <div><div>Sort Order</div>
        <Select
           value={sortOrder}
           onChange={handleSortOrderChange}
          label="Sort Order"
name="sortOrder"
          style={{
            width: 120,
            border: "1px solid black",
            marginBottom: "20px",
          }}
          placeholder="select sort"
        >
          <Option value="asc">Ascending</Option>
          <Option value="desc">Descending</Option>
         
        </Select></div>
        <Table dataSource={formattedData} columns={columns} pagination={false} onChange={handleTableChange} sortOrder={sortOrder} />
      <Modal
      visible={confirmationVisible}
      onOk={handleConfirmationOk}
      onCancel={handleConfirmationCancel}
    >
      <p>Are you sure you want to proceed?</p>
    </Modal>
     
      {lastEvaluatedKey && (
        <div style={{ textAlign: "center", paddingTop: "20px" }}>
          <Button onClick={loadMore}>Load More</Button>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  referral: (details) =>
    dispatch(global.redux.action.teachers.referral(details)),
  approvereferral: (details) =>
    dispatch(global.redux.action.teachers.approvereferral(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Referrallink);

