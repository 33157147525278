import React, { useState, useEffect } from 'react';
import { Button, Select, Pagination, Input } from 'antd';
import formatDate from '../../core_units/utils/dayjs';
import './style.css';
import {
  FilterOutlined,
  
} from "@ant-design/icons";
import AllLeads from './allLeads';
const { TextArea } = Input;
const { Option } = Select;

const LeadsList = (props) => {
  const [loading, setLoading] = useState(false);
  const [sortedLeadsList, setSortedLeadsList] = useState([]);

  // handlfault values to prevent 'undefined' issues
  const currentPage = props.currentPage || 1;
  const pageSize = props.pageSize || 30;

  useEffect(() => {

    const leadsList = Array.isArray(props.leadsList) ? props.leadsList : [];
    setSortedLeadsList([...leadsList]);
  }, [props.leadsList]);
  
  const getStatus = (data) => {
    if (data.meetType?.includes("NoShow")) {
        return "NOSHOW";
    }

    if (data.demoStatus === "Class_Ready_For_Scheduling" && data.classDeleted) {
        return "RESCHEDULED";
    }

    if (data.demoStatus === "SCHEDULED") {
        if (data.actionPerformed === "teacher_demo_cancelclass") {
            return "NOT CONDUCTED";
        } else if (data.actionPerformed === "student_trial_cancelclass") {
            return "CANCELLED";
        }
    }

    // Default case if no other conditions match
    return data.demoStatus;
};

// Usage in JSX



  return (
    <div>
        <div className="leads-section">
          {props.viewName !== "allleads" && 
           <Button id="filterButton" 
        
           className="filter-btn"
            onClick={props.showModal}>
              Filter  <FilterOutlined></FilterOutlined></Button>}
       
      </div>
    <div className="container" style={{ overflow: "auto", height: 'calc(100vh - 255px)', marginTop:"10px" }}>
      <table className="table table-bordered table-striped" style={{ backgroundColor: "whitesmoke", width: "100%", minWidth: "750px" }}>
        <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}>
          <tr>
            <th style={{ border: '1px solid white', textAlign: 'center' }}>S.No</th>
            {props.viewName === "allleads" && <th style={{ border: '1px solid white', textAlign: 'center' }}>Agent Name</th>}
            <th style={{ border: '1px solid white', textAlign: 'center' }}>Student Name</th>
            <th style={{ border: '1px solid white', textAlign: 'center' }}>Teacher Name</th>
            <th style={{ border: '1px solid white', textAlign: 'center' }}>Course Name</th>
            <th style={{ border: '1px solid white', textAlign: 'center' }}>Timezone</th>
            <th style={{ border: '1px solid white', textAlign: 'center' }}>Mobile Number</th>
            <th style={{ border: '1px solid white', textAlign: 'center', cursor: 'pointer' }}>
              Class Time
            </th>
            <th style={{ border: '1px solid white', textAlign: 'center' }}>Demo Status</th>
            <th style={{ border: '1px solid white', textAlign: 'center' }}>Demo Call Status1</th>
            <th style={{ border: '1px solid white', textAlign: 'center' }}>Demo Call Status2</th>
            {/* <th style={{ border: '1px solid white', textAlign: 'center' }}>Not Conducted Reason</th> */}
            <th style={{ border: '1px solid white', textAlign: 'center' }}>Comment</th>
            <th style={{ border: '1px solid white', textAlign: 'center' }}>Demo Join Probability</th>
            {props.viewName && <th style={{ border: '1px solid white', textAlign: 'center' }}>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {sortedLeadsList.map((data, index) => {
            const isEditable = !!data.recordId;
            return (
              <tr key={index}>
                <td style={{ border: '1px solid white', textAlign: 'center', cursor: "pointer" }} onClick={() => props.handleRowClick(data)}>
                  {index + 1} {/* Display the index or some other unique identifier */}
                </td>
                {props.viewName === "allleads" && (
                  <td style={{ border: '1px solid white', textAlign: 'center', cursor: "pointer" }} onClick={() => props.handleRowClick(data)}>
                    {data.agentName}
                
                  </td>
                )}
               <td 
  style={{ 
    border: '1px solid white', 
    textAlign: 'center', 
    cursor: "pointer" 
  }} 
  onClick={() => props.handleRowClick(data)}
>

  {data.firstName} 


  {data.trialType && (
    <span className='paid_demo'>{data.trialType}</span>
  )}
</td>

                <td style={{ border: '1px solid white', textAlign: 'center', cursor: "pointer" }} onClick={() => props.handleRowClick(data)}>
                  {data.teacherFirstName}
                </td>
                <td style={{ border: '1px solid white', textAlign: 'center', cursor: "pointer" }} onClick={() => props.handleRowClick(data)}>
                  {data.courseName}
                </td>
                <td style={{ border: '1px solid white', textAlign: 'center', cursor: "pointer" }} onClick={() => props.handleRowClick(data)}>
                  {data.studentTimezone}
                </td>
                <td style={{ border: '1px solid white', textAlign: 'center', cursor: "pointer" }} onClick={() => props.handleRowClick(data)}>
                  {data.countryCode}{data.mobileNumber}
                </td>
                <td style={{ border: '1px solid white', textAlign: 'center', cursor: "pointer", minWidth: "180px" }} onClick={() => props.handleRowClick(data)}>
                  {formatDate(data.slotDate)} {(data.startTime)}
                </td>
                <td style={{  border: '1px solid white', textAlign: 'center', cursor: "pointer", minWidth: "180px"  }} onClick={() => props.handleRowClick(data)}>
    {getStatus(data)}
</td>
                <td style={{ border: '1px solid white', textAlign: 'center' }}>
                  {props.viewName ? (
                    <Select
                      value={data.demoCallStatus1}
                      onClick={() => props.setDemoCallSelectClicked(true)}
                      onChange={(value) => props.handleStatusChange(value, index, data)}
                      style={{ minWidth: '70px' }}
                      disabled={!isEditable}
                    >
                      <Option value="Confirmed">Confirmed</Option>
                      <Option value="Unanswered">Unanswered</Option>
                      <Option value="Not contactable">Not contactable</Option>
                      <Option value="Wishes to reschedule">Wishes to reschedule</Option>
                      <Option value="Wishes to cancel">Wishes to cancel</Option>
                      <Option value="Wrong number">Wrong number</Option>
                      <Option value="Invalid number">Invalid number</Option>
                      <Option value="Language barrier">Language barrier</Option>
                      <Option value="Call back later">Call back later</Option>
                      <Option value="Disconnected after intro">Disconnected after intro</Option>
                      <Option value="Voicemail">Voicemail</Option>
                      <Option value="Not interested">Not interested</Option>
                    </Select>
                  ) : data.demoCallStatus1
                  }
                </td>
                <td style={{ border: '1px solid white', textAlign: 'center' }}>
                  {props.viewName ? (
                    <Select
                      value={data.demoCallStatus2}
                      onClick={() => props.setDemoCall2SelectClicked(true)}
                      onChange={(value) => props.handleDemoStatusChange(value, index, data)}
                      style={{ minWidth: '70px' }}
                      disabled={!isEditable}
                    >
                      <Option value="Confirmed">Confirmed</Option>
                      <Option value="Unanswered">Unanswered</Option>
                      <Option value="Not contactable">Not contactable</Option>
                      <Option value="Wishes to reschedule">Wishes to reschedule</Option>
                      <Option value="Wishes to cancel">Wishes to cancel</Option>
                      <Option value="Wrong number">Wrong number</Option>
                      <Option value="Invalid number">Invalid number</Option>
                      <Option value="Language barrier">Language barrier</Option>
                      <Option value="Call back later">Call back later</Option>
                      <Option value="Disconnected after intro">Disconnected after intro</Option>
                      <Option value="Voicemail">Voicemail</Option>
                      <Option value="Not interested">Not interested</Option>
                    </Select>
                  ) : data.demoCallStatus2}
                </td>
                {/* <td style={{ border: '1px solid white', textAlign: 'center' }}>
                  {props.viewName ? (
                    <Select
                      value={data.notConductedReason}
                      onClick={() => props.setNotConductedClicked(true)}
                      onChange={(value) => props.handleConductedChange(value, index, data)}
                      style={{ minWidth: '70px' }}
                      disabled={!isEditable}
                    >
                      <Option value="No AE found">No AE found</Option>
                      <Option value="Network/connectivity issues">Network/connectivity issues</Option>
                      <Option value="Language barrier">Language barrier</Option>
                      <Option value="Course change">Course change</Option>
                      <Option value="Zoom connectivity issue">Zoom connectivity issue</Option>
                      <Option value="No response from the student.">No response from the student.</Option>
                      <Option value="Tech issue">Tech issue</Option>
                      <Option value="Student canceled/rescheduled">Student canceled/rescheduled</Option>
                      <Option value="Teacher last minute unavailable.">Teacher last minute unavailable.</Option>
                      <Option value="The teacher joined late.">The teacher joined late.</Option>
                      <Option value="The student joined late.">The student joined late.</Option>
                      <Option value="Parents not around.">Parents not around.</Option>
                      <Option value="User not prepared to sing">User not prepared to sing</Option>
                      <Option value="The student wishes to reschedule">The student wishes to reschedule</Option>
                    </Select>
                  ) : data.notConductedReason}
                </td> */}
                <td style={{ border: '1px solid white', textAlign: 'center' }}>
                  {props.viewName ? (
                    <TextArea
                      name="comment"
                      value={data.agentComments}
                      onClick={() => props.setCommentClicked(true)}
                      onChange={e => props.handleCommentChange(e.target.value, index, data)}
                      style={{ minWidth: '200px' }}
                      disabled={!isEditable}
                    />
                  ) : data.agentComments}
                </td>
                <td style={{ border: '1px solid white', textAlign: 'center' }}>
  {data.prediction !== undefined && data.prediction !== null ? (
    parseFloat(data.prediction) === 100 ? 'Yes' : 
    parseFloat(data.prediction) === 0 ? 'No' : 
    `${data.prediction}%`
  ) : 'No Prediction'}
</td>


                {props.viewName && (
                  <td style={{ border: '1px solid white', textAlign: 'center' }}>
                    <Button 
                      type="primary" 
                      onClick={() => props.handleSaveChanges(data, index)}
                      disabled={!isEditable}
                    >
                      Save
                    </Button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      
      {props.viewName === "allleads"  &&
      <div> <div className='pagi-contaner' style={{ display: 'flex', justifyContent: 'center', marginLeft:"0px !important", margin: '20px 0' }}>
      
        <Pagination
          current={currentPage} 
          total={props.totalLeads || 0} 
          pageSize={pageSize} 
          onChange={props.handlePageChange}
          style={{ marginTop: '16px', textAlign: 'right' }}
        />
      </div></div>
}
    </div>
    </div>
    
  );
};

export default LeadsList;