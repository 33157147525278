import React, { useState, useEffect, useRef } from 'react';
import { Button, notification, Spin, Tabs, Switch, Modal, Pagination, Checkbox, Radio } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import "./style.css";
import * as SecureLS from "secure-ls";
import axios from 'axios';
import appEnvirontVariables from "../../core_units/config/env-variables";
import LeadsList from './leadsList';
import AllLeads from './allLeads';
import envVariables from '../../core_units/config/env-variables';
import { constant } from "../../core_units/constant";
import dayjs from "dayjs"; 
import LiveDemoTable from './LiveDemo';
const mixpanel = require('mixpanel-browser');
const { TabPane } = Tabs;


const DemoOps = () => {
  const navigate = useNavigate();
  const [overallData, setOverallData] = useState([]);


  const [originalData, setOriginaldata] = useState([]);
  const [demoCallSelectClicked, setDemoCallSelectClicked] = useState(false);
  const [demoCall2SelectClicked, setDemoCall2SelectClicked] = useState(false);
  const [commentClicked, setCommentClicked] = useState(false);
  const [tabLoading, setTabLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [notConductReason, setNotConductReason] = useState("");
  const [notConductedClicked, setNotConductedClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [switchActive, setSwitchActive] = useState(null);
  const location = useLocation();
  const initialTab = location.state?.activeTab;
  const [activeTab, setActiveTab] = useState(initialTab || '1');
  const [blinkActive, setBlinkActive] = useState(false);
  const [filter, setFilter] = useState("");
  const [filterOn, setFilterOn] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(500);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [filterMethod, setFilterMethod] = useState({ callStatus1: 'tags', callStatus2: 'tags' });
  const [applyDisabled, setApplyDisabled] = useState(true);
  const ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const switchActiveRef = useRef(switchActive);
  const [hideSwitch, setHideSwitch] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [apiFilters, setApiFilters] = useState({ filter: "", filterOn: "" });


  const getLeadDetails = (page = 0, size = 500, filter = "", filterOn = "") => {
 
    setLoading(true);
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/leads`;

    axios.post(apiUrl, {
      "agentId": token.uId,
      "page": page,
      "size": size,
      "filter": filter,
      "filterOn": filterOn
 
    }, {
      headers: {
        Authorization: token["authorization"]
      }
    })
      .then(response => {
        if (response.data.content.length) {
          setOverallData(response.data.content);
          setOriginaldata(JSON.parse(JSON.stringify(response.data.content)));
          setTotalPages(response.data.totalPages);
        } else {
          setOverallData([]);
          setOriginaldata([]);
          setTotalPages(0);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  const activeAPI = () => {
    getLeadDetails(currentPage, pageSize, filter, filterOn);
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/agent?agentId=${token.uId}`;
    axios.get(apiUrl, {
      headers: {
        Authorization: token["authorization"]
      }
    })
      .then(response => {
        mixpanel.track(constant.MIXPANEL_DEMOOPS_STATUS_API, {
          agentId: token.uId,
          agentName: token.studentName,
          response: response,
          date_time: new Date().toString(),
        });
        const agentStatus = response.data.status;
        if (agentStatus === "active") {
          setSwitchActive(true);
        } else {
          setSwitchActive(false);
        }
      })
      .catch(error => {
        mixpanel.track(constant.MIXPANEL_DEMOOPS_STATUS_API, {
          agentId: token.uId,
          agentName: token.studentName,
          error: error,
          date_time: new Date().toString(),
        });
        console.log(error);
      });
  };

  const handleTodayChange = (page = 0, size = 50, filter = "", filterOn = "") => {
    const dateFormat = dayjs().format("YYYY-MM-DD");
    setLoading(true);
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/leads`;
    axios.post(apiUrl, {
      fromDate: dateFormat,
      toDate: dateFormat,
      page: page,
      size: size,
      filter: filter,
      filterOn: filterOn,
    }, {
      headers: {
        Authorization: token["authorization"]
      }
    })
      .then(response => {
        setOverallData(response.data.content);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page - 1);

    const { filter, filterOn } = apiFilters;
    if (activeTab === '1') {
      getLeadDetails(page - 1, pageSize, filter, filterOn);

    } else if (activeTab === '2') {
      // handleTodayChange(page - 1, pageSize, filter, filterOn);
    }
  };
  // useEffect(() => {
  //   if (location.state?.activeTab) {
  //     setActiveTab(location.state.activeTab);
  //   }
  // }, [location.state]);
  // //this i added
  useEffect(() => {
    activeAPI();
    const getLeadsEvery10Mins = () => {
      if (switchActiveRef.current) {
        getLeadDetails(currentPage, pageSize, filter, filterOn);
      }
    };
    const intervalId = setInterval(getLeadsEvery10Mins, 600000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    switchActiveRef.current = switchActive;
  }, [switchActive]);

  useEffect(() => {
  
    if (activeTab === '1') {
      getLeadDetails(currentPage, pageSize, filter, filterOn);
      console.log("active",activeTab)
    } else if (activeTab === '2') {
      console.log("active",activeTab)
   
    }
  }, [activeTab]);

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
      console.log("active",location.state.activeTab)
    }
  }, [location.state]);

  const onStatusChange = (checked) => {
    const newStatus = checked ? "active" : "inactive";
    mixpanel.track(constant.MIXPANEL_DEMOOPS_TOGGLE_BUTTON_CHANGE, {
      agentId: token.uId,
      agentName: token.studentName,
      agent_status: newStatus,
      date_time: new Date().toString(),
    });
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/status`;
    axios.post(apiUrl, {
      agentId: token.uId,
      status: newStatus
    }, {
      headers: {
        Authorization: token["authorization"]
      }
    })
      .then(response => {
        mixpanel.track(constant.MIXPANEL_DEMOOPS_STATUS_CHANGE, {
          agentId: token.uId,
          agentName: token.studentName,
          agent_status: newStatus,
          date_time: new Date().toString(),
          ...response
        });
        notification.success({ message: "Status updated successfully!" });
        activeAPI();
      })
      .catch(error => {
        mixpanel.track(constant.MIXPANEL_DEMOOPS_STATUS_CHANGE, {
          agentId: token.uId,
          agentName: token.studentName,
          agent_status: newStatus,
          date_time: new Date().toString(),
          ...error
        });
        console.error("Error changing status:", error);
        notification.error({ message: "No Agent Found" });
      });
  };

  const handleRowClick = (data) => {
    navigate('/DetailViewOps', {
      state: { ...data, ...{ "viewName": "", activeTab: activeTab } }
    });
  };

  const handleCommentChange = (value, index, data) => {
    const updatedData = [...overallData];
    updatedData.find(e => e.studentId === data.studentId).agentComments = value;
    setOverallData(updatedData);
  };
  const handleStatusChange = (value, index, data) => {
    const updatedData = [...overallData];
    updatedData.find(e => e.studentId == data.studentId).demoCallStatus1 = value;
    setOverallData(updatedData);
  };
  const handleDemoStatusChange = (value, index, data) => {
    const updatedData = [...overallData];
    updatedData.find(e => e.studentId == data.studentId).demoCallStatus2 = value;
    setOverallData(updatedData);
  };
  const handleConductedChange = (value, index, data) => {
    const updatedData = [...overallData];
    updatedData.find(e => e.studentId == data.studentId).notConductedReason = value;
    setOverallData(updatedData);
  };

  const handleSaveChanges = (data, index) => {
    console.log("...data", data);
    let status = "";
    let leadHistoryList = [];

    const studentData = overallData.find(e => e.studentId === data.studentId);
    const originalStudentData = originalData.find(e => e.studentId === data.studentId);

    if (studentData.demoCallStatus1 && demoCallSelectClicked) {
      status += `&latestDemoStatus=${studentData.demoCallStatus1}`;
      leadHistoryList.push({
        id: data.studentId.toString(),
        field: "Demo Call Status 1",
        user: token.studentName,
        user_id: token.uId,
        old_value: originalStudentData.demoCallStatus1,
        new_value: studentData.demoCallStatus1,
      });
    }

    if (studentData.demoCallStatus2 && demoCall2SelectClicked) {
      status += `&latestDemoStatus=${studentData.demoCallStatus2}`;
      leadHistoryList.push({
        id: data.studentId.toString(),
        field: "Demo Call Status 2",
        user: token.studentName,
        user_id: token.uId,
        old_value: originalStudentData.demoCallStatus2,
        new_value: studentData.demoCallStatus2,
      });
    }

    if (studentData.notConductedReason !== originalStudentData.notConductedReason && notConductedClicked) {
      status += `&notConductedReason=${studentData.notConductedReason}`;
      leadHistoryList.push({
        id: data.studentId.toString(),
        field: "Not Conduct Reason",
        user: token.studentName,
        user_id: token.uId,
        old_value: originalStudentData.notConductedReason,
        new_value: studentData.notConductedReason,
      });
    }

    if (studentData.agentComments || originalStudentData.agentComments || commentClicked) {
      status += `&comment=${studentData.agentComments}`;
      leadHistoryList.push({
        id: data.studentId.toString(),
        field: "Comment",
        user: token.studentName,
        user_id: token.uId,
        old_value: originalStudentData.agentComments || '',
        new_value: studentData.agentComments,
      });
    }

    if (demoCallSelectClicked || demoCall2SelectClicked || commentClicked || notConductedClicked || studentData.notConductedReason !== originalStudentData.notConductedReason) {
      Modal.confirm({
        title: 'Are you sure you want to save changes?',
        onOk: () => {
          updateStatusAPI(data, status, () => {
            leadHistoryList.forEach(data => addLeadHistory(data));
          });
        },
        onCancel: () => {
          console.log('Save changes cancelled');
        },
      });
    }
  };


  const updateStatusAPI = (leadData, status, cb) => {
    setLoading(true);
    const payload = {
      classId: leadData.classScheduleId,
      latestDemoStatus: demoCallSelectClicked ? leadData.demoCallStatus1 : leadData.demoCallStatus2,
      DemoCallStatus1: leadData.demoCallStatus1,
      DemoCallStatus2: leadData.demoCallStatus2,
      notConductedReason: leadData.notConductedReason,
      comments: leadData.agentComments || "",
      agentId: `${token.uId}`
    };

    axios.post(`${appEnvirontVariables.API.BASE_URL}/api/admin/new/update/agent/lead`, payload, {
      headers: {
        Authorization: token["authorization"],
        "Content-Type": "application/json"
      }
    }).then(response => {
      notification.success({ message: "Status updated successfully!" });
      cb();
      setLoading(false);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });
  };


  const addLeadHistory = (data) => {
    const apiUrl = `${envVariables.API.DEMOOPS_HISTORY_URL}/api/lead-history`;

    axios.post(apiUrl, data, {
      headers: {
        Authorization: token["authorization"]
      }
    })
      .then(response => {

      })
      .catch(error => {
        console.log(error);
        notification.error({ message: "Failed to update lead history." });
      });
  }

  const showModal = () => {
    setSelectedFilters(appliedFilters);  
    setIsFilterModalVisible(true);
  };

  const handleCancel = () => {
    setIsFilterModalVisible(false);
  };

  const handleFilterMethodChange = (status, method) => {
    setFilterMethod(prevState => ({ ...prevState, [status]: method }));
  };

  const handleFilterSelection = (status, type, value) => {
    setSelectedFilters(prevState => ({
      ...prevState,
      [status]: {
        ...prevState[status],
        [type]: value
      }
    }));
    setApplyDisabled(false);
  };

  const handleClearFilters = () => {
    setSelectedFilters({
      callStatus1: {
        connected: false,
        notConnected: false,
        connectedReasons: [],
        notConnectedReasons: [],
      },
      callStatus2: {
        connected: false,
        notConnected: false,
        connectedReasons: [],
        notConnectedReasons: [],
      },
    });
  
  };


  const handleApplyFilters = () => {
    let filters = [];

    // Check if any filters are selected
    Object.keys(selectedFilters).forEach((status) => {
        let filter = "";
        const selected = selectedFilters[status];

        if (filterMethod[status] === 'tags') {
            if (selected.connected) {
                const connectedReasons = ['Confirmed', 'Wishes to reschedule', 'Wishes to cancel', 'Call back later', 'Not interested'];
                filter += connectedReasons.join(",") + ",";
            }
            if (selected.notConnected) {
                const notConnectedReasons = ['Unanswered', 'Not Contactable', 'Wrong Number', 'Invalid Number', 'Disconnected after intro', 'Voicemail', 'Language Barrier'];
                filter += notConnectedReasons.join(",") + ",";
            }
        }

        if (filterMethod[status] === 'reasons') {
            if (selected.connectedReasons && selected.connectedReasons.length > 0) {
                filter += selected.connectedReasons.join(",") + ",";
            }
            if (selected.notConnectedReasons && selected.notConnectedReasons.length > 0) {
                filter += selected.notConnectedReasons.join(",") + ",";
            }
        }

        filter = filter.slice(0, -1); 

        if (filter) {
            filters.push({ filter, filterOn: status });
        }
    });

    if (filters.length > 0) {
      const { filter, filterOn } = filters[0]; // Handle multiple filters if necessary
      setApiFilters({ filter, filterOn });
    }
  
    if (filters.length === 0) {
        // If no filters are selected, load the original unfiltered data
        getLeadDetails(currentPage, pageSize, "", "");
    } else {
       
        filters.forEach(({ filter, filterOn }) => {
            if (activeTab === '1') {
                getLeadDetails(currentPage, pageSize, filter, filterOn);
            } else if (activeTab === '2') {
                handleTodayChange(currentPage, pageSize, filter, filterOn);
            }
        });
    }
    setAppliedFilters(selectedFilters);
    setIsFilterModalVisible(false); 
};


  return (
    <div className="container">
      <div style={{ justifyContent: "space-between", alignItems: "center", marginBottom: "10px", display: "flex", justifyContent: "flex-end" }}>
        <div style={{ alignItems: "center" }}>
          <div style={{ marginRight: "10px" }}>{switchActive ? "" : blinkActive ? "" : ""}</div>
          {!switchActive && (
            <Button type="primary" size="small" className={`blink redButton ${blinkActive ? "blink" : ""}`}>
              Inactive
            </Button>
          )}
          {switchActive && (
            <Button type="primary" size="small" className="greenButton">
              Active
            </Button>
          )}

          <Switch checked={switchActive} onChange={checked => {
            setSwitchActive(checked);
            onStatusChange(checked);
          }} style={{ marginLeft: "3px" }} />
        </div>
      </div>

      <Tabs
        activeKey={activeTab}
        onTabClick={(key) => {
          setTabLoading(true);
          setActiveTab(key);
        }}
        style={{ backgroundColor: "white" }}
      >
        <TabPane tab="My Leads" key="1">
          <Spin tip="Loading..." spinning={loading}>
            <LeadsList
              leadsList={overallData}
              handleStatusChange={handleStatusChange}
              handleRowClick={handleRowClick}
              handleConductedChange={handleConductedChange}
              setDemoCallSelectClicked={setDemoCallSelectClicked}
              setDemoCall2SelectClicked={setDemoCall2SelectClicked}
              setCommentClicked={setCommentClicked}
              setNotConductedClicked={setNotConductedClicked}
              viewName={"myLead"}
              handleSaveChanges={handleSaveChanges}
              switchActive={switchActive}
              handleCommentChange={handleCommentChange}
              handleDemoStatusChange={handleDemoStatusChange}
              showModal={showModal}
            />
          </Spin>
        </TabPane>
        <TabPane tab="All Leads" key="2">
          <Spin spinning={loading}>
            <AllLeads
              activeTab={"2"}
              overallData={overallData}
              switchActive={switchActive}
              setActiveTab={setActiveTab}
              filter={filter}
              filterOn={filterOn}
              handleApplyFilters={handleApplyFilters}
              getLeadDetails={getLeadDetails}
            />
          </Spin>
        </TabPane>
        <TabPane tab="Live Demo" key="3">
        
        <LiveDemoTable activeTab={activeTab} />
        </TabPane>
      </Tabs>


      <Modal
        title="Filter Leads"
        visible={isFilterModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="clear" onClick={handleClearFilters}>Clear Filters</Button>,
          <Button key="apply" type="primary" disabled={applyDisabled} onClick={handleApplyFilters}>Apply Filters</Button>,
        ]}
      >
        <Tabs
  defaultActiveKey="1"
  className="filter-tabs"
  onChange={(key) => {
    if (key === "1") {
      setSelectedFilters((prevState) => ({
        ...prevState,
        callStatus2: { connected: false, notConnected: false, connectedReasons: [], notConnectedReasons: [] },
      }));
    } else if (key === "2") {
      setSelectedFilters((prevState) => ({
        ...prevState,
        callStatus1: { connected: false, notConnected: false, connectedReasons: [], notConnectedReasons: [] },
      }));
    }
  }}
>
          <TabPane tab="Call Status 1" key="1">
            <Radio.Group
              onChange={e => handleFilterMethodChange('callStatus1', e.target.value)}
              value={filterMethod.callStatus1}
              className="filter-radio-group"
            >
              <Radio value="tags">Filter by Tags</Radio>
              <Radio value="reasons">Filter by Reasons</Radio>
            </Radio.Group>

            {filterMethod.callStatus1 === 'tags' ? (
              <div className="filter-options">
                <Checkbox
                  checked={selectedFilters.callStatus1?.connected}
                  onChange={e => handleFilterSelection('callStatus1', 'connected', e.target.checked)}
                >
                  Connected
                </Checkbox>
                <Checkbox
                  checked={selectedFilters.callStatus1?.notConnected}
                  onChange={e => handleFilterSelection('callStatus1', 'notConnected', e.target.checked)}
                >
                  Not Connected
                </Checkbox>
              </div>
            ) : (
              <div className="filter-options">
                <b><label className="filter-label">Connected Reasons</label></b>
                <Checkbox.Group
                  value={selectedFilters.callStatus1?.connectedReasons || []}
                  onChange={checkedValues => handleFilterSelection('callStatus1', 'connectedReasons', checkedValues)}
                  options={[
                    { label: 'Confirmed', value: 'Confirmed' },
                    { label: 'Wishes to reschedule', value: 'Wishes to reschedule' },
                    { label: 'Wishes to cancel', value: 'Wishes to cancel' },
                    { label: 'Call back later', value: 'Call back later' },
                    { label: 'Not interested', value: 'Not interested' },
                  ]}
                  className="checkbox-group"
                />
                <b><label className="filter-label">Not Connected Reasons</label></b>
                <Checkbox.Group
                  value={selectedFilters.callStatus1?.notConnectedReasons || []}
                  onChange={checkedValues => handleFilterSelection('callStatus1', 'notConnectedReasons', checkedValues)}
                  options={[
                    { label: 'Unanswered', value: 'Unanswered' },
                    { label: 'Not Contactable', value: 'Not Contactable' },
                    { label: 'Wrong Number', value: 'Wrong Number' },
                    { label: 'Invalid Number', value: 'Invalid Number' },
                    { label: 'Disconnected after intro', value: 'Disconnected after intro' },
                    { label: 'Voicemail', value: 'Voicemail' },
                    { label: 'Language Barrier', value: 'Language Barrier' },
                  ]}
                  className="checkbox-group"
                />
              </div>
            )}
          </TabPane>

          <TabPane tab="Call Status 2" key="2">
            <Radio.Group
              onChange={e => handleFilterMethodChange('callStatus2', e.target.value)}
              value={filterMethod.callStatus2}
              className="filter-radio-group"
            >
              <Radio value="tags">Filter by Tags</Radio>
              <Radio value="reasons">Filter by Reasons</Radio>
            </Radio.Group>

            {filterMethod.callStatus2 === 'tags' ? (
              <div className="filter-options">
                <Checkbox
                  checked={selectedFilters.callStatus2?.connected}
                  onChange={e => handleFilterSelection('callStatus2', 'connected', e.target.checked)}
                >
                  Connected
                </Checkbox>
                <Checkbox
                  checked={selectedFilters.callStatus2?.notConnected}
                  onChange={e => handleFilterSelection('callStatus2', 'notConnected', e.target.checked)}
                >
                  Not Connected
                </Checkbox>
              </div>
            ) : (
              <div className="filter-options">
                <b><label className="filter-label">Connected Reasons</label></b>
                <Checkbox.Group
                  value={selectedFilters.callStatus2?.connectedReasons || []}
                  onChange={checkedValues => handleFilterSelection('callStatus2', 'connectedReasons', checkedValues)}
                  options={[
                    { label: 'Confirmed', value: 'Confirmed' },
                    { label: 'Wishes to reschedule', value: 'Wishes to reschedule' },
                    { label: 'Wishes to cancel', value: 'Wishes to cancel' },
                    { label: 'Call back later', value: 'Call back later' },
                    { label: 'Not interested', value: 'Not interested' },
                  ]}
                  className="checkbox-group"
                />
                <b><label className="filter-label">Not Connected Reasons</label></b>
                <Checkbox.Group
                  value={selectedFilters.callStatus2?.notConnectedReasons || []}
                  onChange={checkedValues => handleFilterSelection('callStatus2', 'notConnectedReasons', checkedValues)}
                  options={[
                    { label: 'Unanswered', value: 'Unanswered' },
                    { label: 'Not Contactable', value: 'Not Contactable' },
                    { label: 'Wrong Number', value: 'Wrong Number' },
                    { label: 'Invalid Number', value: 'Invalid Number' },
                    { label: 'Disconnected after intro', value: 'Disconnected after intro' },
                    { label: 'Voicemail', value: 'Voicemail' },
                    { label: 'Language Barrier', value: 'Language Barrier' },
                  ]}
                  className="checkbox-group"
                />
              </div>
            )}
          </TabPane>
        </Tabs>
      </Modal>

    </div>
  );
};

export default DemoOps;
