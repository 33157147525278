// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { connect } from "react-redux";
// import { useSearchParams, useLocation } from "react-router-dom";
// import _ from "lodash";
// import * as SecureLS from "secure-ls";
// import {
//     Form,
//     Input,
//     Button,
//     Upload,
//     message,
//     InputNumber,
//     Select,
//     notification,
//     DatePicker,
//     Table,
//     Spin,
//     Radio,
//   } from "antd";

//   const ClassDeduction = (props) => {
//     console.log(props,"21==")
//     var ls = new SecureLS({ encodingType: "aes" });
//     const token = ls.get("isLogin");
//     const [form] = Form.useForm();
//     const [classDetails,setClassDetails] = useState([]);
//     const [isLoading, setIsLoading] = useState(true); 
//     useEffect(() => {
//         classdetailsForDeduction();
//       }, []);
//       const classdetailsForDeduction = () => {
//         setIsLoading(true);
//         props.classDeduct({
//             params: {
//             studentId: props.students.viewprofile.studentId,
//               },
//           callback: (res, data) => {
//             setIsLoading(false);
//             if (_.isEqual(res, true)) {
//               setClassDetails(data);
//               console.log(data,"data==")
//             }
//           },
//         });
//       };
//       const columns = [
      
//         {
//           title: "Course Name",
//           dataIndex: "courseName",
//           key: "courseName",
//         },
//         {
//             title: "Class Number",
//             dataIndex: "classNumber",
//             key: "classNumber",
//           },
        
//         {
//             title: "Cancel Time",
//             dataIndex: "cancelTime",
//             key: "cancelTime",
//           },

//         {
//           title: "Class Date & Time",
//           key: "classDateTime",
//           render: (record) => (
//             <span>
//               {record.classDate} {record.classStartTime}
//             </span>
//           ),
//         },
        
        
//       ];
    
//       return (
//         <div>
//       {isLoading ? (
//         <div style={{ textAlign: "center" }}>
//           <Spin size="large" tip="Loading..." style={{ textAlign: "center" }} />
//         </div>
//       ) :  classDetails.length > 0 ? (
//         <Table columns={columns} dataSource={classDetails} pagination={false} />
//       ) : (
//         <div style={{ textAlign: "center", marginTop: "20px" }}>No Data Found</div>
//       )}
//     </div>
//   );
    
//   };
    

// const mapStateToProps = (state) => ({
//     teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
//     students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
//   });
  
//   const mapDispatchToProps = (dispatch) => ({
//     classDeduct: (details) =>
//       dispatch(global.redux.action.students.classDeduct(details)),
//   });
//   export default connect(mapStateToProps, mapDispatchToProps)(ClassDeduction);



import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Button, DatePicker,Table, Spin,} from "antd";
import _ from "lodash";
import SecureLS from "secure-ls";
import moment from "moment"; 
function ClassDeduction(props) {
  console.log(props,"props==")
  const { RangePicker } = DatePicker;
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
    const [form] = Form.useForm();
const [classDetails,setClassDetails] = useState([]);
const [isLoading, setIsLoading] = useState(false); 

          const onFinish = (values) => {
            setIsLoading(true);
            const [fromDate, toDate] = values.fromDate;
          
           
           
            const formattedFromDate = moment(fromDate).format("YYYY-MM-DD");
            const formattedToDate = moment(toDate).format("YYYY-MM-DD");
        
            props.classDeduct({
              params: {
                studentId: props.students.viewprofile.studentId,
                fromDate: formattedFromDate,
                toDate: formattedToDate,
              },
              callback: (response, data) => {
                setIsLoading(false);
                setClassDetails(data);
                console.log(data,"data==")
              },
            });
          };

  const columns = [
   
     
    {
      title: "Course Name",
      dataIndex: "courseName",
      key: "courseName",
    },
    {
      title: "Class Number",
      dataIndex: "classNumber",
      key: "classNumber",
    },
    {
      title: "Cancel Time",
      dataIndex: "cancelTime",
      key: "cancelTime",
    },
    
    {
      title: "Class Date & Time",
      key: "classDateTime",
      render: (record) => (
        <span>
          {record.classDate} {record.classStartTime}
        </span>
      ),
    },
    
  ];

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <div className="" style={{ maxWidth: "100%" }}>
          <Form.Item
            label="Date"
            name="fromDate"
            rules={[
              {
                required: true,
                message: "Please input your date range!",
              },
            ]}
          >
            <RangePicker />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
          {isLoading ? (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" tip="Loading..." style={{ textAlign: "center" }} />
        </div>
      ) : (
        classDetails.length > 0 ? (
          <Table columns={columns} dataSource={classDetails} pagination={false} />
        ) : (
          <div style={{ textAlign: "center" }}>No data found.</div>
        )
      )}
        </div>
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => ({
      teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
      students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
    });
    
    const mapDispatchToProps = (dispatch) => ({
      classDeduct: (details) =>
        dispatch(global.redux.action.students.classDeduct(details)),
    });

export default connect(mapStateToProps, mapDispatchToProps)(ClassDeduction);
