import React, { useState, useEffect } from "react";
import { Form, DatePicker, Button, Select, Spin,Radio, Checkbox } from "antd";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import axios from "axios";
import "./style.css";
import * as SecureLS from "secure-ls";
import appEnvirontVariables from "../../../core_units/config/env-variables";

const TeacherForecast = (props) => {
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [courseList, setCourseList] = useState();
  const [tableDetails, setTableDetails] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [queryvalue, setqueryvalue ] = useState();
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const [teacherslist, setTeachersList] = useState([]);
  const [filteredTeachers, setFilteredTeachers] = useState([]);
  const [kidsFriendly, setKidsFriendly] = useState(false);
  const [overallBooked, setOverallBooked] = useState({
    earlyMorning: 0,
    midMorning: 0,
    Afternoon: 0,
    Evening: 0,
    Night: 0,
  });
  const [overallTotal, setOverallTotal] = useState({
    earlyMorning: 0,
    midMorning: 0,
    Afternoon: 0,
    Evening: 0,
    Night: 0,
  });
  const [overallTeachers, setOverAllTeachers] = useState({
    earlyMorning: 0,
    midMorning: 0,
    Afternoon: 0,
    Evening: 0,
    Night: 0,
  });
  const [overallBookedSum, setOverallBookedSum] = useState();
  const [overallTotalSum, setOverallTotalSum] = useState();

  
  useEffect(() => {
    configjson();
  }, []);
  useEffect(() => {
   
    apicall();
  }, []);
  useEffect(() => {
    handleSearch("");
  }, [teacherslist, form]);
  
  const configjson = () => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          console.log("courselist", data);
          setCourseList(data);
        }
      },
    });
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const onFinish = (values) => {
    setLoading(true);
    const startDate = values.fromDate[0].format("YYYY-MM-DD");
    const endDate = values.fromDate[1].format("YYYY-MM-DD");

    const courseList = selectedCourseId.length > 0 ? selectedCourseId.join(",") : null;
    console.log("Selected Course IDs:", courseList);
    const teacherId = values.teacher || ""; 
    const gender = values.gender || null; 
   
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/teacher/forecast`;

    const params = {
      fromDate: startDate,
      toDate: endDate,
      ...(courseList && { courseList: courseList }),
      ...(teacherId && { teacherId: teacherId }),
      ...(gender !== null && { gender: gender }), 
      ...(kidsFriendly && { kidsFriendly: true }), 
      
    };
  
    console.log('API Parameters:', params); 
    axios
      .get(apiUrl, {
        params,
        headers: {
          Authorization: token["authorization"],
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setLoading(false);
        setTableDetails(response.data);
        console.log(response.data);
        setShowTable(true);
        let overallBookedCount = {
          earlyMorning: 0,
          midMorning: 0,
          Afternoon: 0,
          Evening: 0,
          Night: 0,
        };
        let overallTotalCount = {
          earlyMorning: 0,
          midMorning: 0,
          Afternoon: 0,
          Evening: 0,
          Night: 0,
        };
        let overallTeacherCount = {
          earlyMorning: 0,
          midMorning: 0,
          Afternoon: 0,
          Evening: 0,
          Night: 0,
        };
        const keyMapping = {
          "Early Morning": "earlyMorning",
          "Mid-Morning": "midMorning",
          Afternoon: "Afternoon",
          Evening: "Evening",
          Night: "Night",
        };
        response.data.forEach((slot) => {
          for (let key in slot) {
            if (slot.hasOwnProperty(key)) {
              const stateKey = keyMapping[key];
              if (stateKey) {
                slot[key].forEach((daySlot) => {
                  let booked = parseInt(daySlot.booked, 10);
                  let total = parseInt(daySlot.total, 10);
                  let teachers = parseInt(daySlot.teachers, 10);
                  overallBookedCount[stateKey] += booked;
                  overallTotalCount[stateKey] += total;
                  overallTeacherCount[stateKey] += teachers;
                });
              }
            }
          }
        });
        console.log(overallBooked, "overallBooked");
        setOverallBooked(overallBookedCount);
        console.log(overallBookedCount, "overallBookedCount");
        setOverallTotal(overallTotalCount);
        console.log(overallTotalCount, "overallTotalCount");
        setOverAllTeachers(overallTeacherCount);
        console.log(overallTeacherCount, "overallTeacherCount");
        console.log(overallTotal, "overallTotal");
        const overallBookedSum = Object.values(overallBookedCount).reduce((acc, cur) => acc + cur, 0);
        setOverallBookedSum(overallBookedSum);
        const overallTotalSum = Object.values(overallTotalCount).reduce((acc, cur) => acc + cur, 0);
        setOverallTotalSum(overallTotalSum);
        const ratio = overallBookedSum / overallTotalSum;
        console.log('Overall Booked Sum:', overallBookedSum);
        console.log('Overall Total Sum:', overallTotalSum);
        console.log('Ratio:', ratio);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const calculatePercentage = (booked, total) => {
    console.log("Booked:", booked);
    console.log("Total:", total);
    if (total === 0) {
      return 0; 
    }
    return (booked / total) * 100;
  };
  
  const handleCheckboxChange = (e) => {
    setKidsFriendly(e.target.checked); 
  };

  const getColorClass = (percentage) => {
    console.log("Percentage:", percentage);
    if (isNaN(percentage) || percentage === 0) {
      return "green";
    } else if (percentage > 0 && percentage <= 41) {
      return "green";
    } else if (percentage > 40 && percentage <= 60) {
      return "orange";
    } else {
      return "red";
    }
  };
  
  const calculateOverallDay = (day) => {
    console.log(day,"day")
    let overallBookedDay = 0;
    let overallTotalDay = 0;
    let overallTeacherCount = 0; 

    tableDetails.forEach((timeSlot) => {
      Object.keys(timeSlot).forEach((timeSlotKey) => {
        if (
          timeSlotKey === "Early Morning" ||
          timeSlotKey === "Mid-Morning" ||
          timeSlotKey === "Afternoon" ||
          timeSlotKey === "Evening" ||
          timeSlotKey === "Night"
        ) {
          const slot = timeSlot[timeSlotKey].find(
            (booking) => booking.day === day
          );
          if (slot) {
            overallBookedDay += parseInt(slot.booked, 10);
            overallTotalDay += parseInt(slot.total, 10);
            overallTeacherCount += parseInt(slot.teachers, 10);
          }
        }
      });
    });
    console.log(overallBookedDay,"overallBookedDay")
    console.log(overallTotalDay,"overallTotalDay")
    return { overallBookedDay, overallTotalDay, overallTeacherCount };
    
  };
  const apicall = (query = {}, page = 0) => {
    setqueryvalue(query)
  
    props.list({
      body: {
        page: page,
        size: 1000,
        ...query
      },
      callback: (response, data) => {
      

        if (_.isEqual(response, true)) {

          let arr = [];

          if (_.isEmpty(query) && !_.isEmpty(_.get(data, 'content'))) {
            arr = _.uniqBy([...teacherslist, ..._.get(data, 'content', [])], function (e) {
              return e.teacherId;
            });
          } else if (!_.isEmpty(query)) {
            if(_.size(teacherslist)<_.get(data, 'totalElements') && _.isEqual(queryvalue,query )) {
              arr = _.uniqBy([...teacherslist, ..._.get(data, 'content')], function (e) {
                return e.studentId;
              });	
            }else{
            arr = _.get(data, 'content')
            }					}
            arr = _.get(data, 'content');
          setTeachersList(arr);
          console.log(arr,"arr")
          setApiDataLoaded(true);
        }
       
      }
    })
    
  }
  const handleSearch = (input) => {
    if (input) {
     
      const filtered = teacherslist.filter((teacher) =>
        teacher.teacherName?.trim().toLowerCase().includes(input.toLowerCase())
      );
      setFilteredTeachers(filtered);
      console.log(filtered,"filtered")
    } else {
   
      setFilteredTeachers(teacherslist);
    }
    form.setFieldsValue({ teacher: undefined });
  };
  const overallTeachersSum = Object.values(overallTeachers).reduce((acc, cur) => acc + cur, 0);
  const exportToExcel = () => {
    if (!Array.isArray(tableDetails) || tableDetails.length === 0) {
        console.log("No data to export");
        return;
    }

    const headers = [
        'WEEKDAY',
        'Early Morning',
        'Mid Morning',
        'Afternoon',
        'Evening',
        'Night'
    ];

    let csvContent = "data:text/csv;charset=utf-8," + headers.join(",") + "\n";
    weekdays.forEach((day) => {
        const row = [day];
        headers.slice(1).forEach((timeSlot) => {
            const dayData = tableDetails[timeSlot] ? tableDetails[timeSlot][day] : undefined;
            const slotData = dayData ? `${dayData.booked}/${dayData.total}` : '0/0';
            row.push(slotData);
        });
        csvContent += row.join(",") + "\n";
    });
    console.log("CSV Content:", csvContent);
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "TeacherForecastData.csv");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};




  return (
    <div>
      <div>
        <Form form={form} onFinish={onFinish}>
          <div className="" style={{ maxWidth: "100%" }}>
            <div style={{ display: "flex", gap: "16px" }}>
              <Form.Item
                label="Date"
                name="fromDate"
                rules={[
                  {
                    required: true,
                    message: "Please input your date range!",
                  },
                ]}
              >
                <RangePicker/>
              </Form.Item>
              <Form.Item
                   label="Teacher"
                   name="teacher"
                
              >
<Select
          placeholder="Select the Teacher"
          style={{ background: "#f5f8fa",width: "210px", }}
          allowClear
          showSearch
          filterOption={false} 
          onSearch={handleSearch} 
        >
          {filteredTeachers.map((teacher, index) => (
            <Option key={index} value={teacher.teacherId}>
              {teacher.teacherName?.trim()}
            </Option>
          ))}
        </Select>
              </Form.Item>
              <Form.Item name="courseList" label="Course">
                <Select
                  placeholder="Select the Course"
                  mode="multiple"
                  style={{ width: "287px", background: "#f5f8fa" }}
                  onChange={(value) => setSelectedCourseId(value)}
                >
                  {_.map(_.get(courseList, "courses"), (course, eachParent) => {
                    return _.map(_.get(course, "tags"), (tag, each) => {
                      console.log("tags", tag);
                      console.log("course", course);
                      return (
                        <Option
                          value={_.get(tag, "paidCourseId")}
                          key={eachParent + "-" + each}
                        >
                          {_.get(course, "name")}-{_.get(tag, "name")}
                        </Option>
                      );
                    });
                  })}
                </Select>
              
              </Form.Item>
             
            </div>
            <div style={{ display: "flex", gap: "16px" }}>
            <Form.Item
              name="gender" label="Gender"
              >
                <Select
                  placeholder="Select Gender"
                  style={{ width: "256px", background: "#f5f8fa" }}
                  allowClear
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </Form.Item>
              <Form.Item
              name="kidsFriendly" label="Kids Friendly"
              >
              
              <Checkbox onChange={handleCheckboxChange}>True</Checkbox>
                
             
                 
              </Form.Item>
              <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: "20px" }}
                >
                  Submit
                </Button>
            </div>
            
          </div>
        </Form>
      </div>
      {tableDetails.length > 0 &&
      <div>
        <div>
        <Button onClick={exportToExcel} style={{ marginTop: '20px', marginBottom:"20px" }}>Export to CSV</Button>

        </div>
      <div className='calendar-legendset-conatiner'>
                                    <div className='calendar-legend-set'>
                                        <span className='legend-square' style={{ backgroundColor: 'green' }}>
                                        </span>
                                        <span className='legend-text'>
                                             0-40% &emsp; 
                                        </span>
                                        <span className='legend-square' style={{ backgroundColor: 'orange' }}>
                                        </span>
                                        <span className='legend-text'>
                                              41-60% &emsp; 
                                        </span>
                                        <span className='legend-square' style={{ backgroundColor: 'red' }}>

                                        </span>
                                        <span className='legend-text'>
                                             60% above&emsp;
                                        </span>

                                        
                                    </div>
                                </div>
                                <div style={{display:"flex",float:"right",marginTop:"-29px"}}>
<div className='calendar-legendset-conatiner'>
                                    <div className='calendar-legend-set'>
                                        <span>
                                        </span>
                                      
                                        <span className='legend-text' style={{color:"black"}}>
                                        Total Slots :&emsp; 
                                        </span>
                                        <span style={{border:"1px solid black", 
                                        backgroundColor: getColorClass(
                        calculatePercentage(
                          overallBookedSum,
                          overallTotalSum
                        )
                      ), padding: "2px 5px", color: "white"}}>{overallBookedSum}/{overallTotalSum}</span>
                                       
                                        {/* <span className='legend-text' style={{marginLeft:"10px",color:"black"}}>
                                        Total Teachers :&emsp; 
                                        </span> */}
                                        {/* <span
                                        
                                        >{overallTeachersSum}</span> */}
                                    </div>
                                </div>
</div>
                                </div>
                                
}

      <div>
        {loading ? (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Spin size="large" />
          </div>
        ) : showTable && tableDetails.length > 0 ? (
          <table className="bordered-table">
            <thead>
              <tr>
                <th>WEEKDAY</th>
                <th>
                  Early Morning <br />
                  (04:00-07:00 Hrs) <br />
                  <span
                    style={{
                      marginLeft: "-6px",
                      color: "white",
                      border: "1px solid black",
                      padding: "2px 5px",
                      backgroundColor: getColorClass(
                        calculatePercentage(
                          overallBooked.earlyMorning,
                          overallTotal.earlyMorning
                        )
                      ),
                    }}
                    title={"Overall Booked Slot / Overall Total Slot"}
                  >
                    {overallBooked.earlyMorning} / {overallTotal.earlyMorning}
                   
                    <span style={{ marginTop: "20px" }}>
                      (
                      {Math.round(
                        calculatePercentage(
                          overallBooked.earlyMorning,
                          overallTotal.earlyMorning
                        )
                      )}
                      %)
                    </span>
                 
                  </span><br/>
                  {/* Teachers:{overallTeachers.earlyMorning} */}
                </th>
                <th>
                  Mid Morning <br />
                  (07:00-11:00 Hrs) <br />
                  <span
                    title={"Overall Booked Slot / Overall Total Slot"}
                    style={{
                      marginLeft: "-6px",
                      color: "white",
                      border: "1px solid black",
                      padding: "2px 5px",
                      backgroundColor: getColorClass(
                        calculatePercentage(
                          overallBooked.midMorning,
                          overallTotal.midMorning
                        )
                      ),
                    }}
                  >
                    {overallBooked.midMorning} / {overallTotal.midMorning}
                  
                    <span style={{ marginTop: "20px" }}>
                      (
                      {Math.round(
                        calculatePercentage(
                          overallBooked.midMorning,
                          overallTotal.midMorning
                        )
                      )}
                      %)
                    </span>
                  </span><br/>
                  {/* Teachers:{overallTeachers.midMorning} */}
                </th>
                <th>
                  Afternoon <br />
                  (11:00-16:00 Hrs) <br />
                  <span
                    title={"Overall Booked Slot / Overall Total Slot"}
                    style={{
                      marginLeft: "-6px",
                      color: "white",
                      border: "1px solid black",
                      padding: "2px 5px",
                      backgroundColor: getColorClass(
                        calculatePercentage(
                          overallBooked.Afternoon,
                          overallTotal.Afternoon
                        )
                      ),
                    }}
                  >
                    {overallBooked.Afternoon} / {overallTotal.Afternoon}
                 
                    <span style={{ marginTop: "20px" }}>
                      {" "}
                      (
                      {Math.round(
                        calculatePercentage(
                          overallBooked.Afternoon,
                          overallTotal.Afternoon
                        )
                      )}
                      %)
                    </span>
                  </span><br/>
                  {/* Teachers:{overallTeachers.Afternoon} */}
                </th>
                <th>
                  Evening <br />
                  (16:00-22:00 Hrs) <br />
                  <span
                    title={"Overall Booked Slot / Overall Total Slot"}
                    style={{
                      marginLeft: "-6px",
                      color: "white",
                      border: "1px solid black",
                      padding: "2px 5px",
                      backgroundColor: getColorClass(
                        calculatePercentage(
                          overallBooked.Evening,
                          overallTotal.Evening
                        )
                      ),
                    }}
                  >
                    {overallBooked.Evening} / {overallTotal.Evening}
                 
                    <span style={{ marginTop: "20px" }}>
                      {" "}
                      (
                      {Math.round(
                        calculatePercentage(
                          overallBooked.Evening,
                          overallTotal.Evening
                        )
                      )}
                      %)
                    </span>
                  </span><br/>
                 {/* Teachers:{overallTeachers.Evening} */}
                </th>
                <th>
                  Night <br />
                  (22:00-04:00 Hrs) <br />
                  <span
                    title={"Overall Booked Slot / Overall Total Slot"}
                    style={{
                      marginLeft: "-6px",
                      color: "white",
                      border: "1px solid black",
                      padding: "2px 5px",
                      backgroundColor: getColorClass(
                        calculatePercentage(
                          overallBooked.Night,
                          overallTotal.Night
                        )
                      ),
                    }}
                  >
                    {overallBooked.Night} / {overallTotal.Night}
                 
                    <span style={{ marginTop: "20px" }}>
                      {" "}
                      (
                      {Math.round(
                        calculatePercentage(
                          overallBooked.Night,
                          overallTotal.Night
                        )
                      )}
                      %)
                    </span>
                  </span><br/>
                  {/* Teachers:{overallTeachers.Night} */}
                </th>
              </tr>
            </thead>
            <tbody>
              {weekdays.map((day) => (
                <tr key={day} style={{ border: "1px solid black" }}>
                  <td style={{ textAlign: "center", width: "14.28%" }}>
                    {day}<br/>
                   <div style={{ border: "1px solid black",backgroundColor: getColorClass(
              calculatePercentage(
                calculateOverallDay(day).overallBookedDay,
                calculateOverallDay(day).overallTotalDay
              )
            ),
            color: "white",
            padding: "2px",
            borderRadius: "4px",}}>
              {calculateOverallDay(day).overallBookedDay} / {calculateOverallDay(day).overallTotalDay}
              ({Math.round(
            calculatePercentage(
              calculateOverallDay(day).overallBookedDay,
              calculateOverallDay(day).overallTotalDay
            )
          )}
          %)
              </div>
              {/* <div>Teachers: {calculateOverallDay(day).overallTeacherCount}</div> */}
                  </td>

                  {tableDetails.map((timeSlot, index) => (
                    <React.Fragment key={index}>
                      {Object.keys(timeSlot).map((timeSlotKey) => (
                        <React.Fragment key={timeSlotKey}>
                          {timeSlotKey === "Early Morning" ||
                          timeSlotKey === "Mid-Morning" ||
                          timeSlotKey === "Afternoon" ||
                          timeSlotKey === "Evening" ||
                          timeSlotKey === "Night" ? (
                            <td
                            style={{
                              width: "14.28%",
                              border: "1px solid black",
                              textAlign: "center",
                              color: "white",
                              backgroundColor: getColorClass(
                                calculatePercentage(
                                  timeSlot[timeSlotKey].find((booking) => booking.day === day).booked,
                                  timeSlot[timeSlotKey].find((booking) => booking.day === day).total
                                )
                              ),
                            }}
                            title={"Booked Slot / Total Slot"}
                          >
                            {`${timeSlot[timeSlotKey].find((booking) => booking.day === day).booked} / ${timeSlot[timeSlotKey].find((booking) => booking.day === day).total}`}{" "}
                            {timeSlot[timeSlotKey].find((booking) => booking.day === day).total === "0"
                              ? "(0%)"
                              : `(${Math.round(
                                  calculatePercentage(
                                    timeSlot[timeSlotKey].find((booking) => booking.day === day).booked,
                                    timeSlot[timeSlotKey].find((booking) => booking.day === day).total
                                  )
                                )}%)`}
                          <div>Teachers: {timeSlot[timeSlotKey].find((booking) => booking.day === day).teachers}</div>
                        
                          </td>
                          

                          ) : null}
                          
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                  
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            No data found.
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
    list: details => dispatch(global.redux.action.teachers.list(details))
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherForecast);