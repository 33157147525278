import axios from 'axios'
import appEnvirontVariables from "../../core_units/config/env-variables"
import * as SecureLS from "secure-ls";
let ls = new SecureLS({ encodingType: 'aes' });
const loginDetails = ls.get('isLogin');
export const getMediaUrl = async (setLoading, setMediaUrl, senderId, fileName, token) => {
  setLoading(true)
  try {
    const config = {
      headers: { Authorization: token }
    }
    const response = await axios.post(`${appEnvirontVariables.API.GET_MEDIA_URL}/api/library/retrieve`, {
      userId: senderId,
      fileName: fileName
    }, config)
    // console.log(response.data.signedURL)
    setMediaUrl(response.data.signedURL)
  } catch(error) {
    console.log(error)
  }
  setLoading(false)
}

export const getUserInfo = async (id) => {
  const config = {
    headers: { Authorization: loginDetails['authorization'] }
  }
  let url = `${appEnvirontVariables.API.USER_INFO_URL}/api/user/fetchData/{id}`
  url = url.replace('{id}', id)
  const response = await axios.get(url,  config)
  // console.log(response, "userInfoResponse")
  return response
} 

export const getStudents = async (credentialId) => {
  const config = {
    headers: { Authorization: loginDetails['authorization'] }
  }
  let url = `${appEnvirontVariables.API.GET_STUDENTS_URL}/api/admin/newteacherdashboard/{credentialId}/enrolledStudents?studentType=PAID`
  url = url.replace('{credentialId}', credentialId)
  const response = await axios.get(url, config);
  // console.log(response.data)
  // console.log(Object.keys(response.data).length, `studentList for teacher ${credentialId}`)
  return response.data
}
