import React, { useState, Fragment, useEffect } from "react";
import { Form, Input, Button, DatePicker, Select, notification, Modal,Spin } from "antd";
import { connect } from "react-redux";
import "./styles.css";
import axios from "axios";
import * as SecureLS from "secure-ls";
import gmail from "../../../assets/img/Gmail.svg";
import { Col, Row } from 'antd';
import _ from "lodash";
import logo from '../../../assets/gif/aa_loader.gif';
import appEnvirontVariables from "../../../core_units/config/env-variables";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FormItem from "antd/es/form/FormItem";
const TeacherlistAirline = (props) => {
  console.log(props, "teacherprops");
  var ls = new SecureLS({ encodingType: "aes" });
  const [airlineTeacherDetails, setAirlineTeacherDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackList, setFeedbackList] = useState();
  const [optionsList, setOptionsList] = useState([]);
  const [optionListDetails, setOptionListDetails] =useState();
  const [feedbackDetails,setFeedbackDetails] = useState();
  const [form] = Form.useForm();
  const [timeDetails, setTimeDetails] =useState(false);
  const [courseList, setCourseList] = useState();
  const [courseOption,setCourseOption] = useState();
  const [timeOption,setTimeOption] = useState();
  const [languageOption,setLanguageOption] = useState();  
  const [fileCourse,setFileCourse] = useState("");
  const [fileDay,setFileDay] = useState("");
  const [file, setFile] = useState("");
  const [airlineSlotTrialData, setAirlineSlotTrialData] = useState([]);
  const [filterClassScheduleId, setFilterClassScheduleId] = useState([]);
  console.log(airlineTeacherDetails,airlineTeacherDetails.length, "airlineTeacherDetails");
  const token = ls.get("isLogin");
  const { Option } = Select;
  const { confirm } = Modal;
  const feedbackjson = () => {
    props.feedbacklist({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          setFeedbackList(data);
             const optionDetails = (data.teacherToStudentDemoFeedbackAirlineBooking).find((item) => item.id == 14)
             setOptionListDetails(optionDetails)
             console.log(optionDetails,"optionDetails==")
            console.log((data.teacherToStudentDemoFeedbackAirlineBooking).find((item) => item.id == 14),"pppppppp")
        }
      },
    });
  };
  const configjson = () => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          console.log("courselist", data);
          setCourseList(data);
        }
      },
    });
  };
  const onFileChange = (event) => {
    console.log(event, "eventooo====");
    setFile(event);
    console.log(event, "event");
  };
  const onCourseChange = (event) => {
    console.log(event, "eventooo====");
    setFileCourse(event);
    console.log(event, "event");
  };
  const onDayChange = (event) => {
    console.log(event, "eventooo====");
    setFileDay(event);
    console.log(event, "event");
  };
  const getAirlineSlotTrial = async () => {
 
     return axios.get(
        `${appEnvirontVariables.API.BASE_URL}/api/admin/studentclass/${props.profileDetails.studentId}?size=${100}&page=${0}&studentId=${props.profileDetails.studentId}&courseId=${props.selectedcourse.courseId}`,
        { headers: { Authorization: token["authorization"] } }
      ).then(res =>{
        setAirlineSlotTrialData(res.data.content);
        console.log(res.data.content,"lastElement")
        const filteredData = res.data.content.find(item => item.isAirlineBooking === 'true') || {};
        setFilterClassScheduleId(filteredData)
        console.log(filteredData, "Filtered class schedule IDs");
        if (filteredData  && filteredData.isAirlineBooking === "true") {
          // getTeacherForAirline();
          getTeacherFeedbacks(filteredData);          
        }
        feedbackjson();
      })
   
  };
  const getTeacherFeedbacks = async (filteredData) => {
    return axios.get(`${appEnvirontVariables.API.BASE_URL}/api/course/feedbacks?classId=${filteredData.classScheduleId}&userId=${filteredData.teacherCredentialId}`,
    { headers: { Authorization: token["authorization"] } }).then(res => {
      //getTeacherForAirline("course","time")
      if(res?.data && res.data.length){
        const fcourse = res.data[0].feedbacks.find(a => a.question == "What course do you recommend?").option;
        setCourseOption(fcourse)
        console.log(fcourse,"fcourse==")
        const ftime = res.data[0].feedbacks.find(a => (a.question == "Preferred Timing?" || a.question == "Preferred Day & Timing?")).option;
        setTimeOption(ftime)
        const flanguage = res.data[0].feedbacks.find(a => a.question == "Preferred Language?").option;
        setLanguageOption(flanguage)
        console.log(ftime,"ftime==")
      }
      
    setFeedbackDetails(res.data)
        console.log(res.data,"res.data")
    });
  }
  const getTeacherForAirline = async (course, time) => {
   let c = (course || fileCourse);
   let t = (time || file);
    setIsLoading(true)
    console.log(
      props.classScheduleIds.classScheduleId,
      "studentClassSchedule"
    );
    let reqUrl;
    if(filterClassScheduleId.classScheduleId){
      reqUrl = `classScheduleId=${filterClassScheduleId.classScheduleId}&studentId=${props.profileDetails.studentId}`;
    }else{
      reqUrl = `studentId=${props.profileDetails.studentId}`;
    }
    return axios
      .post(
        `${appEnvirontVariables.API.BASE_URL}/api/course/airline/teacherallocation?`+ reqUrl,
         {selectedTime: t,courseId: c,selectedDay: fileDay},
    
         { headers: { Authorization: token["authorization"] } }
      )
      .then((res) => {
        setIsLoading(false)
        console.log(res?.data?.length,"res?.data?.length==")
       if(res?.data?.length){
        setAirlineTeacherDetails(res.data);
       }else{
        setIsLoading(false)
        setAirlineTeacherDetails([]);
        notification.error({
          message: "No Teacher available",
        }
        )
       
        setTimeDetails(true);
       }
      
      }
       )
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFinish = (values) => {
    setIsLoading(true);
  
    confirm({
      title: "Do you want to do this action?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        getTeacherForAirline()
          .then((res) => {
            console.log(res, "res");
            if (res?.data) {
              onReset();
            } else {
              onReset();
            }
          })
          .catch((err) => {
            console.log("In errorr", err);
            notification.error({
              message: "Error updating Status",
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      },
      onCancel() {},
    });
  };
  
  
  useEffect(() => {
  
    console.log(props?.tabClickEvent,"props?.tabClickEvent")
   
    if(props?.tabClickEvent == "4"){
      getAirlineSlotTrial();
      // if (filterClassScheduleId.length > 0 && filterClassScheduleId[0].isAirlineBooking === "true") {
      //   // getTeacherForAirline();
      //   getTeacherFeedbacks();
      //   feedbackjson();
      // }
  }
  }, [props?.tabClickEvent]);
  useEffect(() => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          setCourseList(data);
        }
      },
    });
  }, []);
  const getUtilizationColor = (utilization) => {
    if (utilization >= 0 && utilization <= 50) {
      return "lightgreen"; 
    } else if (utilization > 50 && utilization <= 80) {
      return "orange"; 
    } else {
      return "red"; 
    }
  };
  return (
   <Form
   form={form}
   onFinish={onFinish}
   >
    <div className="payment-container"
     >
        {filterClassScheduleId.isAirlineBooking =="true" && 
      <Row style={{paddingBottom:'10px'}}>
        <Col span={6}><b>Recommended Course:</b></Col>
      <Col span={6}>{courseOption}</Col>
      <Col span={6}><b>Recommended Time:</b></Col>
      <Col span={6}>{timeOption}</Col>
      <Col span={6}><b>Preferred Language:</b></Col>
      <Col span={6}>{languageOption}</Col>
      </Row>
}
   
<div className="report-card">
    <div className="report-filter-conatiner">
        <span><b>Details</b></span>
        
            <div className="row" style={{"paddingTop": "10px"}}>
             
            {/* <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-6"> */}
            <Form.Item
              label="Course"
              name="courseId"
              style={{width:"auto"}}
              rules={[
                {
                  required: true,
                  message: "Please input your course!",
                },
              ]}
            >
               <Select placeholder="Select Course" onChange={onCourseChange}
              
               style={{width: "266px"}}
               >
                            {_.map(
                              _.get(courseList, "courses"),
                              (course, eachParent) => {
                                {
                                  return _.map(
                                    _.get(course, "tags"),
                                    (tag, each) => {
                                      console.log("tags", tag);
                                      console.log("course", course);
                                      return (
                                        <Option
                                          value={_.get(tag, "paidCourseId")}
                                          key={eachParent + "-" + each}
                                        >
                                          {_.get(course, "name")}-
                                          {_.get(tag, "name")}
                                        </Option>
                                      );
                                    }
                                  );
                                }
                              }
                            )}
                            
                          </Select>
            </Form.Item>
            {/* </div> */}
            <Form.Item
                          label="Custom Time"
                          name="selectedTime"
                          style={{width:"auto"}}
                          rules={[
                            {
                              required: true,
                              message: "Please Select the Time!",
                            },
                          ]}
                        >
                          <Select placeholder="Select Time" onChange={onFileChange}
                         style={{width: "266px"}}
                    >
                           
                          {_.map(
                        _.get(optionListDetails, "options"),
                        (options, each) => {
                          console.log("options", options);
                          return (
                            <Option
                              value={_.get(options, "optionname") + ""}
                              key={each}
                            >
                              {_.get(options, "optionname")}
                            </Option>
                          );
                        }
                      )}
                          </Select>
                        </Form.Item>
            <Form.Item
            label="Day"
                          className=""
                          name="selectedDay"
                          style={{width:"auto"}}
                          rules={[
                            {
                              required: true,
                              message: "Please input your Day!",
                            },
                          ]}
                        >
                           <Select
                          onChange={onDayChange}
                          style={{width: "266px"}}
                dropdownClassName="course-list-dropdown"
                placeholder="Select Day"
              >
                    <Option value="MONDAY"
                    >
                      Monday
                    </Option>
                    <Option value="TUESDAY">Tuesday</Option>
                    <Option value="WEDNESDAY">Wednesday</Option>
                    <Option value="THURSDAY">Thursday</Option>
                    <Option value="FRIDAY">Friday</Option>
                    <Option value="SATURDAY">Saturday</Option>
                    <Option value="SUNDAY">Sunday</Option>
              </Select>
                        </Form.Item>
                       
            
            <Form.Item
            
            >
                    
            
              <Button className="optionbtn" htmlType="submit">Submit</Button>
                </Form.Item>
          </div>
          </div>
          </div>

{airlineTeacherDetails.length > 0 && 
<div className='calendar-legendset-conatiner' style={{marginLeft:"-1px"}}>
                    <div className='calendar-legend-set'>
                        <span className='legend-square' style={{ backgroundColor: 'lightgreen' }}>
                        </span>
                        <span className='legend-text'>
                            0-50 &emsp;
                        </span>
                        <span className='legend-square' style={{ backgroundColor: 'orange' }}>
                        </span>
                        <span className='legend-text'>
                            50-80 &emsp;
                        </span>
                        <span className='legend-square' style={{ backgroundColor: 'red' }}>

                        </span>
                        <span className='legend-text'>
                            80 above &emsp;
                        </span>

                       
                    
                    </div>
                </div>
}

    {isLoading ? (
      <div style={{ textAlign: "center" }}>
        <Spin size="large" tip="Loading..." style={{ textAlign: "center" }} />
      </div>
      ) : (
        airlineTeacherDetails.length > 0 ? (
<div>

      <table className="table table-bordered table-hover" id="tab_logic">
        <thead>
          {console.log(airlineTeacherDetails.length, "$$$$$$$")}
        {airlineTeacherDetails.length > 0 && 
          <tr>
            {/* <th className="text-center">S.No</th> */}
            <th className="text-center">Teacher Name</th>
            <th className="text-center">Profile Link</th>
            <th className="text-center">No.of Active Students</th>
            <th className="text-center">Total No.Of Students</th>
            <th className="text-center">update</th>
          </tr>
}
        </thead>
        <tbody>
          {airlineTeacherDetails &&
            airlineTeacherDetails.map((item, idx) => (
              <tr>
                {/* <td></td> */}
                <td style={{ backgroundColor: getUtilizationColor(item.utilization) }}>
            {item.userDetails.firstName}
          </td>
                <td>
                  <a href={item.photolinkdetails.videolink} target={"_blank"}>
                    {item.photolinkdetails.videolink}
                  </a>
                </td>
                <td style={{ textAlign: "center" }}>
                  {item.activeStudentCount}
                </td>
                <td style={{ textAlign: "center" }}>{item.paidStudentCount? item.paidStudentCount:0}</td>
                <td style={{ textAlign: "center" }}>
                  <div className="sociallink">
                    <div>
                      <img data-key={idx} src={gmail} />
                    </div>
                  </div>
                </td>
              </tr>
            ))
            }
        </tbody>
      </table>
      
      </div>
) : (
  <div style={{ textAlign: "center" }}></div>
))}
        {filterClassScheduleId.isAirlineBooking =="false" &&
          <div className="info"><b>No AE Class Available</b></div>
        }
        
        
{/* {isLoading &&
      <div className="loadbtn">
          <img src={logo} alt="loading..." />
        </div>} */}
    </div>
    
    </Form>
   
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  paymentlink: (details) =>
    dispatch(global.redux.action.payment.paymentlink(details)),
  studentclass: (details) =>
    dispatch(global.redux.action.students.classdetails(details)),
    courseList: details => dispatch(global.redux.action.students.courseList(details)),
    feedbacklist: (details) =>
    dispatch(global.redux.action.students.feedbackList(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TeacherlistAirline);
