import React, { useEffect, useState } from "react";
import "./teacher-profile-style.css";
import { useSearchParams, useLocation } from "react-router-dom";
import Profile from "../view-details/index";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Form,
  Input,
  Button,
  Upload,
  message,
  InputNumber,
  Checkbox,
  Select,
  DatePicker,
  Radio,
  notification,
} from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";

const { Option } = Select;
const dateformat = "DD/MM/YYYY";
const AddTeacherProfilePage = (props) => {
  const [form] = Form.useForm();
  let history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [courseList, setCourseList] = useState();
  const [languageslist, setlanguageslist] = useState();
  const [teacherId] = useSearchParams();
  const [agegroups, setagegroups] = useState();
  const [dob,setDob] = useState();
  const [disableDomestic,setDisableDomestic] = useState(false);
  const [disableInternational,setDisableInternational] = useState(false);

  //api call
  useEffect(() => {
    configjson();
  }, []);

  const handleClick= (ev)=>{ 
console.log("ev", ev)
    // const [isDisabled, setIsDisabled] = useState(false);
  
    
      
    //   if(true) {
    //     setIsDisabled(!isDisabled);
    //   }
    //   if(false) {
    //     setISEnabled(!isEnabled);
    //   }

    };

    const onlyDomestic= (ev)=>{ 
       console.log('onlyDomestic', ev);
       if(ev.target.value){
        form.setFieldsValue({isOnlyInternational : false})        
       }
       setDisableInternational(ev.target.value);
    }
    const onlyInternational= (ev)=>{ 
      console.log('onlyInternational', ev);
      if(ev.target.value){
        form.setFieldsValue({isOnlydomestic : false})       
       }
       setDisableDomestic(ev.target.value);
    }
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImageUrl(imageUrl),
        setLoading(false)
      );
    }
  };
  const configjson = () => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          console.log("courselist", data);
          setCourseList(data);
        }
      },
    });
  };
  const agegrouplist = () => {
    props.getagroups({
      params: {
        teacherId: teacherId.get("id"),
      },
      callback: (response, data) => {
        setagegroups(data);
        // form.setFieldsValue({
        //     course: _.uniqBy(_.map(_.get(data, 'courses'), 'name') )
        // })
      },
    });
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  useEffect(() => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          setCourseList(data);
        }
      },
    });
  }, []);
  const languagelist = () => {
    props.getlanguages({
      params: {
        teacherId: teacherId.get("id"),
      },
      callback: (response, data) => {
        setlanguageslist(_.get(data, "courses"));
        _.map(_.get(data, "courses"), "name");
        form.setFieldsValue({
          course: _.uniqBy(_.map(_.get(data, "courses"), "name")),
        });
      },
    });
  };

  const onFinish = (values) => {
    let req = {
      ...values,
      permanentAddressCountryName: _.get(values, "currentAddressCountryName"),
      permanentAddressStateName: _.get(values, "currentAddressStateName"),
      permanentAddressCityName: _.get(values, "currentAddressCityName"),
      permanentAddressDistrictName: _.get(values, "currentAddressDistrictName"),
      permanentAddressLocality: _.get(values, "currentAddressLocality"),
      permanentAddressPincode: _.get(values, "currentAddressPincode"),
      birthDate: dob,
      profilePhoto: imageUrl,
      locale: "en_US",
      isLoginEnabled: true,
      lastLoginTime: "",
      passwordChangedOn: "",
      passwordChangeCount: "2",
      isEmailIdVerified: true,
      isMobileNumberVerified: true,
      otpCode: "",
      referralCode: "",
      referredBy: "",
      readynessForTraining: _.get(values, "readynessForTraining") ?? true,
      yearsOfExperience: _.get(values, "yearsOfExperience") ?? "2",
      yearsOfOnlineExperience: _.get(values, "yearsOfExperience") ?? "2",
      currentStudentsCount: "2",
      isKidsFriendly: _.get(values, "isKidsFriendly") ?? true,
      isAcademicExpert: _.get(values, "isAcademicExpert") ?? true,
isBatchOffline: _.get(values, "isBatchOffline") ?? true,
isBatchOnline: _.get(values, "isBatchOnline") ?? true,
is1to1Enabled: _.get(values, "is1to1Enabled") ?? true,
      mobileNumber: _.get(values, "mobileNumber") ?? "",
      ageGroupId: _.get(values, "ageGroupId") ?? "",
      gender: _.get(values, "gender") ??  null,
      languageId: _.get(values, "languageId") ?? "",
      courseId: _.get(values, "courseId") ?? "",
      birthPlaceName: _.get(values, "birthPlaceName") ?? "",
      currentAddressDetails: _.get(values, "currentAddressDetails") ?? "",
      currentAddressLocality: _.get(values, " currentAddressLocality") ?? "",
      currentAddressCityName: _.get(values, "currentAddressCityName") ?? "",
      currentAddressDistrictName:
        _.get(values, "currentAddressDistrictName") ?? "",
      currentAddressStateName: _.get(values, "currentAddressStateName") ?? "",
      currentAddressCountryName:
        _.get(values, "currentAddressCountryName") ?? "India",
      currentAddressPincode: _.get(values, "currentAddressPincode") ?? "679531",
      isPermanentAddressSameAsCurrentAddress:
        _.get(values, "isPermanentAddressSameAsCurrentAddress") ?? false,
      permanentAddressDetails: _.get(values, "permanentAddressDetails") ?? "",
      permanentAddressLocality: _.get(values, "permanentAddressLocality") ?? "",
      permanentAddressCityName: _.get(values, "permanentAddressCityName") ?? "",
      permanentAddressDistrictName:
        _.get(values, "permanentAddressDistrictName") ?? "",
      permanentAddressStateName:
        _.get(values, "permanentAddressStateName") ?? "",
      permanentAddressCountryName:
        _.get(values, "permanentAddressCountryName") ?? "India",
      permanentAddressPincode:
        _.get(values, "permanentAddressPincode") ?? "679531",
        state:_.get(values, "state") ?? "",
        preferredGender: values.preferredGender =="Both" ? null: _.get(values, "preferredGender"),
       
        
    };

    props.add({
      body: req,
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          history("/teachers");
          notification.success({
            message: "Profile is added successfully.",
          });
        } else {
          notification.error({
            message: "Something went wrong.",
          });
        }
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onReset = () => {
    form.resetFields();
  };

  const render = () => {
    // console.log(_.get(props, "teachers.viewteacherprofile.data.firstName"));
    // console.log("form", form.getFieldsValue());
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload Photo</div>
      </div>
    );
    return (
      <div className="teacher-profile-container">
        <div>{/* <Profile /> */}</div>
        <div>
          <div className="card mb-5 mb-xl-10">
            {/* <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                            <div className="card-title m-0">
                                <h3 className="fw-bolder m-0">Teacher Profile Details</h3>
                            </div>
                        </div> */}

            <div
              id="kt_account_settings_profile_details"
              className="collapse show"
            >
              <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div id="kt_account_profile_details_form" className="form">
                  <div className="card-body border-top p-9">
                    <div className="row mb-6">
                      <div className="col-lg-8">
                        <Form.Item
                          name="profilePhoto"
                          rules={[
                            {
                              required: false,
                              message: "Please input your image!",
                            },
                          ]}
                        >
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            accept="image/*"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={(e) => handleChange(e)}
                            customRequest={({ file, onSuccess }) => {
                              setTimeout(() => {
                                onSuccess("ok");
                              }, 0);
                            }}
                          >
                            {imageUrl ? (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Title
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"titleName"}
                          rules={[]}
                        >
                          <Select
                            style={{ width: 420 }}
                            placeholder="Select Title"
                          >
                            <Option value="Mr">Mr.</Option>
                            <Option value="Miss">Miss.</Option>
                            <Option value="Mrs">Mrs.</Option>
                            <Option value="Others">Others</Option>
                          </Select>
                        </Form.Item>{" "}
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        First Name  <span className="validationsign">*</span>
                      </div>

                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"firstName"}
                          // label="Email"
                          rules={[{ required: true
                            
                          }]}
                          onKeyPress={(e) =>
                            !/[a-zA-Z]/.test(e.key) && e.preventDefault()
                          }
                        >
                          <Input placeholder="Enter Your First Name " />
                        </Form.Item>{" "}
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Middle Name 
                      </div>

                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"middleName"}
                          // label="Email"
                          rules={[
                            {
                              required: false

                              
                            },
                          ]}
                          onKeyPress={(e) =>
                            !/[a-zA-Z]/.test(e.key) && e.preventDefault()
                          }
                        >
                          <Input placeholder="Enter Your Middle Name" />
                        </Form.Item>{" "}
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Last Name  <span className="validationsign">*</span>
                      </div>

                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"lastName"}
                          // label="Email"
                          rules={[
                            {
                              required: false

                              
                            },
                          ]}
                          onKeyPress={(e) =>
                            !/[a-zA-Z]/.test(e.key) && e.preventDefault()
                          }
                        >
                          <Input placeholder="Enter Your Last Name"/>
                        </Form.Item>{" "}
                      </div>
                    </div>

                    <div className="row mb-6 mt-2">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Email  <span className="validationsign">*</span>
                      </div>

                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"emailId"}
                          // label="Email"
                          rules={[
                            {
                              required: true,
                              type: "email",
                            },
                          ]}
                        >
                          <Input placeholder="Enter valid EmailId " />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Password  <span className="validationsign">*</span>
                      </div>

                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"password"}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input placeholder="Enter Your Password" />
                        </Form.Item>{" "}
                      </div>
                    </div>

                    <div className="row mb-6 mt-2">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Country Code  <span className="validationsign">*</span></span>
                      </div>
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-lg-4 fv-row">
                            <Form.Item
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              name="countryCode"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Select
                                style={{ width: 100 }}
                                placeholder="Select CountryCode"
                              >
                                {_.map(
                                  _.get(courseList, "countryCodes"),
                                  (countrycodes, each) => {
                                    console.log("countrycodes", countrycodes);
                                    return (
                                      <Option
                                        value={_.get(
                                          countrycodes,
                                          "countryCode"
                                        )}
                                        key={each}
                                      >
                                        {_.get(countrycodes, "countryCode")}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                            </Form.Item>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Mobile Number  <span className="validationsign">*</span>
                      </div>

                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"mobileNumber"}
                          rules={[{ required: true }]}
                        >
                          <Input
                            style={{ width: 260 }}
                            onKeyPress={(e) =>
                              !/[0-9]/.test(e.key) && e.preventDefault()
                            }
                            placeholder="Enter Your Mobile Number"
                          />
                        </Form.Item>{" "}
                      </div>
                    </div>

                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Gender  <span className="validationsign">*</span></span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"gender"}
                          rules={[{ required: true }]}
                        >
                          <Select
                            style={{ width: 420 }}
                            placeholder="Select Gender"
                          >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                            <Option value="Other">Other</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    {/* <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Age group</span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"ageGroupId"}
                          rules={[]}
                        >
                          <Select>
                            <Option value="6-12">6-12</Option>
                            <Option value="13-21">13-21</Option>
                            <Option value="22-30">22-30</Option>
                            <Option value="31-40">31-40</Option>
                            <Option value="40+">40+</Option>
                          </Select>
                        </Form.Item>
                      </div> </div>  */}
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Preferred agegroup  <span className="validationsign">*</span></span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"ageGroupId"}
                          rules={[{ required: true }]}
                        >
                          <Select mode="multiple" placeholder="Select Agegroup">
                            {_.map(
                              _.get(courseList, "ageGroups"),
                              (agegroups, each) => {
                                console.log("agegroups", agegroups);
                                return (
                                  <Option
                                    value={_.get(agegroups, "id") + ""}
                                    key={each}
                                  >
                                    {_.get(agegroups, "name")}
                                  </Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Date of Birth  <span className="validationsign">*</span></span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"birthDate"}
                          
                          rules={[]}
                        >
                          <DatePicker
                            format={dateformat}
                            onChange = {(date) => setDob(dayjs(date).format("DD/MM/YYYY")
                            )}
                            style={{ width: 420 }}
                          />{" "}
                        </Form.Item>
                      </div>
                    </div>

                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Academic Qualifications  <span className="validationsign">*</span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"academicQualifications"}
                          rules={[{ required: true }]}
                        >
                          <Input placeholder="Enter Your Academic Qualifications" />
                        </Form.Item>{" "}
                      </div>
                    </div>
                    <div className="row mb-6">
                                            <div className="col-lg-4 col-form-label fw-bold fs-6">
                                                <span className="required">State <span className="validationsign">*</span></span>
                                            </div>
                                            <div className="col-lg-6 fv-row">
                                                <Form.Item
                                                    className="form-control form-control-lg form-control-solid"
                                                    name={'state'}
                                                    rules={[
                                                    ]}
                                                >
                                                    <Select placeholder="Select State">
                                                    {courseList?.states?.length && courseList?.states.map(state => {
                                                        return <Option value={state}>{state}</Option>
                                                    })}
                                                        
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div> 
                    
                                        <div className="row mb-6">
                                            <div className="col-lg-4 col-form-label fw-bold fs-6">
                                                <span className="required">Time zone <span className="validationsign">*</span></span>
                                            </div>
                                            <div className="col-lg-6 fv-row">
                                                <Form.Item
                                                    className="form-control form-control-lg form-control-solid"
                                                    name={'timezone'}
                                                    rules={[
                                                    ]}
                                                >
                                                    <Select placeholder="Select Timezone">
                                                    {courseList?.timezones?.length && courseList?.timezones.map(timezone => {
                                                        return <Option value={timezone.type}>{`${timezone.type} ${timezone.name}`}</Option>

                                                    })}
                                                        
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Only International <span className="validationsign">*</span>
                      </div>

                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"isOnlyInternational"}
                          rules={[{ required: true 
                          
                          }]}
                        >
                          <Radio.Group disabled={disableInternational} className="click" onChange={(event)=>onlyInternational(event)}>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>false</Radio>
                          </Radio.Group>
                        </Form.Item>{" "}
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Only Domestic <span className="validationsign">*</span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"isOnlydomestic"}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group disabled={disableDomestic} onChange={(event)=>onlyDomestic(event)}>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>false</Radio>
                          </Radio.Group>
                        </Form.Item>{" "}
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Photo Link  <span className="validationsign">*</span>
                      </div>

                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"Teacherphotolink"}
                          rules={[{ required: true }]}
                        >
                          <Input placeholder="Enter Valid PhotoLink" />
                        </Form.Item>{" "}
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Video Link  <span className="validationsign">*</span>
                      </div>

                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"TeacherVideoLink"}
                          rules={[{ required: true }]}
                        >
                          <Input placeholder="Enter valid VideoLink" />
                        </Form.Item>{" "}
                      </div>
                    </div>

                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Marked For Offers  <span className="validationsign">*</span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"isMarkedForOffers"}
                          rules={[]}
                        >
                          <Radio.Group>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                          </Radio.Group>
                        </Form.Item>{" "}
                      </div>
                    </div>

                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                       Employment Type  <span className="validationsign">*</span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"employmentType"}
                          rules={[]}
                        >
                          <Radio.Group>
                            <Radio value="full_time">Full Time</Radio>
                            <Radio value="part_time">Part Time</Radio>
                          </Radio.Group>
                        </Form.Item>{" "}
                      </div>
                    </div>
                    



                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Years Of Experience  <span className="validationsign">*</span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"yearsOfExperience"}
                          rules={[{ required: true }]}
                        >
                          <Input
                            style={{ width: 260 }}
                            onKeyPress={(e) =>
                              !/[0-9]/.test(e.key) && e.preventDefault()
                            }
                            placeholder="Enter Your Year Of Experience"
                          />
                        </Form.Item>{" "}
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Preferred Gender  <span className="validationsign">*</span></span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"preferredGender"}
                          rules={[]}
                        >
                          <Select
                            style={{ width: 420 }}
                            placeholder="Select Gender"
                          >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                            <Option value="Both">Both</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Kids Friendly</span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"isKidsFriendly"}
                          rules={[]}
                        >
                          <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Is Academic Expert</span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"isAcademicExpert"}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Eligible for Offline Batches</span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"isBatchOffline"}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Eligible for Online Batches</span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"isBatchOnline"}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Eligible for 1:1 classes</span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"is1to1Enabled"}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Preferred language  <span className="validationsign">*</span></span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"languageId"}
                          rules={[{ required: true }]}
                        >
                          <Select mode="multiple" placeholder="Select Language">
                            {_.map(
                              _.get(courseList, "languages"),
                              (languages, each) => {
                                console.log("languages", languages);
                                return (
                                  <Option
                                    value={_.get(languages, "id") + ""}
                                    key={each}
                                  >
                                    {_.get(languages, "name")}
                                  </Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Courses  <span className="validationsign">*</span></span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"courseId"}
                          style={{ height: "auto" }}
                          rules={[{ required: true }]}
                        >
                          <Select mode="multiple" placeholder="Select Course">
                            {_.map(
                              _.get(courseList, "courses"),
                              (course, eachParent) => {
                                {
                                  return _.map(
                                    _.get(course, "tags"),
                                    (tag, each) => {
                                      console.log("tags", tag);
                                      console.log("course", course);
                                      return (
                                        <Option
                                          value={_.get(tag, "demoCourseId")}
                                          key={eachParent + "-" + each}
                                        >
                                          {_.get(course, "name")}-
                                          {_.get(tag, "name")}-{"Demo"}
                                        </Option>
                                      );
                                    }
                                  );
                                }
                              }
                            )}
                            {_.map(
                              _.get(courseList, "courses"),
                              (course, eachParent) => {
                                {
                                  return _.map(
                                    _.get(course, "tags"),
                                    (tag, each) => {
                                      console.log("tags", tag);
                                      console.log("course", course);
                                      return (
                                        <Option
                                          value={_.get(tag, "paidCourseId")}
                                          key={eachParent + "-" + each}
                                        >
                                          {_.get(course, "name")}-
                                          {_.get(tag, "name")}-{"Paid"}
                                        </Option>
                                      );
                                    }
                                  );
                                }
                              }
                            )}
                            {courseList && courseList.offlineCourseCentres && courseList.offlineCourseCentres.map((offlineCourseCentre, index1) => (
 
 offlineCourseCentre.courses.map((course, index2) => {
    
      {
        return _.map(
          _.get(course, "tags"),
          (tag, each) => {
            //console.log("tags", tag);
            //console.log("course", course);
            return (
              <Option
                value={_.get(tag, "paidCourseId")}
                key={index2 + "-" + each}
              >
                {_.get(course, "name")}-
                {_.get(tag, "name")}
              </Option>
            );
          }
        );
      }
  })
 
))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Description  <span className="validationsign">*</span>
                      </div>

                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"description"}
                          rules={[{ required: true }]}
                        >
                          <Input placeholder="Enter Your Description" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <Form.Item>
                      <Button
                        htmlType="button"
                        className="btn btn-light btn-active-light-primary mr-2"
                        onClick={onReset}
                      >
                        Discard
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn btn-primary"
                        id="kt_account_profile_details_submit"
                      >
                        Save
                      </Button>
                    </Form.Item>
                    {/* <button type="reset" className="btn btn-light btn-active-light-primary me-2">Discard</button>
      <button type="submit" className="btn btn-primary" id="kt_account_profile_details_submit">Save Changes</button> */}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) =>
  // console.log("state", state),
  ({
    teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
  });

const mapDispatchToProps = (dispatch) => ({
  add: (details) =>
    dispatch(global.redux.action.teachers.addteacherprofile(details)),
  courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTeacherProfilePage);

//birthplace name, address, referred by, ready for training

