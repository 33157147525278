import React, { useState, useEffect } from "react";
import appEnvirontVariables from "../../../core_units/config/env-variables";
import { useSearchParams, useLocation, useNavigate} from "react-router-dom";
import {
  Form,
  DatePicker,
  Button,
  Table,
  Spin,
  Tag,
  Select,
  Modal,
  Tooltip,
} from "antd";
import * as SecureLS from "secure-ls";
import axios from 'axios';
import airplane from '../../../assets/img/airplane.svg'
import home from '../../../assets/img/home.svg'
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
function DateView(props) {
  const { state } = props;
  let history = useNavigate();
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const location = useLocation();
  const date = location.search.replace("?date=", "");
  const currentDate = new Date().setHours(0, 0, 0, 0);
  const comparisonDate = new Date(date).setHours(0, 0, 0, 0);
  const isPastDate = comparisonDate < currentDate;
  console.log(date);
  const [tableData, setTableData] = useState([]);
  const [northCategoryData, setNorthCategoryData] = useState([]);
  const [southCategoryData, setSouthCategoryData] = useState([]);
  const [pianoCategoryData, setPianoCategoryData] = useState([]);
  const [guitarCategoryData, setGuitarCategoryData] = useState([]);
  const [tablaCategoryData, setTablaCategoryData] = useState([]);
  const [westernSouthCategoryData, setWesternSouthCategoryData] = useState([]);
  const [westernNorthCategoryData, setWesternNorthCategoryData] = useState([]);
  const [northTeacherName,setNorthTeacherName] = useState([]);
  const [southTeacherName,setSouthTeacherName] = useState([]);
  const [pianoTeacherName,setPianoTeacherName] = useState([]);
  const [guitarTeacherName,setGuitarTeacherName] = useState([]);
  const [tablaTeacherName,setTablaTeacherName] = useState([]);
  const [westernSouthTeacherName,setWesternSouthTeacherName] = useState([]);
  const [westernNorthTeacherName,setWesternNorthTeacherName] = useState([]);
  const [currentDates, setCurrentDates] = useState(" ");
 
  console.log(props, "props==");
  useEffect(() => {
    
    const fetchTableData = async () => {
      try {
        const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/ae/dayview`;
        const response = await axios.get(apiUrl, {
          params: {
            date: date
          },
          headers: {
            Authorization: token["authorization"]
          }
        });
        const responseData = response.data;
          console.log(responseData);
          if(responseData){
            if (responseData['Vocal-North']) {
              const northRespData = responseData['Vocal-North'];
              console.log(responseData['Vocal-North'], '5555');
            
              const mappedNorthRespData = categoryTimings.map(timing => {
                const matchingData = northRespData.find(
                  data => data.startTime.trim() === timing.trim()
                );
                return matchingData ? matchingData : '-';
              });
            
              const teacherNames = {}; 
    for (const data of northRespData) {
      const { startTime, teacherName, isSlotUsed } = data;
      if (!teacherNames[startTime]) {
        teacherNames[startTime] = [];
      }
      teacherNames[startTime].push({ teacherName, isSlotUsed });
    }
  
    console.log(mappedNorthRespData, 'mappedNorthRespData');
    console.log(teacherNames, 'teacherNames');
  
    setNorthCategoryData(mappedNorthRespData);
    setNorthTeacherName(teacherNames);
    console.log(teacherNames, 'separateTeacherNames');
  } else {
    setNorthCategoryData(Array(42).fill('-'));
  }
            
            if(responseData['Vocal-South']){
              const southRespData = responseData["Vocal-South"];
              const mappedSouthRespData = categoryTimings.map(timing => {
                const matchingData = southRespData.find(
                  data => data.startTime.trim() === timing.trim()
                );
                return matchingData ? matchingData : "-";
              });
              const teacherNames = {}; 
              for (const data of southRespData) {
                const { startTime, teacherName, isSlotUsed } = data;
                if (!teacherNames[startTime]) {
                  teacherNames[startTime] = [];
                }
                teacherNames[startTime].push({ teacherName, isSlotUsed });
              }
              setSouthCategoryData(mappedSouthRespData);
              setSouthTeacherName(teacherNames);
            }
            else{
              setSouthCategoryData(Array(42).fill('-'))
            }
          
            if(responseData['Instrument-Piano']){
              const pianoRespData = responseData["Instrument-Piano"];
              const mappedPianoRespData = categoryTimings.map(timing => {
                const matchingData = pianoRespData.find(
                  data => data.startTime.trim() === timing.trim()
                );
                return matchingData ? matchingData : "-";
              });
              const teacherNames = {}; 
              for (const data of pianoRespData) {
                const { startTime, teacherName, isSlotUsed } = data;
                if (!teacherNames[startTime]) {
                  teacherNames[startTime] = [];
                }
                teacherNames[startTime].push({ teacherName, isSlotUsed });
              }
              setPianoCategoryData(mappedPianoRespData);
              setPianoTeacherName(teacherNames);
            }
            else{
              setPianoCategoryData(Array(42).fill('-'))
            }
            if(responseData['Instrument-Guitar']){
              const guitarRespData = responseData["Instrument-Guitar"];
              const mappedGuitarRespData = categoryTimings.map(timing => {
                const matchingData = guitarRespData.find(
                  data => data.startTime.trim() === timing.trim()
                );
                return matchingData ? matchingData : "-";
              });
              const teacherNames = {}; 
              for (const data of guitarRespData) {
                const { startTime, teacherName, isSlotUsed } = data;
                if (!teacherNames[startTime]) {
                  teacherNames[startTime] = [];
                }
                teacherNames[startTime].push({ teacherName, isSlotUsed });
              }
              setGuitarCategoryData(mappedGuitarRespData);
              setGuitarTeacherName(teacherNames);
            }
            else{
              setGuitarCategoryData(Array(42).fill('-'))
            }
            if(responseData['Instrument-Tabla']){
              const tablaRespData = responseData["Instrument-Tabla"];
              const mappedTablaRespData = categoryTimings.map(timing => {
                const matchingData = tablaRespData.find(
                  data => data.startTime.trim() === timing.trim()
                );
                return matchingData ? matchingData : "-";
              });
              const teacherNames = {}; 
              for (const data of tablaRespData) {
                const { startTime, teacherName, isSlotUsed } = data;
                if (!teacherNames[startTime]) {
                  teacherNames[startTime] = [];
                }
                teacherNames[startTime].push({ teacherName, isSlotUsed });
              }
              setTablaCategoryData(mappedTablaRespData);
              setTablaTeacherName(teacherNames);
            }
            else{
              setTablaCategoryData(Array(42).fill('-'))
            }
            if(responseData['Vocal Western-North']){
              const westernNorthRespData = responseData["Vocal Western-North"];
              const mappedWesternNorthRespData = categoryTimings.map(timing => {
                const matchingData = westernNorthRespData.find(
                  data => data.startTime.trim() === timing.trim()
                );
                return matchingData ? matchingData : "-";
              });
              const teacherNames = {}; 
              for (const data of westernNorthRespData) {
                const { startTime, teacherName, isSlotUsed } = data;
                if (!teacherNames[startTime]) {
                  teacherNames[startTime] = [];
                }
                teacherNames[startTime].push({ teacherName, isSlotUsed });
              }
              setWesternSouthCategoryData(mappedWesternNorthRespData);
              setWesternSouthTeacherName(teacherNames);
            }
            else{
              setWesternSouthCategoryData(Array(42).fill('-'))
            }
            if(responseData['Vocal Western-South']){
              const westernSouthRespData = responseData["Vocal Western-South"];
              const mappedWesternSouthRespData = categoryTimings.map(timing => {
                const matchingData = westernSouthRespData.find(
                  data => data.startTime.trim() === timing.trim()
                );
                return matchingData ? matchingData : "-";
              });
              const teacherNames = {}; 
              for (const data of westernSouthRespData) {
                const { startTime, teacherName, isSlotUsed } = data;
                if (!teacherNames[startTime]) {
                  teacherNames[startTime] = [];
                }
                teacherNames[startTime].push({ teacherName, isSlotUsed });
              }
              setWesternNorthCategoryData(mappedWesternSouthRespData);
              setWesternNorthTeacherName(teacherNames);
            }
  else{
    setWesternNorthCategoryData(Array(42).fill('-'))
  }
          }
        } catch (error) {
          console.error("Error fetching table data:", error);
        }
      };
    


  fetchTableData();

   
  
    
  }, [date]);




  const fetchDataForDate = async (date) => {
  try {
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/ae/dayview?date=${date}`;

    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: token["authorization"]
      }
    });

    const responseData = response.data;
      if(responseData){
        if (responseData['Vocal-North']) {
          const northRespData = responseData['Vocal-North'];
          console.log(responseData['Vocal-North'], '5555');
        
          const mappedNorthRespData = categoryTimings.map(timing => {
            const matchingData = northRespData.find(
              data => data.startTime.trim() === timing.trim()
            );
            return matchingData ? matchingData : '-';
          });
        
          const teacherNames = {}; 
for (const data of northRespData) {
  const { startTime, teacherName, isSlotUsed } = data;
  if (!teacherNames[startTime]) {
    teacherNames[startTime] = [];
  }
  teacherNames[startTime].push({ teacherName, isSlotUsed });
}

console.log(mappedNorthRespData, 'mappedNorthRespData');
console.log(teacherNames, 'teacherNames');

setNorthCategoryData(mappedNorthRespData);
setNorthTeacherName(teacherNames);
console.log(teacherNames, 'separateTeacherNames');
} else {
setNorthCategoryData(Array(42).fill('-'));
}
        
        if(responseData['Vocal-South']){
          const southRespData = responseData["Vocal-South"];
          const mappedSouthRespData = categoryTimings.map(timing => {
            const matchingData = southRespData.find(
              data => data.startTime.trim() === timing.trim()
            );
            return matchingData ? matchingData : "-";
          });
          const teacherNames = {}; 
          for (const data of southRespData) {
            const { startTime, teacherName, isSlotUsed } = data;
            if (!teacherNames[startTime]) {
              teacherNames[startTime] = [];
            }
            teacherNames[startTime].push({ teacherName, isSlotUsed });
          }
          setSouthCategoryData(mappedSouthRespData);
          setSouthTeacherName(teacherNames);
        }
        else{
          setSouthCategoryData(Array(42).fill('-'))
        }
      
        if(responseData['Instrument-Piano']){
          const pianoRespData = responseData["Instrument-Piano"];
          const mappedPianoRespData = categoryTimings.map(timing => {
            const matchingData = pianoRespData.find(
              data => data.startTime.trim() === timing.trim()
            );
            return matchingData ? matchingData : "-";
          });
          const teacherNames = {}; 
          for (const data of pianoRespData) {
            const { startTime, teacherName, isSlotUsed } = data;
            if (!teacherNames[startTime]) {
              teacherNames[startTime] = [];
            }
            teacherNames[startTime].push({ teacherName, isSlotUsed });
          }
          setPianoCategoryData(mappedPianoRespData);
          setPianoTeacherName(teacherNames);
        }
        else{
          setPianoCategoryData(Array(42).fill('-'))
        }
        if(responseData['Instrument-Guitar']){
          const guitarRespData = responseData["Instrument-Guitar"];
          const mappedGuitarRespData = categoryTimings.map(timing => {
            const matchingData = guitarRespData.find(
              data => data.startTime.trim() === timing.trim()
            );
            return matchingData ? matchingData : "-";
          });
          const teacherNames = {}; 
          for (const data of guitarRespData) {
            const { startTime, teacherName, isSlotUsed } = data;
            if (!teacherNames[startTime]) {
              teacherNames[startTime] = [];
            }
            teacherNames[startTime].push({ teacherName, isSlotUsed });
          }
          setGuitarCategoryData(mappedGuitarRespData);
          setGuitarTeacherName(teacherNames);
        }
        else{
          setGuitarCategoryData(Array(42).fill('-'))
        }
        if(responseData['Instrument-Tabla']){
          const tablaRespData = responseData["Instrument-Tabla"];
          const mappedTablaRespData = categoryTimings.map(timing => {
            const matchingData = tablaRespData.find(
              data => data.startTime.trim() === timing.trim()
            );
            return matchingData ? matchingData : "-";
          });
          const teacherNames = {}; 
          for (const data of tablaRespData) {
            const { startTime, teacherName, isSlotUsed } = data;
            if (!teacherNames[startTime]) {
              teacherNames[startTime] = [];
            }
            teacherNames[startTime].push({ teacherName, isSlotUsed });
          }
          setTablaCategoryData(mappedTablaRespData);
          setTablaTeacherName(teacherNames);
        }
        else{
          setTablaCategoryData(Array(42).fill('-'))
        }
        if(responseData['Vocal Western-North']){
          const westernNorthRespData = responseData["Vocal Western-North"];
          const mappedWesternNorthRespData = categoryTimings.map(timing => {
            const matchingData = westernNorthRespData.find(
              data => data.startTime.trim() === timing.trim()
            );
            return matchingData ? matchingData : "-";
          });
          const teacherNames = {}; 
          for (const data of westernNorthRespData) {
            const { startTime, teacherName, isSlotUsed } = data;
            if (!teacherNames[startTime]) {
              teacherNames[startTime] = [];
            }
            teacherNames[startTime].push({ teacherName, isSlotUsed });
          }
          setWesternSouthCategoryData(mappedWesternNorthRespData);
          setWesternSouthTeacherName(teacherNames);
        }
        else{
          setWesternSouthCategoryData(Array(42).fill('-'))
        }
        if(responseData['Vocal Western-South']){
          const westernSouthRespData = responseData["Vocal Western-South"];
          const mappedWesternSouthRespData = categoryTimings.map(timing => {
            const matchingData = westernSouthRespData.find(
              data => data.startTime.trim() === timing.trim()
            );
            return matchingData ? matchingData : "-";
          });
          const teacherNames = {}; 
          for (const data of westernSouthRespData) {
            const { startTime, teacherName, isSlotUsed } = data;
            if (!teacherNames[startTime]) {
              teacherNames[startTime] = [];
            }
            teacherNames[startTime].push({ teacherName, isSlotUsed });
          }
          setWesternNorthCategoryData(mappedWesternSouthRespData);
          setWesternNorthTeacherName(teacherNames);
        }
else{
setWesternNorthCategoryData(Array(42).fill('-'))
}
      }
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };
  const fetchDataForPreviousDate = async (date) => {
    try {
      const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/ae/dayview?date=${date}`;
  
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: token["authorization"]
        }
      });
  
      const responseData = response.data;
      if(responseData){
        if (responseData['Vocal-North']) {
          const northRespData = responseData['Vocal-North'];
          console.log(responseData['Vocal-North'], '5555');
        
          const mappedNorthRespData = categoryTimings.map(timing => {
            const matchingData = northRespData.find(
              data => data.startTime.trim() === timing.trim()
            );
            return matchingData ? matchingData : '-';
          });
        
          const teacherNames = {}; 
for (const data of northRespData) {
  const { startTime, teacherName, isSlotUsed } = data;
  if (!teacherNames[startTime]) {
    teacherNames[startTime] = [];
  }
  teacherNames[startTime].push({ teacherName, isSlotUsed });
}

console.log(mappedNorthRespData, 'mappedNorthRespData');
console.log(teacherNames, 'teacherNames');

setNorthCategoryData(mappedNorthRespData);
setNorthTeacherName(teacherNames);
console.log(teacherNames, 'separateTeacherNames');
} else {
setNorthCategoryData(Array(42).fill('-'));
}
        
        if(responseData['Vocal-South']){
          const southRespData = responseData["Vocal-South"];
          const mappedSouthRespData = categoryTimings.map(timing => {
            const matchingData = southRespData.find(
              data => data.startTime.trim() === timing.trim()
            );
            return matchingData ? matchingData : "-";
          });
          const teacherNames = {}; 
          for (const data of southRespData) {
            const { startTime, teacherName, isSlotUsed } = data;
            if (!teacherNames[startTime]) {
              teacherNames[startTime] = [];
            }
            teacherNames[startTime].push({ teacherName, isSlotUsed });
          }
          setSouthCategoryData(mappedSouthRespData);
          setSouthTeacherName(teacherNames);
        }
        else{
          setSouthCategoryData(Array(42).fill('-'))
        }
      
        if(responseData['Instrument-Piano']){
          const pianoRespData = responseData["Instrument-Piano"];
          const mappedPianoRespData = categoryTimings.map(timing => {
            const matchingData = pianoRespData.find(
              data => data.startTime.trim() === timing.trim()
            );
            return matchingData ? matchingData : "-";
          });
          const teacherNames = {}; 
          for (const data of pianoRespData) {
            const { startTime, teacherName, isSlotUsed } = data;
            if (!teacherNames[startTime]) {
              teacherNames[startTime] = [];
            }
            teacherNames[startTime].push({ teacherName, isSlotUsed });
          }
          setPianoCategoryData(mappedPianoRespData);
          setPianoTeacherName(teacherNames);
        }
        else{
          setPianoCategoryData(Array(42).fill('-'))
        }
        if(responseData['Instrument-Guitar']){
          const guitarRespData = responseData["Instrument-Guitar"];
          const mappedGuitarRespData = categoryTimings.map(timing => {
            const matchingData = guitarRespData.find(
              data => data.startTime.trim() === timing.trim()
            );
            return matchingData ? matchingData : "-";
          });
          const teacherNames = {}; 
          for (const data of guitarRespData) {
            const { startTime, teacherName, isSlotUsed } = data;
            if (!teacherNames[startTime]) {
              teacherNames[startTime] = [];
            }
            teacherNames[startTime].push({ teacherName, isSlotUsed });
          }
          setGuitarCategoryData(mappedGuitarRespData);
          setGuitarTeacherName(teacherNames);
        }
        else{
          setGuitarCategoryData(Array(42).fill('-'))
        }
        if(responseData['Instrument-Tabla']){
          const tablaRespData = responseData["Instrument-Tabla"];
          const mappedTablaRespData = categoryTimings.map(timing => {
            const matchingData = tablaRespData.find(
              data => data.startTime.trim() === timing.trim()
            );
            return matchingData ? matchingData : "-";
          });
          const teacherNames = {}; 
          for (const data of tablaRespData) {
            const { startTime, teacherName, isSlotUsed } = data;
            if (!teacherNames[startTime]) {
              teacherNames[startTime] = [];
            }
            teacherNames[startTime].push({ teacherName, isSlotUsed });
          }
          setTablaCategoryData(mappedTablaRespData);
          setTablaTeacherName(teacherNames);
        }
        else{
          setTablaCategoryData(Array(42).fill('-'))
        }
        if(responseData['Vocal Western-North']){
          const westernNorthRespData = responseData["Vocal Western-North"];
          const mappedWesternNorthRespData = categoryTimings.map(timing => {
            const matchingData = westernNorthRespData.find(
              data => data.startTime.trim() === timing.trim()
            );
            return matchingData ? matchingData : "-";
          });
          const teacherNames = {}; 
          for (const data of westernNorthRespData) {
            const { startTime, teacherName, isSlotUsed } = data;
            if (!teacherNames[startTime]) {
              teacherNames[startTime] = [];
            }
            teacherNames[startTime].push({ teacherName, isSlotUsed });
          }
          setWesternSouthCategoryData(mappedWesternNorthRespData);
          setWesternSouthTeacherName(teacherNames);
        }
        else{
          setWesternSouthCategoryData(Array(42).fill('-'))
        }
        if(responseData['Vocal Western-South']){
          const westernSouthRespData = responseData["Vocal Western-South"];
          const mappedWesternSouthRespData = categoryTimings.map(timing => {
            const matchingData = westernSouthRespData.find(
              data => data.startTime.trim() === timing.trim()
            );
            return matchingData ? matchingData : "-";
          });
          const teacherNames = {}; 
          for (const data of westernSouthRespData) {
            const { startTime, teacherName, isSlotUsed } = data;
            if (!teacherNames[startTime]) {
              teacherNames[startTime] = [];
            }
            teacherNames[startTime].push({ teacherName, isSlotUsed });
          }
          setWesternNorthCategoryData(mappedWesternSouthRespData);
          setWesternNorthTeacherName(teacherNames);
        }
else{
setWesternNorthCategoryData(Array(42).fill('-'))
}
      }
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };
  
  useEffect(() => {
   
    if(location.search){
      const date = location.search.replace("?date=", "");
      setCurrentDates(date);
      // fetchDataForDate(date);
    }
   
  }, [location.search]);

  const handleNextDate = () => {
   
    const currentDateObj = new Date(currentDates);
    currentDateObj.setDate(currentDateObj.getDate() + 1);
    const nextDate = currentDateObj.toISOString().split("T")[0];

   
    setCurrentDates(nextDate);

   
    fetchDataForDate(nextDate);
  };
  const handleBackDate = () => {
   
    const currentDateObj = new Date(currentDates);
    currentDateObj.setDate(currentDateObj.getDate() - 1);
    const previousDate = currentDateObj.toISOString().split("T")[0];
    setCurrentDates(previousDate);
    fetchDataForPreviousDate(previousDate);
  };
  
  const handleBackClick = () => {
    history('/status');
  };
  
  
  const categoryTimings = [
    "00:00:00",
    "00:30:00",
    "01:00:00",
    "01:30:00",
    "02:00:00",
    "02:30:00",
    "03:00:00",
    "03:30:00",
    "04:00:00",
    "04:30:00",
    "05:00:00",
    "05:30:00",
    "06:00:00",
    "06:30:00",
    "07:00:00",
    "07:30:00",
    "08:00:00",
    "08:30:00",
    "09:00:00",
    "09:30:00",
    "10:00:00",
    "10:30:00",
    "11:00:00",
    "11:30:00",
    "12:00:00",
    "12:30:00",
    "13:00:00",
    "13:30:00",
    "14:00:00",
    "14:30:00",
    "15:00:00",
    "15:30:00",
    "16:00:00",
    "16:30:00",
    "17:00:00",
    "17:30:00",
    "18:00:00",
    "18:30:00",
    "19:00:00",
    "19:30:00",
    "20:00:00",
    "20:30:00",
    "21:00:00",
    "21:30:00",
    "22:00:00",
    "22:30:00",
    "23:00:00",
    "23:30:00",
    
  ];
  const getColorByPercentage = (percentage) => {
    if (percentage <= 80) {
      return "lightgreen";
    } else if (percentage <= 100) {
      return "orange";
    } else {
      return "red";
    }
  };
  
  return (
    <div>
      <Button onClick={handleBackClick} style={{ marginBottom: "16px" , backgroundColor:"skyblue"}}>
        Back
      </Button>
      <div style={{ marginBottom: "7px" }}>
        <LeftOutlined style={{ marginRight: "8px", cursor: "pointer" }}  onClick={handleBackDate}/>
        Date: {currentDates}
        <RightOutlined style={{ marginLeft: "8px", cursor: "pointer" }}  onClick={handleNextDate}/> 
      </div>
      <div className='calendar-legend-set'>
      <span className='legend-square' style={{ backgroundColor: 'lightgreen' }}>
                        </span>
                        <span className='legend-text' style={{color: "black"}}>
                            0 - 80% &emsp;
                        </span>

                        <span className='legend-square' style={{ backgroundColor: 'orange' }}>
                        </span>
                        <span className='legend-text' style={{color: "black"}}>
                            81 - 90% &emsp;
                        </span>
                        <span className='legend-square' style={{ backgroundColor: 'red' }}>
                        </span>
                        <span className='legend-text' style={{color: "black"}}>
                           90% above &emsp;
                        </span>
      </div>
      {isPastDate && (
        <div style={{ marginBottom: "16px" }}>
          <Tooltip title="Slot Not Conducted" color="red">
            <Tag color="red">Slot Not Conducted</Tag>
          </Tooltip>
          <Tooltip title="Slot Conducted" color="green">
            <Tag color="green">Slot Conducted</Tag>
          </Tooltip>
        </div>
      )}
      <table style={{ width: "100%", border: "1px solid black" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Category Name
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              00:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              00:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              01:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              01:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              02:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              02:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              03:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              03:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              04:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              04:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              05:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              05:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              06:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              06:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              07:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              07:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              08:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              08:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              09:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              09:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              10:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              10:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              11:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              11:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              12:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              12:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              13:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              13:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              14:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              14:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              15:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              15:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              16:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              16:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              17:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              17:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              18:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              18:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              19:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              19:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              20:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              20:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              21:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              21:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              22:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              22:30:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              23:00:00
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              23:30:00
            </th>
           
          </tr>
        </thead>
        <tbody>
            <tr style={{ border: "1px solid black", padding: "8px" }}>
            <td style={{ border: "1px solid black", padding: "8px" }}>Vocal-North</td>
    {northCategoryData.map((item, index) => (
      
      <td style={{ border: "1px solid black", padding: "8px",
      backgroundColor: item !== "-" &&
      item.internationalSlotsCount != null &&
      item.domesticSlotsCount != null
      ? (() => {
          const totalSlots = item.internationalSlotsCount * 2 + item.domesticSlotsCount * 2;
          const bookedSlots = item.bookedSlots || 0;
          const percentage = (bookedSlots / totalSlots) * 100;
          if (percentage <= 80) {
              return "lightgreen";
          } else if (percentage <= 90) {
              return "orange";
          } else {
              return "red";
          }
      })()
      : null,
  
        
  
      }} key={index} title={item.bookedSlots != null && item.totalSlots != null ? `Total Slots: ${item.totalSlots}` : null}>
        {item !== '-' ? (
          item.internationalSlotsCount != null && item.domesticSlotsCount != null ? (
            <span>
              {item.internationalSlotsCount * 2 !== 0 && (
        <>
          <img src={airplane} alt="Airplane" style={{ width: "16px", marginRight: "4px" }} />
          {`${item.bookedSlots}/${item.internationalSlotsCount * 2}`}
          <br />
          {item.internationalSlotsCount !== 0 && item.internationalSlotsCount != null ? `${Math.round((item.bookedSlots / (item.internationalSlotsCount * 2)) * 100)}%` : '0%'}
          <br />
        </>
      )}
      {item.domesticSlotsCount * 2 !== 0 && (
        <>
          <img src={home} alt="Home" style={{ width: "16px", marginRight: "4px" }} />
          {`${item.bookedSlots}/${item.domesticSlotsCount * 2}`}
          <br />
          {item.domesticSlotsCount !== 0 && item.domesticSlotsCount != null ? `${Math.round((item.bookedSlots / (item.domesticSlotsCount * 2)) * 100)}%` : '0%'}
        </>
      )}
            </span>
          ) : (
            northTeacherName[item.startTime] && northTeacherName[item.startTime].map((teacher, index) => (
              <Tag key={index} color={teacher.isSlotUsed ? 'green' : 'red'}>{teacher.teacherName}</Tag>
            ))
          )
        ) : (
          '-'
        )}
        {console.log(item, "item===")}
      </td>

))}

          </tr>
            <tr style={{ border: "1px solid black", padding: "8px" }}>
              <td style={{ border: "1px solid black", padding: "8px" }}>Vocal-South</td>
              {southCategoryData.map((item, index) => (
                <td style={{ border: "1px solid black", padding: "8px", backgroundColor: item !== "-" &&
                item.internationalSlotsCount != null &&
                item.domesticSlotsCount != null
                ? (() => {
                    const totalSlots = item.internationalSlotsCount * 2 + item.domesticSlotsCount * 2;
                    const bookedSlots = item.bookedSlots || 0;
                    const percentage = (bookedSlots / totalSlots) * 100;
                    if (percentage <= 80) {
                        return "lightgreen";
                    } else if (percentage <= 90) {
                        return "orange";
                    } else {
                        return "red";
                    }
                })()
                : null,
             }} key={index} 
                title={item.bookedSlots != null && item.totalSlots != null ? `Total Slots: ${item.totalSlots}` : null}>
                {item !== '-' ? (
      item.internationalSlotsCount != null && item.domesticSlotsCount != null ? (
        <span>
              {item.internationalSlotsCount * 2 !== 0 && (
        <>
          <img src={airplane} alt="Airplane" style={{ width: "16px", marginRight: "4px" }} />
          {`${item.bookedSlots}/${item.internationalSlotsCount * 2}`}
          <br />
          {item.internationalSlotsCount !== 0 && item.internationalSlotsCount != null ? `${Math.round((item.bookedSlots / (item.internationalSlotsCount * 2)) * 100)}%` : '0%'}
          <br />
        </>
      )}
      {item.domesticSlotsCount * 2 !== 0 && (
        <>
          <img src={home} alt="Home" style={{ width: "16px", marginRight: "4px" }} />
          {`${item.bookedSlots}/${item.domesticSlotsCount * 2}`}
          <br />
          {item.domesticSlotsCount !== 0 && item.domesticSlotsCount != null ? `${Math.round((item.bookedSlots / (item.domesticSlotsCount * 2)) * 100)}%` : '0%'}
        </>
      )}
            </span>
        ) : (
        southTeacherName[item.startTime] && southTeacherName[item.startTime].map((teacher, index) => (
          <Tag key={index} color={teacher.isSlotUsed ? 'green' : 'red'}>{teacher.teacherName}</Tag>
        ))
      )
    ) : (
      '-'
    )}
                </td>
            ))}
            </tr>
            <tr style={{ border: "1px solid black", padding: "8px" }}>
              <td style={{ border: "1px solid black", padding: "8px" }}>Instrument-Piano</td>
              {pianoCategoryData.map((item, index) => (
                <td style={{ border: "1px solid black", padding: "8px", backgroundColor: item !== "-" &&
                item.internationalSlotsCount != null &&
                item.domesticSlotsCount != null
                ? (() => {
                    const totalSlots = item.internationalSlotsCount * 2 + item.domesticSlotsCount * 2;
                    const bookedSlots = item.bookedSlots || 0;
                    const percentage = (bookedSlots / totalSlots) * 100;
                    if (percentage <= 80) {
                        return "lightgreen";
                    } else if (percentage <= 90) {
                        return "orange";
                    } else {
                        return "red";
                    }
                })()
                : null, }} key={index} title={item.bookedSlots != null && item.totalSlots != null ? `Total Slots: ${item.totalSlots}` : null}>
                 {item !== '-' ? (
      item.internationalSlotsCount != null && item.domesticSlotsCount != null ? (
        <span>
        {item.internationalSlotsCount * 2 !== 0 && (
  <>
    <img src={airplane} alt="Airplane" style={{ width: "16px", marginRight: "4px" }} />
    {`${item.bookedSlots}/${item.internationalSlotsCount * 2}`}
    <br />
    {item.internationalSlotsCount !== 0 && item.internationalSlotsCount != null ? `${Math.round((item.bookedSlots / (item.internationalSlotsCount * 2)) * 100)}%` : '0%'}
    <br />
  </>
)}
{item.domesticSlotsCount * 2 !== 0 && (
  <>
    <img src={home} alt="Home" style={{ width: "16px", marginRight: "4px" }} />
    {`${item.bookedSlots}/${item.domesticSlotsCount * 2}`}
    <br />
    {item.domesticSlotsCount !== 0 && item.domesticSlotsCount != null ? `${Math.round((item.bookedSlots / (item.domesticSlotsCount * 2)) * 100)}%` : '0%'}
  </>
)}
      </span>
            ) : (
        pianoTeacherName[item.startTime] && pianoTeacherName[item.startTime].map((teacher, index) => (
          <Tag key={index} color={teacher.isSlotUsed ? 'green' : 'red'}>{teacher.teacherName}</Tag>
        ))
      )
    ) : (
      '-'
    )}
                </td>
            ))}
            </tr>
            <tr style={{ border: "1px solid black", padding: "8px" }}>
              <td style={{ border: "1px solid black", padding: "8px" }}>Instrument-Guitar</td>
              {guitarCategoryData.map((item, index) => (
                <td style={{ border: "1px solid black", padding: "8px", backgroundColor: item !== "-" &&
                item.internationalSlotsCount != null &&
                item.domesticSlotsCount != null
                ? (() => {
                    const totalSlots = item.internationalSlotsCount * 2 + item.domesticSlotsCount * 2;
                    const bookedSlots = item.bookedSlots || 0;
                    const percentage = (bookedSlots / totalSlots) * 100;
                    if (percentage <= 80) {
                        return "lightgreen";
                    } else if (percentage <= 90) {
                        return "orange";
                    } else {
                        return "red";
                    }
                })()
                : null, }} key={index} title={item.bookedSlots != null && item.totalSlots != null ? `Total Slots: ${item.totalSlots}` : null}>
                 {item !== '-' ? (
      item.internationalSlotsCount != null && item.domesticSlotsCount != null ? (
        <span>
        {item.internationalSlotsCount * 2 !== 0 && (
  <>
    <img src={airplane} alt="Airplane" style={{ width: "16px", marginRight: "4px" }} />
    {`${item.bookedSlots}/${item.internationalSlotsCount * 2}`}
    <br />
    {item.internationalSlotsCount !== 0 && item.internationalSlotsCount != null ? `${Math.round((item.bookedSlots / (item.internationalSlotsCount * 2)) * 100)}%` : '0%'}
    <br />
  </>
)}
{item.domesticSlotsCount * 2 !== 0 && (
  <>
    <img src={home} alt="Home" style={{ width: "16px", marginRight: "4px" }} />
    {`${item.bookedSlots}/${item.domesticSlotsCount * 2}`}
    <br />
    {item.domesticSlotsCount !== 0 && item.domesticSlotsCount != null ? `${Math.round((item.bookedSlots / (item.domesticSlotsCount * 2)) * 100)}%` : '0%'}
  </>
)}
      </span>
        ) : (
        guitarTeacherName[item.startTime] && guitarTeacherName[item.startTime].map((teacher, index) => (
          <Tag key={index} color={teacher.isSlotUsed ? 'green' : 'red'}>{teacher.teacherName}</Tag>
        ))
      )
    ) : (
      '-'
    )}
                </td>
            ))}
            </tr>
            <tr style={{ border: "1px solid black", padding: "8px" }}>
              <td style={{ border: "1px solid black", padding: "8px" }}>Instrument-Tabla</td>
              {tablaCategoryData.map((item, index) => (
                <td style={{ border: "1px solid black", padding: "8px", backgroundColor: item !== "-" &&
                item.internationalSlotsCount != null &&
                item.domesticSlotsCount != null
                ? (() => {
                    const totalSlots = item.internationalSlotsCount * 2 + item.domesticSlotsCount * 2;
                    const bookedSlots = item.bookedSlots || 0;
                    const percentage = (bookedSlots / totalSlots) * 100;
                    if (percentage <= 80) {
                        return "lightgreen";
                    } else if (percentage <= 90) {
                        return "orange";
                    } else {
                        return "red";
                    }
                })()
                : null, }} key={index} title={item.bookedSlots != null && item.totalSlots != null ? `Total Slots: ${item.totalSlots}` : null}>
                 {item !== '-' ? (
      item.internationalSlotsCount != null && item.domesticSlotsCount != null ? (
        <span>
        {item.internationalSlotsCount * 2 !== 0 && (
  <>
    <img src={airplane} alt="Airplane" style={{ width: "16px", marginRight: "4px" }} />
    {`${item.bookedSlots}/${item.internationalSlotsCount * 2}`}
    <br />
    {item.internationalSlotsCount !== 0 && item.internationalSlotsCount != null ? `${Math.round((item.bookedSlots / (item.internationalSlotsCount * 2)) * 100)}%` : '0%'}
    <br />
  </>
)}
{item.domesticSlotsCount * 2 !== 0 && (
  <>
    <img src={home} alt="Home" style={{ width: "16px", marginRight: "4px" }} />
    {`${item.bookedSlots}/${item.domesticSlotsCount * 2}`}
    <br />
    {item.domesticSlotsCount !== 0 && item.domesticSlotsCount != null ? `${Math.round((item.bookedSlots / (item.domesticSlotsCount * 2)) * 100)}%` : '0%'}
  </>
)}
      </span>
        ) : (
        tablaTeacherName[item.startTime] && tablaTeacherName[item.startTime].map((teacher, index) => (
          <Tag key={index} color={teacher.isSlotUsed ? 'green' : 'red'}>{teacher.teacherName}</Tag>
        ))
      )
    ) : (
      '-'
    )}
                </td>
            ))}
            </tr>
            <tr style={{ border: "1px solid black", padding: "8px" }}>
              <td style={{ border: "1px solid black", padding: "8px" }}>Vocal Western-North</td>
              {westernSouthCategoryData.map((item, index) => (
                <td style={{ border: "1px solid black", padding: "8px", backgroundColor: item !== "-" &&
                item.internationalSlotsCount != null &&
                item.domesticSlotsCount != null
                ? (() => {
                    const totalSlots = item.internationalSlotsCount * 2 + item.domesticSlotsCount * 2;
                    const bookedSlots = item.bookedSlots || 0;
                    const percentage = (bookedSlots / totalSlots) * 100;
                    if (percentage <= 80) {
                        return "lightgreen";
                    } else if (percentage <= 90) {
                        return "orange";
                    } else {
                        return "red";
                    }
                })()
                : null, }} key={index} title={item.bookedSlots != null && item.totalSlots != null ? `Total Slots: ${item.totalSlots}` : null}>
                 {item !== '-' ? (
      item.internationalSlotsCount != null && item.domesticSlotsCount != null ? (
        <span>
        {item.internationalSlotsCount * 2 !== 0 && (
  <>
    <img src={airplane} alt="Airplane" style={{ width: "16px", marginRight: "4px" }} />
    {`${item.bookedSlots}/${item.internationalSlotsCount * 2}`}
    <br />
    {item.internationalSlotsCount !== 0 && item.internationalSlotsCount != null ? `${Math.round((item.bookedSlots / (item.internationalSlotsCount * 2)) * 100)}%` : '0%'}
    <br />
  </>
)}
{item.domesticSlotsCount * 2 !== 0 && (
  <>
    <img src={home} alt="Home" style={{ width: "16px", marginRight: "4px" }} />
    {`${item.bookedSlots}/${item.domesticSlotsCount * 2}`}
    <br />
    {item.domesticSlotsCount !== 0 && item.domesticSlotsCount != null ? `${Math.round((item.bookedSlots / (item.domesticSlotsCount * 2)) * 100)}%` : '0%'}
  </>
)}
      </span>
        ) : (
        westernSouthTeacherName[item.startTime] && westernSouthTeacherName[item.startTime].map((teacher, index) => (
          <Tag key={index} color={teacher.isSlotUsed ? 'green' : 'red'}>{teacher.teacherName}</Tag>
        ))
      )
    ) : (
      '-'
    )}
                </td>
            ))}
            </tr>
            <tr style={{ border: "1px solid black", padding: "8px" }}>
              <td style={{ border: "1px solid black", padding: "8px" }}>Vocal Western-South</td>
              {westernNorthCategoryData.map((item, index) => (
                <td style={{ border: "1px solid black", padding: "8px" ,
                backgroundColor: item !== "-" &&
                item.internationalSlotsCount != null &&
                item.domesticSlotsCount != null
                ? (() => {
                    const totalSlots = item.internationalSlotsCount * 2 + item.domesticSlotsCount * 2;
                    const bookedSlots = item.bookedSlots || 0;
                    const percentage = (bookedSlots / totalSlots) * 100;
                    if (percentage <= 80) {
                        return "lightgreen";
                    } else if (percentage <= 90) {
                        return "orange";
                    } else {
                        return "red";
                    }
                })()
                : null,}} key={index} title={item.bookedSlots != null && item.totalSlots != null ? `Total Slots: ${item.totalSlots}` : null}>
                 {item !== '-' ? (
      item.internationalSlotsCount != null && item.domesticSlotsCount != null ? (
        <span>
        {item.internationalSlotsCount * 2 !== 0 && (
  <>
    <img src={airplane} alt="Airplane" style={{ width: "16px", marginRight: "4px" }} />
    {`${item.bookedSlots}/${item.internationalSlotsCount * 2}`}
    <br />
    {item.internationalSlotsCount !== 0 && item.internationalSlotsCount != null ? `${Math.round((item.bookedSlots / (item.internationalSlotsCount * 2)) * 100)}%` : '0%'}
    <br />
  </>
)}
{item.domesticSlotsCount * 2 !== 0 && (
  <>
    <img src={home} alt="Home" style={{ width: "16px", marginRight: "4px" }} />
    {`${item.bookedSlots}/${item.domesticSlotsCount * 2}`}
    <br />
    {item.domesticSlotsCount !== 0 && item.domesticSlotsCount != null ? `${Math.round((item.bookedSlots / (item.domesticSlotsCount * 2)) * 100)}%` : '0%'}
  </>
)}
      </span>
        ) : (
        westernNorthTeacherName[item.startTime] && westernNorthTeacherName[item.startTime].map((teacher, index) => (
          <Tag key={index} color={teacher.isSlotUsed ? 'green' : 'red'}>{teacher.teacherName}</Tag>
        ))
      )
    ) : (
      '-'
    )}
                </td>
            ))}
            </tr>
       
    
     
      
</tbody>
     
      </table>
    </div>
  );
}

export default DateView;
