
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form, DatePicker, Button, Input, notification, Spin } from "antd";
import appEnvirontVariables from "../../../core_units/config/env-variables";
import * as SecureLS from "secure-ls";
import "./style.css";

function WeeklyReport() {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const [list, setList] = useState([]);
  const [engagementOrder, setEngagementOrder] = useState("false");
  const [list10to30, setList10to30] = useState([]);
  console.log("List 10-30:", list10to30);
  const [list30plus, setList30plus] = useState([]);

  console.log("List 30+:", list30plus);

  const [checkboxState10to30, setCheckboxState10to30] = useState({});
  const [checkboxState30plus, setCheckboxState30plus] = useState({});
  const [activeButton, setActiveButton] = useState(null);
  const [isResponseSuccessful, setResponseSuccessful] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(false); 
  const handleStartDateChange = (startDate) => {
    if (startDate) {
      const endDate = startDate.clone().add(6, "days");
      form.setFieldsValue({
        endDate,
      });
    }
  };

  const weeklyEngage = (newEngagementOrder) => {
    setLoading(true);
    if ((newEngagementOrder === "true" && list30plus.length > 0) ||
    (newEngagementOrder === "false" && list10to30.length > 0)) {
  setLoading(false);
  setEngagementOrder(newEngagementOrder);
  setResponseSuccessful(true);
  return; 
}
    const values = form.getFieldsValue();
    const startDate = values.startDate.format("YYYY-MM-DD");
    const endDate = values.endDate.format("YYYY-MM-DD");
    let sortFunction = (a, b) =>
    newEngagementOrder === "true"
      ? a.engagementIndex - b.engagementIndex 
      : b.engagementIndex - a.engagementIndex;
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/weeklyEngagement?fromDate=${startDate}&toDate=${endDate}&engagementOrder=${newEngagementOrder}`;

    axios
      .post(apiUrl, null, {
        headers: {
          Authorization: token["authorization"],
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setLoading(false);
        setList(response.data);
        console.log(response.data, "response.data");
        const instrumentalList10to30 = Array.isArray(response.data.intrumentalList10to30)
        ? response.data.intrumentalList10to30.sort(sortFunction)
        : [];
      const northList10to30 = Array.isArray(response.data.northList10to30)
        ? response.data.northList10to30.sort(sortFunction)
        : [];
      const southList10to30 = Array.isArray(response.data.southList10to30)
        ? response.data.southList10to30.sort(sortFunction)
        : [];
      const instrumentalList30plus = Array.isArray(
        response.data.intrumentalList30plus
      )
        ? response.data.intrumentalList30plus.sort(sortFunction)
        : [];
      const northList30plus = Array.isArray(response.data.northList30plus)
        ? response.data.northList30plus.sort(sortFunction)
        : [];
      const southList30plus = Array.isArray(response.data.southList30plus)
        ? response.data.southList30plus.sort(sortFunction)
        : [];

        setList10to30([
          ...instrumentalList10to30,
          ...northList10to30,
          ...southList10to30,
        ]);

        setList30plus([
          ...instrumentalList30plus,
          ...northList30plus,
          ...southList30plus,
        ]);

        const newCheckboxState10to30 = {};
        const newCheckboxState30plus = {};

        for (const item of instrumentalList10to30) {
          newCheckboxState10to30[item.teacherId] =
            item.eligibleForIncentive === "true";
        }
        for (const item of northList10to30) {
          newCheckboxState10to30[item.teacherId] =
            item.eligibleForIncentive === "true";
        }
        for (const item of southList10to30) {
          newCheckboxState10to30[item.teacherId] =
            item.eligibleForIncentive === "true";
        }
        for (const item of instrumentalList30plus) {
          newCheckboxState30plus[item.teacherId] =
            item.eligibleForIncentive === "true";
        }
        for (const item of northList30plus) {
          newCheckboxState30plus[item.teacherId] =
            item.eligibleForIncentive === "true";
        }
        for (const item of southList30plus) {
          newCheckboxState30plus[item.teacherId] =
            item.eligibleForIncentive === "true";
        }

        setCheckboxState10to30(newCheckboxState10to30);
        setCheckboxState30plus(newCheckboxState30plus);
        setResponseSuccessful(true); 
      })
      .catch((error) => {
        setLoading(false);
        console.error("API Error:", error);
        notification.error({
          message: "Error",
          description: "Failed",
        });
      });
  };

  const onFinish = () => {
    const newEngagementOrder = activeButton === "Below 10" ? "true" : "false";
    setEngagementOrder(newEngagementOrder);
    weeklyEngage(newEngagementOrder);
    // handleEngagementOrderChange("false"); 
    setActiveButton("false");
  };

  const handleEngagementOrderChange = (order) => {
    setActiveButton(order);

    const newEngagementOrder = order === "false" ? "false" : "true";
    setEngagementOrder(newEngagementOrder);
    weeklyEngage(newEngagementOrder);
  };

  const handleCheckboxChange = (teacherId, is10to30) => {
    if (is10to30) {
      const updatedCheckboxState = { ...checkboxState10to30 };
      updatedCheckboxState[teacherId] = !updatedCheckboxState[teacherId];
      setCheckboxState10to30(updatedCheckboxState);
      updateSelectedData(updatedCheckboxState);
    } else {
      const updatedCheckboxState = { ...checkboxState30plus };
      updatedCheckboxState[teacherId] = !updatedCheckboxState[teacherId];
      setCheckboxState30plus(updatedCheckboxState);
      updateSelectedData(updatedCheckboxState);
    }
  };
  const generateRequestData = () => {
    const requestData = [];
  
    for (const teacherId in checkboxState10to30) {
      if (checkboxState10to30[teacherId]) {
        const dataItem = list10to30.find((item) => item.teacherId === teacherId);
        if (dataItem) {
          requestData.push({
            teacherId: dataItem.teacherId,
            price: dataItem.price,
            genre: dataItem.type,
            teacherName: dataItem.teacherName,
            classConducted: dataItem.noOfclassesTaken,
            engagementIndex: dataItem.engagementIndex,
            activeStudents: dataItem.activeStudents,
            eligibleForIncentive: true,
          });
        }
      }
    }
  
    for (const teacherId in checkboxState30plus) {
      if (checkboxState30plus[teacherId]) {
        const dataItem = list30plus.find((item) => item.teacherId === teacherId);
        if (dataItem) {
          requestData.push({
            teacherId: dataItem.teacherId,
            price: dataItem.price,
            genre: dataItem.type,
            teacherName: dataItem.teacherName,
            classConducted: dataItem.noOfclassesTaken,
            engagementIndex: dataItem.engagementIndex,
            activeStudents: dataItem.activeStudents,
            eligibleForIncentive: true,
          });
        }
      }
    }
  
    return requestData;
  };
  
  const updateSelectedData = (checkboxState) => {
    const selectedData = [];
  
    for (const teacherId in checkboxState) {
      if (checkboxState[teacherId]) {
       
        const dataItem = list10to30.find((item) => item.teacherId === teacherId);
        if (dataItem) {
          selectedData.push(dataItem);
        }
      }
    }
  
    setSelectedData(selectedData);
    console.log(selectedData,"selectedData")
  };
  const addWeeklyEngagement = () => {
    const values = form.getFieldsValue();
    const startDate = values.startDate.format('YYYY-MM-DD');
    const endDate = values.endDate.format('YYYY-MM-DD');
  
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/add/weeklyengagement?fromDate=${startDate}&toDate=${endDate}`;
    const requestData = generateRequestData();
  
    axios
      .post(apiUrl, requestData, {
        headers: {
          Authorization: token["authorization"],
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        notification.success({
          message: "Teacher marked successfully",
          
        });
      })
      .catch((error) => {
        console.error("API Error:", error);
        notification.error({
          message: "Error",
          description: "Failed",
        });
      });
  };
  
  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          label="Start Date"
          name="startDate"
          rules={[
            {
              required: true,
              message: "Please input your Start Date!",
            },
          ]}
        >
          <DatePicker onChange={handleStartDateChange} />
        </Form.Item>
        <Form.Item
          label="End Date"
          name="endDate"
          rules={[
            {
              required: true,
              message: "Please input your End Date!",
            },
          ]}
        >
          <DatePicker disabled />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <div>
      <Button
          type={activeButton === "false" ? "primary" : "default"}
          onClick={() => handleEngagementOrderChange("false")}
          style={{
            backgroundColor: activeButton === "false" ? "white" : "#1890ff",
            color: activeButton === "false" ? "#1890ff" : "white",
            border: activeButton === "false" ? "1px solid #1890ff" : "none",
            boxShadow: activeButton === "false" ? "0 0 10px #1890ff" : "none",
          }}
        >
          Top 5
        </Button>
        <Button
          type={activeButton === "true" ? "primary" : "default"}
        
          onClick={() => handleEngagementOrderChange("true")}
          style={{
            backgroundColor: activeButton === "true" ? "white" : "#1890ff",
            color: activeButton === "true" ? "#1890ff" : "white",
            border: activeButton === "true" ? "1px solid #1890ff" : "none",
            boxShadow: activeButton === "true" ? "0 0 10px #1890ff" : "none",
            marginLeft: "20px",
          }}
        >
          Below 10
        </Button>
      </div>
{loading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
            <Spin size="large" />
          </div>
        ):  (

      <div style={{ marginTop: "20px"}}>
      {list10to30.length > 0 && (
        <div style={{ border:"1px solid black", padding:"26px"}}>
        <label style={{ fontWeight: "800" }}>10-30 Active Students</label>
        <table className="custom-tables">
          <thead>
            <tr>
            {activeButton !== "true" && (
                  <th>Checkbox</th>
                )}
              <th>Teacher Name</th>
              <th>Type</th>
              <th>Active Student Count</th>
              <th>Conducted Classes</th>
              <th>Engagement Index</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {list10to30.map((item, index) => (
              <tr key={index}>
                 {activeButton !== "true" && (
                    <td>
                      <input
                        type="checkbox"
                        checked={checkboxState10to30[item.teacherId]}
                        onChange={() => handleCheckboxChange(item.teacherId, true)}
                      />
                    </td>
                  )}
                <td>{item.teacherName}</td>
                <td>{item.type}</td>
                <td>{item.activeStudents}</td>
                <td>{item.noOfclassesTaken}</td>
                <td>{item.engagementIndex}</td>
                <td>{item.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>
      )}
 {list30plus.length > 0 && ( 
        <div style={{ marginTop: "20px", border:"1px solid black", padding:"26px" }}>
          <label style={{ fontWeight: "800" }}>30+ Active Students</label>
          <table className="custom-tables">
            <thead>
              <tr>
              {activeButton !== "true" && (
                  <th>Checkbox</th>
                )}
                <th>Teacher Name</th>
                <th>Type</th>
                <th>Active Student Count</th>
                <th>Conducted Classes</th>
                <th>Engagement Index</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {list30plus.map((item, index) => (
                <tr key={index}>
                 {activeButton !== "true" && (
                    <td>
                      <input
                        type="checkbox"
                        checked={checkboxState30plus[item.teacherId]}
                        onChange={() => handleCheckboxChange(item.teacherId, false)}
                      />
                    </td>
                  )}
                  <td>{item.teacherName}</td>
                  <td>{item.type}</td>
                  <td>{item.activeStudents}</td>
                  <td>{item.noOfclassesTaken}</td>
                  <td>{item.engagementIndex}</td>
                  <td>{item.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
 )}

{isResponseSuccessful && activeButton !== "true" && list30plus.length > 0 && list10to30.length > 0 &&(
        <button
          style={{ display: "flex", float: "right", marginTop: "20px" }}
          onClick={addWeeklyEngagement}
        >
          Add
        </button>
      )}

      </div>
)}
    </div>
  );
}

export default WeeklyReport;
