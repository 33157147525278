import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, notification } from 'antd';
import logo from '../../../assets/img/ArtiumAcademyLogo.svg';
import login_bg from '../../../assets/img/login-bg.png';
import appEnvirontVariables from "../../../core_units/config/env-variables";

import './login.css'
import { useNavigate } from "react-router-dom";
import * as SecureLS from "secure-ls";
import _ from 'lodash';

import { connect } from "react-redux";
import axios from 'axios';
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { auth } from '../../../firebase';
import { FaCheck, FaTimes } from 'react-icons/fa';
const LoginScreen = (props) => {
	let history = useNavigate();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	var ls = new SecureLS({ encodingType: 'aes' });
	const token = ls.get("isLogin");
	const [otpSent, setOTPSent] = useState(false); 
	const [buttonText, setButtonText] = useState('Get Login OTP');
	const [verificationStatus, setVerificationStatus] = useState('');
	const [showCheckIcon, setShowCheckIcon] = useState(false);
	const [usernameFocused, setUsernameFocused] = useState(false);
	const [ buttonDisabled , setButtonDisabled] = useState(false);
	const [showOTP, setShowOTP] = useState(false);
	
	const onFinish = async (values) => {
		if (!otpSent) {
			return;
		}
		setLoading(true);
		props.newLogin({
			body: {
				code: "+91",
				emailId: values.username.includes('@') ? values.username : null,
				mobileNo: !values.username.includes('@') ? values.username : null,
				otp: values.otp,
			},
			callback: async (res, data) => {
				console.log(res, "res==")
				let loginDetails = ls.get('isLogin');
				setLoading(false);
				if (res === true) {
					if (loginDetails.authorities[0] === 'ROLE_ADMIN_STUDENT' || loginDetails.authorities[0] === 'ROLE_ADMIN_TEACHER') {
						notification.error({
							message: 'Access Denied',
						});
					} else {
						if (loginDetails.authorities[0].includes('_MARKETING')) {
							history('/status');
						} else if (loginDetails.authorities[0].includes('_SUPERADMIN')) {
							history('/Restart');
						} else if (loginDetails.authorities[0].includes('_COUNSELLOR')){
							history('/BatchCreationOffline');
						}
						else if (loginDetails.authorities[0].includes('_DEMOOPS_AGENT')){
							history('/DemoOps');
						}
						else {
							let result = await generateFirebaseCredentials(loginDetails.uId, loginDetails.authorization);
							if (result.data.firebaseToken) {
								signiInUsingCredentials(result.data.firebaseToken);
							}
							notification.success({
								message: 'Logged in successfully.',
							});
							history('/teachers');
						}
					}
				} else {
					notification.error({
						message: "Invalid OTP",
					});
				}
			}
		});
	};
	  
	  
	const generateFirebaseCredentials = async (uId,token) => {
        let payload= {uId,role:'admin'}
		let headers = 	{ headers: { "Authorization": token } };
		
    let result=  await axios
      .post(appEnvirontVariables.API.UTILITY_SERVICE_URL+ "/api/authentication/credentials",payload,headers  ).catch(err => console.log('token',err.response));
	  	// console.log('result',result)
      return result
  }
   const signiInUsingCredentials=  (firebaseToken) => {
    signInWithCustomToken(auth,firebaseToken).then(res => {
    let uid = res.user.uid
    localStorage.setItem('firebaseUID',uid)
   }).catch(err=>console.error(err))


}
	const onFinishFailed = (errorInfo) => {
	};

	useEffect(() => {
		if (_.has(_.get(props, 'account.isLogin'), 'headers')) {
			ls.set('isLogin', _.get(props, 'account.isLogin'))
		}
	}, [_.get(props, 'account.isLogin')])


	// const handleGetLoginOTP = () => {
	// 	const { username } = form.getFieldsValue(); 
	// 	if (!username) {
	// 		console.error('Username is undefined or empty');
	// 		return;
	// 	}
		
	// 	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	// 	const mobileRegex = /^[0-9]{10}$/;
		
	// 	let apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/users/user/sendloginotp?`;
		
	// 	if (emailRegex.test(username)) {
	// 		apiUrl += `emailId=${encodeURIComponent(username)}`;
	// 	} 
		
	// 	else if (mobileRegex.test(username)) {
	// 		apiUrl += `mobileNo=${encodeURIComponent(username)}`;
	// 	} 
	// 	else {
	// 		console.error('Invalid input format. Please enter a valid email or mobile number.');
	// 		return;
	// 	}
		
	// 	axios
	// 		.post(apiUrl, null, {
	// 			headers: {
	// 				Authorization: token["authorization"],
	// 				"Content-Type": "application/json",
	// 			},
	// 		})
	// 		.then(async (res,data) => {
	// 			console.log(data,"66666")
	// 			console.log(res,"99999")
	// 			if (res.status == 200) {
	// 				console.log(res.status,"2000")
	// 				notification.success({
	// 					message: 'OTP sent successfully.',
	// 				});
	// 				setOTPSent(true);
	// 			} else {
	// 				notification.error({
	// 					message: 'Something went wrong',
	// 				});
	// 				console.error('API Error:', res ? res.data : 'Response undefined');
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			notification.error({
	// 				message: 'API Error: Something went wrong',
	// 			});
	// 			console.error("API Error:", error);
	// 		});
	// };
	
	const handleGetLoginOTP = () => {
		const { username } = form.getFieldsValue();
		if (!username) {
			notification.error({
				message: 'Please Enter valid EmailId/mobile number.',
			});
			return;
		}
	
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const mobileRegex = /^[0-9]{10}$/;
		if (!username || (!emailRegex.test(username) && !mobileRegex.test(username))) {
			notification.error({
				message: 'Please enter a valid email or mobile number.',
			});
			return;
		}
		setButtonText('Sending...');
		setButtonDisabled(true);
		let validateUrl = '';
		let sendOtpUrl = `${appEnvirontVariables.API.BASE_URL}/api/users/user/sendloginotp?`;
	
		if (emailRegex.test(username)) {
			validateUrl = `${appEnvirontVariables.API.BASE_URL}/api/users/ValidateNewUser1/${encodeURIComponent(username)}`;
			sendOtpUrl += `emailId=${encodeURIComponent(username)}`;
		} else if (mobileRegex.test(username)) {
			validateUrl = `${appEnvirontVariables.API.BASE_URL}/api/users/ValidateNewUser/+91/${encodeURIComponent(username)}`;
			sendOtpUrl += `mobileNo=${encodeURIComponent(username)}`;
		} else {
			
			return;
		}
	
		axios.get(validateUrl, {
				headers: {
					Authorization: token["authorization"],
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				console.log('Validation response:', response); 
				if (response.status === 200) {
					setVerificationStatus('Verified successfully');
					setShowCheckIcon(true);

           
            setTimeout(() => {
                setShowCheckIcon(false);
            }, 3000);
					console.log('Validation successful');
					setOTPSent(true);
					setShowOTP(true);
					// notification.success({
					// 	message: 'OTP sent successfully.',
					// });
				
					if (response.data === "EmailId not Registed") {
						notification.error({
							message: 'Email is not registered.',
						});
						setOTPSent(false);
						setButtonText('Get Login OTP');
						setButtonDisabled(false); 
						return; 
					}
					if(response.data === "Mobile number not registed"){
						notification.error({
							message: 'Mobile Number is not registered.',
						});
						setOTPSent(false);
						setButtonText('Get Login OTP');
						setButtonDisabled(false);
						return; 
					}
					axios.post(sendOtpUrl, null, {
							headers: {
								Authorization: token["authorization"],
								"Content-Type": "application/json",
							},
						})
						.then((otpResponse) => {
							console.log(otpResponse,"otpResponse")
							if (otpResponse.status === 200) {
								notification.success({
									message: 'OTP sent successfully.',
								});
								console.log('OTP Sent Successfully');
								setOTPSent(true);
								setButtonText('Get Login OTP');
							} else {
								setButtonText('Get Login OTP');
								notification.error({
									message: 'Error while sending OTP',
								});
								
							}
							setButtonDisabled(false); 
						})
						.catch((otpError) => {
							if (otpError.response && otpError.response.data && otpError.response.data.message) {
								notification.error({
									message: otpError.response.data.message,
								});
							} else {
								notification.error({
									message: 'API Error: Something went wrong while sending OTP',
								});
							}
							setButtonText('Get Login OTP');
							console.error("API Error:", otpError);
							setButtonDisabled(false); 
						});
					} else {
						
					}
			})
			.catch((error) => {
				setButtonText('Get Login OTP');
				notification.error({
					message: 'API Error: Something went wrong during validation',
				});
				console.error("API Error:", error);
				setButtonDisabled(false); 
			});
	};

	const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (usernameFocused) {
                handleGetLoginOTP();
            }
        }
    };
	const handleBack = () => {
		setShowOTP(false); 
		setOTPSent(false);
		form.resetFields(); 
	};
	const handleResendOTP = () => {
		const { username } = form.getFieldsValue();
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const mobileRegex = /^[0-9]{10}$/;
		let sendOtpUrl = `${appEnvirontVariables.API.BASE_URL}/api/users/user/sendloginotp?`;
		if (emailRegex.test(username)) {
			
			sendOtpUrl += `emailId=${encodeURIComponent(username)}`;
		} else if (mobileRegex.test(username)) {
			
			sendOtpUrl += `mobileNo=${encodeURIComponent(username)}`;
		} else {
			
			return;
		}
		axios.post(sendOtpUrl, null, {
			headers: {
				Authorization: token["authorization"],
				"Content-Type": "application/json",
			},
		})
		.then((otpResponse) => {
			if (otpResponse.status === 200) {
				notification.success({
					message: 'OTP resent successfully.',
				});
				
			} else {
				notification.error({
					message: 'Error while resending OTP',
				});
			}
		})
		.catch((otpError) => {
			if (otpError.response && otpError.response.data && otpError.response.data.message) {
				notification.error({
					message: otpError.response.data.message,
				});
			} else {
				notification.error({
					message: 'API Error: Something went wrong while resending OTP',
				});
			}
			console.error("API Error:", otpError);
		});
	};
	
	return (
		<div className="d-flex flex-column flex-root login-page-container">
			<div className="d-flex flex-column flex-column-fluid login-bg-container" style={{ backgroundImage: "url(" + login_bg + ")" }}>
				<div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
					<a href="#" className="mb-12">
						<img src={logo} alt="Logo" className="h-40px" />
					</a>
					<div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
						<div className="form w-100" id="kt_sign_in_form" >
							<div className="text-center mb-4">
							{showOTP && ( 
                                    <Form.Item>
                                        <Button style={{float:"left"}}onClick={handleBack}>Back</Button> 
                                    </Form.Item>
                                )}
								<div className="text-dark mb-2 h1">Sign In to Artium</div>
							</div>
							<Form
								layout="vertical"
								autoComplete="off"
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}
								form={form}
							>
								
								
                                    <Form.Item
                                        label="Email/Mobile"
                                        name="username"
                                        rules={[{ required: true, message: 'Please Enter Valid email/mobileNumber!' }]}
                                    >
                                       <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Input placeholder='example@gmail.com/123456' onKeyDown={handleKeyPress} 
                                                onFocus={() => setUsernameFocused(true)}
                                                onBlur={() => setUsernameFocused(false)}
                                                disabled={otpSent}
                                            />
                                            {showCheckIcon &&
                                                <FaCheck style={{ color: 'green', marginLeft: '8px' }} title={verificationStatus} />
                                            }
                                        </div>
                                    </Form.Item>
                                
								<Form.Item>
    { !otpSent && !showOTP &&
        <Button type="primary" onClick={!buttonDisabled ? handleGetLoginOTP : null}>
            {buttonText}
        </Button>
    }
    { otpSent &&
        <>
            <Form.Item
                label="OTP"
                name="otp"
                rules={[
                    { required: true, message: 'Please Enter your OTP!' },
                    { min: 4, message: 'OTP must be at least 4 characters long.' }, 
                ]}
            >
                <Input.Password placeholder="Enter your OTP" />
            </Form.Item>
			<Form.Item>
                <span onClick={handleResendOTP} style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}>Resend OTP</span>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} style={{borderRadius:"30px"}}>
                    Submit
                </Button>
            </Form.Item>
        </>
    }
</Form.Item>

							</Form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = state => (
	console.log(state, 'state'),
	{

		account: _.get(state, `app.${global.redux.actionTypes.account.name}`, {}),

	});

const mapDispatchToProps = dispatch => ({
	login: details => dispatch(global.redux.action.account.login(details)),
	newLogin: details => dispatch(global.redux.action.account.newLogin(details))
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
