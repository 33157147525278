import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useSearchParams, useLocation } from "react-router-dom";
import _ from "lodash";
import * as SecureLS from "secure-ls";
import {
    Form,
    Input,
    Button,
    Upload,
    message,
    InputNumber,
    Select,
    Table,
    notification,
    DatePicker,
    Spin,
    Radio,
  } from "antd";
 
  const { Option } = Select;
  const PreviousClass = (props) => {

    var ls = new SecureLS({ encodingType: "aes" });
    const token = ls.get("isLogin");
    const [form] = Form.useForm();
    const [teacherId] = useSearchParams();
    const [classData,setClassData] = useState([]);
    const [isLoading, setIsLoading] = useState(true); 
    useEffect(() => {
        markedClasses();
      }, []);
      const markedClasses = () => {
        setIsLoading(true);
        props.viewPreviousClass({
            params: {
                credentialId: teacherId.get("credid"),
              },
          callback: (res, data) => {
            setIsLoading(false);
            if (_.isEqual(res, true)) {
              setClassData(data);
              console.log(data,"data==")
            }
          },
        });
      };
      const columns = [
        {
          title: "Student Id",
          dataIndex: "studentId",
          key: "studentId",
        },
        {
          title: "Course Name",
          dataIndex: "courseName",
          key: "courseName",
        },
        
        {
          title: "Class Date & Time",
          key: "classDateTime",
          render: (record) => (
            <span>
              {record.classDate} {record.classStartTime}
            </span>
          ),
        },
        
      ];
  return (
    <div>
      {isLoading ? (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" tip="Loading..." style={{ textAlign: "center" }} />
        </div>
      ) : (
        classData.length > 0 ? (
          <Table columns={columns} dataSource={classData} pagination={false} />
        ) : (
          <div style={{ textAlign: "center" }}>No data found.</div>
        )
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
    teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
    students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
  });
  
  const mapDispatchToProps = (dispatch) => ({
   
    
  
    viewPreviousClass: (details) =>
      dispatch(global.redux.action.teachers.viewPreviousClass(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(PreviousClass);
