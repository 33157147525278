let host = window.location.host;
let hostName = host.split(":");

// console.log('process', process.env);
// const baseUrl = `${window.location.protocol}//${hostName[0]}`;
const videoCallUrl = "https://artiumacademy.com/practice-studio/" // https://artiumacademy.com/practice-studio/543276?activeSectionValue=4&classNumber=8 //if it is batch course instead of classscheduleid need to teacherslotinfoid

const baseUrl = process.env.REACT_APP_ENV==='production' ?
`https://learn.artiumacademy.com`:
process.env.REACT_APP_ENV==='beta'?
`https://learn.artiumacademy.com` :
`https://learn.artiumacademy.com`;
const apiurl = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta') ?
'https://api.artiumacademy.com/config/prod' :
'https://api.artiumacademy.com/config/prod'
const paymenturl = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta')?
 "https://4wl9anlpqk.execute-api.ap-south-1.amazonaws.com":"https://4wl9anlpqk.execute-api.ap-south-1.amazonaws.com"
 const couponurl = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta')?
 "https://2axlv17c7a.execute-api.ap-south-1.amazonaws.com":"https://2axlv17c7a.execute-api.ap-south-1.amazonaws.com"
 const referralurl = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta')?
 "https://wgjv0rt6u2.execute-api.ap-south-1.amazonaws.com":"https://wgjv0rt6u2.execute-api.ap-south-1.amazonaws.com"
 const utilityServiceUrl = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta') ?
 "https://3wu6q5chvi.execute-api.ap-south-1.amazonaws.com" :"https://3wu6q5chvi.execute-api.ap-south-1.amazonaws.com"
 const recordingUrl = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta') ?
 "https://3wu6q5chvi.execute-api.ap-south-1.amazonaws.com" :"https://3wu6q5chvi.execute-api.ap-south-1.amazonaws.com"
 const getMediaUrl = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta') ?
 "https://xiyrds5bja.execute-api.ap-south-1.amazonaws.com" : "https://xiyrds5bja.execute-api.ap-south-1.amazonaws.com"
 const firebaseApiKey = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta') ?
 "AIzaSyBRsKGcRmMTP5-fyBH8xmle6dJRgToC2CE" : "AIzaSyBRsKGcRmMTP5-fyBH8xmle6dJRgToC2CE"
 const firebase_AppId = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta') ?
 "1:998100584324:web:2b0ca370cca8c3485bffd1" : "1:998100584324:web:2b0ca370cca8c3485bffd1"
 const firebaseAuthDomain = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta') ?
 "artium-mobile-app.firebaseapp.com" : "artium-mobile-app.firebaseapp.com"
 const firebaseMeasurementId = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta') ?
 "G-9L050DW2CJ" : "G-9L050DW2CJ"
 const firebaseMessagingSenderId = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta') ?
 "998100584324" : "998100584324"
 const firebaseProjectId = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta') ?
 "artium-mobile-app" : "artium-mobile-app"
 const firebaseStorageBucket = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta') ?
 "artium-mobile-app.appspot.com" : "artium-mobile-app.appspot.com"
 const libraryurl = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta')?
 "https://otq7u6iz95.execute-api.ap-south-1.amazonaws.com":"https://otq7u6iz95.execute-api.ap-south-1.amazonaws.com"
 const retrieveurl = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta')?
 "https://xiyrds5bja.execute-api.ap-south-1.amazonaws.com":"https://xiyrds5bja.execute-api.ap-south-1.amazonaws.com"
 const DemoOpsHistory = (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'beta') ?
 "https://ef2da49rre.execute-api.ap-south-1.amazonaws.com" : "https://ef2da49rre.execute-api.ap-south-1.amazonaws.com"
 
const pathUrl = `/api/admin`

const MODES = {
    DEVELOPMENT: {
        VIDEO_CALL_URL: `${videoCallUrl}`,
        API: {
            BASE_URL: `${baseUrl}`,
            API_URL:`${apiurl}`,
            PAYMENT_URL :`${paymenturl}`,
            LIBRARY_URL : `${libraryurl}`,
           RETRIEVE_URL : `${retrieveurl}`,
            COUPON_URL :`${couponurl}`,
            REFERRAL_URL :`${referralurl}`,
            RECORDING_URL : `${recordingUrl}`,
            COURSE_PRICE_URL:`${utilityServiceUrl}`,
            UTILITY_SERVICE_URL: `${utilityServiceUrl}`,
            RECORDING_URL: `${recordingUrl}`,
            USER_INFO_URL: `${utilityServiceUrl}`,
            GET_STUDENTS_URL: `${baseUrl}`,
            GET_MEDIA_URL: `${getMediaUrl}`,
            FIREBASE_API_KEY: `${firebaseApiKey}`,
            FIREBASE_APPID: `${firebase_AppId}`,
            FIREBASE_AUTHDOMAIN: `${firebaseAuthDomain}`,
            FIREBASE_MEASUREMENTID: `${firebaseMeasurementId}`,
            FIREBASE_MESSAGINGSENDERID: `${firebaseMessagingSenderId}`,
            FIREBASE_PROJECTID: `${firebaseProjectId}`,
            FIREBASE_STORAGEBUCKET: `${firebaseStorageBucket}`,
            DEMOOPS_HISTORY_URL:`${DemoOpsHistory}`

        },
        FACEBOOK: {
           
        },
        GOOGLE: {
            
        },
        GOOGLEMAP: {
            
        },
        
    }
};

const getEnvVariables = ()=>{
    return MODES.DEVELOPMENT;
};

export default {
    ...getEnvVariables()
};