import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { Modal, Button, Form, Input, DatePicker, TimePicker, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import * as xlsx from "xlsx";
import moment from 'moment'
import { useSearchParams, useLocation } from "react-router-dom";
import csv from'csvtojson';

const MultipleSlotsScreen = (props) => {
  const [teacherId] = useSearchParams();
  const [form] = Form.useForm();
  const [file, setFile] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(props.multipleslot);
  const  [slotArray, setSlotArray ] = useState([]);

  useEffect(() => {
    setIsModalVisible(_.get(props, 'multipleslot'))
  }, [_.get(props, 'multipleslot')])
  
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    const formData = new FormData();
    formData.append(
      "file", file
    );
    props.createMultipleSlots({
      body: formData,
       params:{
        teacherId:teacherId.get("id")
       },
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          props.apiCall();
          if(_.isEqual(_.get(data,'TeacherSlot already exist!'),true)) {
            notification.success({
              message: 'Slot already exist.',
          });  
          }else{
            notification.success({
              message: 'Slot added successfully.',
          });  
          }
               
      } else {
        if(_.size(data)>0 && !_.isEqual(_.get(data,'TeacherSlot_Added'),true)) {
          notification.error({
            message: 'Something went wrong',
        }); 
        }else if(_.isEqual(_.get(data,'TeacherSlot already exist!'),true) || 
        _.isEqual(_.get(props,'teachers.multipleSlotsCreate.TeacherSlot already exist!'),true)) {
            notification.success({
              message: 'Slot already exist.',
          });  

          props.apiCall();
          }else{
            notification.success({
              message: 'Slot added successfully.',
          }); 
          
          props.apiCall(); 
          }
          

      }

      }
    })
    setIsModalVisible(false);
    props.setMultipleslot(false)

  };

  const handleCancel = () => {
    setIsModalVisible(false);
    props.setMultipleslot(false)
  };

  const onFileChange = event => {
    setFile(event.target.files[0]);

  };
  const add = async (changedValues, allValues) => {
setSlotArray(prevValues=>[...prevValues,  {"teacherId": teacherId.get("id"),
"slotDate": moment(form.getFieldValue('date')).format('YYYY-MM-DD'),
"startTime": moment(form.getFieldValue('start')).format('HH:mm:ss'),
"EndTime": moment(form.getFieldValue('end')).format('HH:mm:ss')}])
await form.resetFields();
  }
  
  const render = () => {
    if (_.isEqual(_.get(props, 'multipleslot'), true)) {
      return (
        <React.Fragment>
                    <Modal title="Multiple slots" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} 
                    // footer={false}
                    >
           <form>
            <input
              type="file"
              name="upload"
              id="upload"
              accept='.csv'
              onChange={onFileChange}
            />
          </form>  
            </Modal>
        </React.Fragment>
       
        
      )
    }
    else {
      return null
    }

  }
  return render()
}
export default MultipleSlotsScreen;