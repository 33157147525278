import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { connect } from "react-redux";
import {
  Form,
  Input,
  Button,
  Upload,
  message,
  InputNumber,
  Checkbox,  
  Select,
  DatePicker,
  Radio,
  TimePicker,
  notification,
  Modal,
} from "antd";
import _ from "lodash";
import moment from "moment";
import BannerAnim, { Element } from "rc-banner-anim";
import TweenOne from "rc-tween-one";
import "rc-banner-anim/assets/index.css";
import Card from "antd/lib/card/Card";

const BgElement = Element.BgElement;
function WebinarMasterClass(props) {
  console.log('props:', props);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [bannerData, setBannerData] = useState([]);
  const [currentBanner, setCurrentBanner] = useState(null);
  const [updateBannerList,setUpdateBannerList] = useState([])
  const [isEditMode, setIsEditMode] = useState(false);
  const[editBanner,setEditBanner] = useState(false);
  console.log(editBanner,"editBanner")
  useEffect(() => {
    form.resetFields(); 
    form.setFieldsValue(currentBanner); 
  }, [form, currentBanner]);
  const showModal = (banner) => {
    setIsEditMode(!!banner);
    setModalVisible(true);
    setCurrentBanner({ ...banner });
    console.log(banner,"39")
  };

  const handleCancel = () => {
    setModalVisible(false);
    formRef.current.resetFields();
  };
 
  const handleStartTimeChange = (time, timeString) => {
    const selectedDate = moment(startDateTime).format("YYYY-MM-DD");
    const newDateTime = moment(`${selectedDate} ${timeString}`, "YYYY-MM-DD HH:mm:ss A");
    setStartDateTime(newDateTime);
  };
 

  useEffect(() => {
    allBanners();
  }, []);
  const allBanners = () => {
    props.fetchWebinar({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          notification.success({
            message: data,
          });
          setBannerData(data.data);
          setCurrentBanner(data.data); 
          console.log(data.data, "data==");
        } else {
          notification.error({
            message: _.get(data, "body", "Something went wrong."),
          });
        }
      },
    });
  };

 

  const onFinish = (values) => {
    const {
      name,
      userType,
      title,
      type,
      description,
      CTA,
      enabled,
      buttonEnabled,
      role,
      redirectUrl,
      redirectPage,
      redirectScreen,
      redirectTab,
      buttonText,
      buttonRedirectTo,
      buttonRedirectURL,
      buttonRedirectPage,
      buttonRedirectScreen,
      buttonRedirectTab,
      platform,
      bannerImageDesktop,
      bannerImageMobile,
    } = values;
  
    const updatedBanner = {
      ...currentBanner,
      name,
      userType,
      title,
      type,
      description,
      CTA,
      enabled: enabled.toString(),
      buttonEnabled: buttonEnabled === true,
      role,
      platform,
      redirectUrl,
      redirectPage,
      redirectScreen,
      redirectTab,
      button: {
        buttonText,
        buttonRedirectTo,
        buttonRedirectURL,
        buttonRedirectPage,
        buttonRedirectScreen,
        buttonRedirectTab,
      },
      startTimeIST: startDateTime,
      bannerImageDesktop,
      bannerImageMobile,
    };
  
    console.log(values, "values==");
    console.log(editBanner,"000")
    if (editBanner) {
     
      props.updateBanner({
        body: updatedBanner,
        callback: (res, data) => {
          if (_.isEqual(res, true)) {
            notification.success({
              message: _.get(data, "body", "Banner added Successfully."),
            });
            setModalVisible(false);
            setCurrentBanner(updatedBanner);
            allBanners();
            formRef.current.resetFields();
          } else {
            notification.error({
              message: _.get(data, "body", "Something went wrong."),
            });
          }
        },
      });
    } else {
      props.webinarLink({
        body: updatedBanner,
        callback: (res, data) => {
          if (_.isEqual(res, true)) {
            notification.success({
              message: _.get(data, "body", "Banner added Successfully."),
            });
            setModalVisible(false);
            setCurrentBanner(updatedBanner);
            allBanners();
            formRef.current.resetFields();
          } else {
            notification.error({
              message: _.get(data, "body", "Something went wrong."),
            });
          }
        },
      });
    }
  };
  

  
 
  return (
    <div className="webinarmodel">
      <Button onClick={() => {showModal(null);setEditBanner(false)}} style={{ backgroundColor: "skyblue" }}>
        Add
      </Button>
      <div className="">
      
        <Modal
          title="Webinar Details"
          visible={modalVisible}
          onCancel={handleCancel}
          footer={null}
          bodyStyle={{ maxHeight: "80vh", overflow: "auto" }}
          width={"50%"}
        >
          <Form
           initialValues={currentBanner}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            height={"1028px"}
            ref={formRef}
           
          >
            <Form.Item
              label="Name"
              name="name"
              style={{ paddingTop: "15px", marginBottom: "0px" }}
              className="nameTest"
              rules={[
                {
                  required: true,
                  message: "Please Enter the Name!",
                },
              ]}
            >
              <div>
                <Input style={{ width: "53%" }} defaultValue={currentBanner ? currentBanner.name : ''} />
              </div>
            </Form.Item>
            <Form.Item
              label="Event Title"
              name="title"
              style={{ paddingTop: "15px", marginBottom: "0px" }}
              rules={[
                {
                  required: true,
                  message: "Please Enter the Title!",
                },
              ]}
            >
              <div>
                <Input style={{ width: "53%" }} defaultValue={currentBanner ? currentBanner.title : ''}/>
              </div>
            </Form.Item>
            <Form.Item
              label="Type"
              name="type"
              style={{ paddingTop: "15px", marginBottom: "0px" }}
              rules={[
                {
                  required: true,
                  message: "Please Enter the type!",
                },
              ]}
            >
              <div>
                <Input style={{ width: "53%" }} defaultValue={currentBanner ? currentBanner.type : ''}/>
              </div>
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              style={{ paddingTop: "15px", marginBottom: "0px" }}
              rules={[
                {
                  required: true,
                  message: "Please Enter the Description!",
                },
              ]}
            >
              <div>
                {" "}
                <Input.TextArea rows={4} defaultValue={currentBanner ? currentBanner.description : ''}/>
              </div>
            </Form.Item>
            <Form.Item label="Role" name="role">
              <div>
                <Radio.Group defaultValue={currentBanner ? currentBanner.role : ''}>
                  <Radio value="teacher">Teacher</Radio>
                  <Radio value="student">Student</Radio>
                </Radio.Group>
              </div>
            </Form.Item>
            <Form.Item label="Platform" name="platform">
              <div>
                <Radio.Group defaultValue={currentBanner ? currentBanner.platform : ''}>
                  <Radio value="web">WebApp</Radio>
                  <Radio value="app">MobileApp</Radio>
                  <Radio value="all">All</Radio>
                </Radio.Group>
              </div>
            </Form.Item>
            <Form.Item
              name="userType"
              label="User Type"
              initialValue="Teachers"
              style={{ paddingTop: "20px" }}
              rules={[
                {
                  required: true,
                  message: "Please Select the Type!",
                },
              ]}
            >
              <div>
                <Radio.Group defaultValue={currentBanner ? currentBanner.userType : ''}>
                  <Radio value="teacher">Teachers</Radio>
                  <Radio value="paid_student">paid_students</Radio>
                  <Radio value="demo_student">Demo_students</Radio>
                  <Radio value="All_Student">All_Students</Radio>
                  <Radio value="All">All</Radio>
                </Radio.Group>
              </div>
            </Form.Item>

            <Form.Item name="startTimeIST" label="Start Date & Time">
            <DatePicker
              format="YYYY-MM-DD"
              defaultValue={currentBanner ? moment(currentBanner.startTimeIST) : null}
              onChange={(_, dateString) => setStartDateTime(dateString)}
              getPopupContainer={(trigger) => trigger.parentElement}
              style={{ zIndex: 1 }}
            />
            <TimePicker
              format="HH:mm:ss A"
              defaultValue={currentBanner ? moment(currentBanner.startTimeIST) : null}
              onChange={handleStartTimeChange}
              getPopupContainer={(trigger) => trigger.parentElement}
              style={{ zIndex: 1 }}
            />
          </Form.Item>
            
           
            <Form.Item
              name="CTA"
              label="CTA"
              rules={[
                {
                  required: true,
                  message: "Please Select the CTA!",
                },
              ]}
            >
              <Radio.Group defaultValue={currentBanner ? currentBanner.CTA : ''}>
                <Radio value="none">None</Radio>
                <Radio value="url">URL</Radio>
                <Radio value="screen">Screen</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="enabled"
              label="Enabled"
              rules={[
                {
                  required: true,
                  message: "Please Select the Enable!",
                },
              ]}
            >
              <Radio.Group defaultValue={currentBanner ? currentBanner.enabled : ''}>
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="buttonEnabled"
              label="Button Enabled"
              rules={[
                {
                  required: true,
                  message: "Please Select the Button Enable!",
                },
              ]}
            >
              <Radio.Group defaultValue={currentBanner ? currentBanner.buttonEnabled : ''}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="redirectUrl" rules={[]} label="Redirect URL">
              <Input defaultValue={currentBanner ? currentBanner.redirectUrl : ''}/>
            </Form.Item>
            <Form.Item name="redirectPage" rules={[]} label="Redirect Page">
              <Input defaultValue={currentBanner ? currentBanner.redirectPage : ''}/>
            </Form.Item>
            <Form.Item name="redirectScreen" rules={[]} label="Redirect Screen">
              <Input defaultValue={currentBanner ? currentBanner.redirectScreen : ''}/>
            </Form.Item>
            <Form.Item name="redirectTab" rules={[]} label="Redirect Tab">
              <Radio.Group defaultValue={currentBanner ? currentBanner.redirectTab : ''}>
                <Radio value="same">Same</Radio>
                <Radio value="new">New</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="buttonText" rules={[]} label="Button Text">
              <Input defaultValue={currentBanner ? currentBanner.button?.buttonText : ''}/>
            </Form.Item>
            <Form.Item name="buttonRedirectTo" rules={[]} label="Redirect To">
              <Radio.Group defaultValue={currentBanner ? currentBanner.button?.buttonRedirectTo : ''}>
                <Radio value="url">URL</Radio>
                <Radio value="screen">Screen</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="buttonRedirectURL"
              rules={[]}
              label="Button Redirect URL"
            >
              <Input defaultValue={currentBanner ? currentBanner.button?.buttonRedirectURL : ''}/>
            </Form.Item>
            <Form.Item
              name="buttonRedirectPage"
              rules={[]}
              label="Button Redirect Page"
            >
              <Input defaultValue={currentBanner ? currentBanner.button?.buttonRedirectPage : ''}/>
            </Form.Item>
            <Form.Item
              name="buttonRedirectScreen"
              rules={[]}
              label="Button Redirect Screen"
            >
              <Input defaultValue={currentBanner ? currentBanner.button?.buttonRedirectScreen : ''}/>
            </Form.Item>
            <Form.Item
              name="buttonRedirectTab"
              rules={[]}
              label="Button Redirect Tab"
            >
              <Radio.Group defaultValue={currentBanner ? currentBanner.button?.buttonRedirectTab : ''}>
                <Radio value="same">Same</Radio>
                <Radio value="new">New</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="bannerImageDesktop" rules={[]} label="Banner Image Desktop">
              <Input defaultValue={currentBanner ? currentBanner.bannerImageDesktop : ''}/>
            </Form.Item>
            <Form.Item name="bannerImageMobile" rules={[]} label="Banner Image Mobile">
              <Input defaultValue={currentBanner ? currentBanner.bannerImageMobile : ''}/>
            </Form.Item>

            <Button type="primary" htmlType="submit">
          Submit
          </Button>
          </Form>
        </Modal>

        {bannerData.map((item, index) => (
  <div className="banner" key={index}>
    {item.bannerImageDesktop && (
      <img
        src={item.bannerImageDesktop}
        alt="Banner"
        className="banner-image"
      />
    )}
    <div className="banner-content">
      <TweenOne
        className="banner-user-title"
        animation={{ y: 30, opacity: 0, type: "from" }}
      >
        {console.log(item.title)}
        {item.title}
      </TweenOne>
      <TweenOne
        className="banner-user-text"
        animation={{ y: 30, opacity: 0, type: "from", delay: 100 }}
      >
        Date: {moment(item.startTimeIST).format("Do MMMM YYYY")}
      </TweenOne>
      <TweenOne
        className="banner-user-text"
        animation={{ y: 30, opacity: 0, type: "from", delay: 100 }}
        style={{paddingTop:"10px"}}
      >
        UserType: {item.userType}
      </TweenOne>
      <TweenOne
        className="banner-user-text"
        animation={{ y: 30, opacity: 0, type: "from", delay: 100 }}
        style={{paddingTop:"10px"}}
      >
        Platform: {item.platform}
      </TweenOne>
      <div className="banner-button-row">
        <button
          type="primary"
          style={{
            width: "60px",
            borderRadius: "30px",
            backgroundColor: "deeppink",
          }}
          onClick={() => {showModal(item);setEditBanner(true)}}
        >
          Edit
        </button>
        {/* <button type="primary"
          style={{
            width: "60px",
            borderRadius: "30px",
            backgroundColor: "deeppink",
            marginLeft:"5px"
          }}>Delete</button> */}
      </div>
    </div>
  </div>
))}

      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
  students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  webinarLink: (details) =>
    dispatch(global.redux.action.teachers.webinarLink(details)),
    updateBanner: (details) =>
    dispatch(global.redux.action.teachers.updateBanner(details)),
  fetchWebinar: (details) =>
    dispatch(global.redux.action.teachers.fetchWebinar(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebinarMasterClass);